export class Fault {
    zoneId: number;
    zoneName: string;
    componentId: number;
    componentName: string;
    faultId: number;
    faultName: string;
    isNew: boolean;
    cost: number;
    isFavouriteFault: boolean;
    flexAmount: number;
    canFlex: boolean;
    uiId: string;
    vehicleZoneId: number;
    vehicleZoneName: string;
    alreadyLoaded: boolean;
}
