export enum ScreenControlTypes {
  UNKNOWN = 0,
  TEXT_ENTRY = 1,
  ADDRESS_LOOKUP = 2,
  LIST_RADIO = 3,
  BOOL_RADIO = 4,
  INLINE_BOOL_RADIO = 5,
  LIST_DROPDOWN = 6,
  OCR_TEXT_ENTRY = 7,
  DATE_ENTRY = 8,
  INLINE_LIST_RADIO = 9,
  SEARCHABLE_DROPDOWN = 10,
  BANK_DETAILS_LOOKUP_TABLE = 11,
  IMAGE_UPLOAD_QUESTION = 12,
  NUMBER_ENTRY = 13,
  VAT_LOOKUP = 14,
  REQUIRED_TO_BUY = 100,
  VALUATION = 109,
  HIDDEN = 110,
  DAMAGE = 112,
  HPICHECK = 113,
  MILEAGECHECK = 114,
  SERVICE_HISTORY_WARRANTY_CHECK = 115,
  PAYMENT_OPTIONS = 116,
  SERVICE_HISTORY_SERVICE_INTERVAL_CHECK = 117,
  SERVICE_HISTORY_FINAL_STATE = 119,
  DAMAGE_COSTS = 120,
  IMAGE_UPLOAD_PAGE = 121,
  OUTSTANDING_FINANCE = 122,
  CUSTOMER_GUARANTEES = 123,
  VEHICLE_IDENTIFICATION = 124,
  SIGN_INVOICE = 125,
  CONGRATULATIONS = 126,
  APPRAISAL_REPORT = 127,
  SIGN_D2 = 128,
  VIDEO_OF_VEHICLE = 129,
  BANK_VERIFICATION_SERVICE = 130,
  OPTIONAL_EXTRAS = 131,
  
}
