import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PurchaseSummary } from '../purchase/models/purchase-summary.model';
import { PurchaseFilter } from '../purchase/models/purchase-filter.model';
import { PurchaseResult } from '../purchase/models/purchase-result.model';
import { Appointment } from '../appointment-details/models/appointment.model';
import { PaymentMethodOptions } from '../payment-options/models/payment-method-options.model';
import { AnswerValue } from '../purchase/models/answer-value.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private http: HttpClient) { }

  searchPurchases$(filterCriteria: PurchaseFilter): Observable<PurchaseResult> {
    const url = `${environment.ppxPrivateApi}api/Purchase`;
    return this.http.post<PurchaseResult>(url, filterCriteria).pipe(
      catchError(err => throwError(err))
    );
  }

  searchPurchasesByVrm$(vrm: string): Observable<PurchaseResult> {
    const url = `${environment.ppxPrivateApi}api/Purchase/SearchPurchases/${vrm}`;
    return this.http.get<PurchaseResult>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getPurchaseDetails$(quoteStateId: number): Observable<PurchaseSummary> {
    const url = `${environment.ppxPrivateApi}api/Purchase/Summary/${quoteStateId}`;
    return this.http.get<PurchaseSummary>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getAppointmentDetail$(appointmentId: number): Observable<Appointment> {
    const url = `${environment.ppxPrivateApi}api/purchase/appointment/${appointmentId}/0`;
    return this.http.get<Appointment>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getAuthTaskAppointmentDetail$(appointmentId: number, authTypeId: number): Observable<Appointment> {
    const url = `${environment.ppxPrivateApi}api/purchase/appointment/${appointmentId}/${authTypeId}`;
    return this.http.get<Appointment>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  addNote$(quoteStateId: number, text: string): Observable<void> {
    const url = `${environment.ppxPrivateApi}api/purchase/note/${quoteStateId}`;
    return this.http.post<void>(url, `\"${text}\"`, { headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  getPaymentOptions$(quoteStateId: number): Observable<Array<PaymentMethodOptions>> {
    const url = `${environment.ppxPrivateApi}api/Purchase/PaymentOptions/${quoteStateId}`;
    return this.http.get<Array<PaymentMethodOptions>>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getOptionalExtras$(quoteStateId: number, stateName: string): Observable<Array<AnswerValue>> {
    const url = `${environment.ppxPrivateApi}api/Purchase/OptionalExtras/${quoteStateId}/${stateName}`;
    return this.http.get<Array<AnswerValue>>(url).pipe(
      catchError(err => throwError(err))
    );
  }
}
