import { Address } from './address';

export class Target {

  constructor() {
    this.address = new Address();
  }
    name: string;
    vatNumber: string;
    address: Address;
    addressLine: string;
  }

