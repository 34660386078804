<div class="purchase-menu content">
    <div *ngIf="completedStates">
        <div *ngFor="let state of completedStates" class="purchase-menu-item" [ngClass]="{ 'selectable':state.canGoBack }" (click)="rollback(state)">
            <span>{{ state.stateName }}</span>
            <img src="../../assets/icons/purchase/Completed.png" alt="Completed purchases">
        </div>
    </div>
    <div #currentItem *ngIf="currentState" class="purchase-menu-item selected">
        <span>{{ currentState }}</span>
        <img src="../../assets/icons/purchase/CurrentPosition.png" alt="Current state">
    </div>
</div>
