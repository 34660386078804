<div class="modal-header">
    <h4 class="modal-title text-center">Enter Mileage</h4>
    <img class="modal-close" (click)="dismissModal()" alt="Close modal">
</div>
<div class="modal-body walk-in-width">
    <input type="text" style="display:none" ngbAutofocus />
    <div class="container">
        <div class="row">
            <div class="col-12 replace-padding">
                <app-purchase-questions [vrm]="this.vrm" [quoteStateId]="quoteStateId"
                    [requireCustomerName]="false" (dismissModal)="dismissModal()">
                </app-purchase-questions>
            </div>
        </div>
    </div>
</div>