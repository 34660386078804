import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyValuePair } from '../models/key-value-pair.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-modal-input',
  templateUrl: './modal-input.component.html',
  styleUrls: ['./modal-input.component.scss']
})
export class ModalInputComponent {

  @Input() title: string;
  @Input() options: Array<KeyValuePair>;
  @Input() failButton: string;
  @Input() successButton: string;
  close: boolean;

  inputForm = new UntypedFormGroup({
    text: new UntypedFormControl(),
    select: new UntypedFormControl()
  });

  constructor(private modal: NgbActiveModal) { }

  onSelection(event) {
    if (event) {
      const value = event.target.value;
      this.modal.close(value);
    }
  }

  closeModal(): void {
    let answer = '';
    if (this.isMultipleChoice) {
      const value = this.inputForm.get('select').value;
      if (value) {
        answer = this.options.find(o => o.value === value).key;
      } else if (this.options.length > 0) {
        answer = this.options[0].key;
      }
    } else {
      answer = this.inputForm.get('text').value;
    }
    this.modal.close(answer);
  }

  manualEntry(): void {
    this.modal.close('manualEntry');
  }

  dismissModal(): void {
    this.modal.dismiss();
  }

  get isMultipleChoice() {
    return this.options;
  }
}
