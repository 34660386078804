<div class="view-header-container">
  <div class="view-header">
    <div class="view-information-field">
      <div class="row height-100-percent">
        <div class="col">
          <div class="current-time-field">
            <div class="time-header">Current Time</div>
            <div class="time">{{ currentTime | date: "HH:mm" }}</div>
          </div>
        </div>
        <div class="col">
          <div class="week-duration">
            {{ startWeekDateFormated }} - {{ endWeekDate | date: "d MMMM y" }}
          </div>
        </div>
        <div class="col">
          <a class="change-date-btn" (click)="changeDate(scrollTypes.Next)">
            <span class="date-next"></span>
          </a>
          <a class="change-date-btn today" (click)="changeDate(scrollTypes.Current)">
            <span class="nav-button-text">Today</span>
          </a>
          <a class="change-date-btn previous" (click)="changeDate(scrollTypes.Previous)">
            <span class="date-previous"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="time-text">Time</div>
    <div class="days-information-container">
      <div class="d-flex flex-row">
        <div *ngFor="let day of weekDetails" class="day-details">
          <div class="day-column-header {{ day.dayType }}">
            <div class="day-number">{{ day.dayNumber }}</div>
            <div class="week-day-name">{{ day.dayWeekName }}</div>
            <div class="site-name" [ngClass]="{ 'day-off': day.isDayOff }">{{ day.abbreviatedSiteName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="calendar-container">
  <app-time-marker *ngIf="calendarHeight"
                   [dayHours]="dayHours"
                   [dayStartHour]="dayStartHour"
                   [dayEndHour]="dayEndHour"
                   [calendarHeight]="calendarHeight">
  </app-time-marker>

  <div class="d-flex flex-row calendar" #calendar>
    <div class="d-flex flex-column">
      <div *ngFor="let hour of dayHours">
        <div class="calendar-hour-row">
          <div id="hour-row-{{ hour }}" class="hour-label">
            <hr *ngIf="hour !== dayHours[0]" />
            <div>{{ hour }}:00</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let dayDetails of weekDetails" class="appointments-container">
      <div class="calendar-appointments-content">
        <div class="day-column {{ dayDetails.dayType }} {{ dayDetails.dayWeekName }}">
          <app-day-view-appointments [dailyAppointments]="dayDetails.dailyAppointments"
                                     [lunchTopPosition]="dayDetails.lunchTopPosition"
                                     [lunchHeight]="dayDetails.lunchHeight"
                                     [isWeekView]="true"
                                     [lunchSequence]="dayDetails.lunchLeft"
                                     [lunchWidth]="dayDetails.lunchWidth">
          </app-day-view-appointments>
        </div>
      </div>
    </div>
  </div>
</div>
