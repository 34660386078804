<div class="modal-header">
    <h4 *ngIf="!vrmHasBeenCheckedAndDoesNotMatch && !vrmConfirmed" class="modal-title text-center">Confirm VRM</h4>
    <h4 *ngIf="vrmHasBeenCheckedAndDoesNotMatch" class="modal-title text-center">VRM Check</h4>
    <h4 *ngIf="vrmConfirmed" class="modal-title text-center">Vehicle Details Mismatch</h4>
    <img class="modal-close" (click)="dismissModal()" alt="Close modal">
</div>
<div [ngClass]="(!vrmHasBeenCheckedAndDoesNotMatch && !vrmConfirmed) ? 'search-vrm-modal-width' : 'modal-width'"
    class="modal-body">
    <form *ngIf="!vrmHasBeenCheckedAndDoesNotMatch && !vrmConfirmed" [formGroup]="vrmSearchForm" (ngSubmit)="checkVrm()"
        class="search-form">
        <input class="form-control input-text" formControlName="vrm" maxlength="8" />
        <button class="search-button modal-button button_green">Search</button>
    </form>
    <div>
        <div *ngIf="vrmConfirmed">
            <img src="{{ displayImageUrl }}" />
        </div>
        <app-registration-plate *ngIf="vrmHasBeenCheckedAndDoesNotMatch || vrmConfirmed" [isStartPurchase]='true'
            [vrm]='vrmEntered' [isHpiModal]="false"></app-registration-plate>
        <div *ngIf="vrmHasBeenCheckedAndDoesNotMatch">
            <p>
                The VRM entered doesn't match the VRM of the appointment.
            </p>
        </div>
        <div *ngIf="vrmConfirmed">
            <p>
                The VRM entered does not have a relationship to the appointment VRM. Please start a purchase using the VRM on the vehicle. 
            </p>
        </div>
    </div>
    <div *ngIf="vrmHasBeenCheckedAndDoesNotMatch" class="row">
        <div class="col-6">
            <button class="modal-button vrm-correct-btn" (click)="vrmIsCorrect()">
                <span>VRM is correct</span>
            </button>
        </div>
        <div class="col-6">
            <button class="modal-button re-enter-vrm-btn" (click)="reEnterVrm()">
                <span>Re-Enter VRM</span>
            </button>
        </div>
    </div>
    <div *ngIf="vrmConfirmed" class="row">
        <div class="col-4 offset-4">
            <button class="modal-button cancel-btn" (click)="dismissModal()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>