import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { CalendarHelper } from '../helpers/calendar-helper';

@Component({
  selector: 'app-time-marker',
  templateUrl: './time-marker.component.html',
  styleUrls: ['./time-marker.component.scss']
})

export class TimeMarkerComponent implements OnInit, OnDestroy {
  @Input() dayHours: number[];
  @Input() dayStartHour: number;
  @Input() dayEndHour: number;
  @Input() calendarHeight: number;

  @HostBinding('style.--marker-start-position') private markerStartPosition: string;
  @HostBinding('style.--marker-end-position') private markerEndPosition: string;
  @HostBinding('style.--animation-duration') private markerAnimationDuration: string;
  @HostBinding('style.--animation-name') private animationName = 'move-marker';

  currentDate: number;
  currentHour: number;
  currentMinutes: number;
  currentSeconds: number;
  refreshAnimationTime: number;
  displayTimeMarker = false;
  refreshTimeMarkerInterval: NodeJS.Timeout;

  ngOnInit() {
    if (this.isCurrentTimeWithinWorkinHours()) {
      this.setTimeMarker();
    } else {
      this.calculateMarkerRefreshTime();
      this.setMarkerRefreshTime();
      this.toggleOnOffTimeMarker(false);
    }
  }

  ngOnDestroy() {
    clearInterval(this.refreshTimeMarkerInterval);
  }

  private setTimeMarker() {
    this.calculateTimeMarkerPosition();
    this.calculateMarkerRefreshTime();
    this.switchAnimation();
    this.setMarkerRefreshTime();
  }

  private setMarkerRefreshTime() {
    this.refreshTimeMarkerInterval = setInterval(() => {
      this.isCurrentTimeWithinWorkinHours();
      clearInterval(this.refreshTimeMarkerInterval);
      this.setTimeMarker();
    }, this.refreshAnimationTime);
  }

  private calculateTimeMarkerPosition() {
    const currentStartingPosition = CalendarHelper.getCalendarTimePosition(this.currentHour, this.currentMinutes, this.currentSeconds,
      this.dayStartHour, this.dayHours, this.calendarHeight);
    const endPosition = CalendarHelper.getCalendarTimePosition(this.dayEndHour, 0, 0, this.dayStartHour, this.dayHours, this.calendarHeight);
    const browserFontSize = Number(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace('px', ''));
    this.markerStartPosition = (currentStartingPosition / browserFontSize) + 'rem';
    this.markerEndPosition = (endPosition / browserFontSize) + 'rem';
  }

  private calculateMarkerRefreshTime() {
    this.refreshAnimationTime = (3600 * this.dayHours.length - 3600 * this.currentHour - this.currentMinutes * 60 - this.currentSeconds) * 1000;
    this.markerAnimationDuration = this.refreshAnimationTime + 'ms';
  }

  private isCurrentTimeWithinWorkinHours(): boolean {
    this.currentDate = Date.now();
    this.currentHour = moment(this.currentDate).hour();
    this.currentMinutes = moment(this.currentDate).minute();
    this.currentSeconds = moment(this.currentDate).second();

    if (this.currentHour >= this.dayStartHour && this.currentHour < this.dayEndHour) {
      this.toggleOnOffTimeMarker(true);
      return true;
    } else {
      this.toggleOnOffTimeMarker(false);
      return false;
    }
  }

  private toggleOnOffTimeMarker(enable: boolean) {
    this.displayTimeMarker = enable;
  }

  private switchAnimation() {
    // prevents time marker from flickering in certain situations
    this.animationName = this.animationName === 'move-marker' ? 'move-marker-continue' : 'move-marker';
  }
}
