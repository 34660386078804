<div class="container-fluid main-page main-page-padding">
  <div class="page-title">
    <h2 class="page-header">Review Purchases</h2>

    <div ngbDropdown class="dropdown popout">
      <button ngbDropdownToggle id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" class="popout-btn">
        <img src="../../assets/icons/buttons/searchFilters.png" alt='popout-button'>Search Criteria
      </button>
      <div ngbDropdownMenu *ngIf="filters" #filterPanel class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <form class="row">
          <div class="col-12 filter-apply">
            <button class="btn" [ngClass]="filterChanged ? 'changed' : ''" (click)="search($event)">
              Apply
            </button>
          </div>
          <div class="form-group col-4">
            <label for="from">Date From</label>
            <div class="input-group">
              <input type="date" id="from" name="from" class="form-control"
                [ngModel]="filters.dateFrom | date:'yyyy-MM-dd'" (ngModelChange)="filters.dateFrom = $event" (change)="filterChange()">
            </div>
          </div>
          <div class="form-group col-4">
            <label for="to">Date To</label>
            <div class="input-group">
              <input type="date" id="to" name="to" class="form-control" [ngModel]="filters.dateTo | date:'yyyy-MM-dd'"
                (ngModelChange)="filters.dateTo = $event"  (change)="filterChange()">
            </div>
          </div>
          <div class="form-group col-4">
            <label for="vrm">VRM</label>
            <div class="input-group">
              <input type="text" id="vrm" name="vrm" class="form-control" placeholder="VRM" [(ngModel)]="filters.vrm"
                autocomplete="off" autocorrect="off" (ngModelChange)="filterChange()">
            </div>
          </div>
          <div class="form-group col-4">
            <label for="buyer">Buyer</label>
            <div class="input-group">
              <input type="text" id="buyer" name="buyer" class="form-control" placeholder="Buyer"
                [(ngModel)]="filters.username" autocomplete="off" autocorrect="off" (ngModelChange)="filterChange()">
            </div>
          </div>
          <div class="form-group col-4">
            <label for="site">Site</label>
            <div class="input-group">
              <input type="text" id="site" name="site" class="form-control" placeholder="Site" [(ngModel)]="filters.siteName"
                autocomplete="off" autocorrect="off" (ngModelChange)="filterChange()">
            </div>
          </div>
          <div class="form-group col-4">
            <label for="status">Outcome</label>
            <div class="input-group" ngbDropdown>
              <select class="custom-select" id="status" name="status" [(ngModel)]="filters.outcomeDesc" (change)="statusFilterChanged()">
                <option *ngFor="let status of statuses">{{ status.value }}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div *ngIf="loading" class="text-center">
      <app-loading-wbac-animation></app-loading-wbac-animation>
    </div>

    <p *ngIf="initLoading" class="mt-3">Please enter search criteria to view the results.</p>
    <div *ngIf="purchases">
      <p *ngIf="purchases.length == 0" class="mt-3">No purchases found</p>
      
      <div *ngIf="purchases.length > 0">
        <table class="purchase-table" aria-label="purchases">
          <thead>
            <tr>
              <th class="purchase-cell purchase-cell-left">Purchase ID</th>
              <th class="purchase-cell">Quote ID</th>
              <th class="purchase-cell">VRM</th>
              <th class="purchase-cell">Buyer</th>
              <th class="purchase-cell">Site</th>
              <th class="purchase-cell">Outcome</th>
              <th class="purchase-cell">Appointment Date</th>
              <th class="purchase-cell purchase-cell-right">Purchase Date</th>
              <th class="button-cell"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="data-row" *ngFor="let purchase of purchases">
              <td class="purchase-cell purchase-cell-left" (click)="viewData(purchase)">{{ purchase.quoteStateId }}</td>
              <td class="purchase-cell" (click)="viewData(purchase)">{{ purchase.quoteId }}</td>
              <td class="purchase-cell" (click)="viewData(purchase)">{{ purchase.vrm }}</td>
              <td class="purchase-cell" (click)="viewData(purchase)">{{ purchase.username }}</td>
              <td class="purchase-cell" (click)="viewData(purchase)">{{ purchase.siteName }}</td>
              <td class="purchase-cell" (click)="viewData(purchase)">{{ purchase.outcome }}</td>
              <td class="purchase-cell" (click)="viewData(purchase)">{{ purchase.appointmentDate | date: 'dd/MM/yyyy' }}<br>{{ purchase.appointmentDate | date: 'HH:mm' }}</td>
              <td class="purchase-cell purchase-cell-right" (click)="viewData(purchase)">{{ purchase.purchaseDate | date: 'dd/MM/yyyy' }}<br>{{ purchase.purchaseDate | date: 'HH:mm' }}</td>
              <td class="button-cell">
                <button class="btn migrate-button" *ngIf="canMigratePurchases && purchase.isPurchased" [ngClass]="migrationClass(purchase.quoteStateId)"
                  (click)="migrate(purchase.quoteStateId)">
                  <span class="migrate-button-icon" [ngClass]="migrationClass(purchase.quoteStateId)"></span>
                  Migrate
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pager-container">
          <app-pager [pageCount]="pageCount"></app-pager>
        </div>
      </div>
    </div>
  </div>
</div>
