import { Fault } from '../../damage/models/fault.model';

export class QuickValuationLookupData {
  quoteId: number;
  quoteGuid: string;
  vrm: string;
  mileage: number;
  fault: Fault;
  isManualLookup: boolean;
  capId: number;

  constructor(quoteId, quoteGuid, vrm, mileage, fault, isManualLookup, capId) {
    this.quoteId = quoteId;
    this.quoteGuid = quoteGuid;
    this.vrm = vrm;
    this.mileage = mileage;
    this.fault = fault;
    this.isManualLookup = isManualLookup;
    this.capId = capId;
  }
}
