import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { CustomValidators } from '../custom-validators/custom-validators';

@Component({
  selector: 'app-postcode-lookup',
  templateUrl: './postcode-lookup.component.html',
  styleUrls: ['./postcode-lookup.component.scss']
})

export class PostcodeLookupComponent implements OnInit, OnDestroy {
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() newPostcode = new EventEmitter<string>();

  postcodeLookupForm: UntypedFormGroup;
  postcode: string;
  loading: boolean;
  destroy$ = new Subject();
  postcodeField: UntypedFormControl;

  constructor(private confirmDialogService: ConfirmDialogService, private modalService: NgbModal, private activeModal: NgbActiveModal, private formBuilder: UntypedFormBuilder) { }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.postcodeLookupForm = this.formBuilder.group({
      postcodeField: ['', { validators: [Validators.required, Validators.maxLength(8)], asyncValidators: [CustomValidators.validateRegex(CustomValidators.postcodeRegex)] }]
    });
  }

  search() {
    this.loading = true;
    this.postcode = this.postcodeLookupForm.get('postcodeField').value;
    this.newPostcode.emit(this.postcode);
  }

  dismissModal() {
    this.confirmDialogService.confirmCancellation()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.modalService.dismissAll();
      });
  }

  onBackClick() {
    this.closeModal.emit(true);
    this.activeModal.close();
  }
}
