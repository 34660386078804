import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ValuationSummary } from '../damage/models/valuation-summary.model';
import { QuickValuationLookupData } from '../quick-quote/Models/quick-valuation-lookup-data.model';
import { WalkInLookupData } from '../walk-in/models/walk-in-lookup-data.model';
import { WalkInResult } from '../walk-in/models/walk-in-result.model';
import { Upjar } from '../upjar/models/upjar.model';
import { UpjarPurchaseDetail } from '../upjar/models/upjar-purchase-detail.model';
import { PreviousValuationHistory } from '../walk-in/models/previous-valuations-history.model';

@Injectable({
  providedIn: 'root'
})
export class ValuationService {
  constructor(
    private http: HttpClient
  ) { }

  getPreviousValuations$(vrm: string): Observable<PreviousValuationHistory> {
    return this.http.get<PreviousValuationHistory>(`${environment.ppxPrivateApi}api/valuation/GetPreviousValuations/?vrm=${vrm}`).pipe(
      catchError(err => throwError(err))
    );
  }

  getValuation$(quoteStateId: number): Observable<ValuationSummary> {
    return this.http.get<ValuationSummary>(`${environment.ppxPrivateApi}api/valuation/${quoteStateId}/`).pipe(
      catchError(err => throwError(err))
    );
  }

  performValuation$(quoteStateId: number, isAppraisalFinalised: boolean): Observable<ValuationSummary> {
    const body = new HttpParams()
      .set('quoteStateId', quoteStateId.toString())
      .set('isAppraisalFinalised', isAppraisalFinalised.toString());

    return this.http.post<ValuationSummary>(`${environment.ppxPrivateApi}api/Valuation/PerformValuation/`, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  performQuickValuation$(quickValuationLookupData: QuickValuationLookupData, saveValuation: boolean): Observable<ValuationSummary> {
    return this.http.post<ValuationSummary>(`${environment.ppxPrivateApi}api/Valuation/PerformQuickValuation/?saveValuation=${saveValuation}`, quickValuationLookupData)
      .pipe(
        catchError(err => throwError(err))
      );
  }

  startWalkinValuation$(walkInLookupData: WalkInLookupData): Observable<WalkInResult> {
    return this.http.post<WalkInResult>(`${environment.ppxPrivateApi}api/Valuation/Walkin`, walkInLookupData)
      .pipe(
        catchError(err => throwError(err))
      );
  }

  startPreviousValuationWalkin$(walkInLookupData: WalkInLookupData): Observable<WalkInResult> {
    return this.http.post<WalkInResult>(`${environment.ppxPrivateApi}api/Valuation/Walkin`, walkInLookupData)
      .pipe(
        catchError(err => throwError(err))
      );
  }

  performPreBookingValuation$(walkInLookupData: WalkInLookupData): Observable<ValuationSummary> {
    return this.http.post<ValuationSummary>(`${environment.ppxPrivateApi}api/Valuation/PerformPreBookingValuation`, walkInLookupData)
      .pipe(
        catchError(err => throwError(err))
      );
  }

  requiresRevaluation$(quoteStateId: number): Observable<ValuationSummary> {
    return this.http.get<ValuationSummary>(`${environment.ppxPrivateApi}api/Valuation/RequiresRevaluation/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  getUpjar$(vrm: string): Observable<UpjarPurchaseDetail> {
    const url = `${environment.ppxPrivateApi}api/Valuation/upjar/${vrm}`;
    return this.http.get<UpjarPurchaseDetail>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  saveUpjar$(upjar: Upjar): Observable<void> {
    const url = `${environment.ppxPrivateApi}api/Valuation/upjar`;
    return this.http.post<void>(url, upjar).pipe(
      catchError(err => throwError(err))
    );
  }
}
