import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagerService {

  private pageNumber = new BehaviorSubject(1);

  setPageNumber(value: number) {
    this.pageNumber.next(value);
  }

  getPageNumber() {
    return this.pageNumber.asObservable();
  }
}
