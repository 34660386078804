import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Question } from './models/quiz-of-the-day.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { QuizQuestionMark } from '../enums/quiz-question-mark.enum';

@Component({
  selector: 'app-quiz-question',
  templateUrl: './quiz-question.component.html',
  styleUrls: ['./quiz.component.scss']
})

export class QuizQuestionComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() question: Question;
  @Input() mark: QuizQuestionMark;
  @Output() change = new EventEmitter<UntypedFormGroup>();

  identifier: string;
  disabled = false;
  value: number;

  constructor(public fb: UntypedFormBuilder) {

  }

  ngOnInit() {
    this.mark = QuizQuestionMark.NotMarked;
  }

  valueChanged() {
    this.change.emit(this.form);
  }

  isCorrect(): boolean {
    return this.mark === QuizQuestionMark.Correct;
  }

  isIncorrect(): boolean {
    return this.mark === QuizQuestionMark.Incorrect;
  }
}
