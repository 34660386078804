import { ValuationSummary } from '../../damage/models/valuation-summary.model';
import { AnswerValue } from '../../purchase/models/answer-value.model';
import { Upjar } from './upjar.model';

export class UpjarPurchaseDetail {
    quoteStateId: number;
    vehicleCheckId: number;
    appointmentId: number;
    customerName: string;
    customerPhone: string;
    customerEmail: string;
    postcode: string;
    source: string;
    vrm: string;
    make: string;
    model: string;
    derivative: string;
    colour: string;
    mileage: string;
    engineSize: number;
    bookId: number;
    capId: number;
    capCode: string;
    appointmentTime: Date;
    branch: string;
    assignedTo: string;
    valuation: ValuationSummary;
    upjar: Upjar;
    answers: Array<AnswerValue>;
    vehicleAge: string;
    vin: string;
    isVehiclePurchased: boolean;
    hpiKeepers: number;
}
