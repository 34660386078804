<div style="width: 100%;">
    <table class="table table-bordered">
        <thead class="">
            <tr>
              <th class="table-value header">Optional Extra Item Name</th>
              <th class="table-value header">Customer's Answer</th>
              <th class="table-value header">Buyer's Answer</th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let optionalExtra of optionalExtras">
              <td class="name-value">{{ optionalExtra.parameterName }}</td>
              <td class="customer-value">{{ optionalExtra.displayValue }}</td>
              <td class="buyer-value">{{ optionalExtra.value }}</td>
            </tr>
          </tbody>
    </table>
</div>