import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-wbac-animation',
  templateUrl: './loading-wbac-animation.component.html',
  styleUrls: ['./loading-wbac-animation.component.scss']
})
export class LoadingWbacAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
