import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { EMPTY } from 'rxjs';
import { tap, catchError, take } from 'rxjs/operators';
import { BaseComponentDirective } from '../base/base.component';
import { ValuationSummary } from '../damage/models/valuation-summary.model';
import { ValuationService } from '../services/valuation.service';
import { environment } from '../../environments/environment';
import { PathFinderService } from '../services/path-finder.service';
import { LoadingService } from '../services/loading.service';
@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss'],
})
export class ValuationComponent extends BaseComponentDirective implements OnInit {

  @Input() group: UntypedFormGroup;
  @Input() quoteStateId: number;
  @Input() isAppraisalFinalised: boolean;

  loading: boolean;
  valuation: ValuationSummary;
  hasDamageCost: boolean;
  paymentUrl: string = environment.paymentUrl;

  constructor(private valuationService: ValuationService,
    private notifications: NotificationService,
    private loadingService: LoadingService,
    private pathFinder: PathFinderService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.pathFinder.registerTransitionEvent(onComplete => {
      this.valuationService.requiresRevaluation$(this.quoteStateId).pipe(
        tap(result => {
          if (result && result.hasUpjar) {
            this.valuation = result;
            onComplete(false);
          } else {
            onComplete(true);
          }
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to confirm offer', err);
          onComplete(false);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    });

    if (this.quoteStateId) {
      this.group.controls.value.setValue(null);
      this.valuationService.performValuation$(this.quoteStateId, this.isAppraisalFinalised).pipe(
        tap(result => {
          this.valuation = result;
          this.hasDamageCost = result.totalDamageCost > 0;
          if (this.valuation.customerPaymentAmount >= 0) {
            this.group.controls.value.setValue(false);
          }
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
        }),
        catchError(err => {
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
          this.notifications.dangerToast('Failed to do a valuation', err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  takePayment() {
    this.group.controls.value.setValue(true);
  }

  get hasVat(): boolean {
    return this.valuation.vat > 0;
  }
}
