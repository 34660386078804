import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { VehicleCheckService } from '../services/vehicle-check.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HpiCondition } from './models/hpi-condition.model';
import { VehicleCheck } from './models/vehicle-check.model';
import { HpiPlate } from './models/hpi-plate.model';
import { Observable, EMPTY, fromEvent, Subject } from 'rxjs';
import { catchError, tap, takeUntil, throttleTime } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { FinanceAgreementService } from '../services/finance-agreement.service';
import { BaseComponentDirective } from '../base/base.component';
import { HpiFinance } from './models/hpi-finance.model';
import { VehicleDetails } from '../models/vehicle-details';
import { HpiStolen } from './models/hpi-stolen.model';
import { TheftMarkerTypes } from '../enums/theft-marker-types';
import { PathFinderService } from '../services/path-finder.service';
import { LoadingService } from '../services/loading.service';
import { KeyValuePair } from '../models/key-value-pair.model';

@Component({
  selector: 'app-vehicle-check',
  templateUrl: './vehicle-check-hpi.component.html',
  styleUrls: ['./vehicle-check-hpi.component.scss'],
})
export class VehicleCheckComponent extends BaseComponentDirective implements OnInit, AfterViewInit {
  @Input() quoteStateId: number;
  @Input() vrm: string;
  @Input() vehicleDetails: VehicleDetails;
  @Input() mileage: number;
  @Input() authTask: boolean;
  @Input() group: UntypedFormGroup;
  @Input() questionForm: UntypedFormGroup;
  @Input() writeOffOptions: KeyValuePair[];
  @Output() change = new EventEmitter<UntypedFormGroup>();

  @ViewChild('tryAgainButton', { read: ElementRef, static: true }) tryAgainButton: ElementRef;
  isImported: number;

  // Never, VCAR, D, N, T/LOSS, C, S, B, A, SCRAPPED
  catPriority = ['5', '4', '3', '9', '10', '2', '8', '1', '0', '11'];
  constructor(
    private vehicleCheckService: VehicleCheckService,
    private financeAgreementService: FinanceAgreementService,
    private pathFinder: PathFinderService,
    private loadingService: LoadingService,
    private notifications: NotificationService
  ) {
    super();
  }

  plates = new Array<HpiPlate>();
  // Damage and finance records against plate
  // If theft or stolen records, then show against all plates
  inspectionRecords = new Array<HpiCondition>();
  theftRecords = new Array<HpiCondition>();
  damageRecords = new Array<HpiCondition>();
  financeRecords = new Array<HpiFinance>();
  stolenRecords = new Array<HpiStolen>();
  outstandingFinance = false;
  hasSalvage: boolean;
  vehicleCheck: VehicleCheck = null;
  errorMessage: string;
  showTryAgainButton = false;
  loadingError$ = new Subject<boolean>();
  vehicleCheck$: Observable<VehicleCheck>;
  vehicleDetailsCollapsed = true;
  purchaseCodeDetailsCollapsed = true;
  damageRecordsCollapsed = false;
  inspectionRecordsCollapsed = true;
  currentPlate: HpiPlate;
  currentPlateIndex = 0;
  preFilledWriteOffValue: null;
  theftMarker: UntypedFormGroup;
  importMarker: UntypedFormGroup;
  importQuestion: UntypedFormGroup;
  continue: UntypedFormGroup;
  writeOffOverride: UntypedFormGroup;

  lastColour = {
    ChangeDate: new Date(),
    Colour: '',
  };

  ngOnInit(): void {
    this.loadingService.loading.emit(true);
    this.createFormControls();
    this.createHpiLookupObservable();
  }

  ngAfterViewInit() {
    fromEvent(this.tryAgainButton.nativeElement, 'click')
      .pipe(throttleTime(5000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.retryButtonPress.next(null);
        this.createHpiLookupObservable();
      });

    if (this.continue) {
      this.change.emit(this.continue);
    }
  }

  createHpiLookupObservable() {
    this.vehicleCheck$ = this.vehicleCheckService.getVehicleCheck$(this.vrm, this.quoteStateId, false).pipe(
      tap((result) => {
        this.showTryAgainButton = false;
        this.vehicleCheck = result;
        this.setLastColour();
        this.setLastChangeDate();
        this.setPlates();
        this.setImported();
        this.updateOverride();
        this.setVehicleDetails();
        this.hasSalvage = result.hasSalvage;

        if (this.plates) {
          this.currentPlateIndex = this.plates.length - 1;
          this.currentPlate = this.plates[this.currentPlateIndex];
          this.setRecords();
        }
        if (this.theftMarker && this.theftMarker.get('value').value !== TheftMarkerTypes.StolenOrVin) {
          if (this.continue) {
            this.continue.get('value').setValue(true);
          }
        }

        if (this.group) {

          this.preFilledWriteOffValue = this.group.value.value;

          // Check if website answer and HPI worse
          const inputAnswer = this.pathFinder.getAnswers().find((a) => a.parameterName === this.group.value.name && !a.isAppointmentValue);
          if (!inputAnswer) {
            const appointmentAnswer = this.pathFinder.getAnswers().find((a) => a.parameterName === this.group.value.name && a.isAppointmentValue);
            if (appointmentAnswer) {
              const hpiPriority = this.catPriority.indexOf(this.group.value.informedAnswer);
              const appointmentPriority = this.catPriority.indexOf(appointmentAnswer.value);
              if (appointmentPriority < hpiPriority) {
                this.group.get('value').setValue(this.group.value.informedAnswer);
                this.updateOverride();
              }
            }
          }

        }
        this.loadingService.loading.emit(false);
      }),
      catchError((err) => {
        this.loadingError$.next(true);
        this.loadingService.loading.emit(false);
        this.showTryAgainButton = true;
        this.notifications.dangerToast('There was a problem retrieving HPI data, please try again.', err);
        return EMPTY;
      }),
      takeUntil(this.retryButtonPress)
    );
  }

  setRecords(): void {
    this.outstandingFinance = false;
    this.financeRecords.length = 0;
    this.damageRecords.length = 0;
    this.inspectionRecords.length = 0;
    this.theftRecords.length = 0;
    this.stolenRecords.length = 0;
    this.financeRecords.length = 0;

    // Damage flag isn't set by getVehicleCheck call, so need to
    // set from condition records
    this.currentPlate.damage = false;

    let isTheftForVinOrStolen = false;

    this.plates.forEach((plate) => {
      if (plate === this.currentPlate && plate.finance) {
        this.outstandingFinance = true;
        for (const financeRecord of plate.financeRecords) {
          this.financeRecords.push(financeRecord);
        }
      }

      if (plate.conditionRecords) {
        plate.conditionRecords.forEach((record) => {
          if (plate === this.currentPlate && record.inspection) {
            this.inspectionRecords.push(record);
          }

          if (record.theft) {
            if (this.recordedAgainstVin(record)) {
              isTheftForVinOrStolen = true;
            }
            this.theftRecords.push(record);
          }

          if (plate === this.currentPlate && record.damage) {
            this.currentPlate.damage = true;
            this.damageRecords.push(record);
          }
        });
      }

      if (plate.stolenRecords) {
        plate.stolenRecords.forEach((record) => {
          isTheftForVinOrStolen = true;
          this.stolenRecords.push(record);
        });
      }
    });
    if (this.outstandingFinance) {
      this.financeRecords = this.financeAgreementService.removeFinanceAgreementDuplicates(this.financeRecords);
    }

    // Work out stolen/theft marker type
    if (this.theftMarker) {
      let theftMarker: TheftMarkerTypes;
      if (isTheftForVinOrStolen) {
        theftMarker = TheftMarkerTypes.StolenOrVin;
      } else if (this.theftRecords.length > 0) {
        theftMarker = TheftMarkerTypes.Vrm;
      } else if (this.currentPlate.securityWatch) {
        theftMarker = TheftMarkerTypes.SecurityWatch;
      } else {
        theftMarker = TheftMarkerTypes.None;
      }

      this.theftMarker.get('value').setValue(theftMarker);
    }
  }

  recordedAgainstVin(record: HpiCondition): boolean {
    if (record.recordedAgainst) {
      return record.recordedAgainst.toUpperCase().includes('VIN');
    }
    return false;
  }

  setImported(): void {
    if (this.vehicleCheck.vehicles[0]) {
      this.isImported = this.vehicleCheck.vehicles[0].imported ? 1 : 0;
      if (this.group) {
        this.importMarker.get('value').setValue(this.isImported.toString());
        this.change.emit(this.importMarker);
        this.importQuestion.get('informedAnswer').setValue(this.isImported.toString());
        this.importQuestion.controls.isHidden.setValue(false);
        const inputAnswer = this.pathFinder.getAnswers().find((a) => a.parameterName === this.importQuestion.value.name && !a.isAppointmentValue);
        if (!inputAnswer) {
          this.importQuestion.get('value').setValue(this.isImported.toString());
        }
        this.importQuestion.updateValueAndValidity();
        this.change.emit(this.importQuestion);
      }
    }
  }

  setPlates(): void {
    if (!this.vehicleCheck.vehicles[0].plate) {
      this.plates = null;
      return;
    }
    this.plates = this.vehicleCheck.vehicles[0].plate.sort(
      (a, b) => new Date(a.plateDate).getTime() - new Date(b.plateDate).getTime()
    );
  }

  setLastColour(): void {
    if (!this.vehicleCheck.vehicles[0].colourChanges.length) {
      this.lastColour.Colour = null;
      this.lastColour.ChangeDate = null;
      return;
    }

    const colour = this.vehicleCheck.vehicles[0].colourChanges.sort(
      (a, b) => new Date(b.colourDate).getTime() - new Date(a.colourDate).getTime()
    )[0];
    this.lastColour.Colour = colour.vehicleColour;
    this.lastColour.ChangeDate = colour.colourDate;
  }

  setLastChangeDate(): void {
    if (this.vehicleCheck.vehicles[0].numberOfPreviousKeepers === 0) {
      this.vehicleCheck.vehicles[0].lastChangeOfKeeperDate = null;
    }
  }

  get plateHasMarkers(): boolean {
    return (
      this.outstandingFinance ||
      (this.damageRecords && this.damageRecords.length > 0) ||
      (this.inspectionRecords && this.inspectionRecords.length > 0) ||
      this.hasPurchaseCode || this.hasSalvage
    );
  }

  get hasPurchaseCode(): boolean {
    return (
      (this.stolenRecords && this.stolenRecords.length > 0) ||
      (this.theftRecords && this.theftRecords.length > 0) ||
      this.vehicleCheck.vehicles[0].imported === true ||
      this.vehicleCheck.vehicles[0].niRegistered === true
    );
  }

  nextPlate() {
    this.currentPlateIndex++;
    this.currentPlate = this.plates[this.currentPlateIndex];
    this.setRecords();
  }

  previousPlate() {
    this.currentPlateIndex--;
    this.currentPlate = this.plates[this.currentPlateIndex];
    this.setRecords();
  }

  createFormControls() {
    if (this.questionForm) {
      const controlArray = this.questionForm.get('items') as UntypedFormArray;
      this.continue = controlArray.controls[1] as UntypedFormGroup;
      this.theftMarker = controlArray.controls[2] as UntypedFormGroup;
      this.writeOffOverride = controlArray.controls[3] as UntypedFormGroup;
      this.importMarker = controlArray.controls[4] as UntypedFormGroup;
      this.importQuestion = controlArray.controls[5] as UntypedFormGroup;
    }
  }

  addValidator() {
    const canContinue = this.continue.controls.value as UntypedFormControl;
    canContinue.addValidators(Validators.requiredTrue);
  }

  valueChanged(group: UntypedFormGroup) {
    this.updateOverride();
    this.change.emit(group);
  }

  updateOverride() {
    if (this.writeOffOverride) {
      const hpiPriority = this.catPriority.indexOf(this.group.value.informedAnswer);
      const selectedPriority = this.catPriority.indexOf(this.group.value.value.toString());

      if (selectedPriority < hpiPriority) {
        this.writeOffOverride.get('value').setValue('true');
      } else {
        this.writeOffOverride.get('value').setValue('false');
      }
    }
  }

  isPrefilledAnswer(value) {
    return this.preFilledWriteOffValue && this.preFilledWriteOffValue === value.toString();
  }

  isInformedAnswer(value) {
    return this.group.value.informedAnswer && this.group.value.informedAnswer === value.toString();
  }

  getOptionDescription(value: string): string {
    const parts = value.split(' - ');
    if (parts.length > 1) {
      return parts[1].replace(', ', ',<br>');
    } else {
      return parts[0].replace(', ', ',<br>');
    }
  }

  getOptionIcon(value: string): string {
    const parts = value.split(' ');
    const first = parts[0].replace('/', '-');
    return '../../assets/icons/write-off/write-off-' + first + '.png';
  }

  setVehicleDetails() {
    if (this.authTask || this.isAnswerTrue('IsManualLookup') || this.isAnswerTrue('IsLookupFailure')) {
      const vehicle = this.vehicleCheck.vehicles[0];
      this.vehicleDetails = new VehicleDetails(vehicle.manufacturer, vehicle.colour, vehicle.model,
        vehicle.derivative, vehicle.engineSize, null, null, null, vehicle.fuel, false, vehicle.ukDateOfFirstRegistration, null);
    }
  }

  isAnswerTrue(param: string) {
    return this.pathFinder.getAnswerValueForStringComparison(param, null) === 'true';
  }
}
