export class FinanceAgreement {
  financeCompanyName: string;
  agreementNumber: string;
  agreementTypeCode: string;
  expiryDate: Date;
  amount: number;
  isNewLine: boolean;
  indexNo: number;
  financeAgreementId: number;
  isHpiFinance: boolean;
}
