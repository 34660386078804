<div *ngFor="let record of records">
  <div class="grid">
    <div class="child header">Date</div>
    <div class="child header">Description</div>
    <div class="child header">Recorded Against</div>
    <div class="child">{{ record.recordedDate | date }}</div>
    <div class="child">{{ record.vehicleDescription }}</div>
    <div class="child">{{ record.recordedAgainst }}</div>
    <div class="child header" style="grid-column: 1 / span 3;">Recorded on VCAR</div>
    <div class="child" style="grid-column: 1 / span 3;">{{ record.incidentDescription }}</div>
  </div>
</div>
