import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-marker',
  templateUrl: './vehicle-marker.component.html',
  styles: [':host { display: contents; }'],
  styleUrls: ['./vehicle-marker.component.scss']
})
export class VehicleMarkerComponent {
  @Input() label: string;
  @Input() value: string;
}

