import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { DamageService } from './services/damage.service';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponentDirective } from './base/base.component';
import { takeUntil, catchError, filter } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NotificationService } from './services/notification.service';
import { environment } from '../environments/environment';
import { PathFinderService } from './services/path-finder.service';
import { ViewportService } from './services/viewport.service';
import { NavigationHistoryService } from './core/navigation-history.service';
import { SignalRService } from './services/signal-r.service';
import { HubConnection } from '@microsoft/signalr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponentDirective implements OnInit, OnDestroy {
  showMenu = true;
  version: string = environment.version;
  isIframe = false;
  isDisplayingVersionNumber: boolean;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    damageService: DamageService,
    private pathFinder: PathFinderService,
    private router: Router,
    private notifications: NotificationService,
    private viewportService: ViewportService,
    private navigationHistory: NavigationHistoryService,
    public signalRService: SignalRService) {
    super();

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        this.showMenu = !this.isInPurchase();
        this.viewportService.setStickyContainersVisibleState(true);
        this.checkLocalStorage();
      }
    });

    if (this.authService.instance.getAllAccounts().length !== 0) {

      // Don't care about the data, this is simulate precaching by getting it on app load. Service worker will add to cache
      damageService.getDamageMatrix$().pipe(
        catchError(err => {
          this.notifications.dangerToast('Failed to get damage matrix', err);
          return EMPTY;
        }),
        takeUntil(this.componentDestroyed)
      ).subscribe();

      damageService.getVehicleTypes$().pipe(
        catchError(err => {
          this.notifications.dangerToast('Failed to get vehicle types', err);
          return EMPTY;
        }),
        takeUntil(this.componentDestroyed)
      ).subscribe();

      this.navigationHistory.setId(null);
    }
  }

  private hubConnection: HubConnection;
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(() => {
        // don't need at the moment but here if needed
      });

    this.signalRService.connectToSignalR().then(connection => {
      this.hubConnection = connection;
      }).catch(error => {
      console.error('Error while connecting to SignalR: ' + error);
    });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
  }

  checkLocalStorage() {
    if (localStorage.getItem('PurchaseJourney') === null && this.authService.instance.getAllAccounts().length !== 0) {
        this.pathFinder.updatePurchaseJourneyInLocalStorage(null);
    }
  }

  canAccessUnauthenticated(): boolean {
    const page = this.getCurrentPage();
    const routeConfig = this.router.config.find(function (element) {
      return element.path.split('/')[0] === page;
    });
    if (routeConfig && !routeConfig.canActivate) {
      return true;
    }
    return false;
  }

  getCurrentPage() {
    return window.location.pathname.substring(1).split('/')[0];
  }

  isInPurchase(): boolean {
    const page = this.getCurrentPage().toLowerCase();
    return page === 'purchase' || page === 'postpurchase';
  }
}
