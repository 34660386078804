<div *ngIf="complete$ | async">
  <form [formGroup]="group" class="questionsForm mt-2 mb-4">
    <div class="d-flex gap">
      <div>
        <div class="bold-label">Entered Mileage:</div>
        <app-number-input [group]="mileageControl" isInline="-1"></app-number-input>
      </div>
      <div>
        <div class="bold-label">Mileage Decision:</div>
        <app-list-input [group]="mileageDecisionControl" [options]="allowedDecisionOptions" 
          customClass="small-two-line" alert="true" isInline="-1" class="popup-button">
        </app-list-input>
      </div>
    </div>
  </form>
  
  <div class="d-flex flex-row">
    <app-mot-history [provider]="'dvsa'" [mileageData]="dvsaMileage" [hasDiscrepancy]="hasDvsaDiscrepancy" [showMotHistory]="showDvsaHistory"></app-mot-history>
    <app-mot-history [provider]="'nmr'" [mileageData]="nmrMileage" [hasDiscrepancy]="hasNmrDiscrepancy" [showMotHistory]="showNmrHistory"></app-mot-history>
    <app-mot-history [provider]="'bca'" [mileageData]="bcaMileage" [hasDiscrepancy]="hasBcaDiscrepancy" [showMotHistory]="showBcaHistory"></app-mot-history>
  </div>
  <div style="margin-top: 1rem;" *ngIf="possiblyNIPlate">
    This vehicle may have been registered in Northern Ireland and as a result the full mileage history may not be displayed. Please check the mileage history <a style="text-decoration: underline" href="{{ motUrl }}" target="_blank">here</a>.
  </div>
</div>
