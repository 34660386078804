import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salvaged-records',
  templateUrl: './salvaged-records.component.html',
  styleUrls: ['./salvaged-records.component.scss']
})
export class SalvagedRecordsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
