export class FlexAuth {
    priceBeforeFlex: number;
    priceAfterFlex: number;
    damageCost: number;
    flexAmount: number;

    constructor(priceBeforeFlex: number, priceAfterFlex: number, damageCost: number, flexAmount: number) {
        this.priceBeforeFlex = priceBeforeFlex;
        this.priceAfterFlex = priceAfterFlex;
        this.damageCost = damageCost;
        this.flexAmount = flexAmount;
    }
}
