import { Component, Input } from '@angular/core';
import { HpiPlate } from '../models/hpi-plate.model';
import { HpiVehicle } from '../models/hpi-vehicle.model';

@Component({
  selector: 'app-plate-details',
  templateUrl: './plate-details.component.html',
  styleUrls: ['./plate-details.component.scss']
})
export class PlateDetailsComponent {

  @Input() vehicle:  HpiVehicle;
  @Input() plate:  HpiPlate;

  booleanIcon(value: boolean) {
    if (value) {
      return '../../assets/icons/boolean/booleanTrue.png';
    } else {
      return '../../assets/icons/boolean/booleanFalse.png';
    }
  }
}
