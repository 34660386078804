<div class="modal-input">
  <div class="modal-header">
    <h4 class="modal-title text-center">{{ title }}</h4>
    <img class="modal-close x-icon-white" (click)="dismissModal()" alt='x-icon-white' *ngIf="!close">
  </div>
  <div class="modal-body obfuscated-playback" [formGroup]="inputForm">
    <div *ngIf="isMultipleChoice">
      <div class="selection" *ngFor="let option of options">
        <input type="radio" [name]="option.value" [value]='option.key' (change)="onSelection($event)">
        <label class="selection__option" [for]="option.value">{{ option.value }}</label>
      </div>
    </div>
    <div *ngIf="!isMultipleChoice">
      <input class="form-control" type="text" formControlName="text" [attr.placeholder]="title" autocomplete="off" autocorrect="off" />
    </div>
  </div>
  <div class="modal-footer"  *ngIf="!isMultipleChoice">
    <button class="btn btn-danger" *ngIf="failButton" (click)="dismissModal()">{{ failButton }}</button>
    <button class="btn btn-success" (click)="closeModal()">{{ successButton }}</button>
  </div>
</div>
