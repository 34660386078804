import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { MileageCheckResponse } from '../mileage/models/mileage-check-response.model';
import { catchError } from 'rxjs/operators';
import { MotCheckResponse } from '../mileage/models/mot-check-response.model';

@Injectable({
  providedIn: 'root',
})
export class MileageService {

  constructor(private httpClient: HttpClient) { }

  mileageCheck$(quoteStateId: number): Observable<MileageCheckResponse> {
    return this.httpClient.get<MileageCheckResponse>(`${environment.ppxPrivateApi}api/hpi/${quoteStateId}/Mileage`).pipe(
        catchError(err => throwError(err))
      );
  }

  doMotHistoryCheck$(quoteStateId: number) {
    return this.httpClient.get<MotCheckResponse>(`${environment.ppxPrivateApi}api/hpi/${quoteStateId}/MotHistory`).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
