import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { VehicleDetails } from '../../models/vehicle-details';
import { VehicleListValue } from '../../walk-in/models/vehicle-list-value.model';
import { CapIdDateRangeService } from '../../services/cap-id-date-range.service';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { SharedService } from '../../core/shared.service';
import moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit, AfterViewInit {

  @Input() questionForm: UntypedFormGroup;
  @Input() vrm: string;
  @Input() vehicleDescription: VehicleListValue;
  @Input() vehicleDetails: VehicleDetails;
  @Input() mileage: string;
  @Input() hideDetails: boolean;
  @Input() altStyle: number;
  @Input() HPIFirstRegistered: string;
  @Input() isUserEnteredDate: string;
  @Input() isHPIDate: string;

  hasCapIdDateDiscrepancy: boolean;
  firstRegisteredDate: Date;
  HPIFirstRegisteredDate: Date;
  firstRegisteredDateDisplay: string;
  HPIFirstRegisteredDateDisplay: string;
  hasSalvage = '';

  constructor(
    private dateRangeService: CapIdDateRangeService,
    private notifications: NotificationService,
    private sharedService: SharedService,
  ) { }


  ngOnInit(): void {
    if (this.vehicleDescription) {
      this.hasSalvage = this.vehicleDescription.HasSalvage ? 'Salvage Auction' : this.booleanIcon(this.vehicleDescription.HasSalvage);
    }

      this.setFirstRegisteredDates();

    this.sharedService.selectedVehicle.subscribe((response: VehicleListValue) => {
      if (this.vehicleDetails) {
        this.vehicleDetails.userEnteredFirstRegisteredDate = response.RegYear;
      }
      this.setFirstRegisteredDates();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.questionForm) {
        this.updateQuestionFormValue(this.questionForm);
      }
    });
  }

  setFirstRegisteredDates() {
    if (this.vehicleDetails) {
      if (this.HPIFirstRegistered) {
        this.formatHPIDate();
      } else {
        this.HPIFirstRegisteredDate = new Date(this.vehicleDetails.firstRegistered);
        this.HPIFirstRegisteredDateDisplay = moment(this.HPIFirstRegisteredDate).format('DD MMMM YYYY');
      }
      if(!this.vehicleDetails.firstRegistered && !this.HPIFirstRegistered ) {
        return;
      }
      const firstRegistered = this.vehicleDetails.userEnteredFirstRegisteredDate;
      if (firstRegistered) {
        if (firstRegistered.includes('/')) {
          this.checkForDatesDiscrepancyByYearAndLetter(firstRegistered, this.HPIFirstRegisteredDate.toJSON());
        } else {
          this.firstRegisteredDate = new Date(this.vehicleDetails.userEnteredFirstRegisteredDate);
          if (this.firstRegisteredDate !== this.HPIFirstRegisteredDate) {
            this.checkForDatesDiscrepancy(this.firstRegisteredDate.toJSON(), this.HPIFirstRegisteredDate.toJSON());
            this.firstRegisteredDateDisplay = moment(this.firstRegisteredDate).format('DD MMMM YYYY');
          }
        }
      }
    }

  }

  formatHPIDate() {
    const parts = this.HPIFirstRegistered.split('/');
    this.HPIFirstRegisteredDate = new Date(parseInt(parts[2], 10),
                  parseInt(parts[1], 10) - 1,
                  parseInt(parts[0], 10));
    this.HPIFirstRegisteredDateDisplay = moment(this.HPIFirstRegisteredDate).format('DD MMMM YYYY');
  }

  checkForDatesDiscrepancy(firstRegisteredDate, HPIFirstRegisteredDate) {
      this.dateRangeService.checkForDateDiscrepancy$(firstRegisteredDate, HPIFirstRegisteredDate).pipe(
        tap(result => {
          this.hasCapIdDateDiscrepancy = result;
          this.updateTextColor();
        }),
        catchError(err => {
          this.notifications.dangerToast(`Failed to retrieve capid date ranges`, err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
  }

  checkForDatesDiscrepancyByYearAndLetter(firstRegisteredDate, HPIFirstRegisteredDate) {
    this.dateRangeService.checkForDateDiscrepancyByYearAndLetter$(firstRegisteredDate, HPIFirstRegisteredDate).pipe(
      tap(result => {
        this.hasCapIdDateDiscrepancy = result.hasDateRangeDiscrepancy;
        this.firstRegisteredDate = result.firstRegisteredDate;
        this.firstRegisteredDateDisplay = moment(result.firstRegisteredDate).format('DD MMMM YYYY');
        this.updateTextColor();
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to retrieve capid date ranges`, err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  updateTextColor() {
    if (this.hasCapIdDateDiscrepancy && !this.isHPIDate && !this.isUserEnteredDate) {
      this.altStyle = 4
    }
  }


  updateQuestionFormValue(question: UntypedFormGroup) {
    question.controls.value.setValue(true);
  }

  get engineFormat() {
    if (this.vehicleDetails) {
      return this.vehicleDetails.fuelType
        ? `${this.vehicleDetails.engineSize}cc - ${this.vehicleDetails.fuelType}`
        : `${this.vehicleDetails.engineSize}cc`;
    }
    return `${this.vehicleDescription.EngineSize}cc`;
  }

  booleanIcon(value) {
    if (value === true) {
      return '../../assets/icons/buttons/trueTick.svg';
    } else if (value === false) {
      return '';
    } else {
      return '../../assets/icons/buttons/redDash.svg';
    }
  }

  get capData() {
    let data = '';
    if (this.vehicleDetails) {
      switch (this.vehicleDetails.bookId) {
        case 1:
          data = `Car - ${this.vehicleDetails.capId}`;
          break;

        case 2:
          data = `Van - ${this.vehicleDetails.capId}`;
          break;
      }
    }
    if (data === '') {
      data = `${this.vehicleDescription.CapCode.endsWith('L') ? 'Van' : 'Car'} - ${this.vehicleDescription.CapCodeId}`;
    }

    return data;
  }
}

