import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { VehicleCheck } from '../vehicle-check-hpi/models/vehicle-check.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VehicleCheckService {

  constructor(private http: HttpClient) { }

  getVehicleCheck$(vrm: string, quoteStateId: number, getCalculatedManufacturedDate: boolean): Observable<VehicleCheck> {
    return this.http.post<VehicleCheck>(`${environment.ppxPrivateApi}api/Hpi/performvehiclecheck`, { vrm, quoteStateId, getCalculatedManufacturedDate }).pipe(
      catchError(err => throwError(err))
    );
  }

  getLastMotExpiryDate$(vrm: string): Observable<Date> {
    return this.http.get<Date>(`${environment.ppxPrivateApi}api/Hpi/lastValidMotExpiryDate/${vrm}`).pipe(
      catchError(err => throwError(err))
    );
  }
}
