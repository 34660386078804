<div class="dialog">
  <div class="modal-header">
    <span class="title">HPI Check Failed</span>
    <img class="modal-close" (click)="close()" alt="" />
  </div>
  <div class="modal-body">
    <div class="registration-plate">
    <app-registration-plate [vrm]="vrm"></app-registration-plate>
    </div>
    <div class="modal-body__message mt-4 mb-4">
      <p class="message"><b>Sorry, our vehicle lookup is experiencing technical issues.</b></p>
      <p><b>Please try again in a couple of minutes.</b></p>
      <p class="small-text" [hidden]="lastRetryTime == null">Retried at {{ lastRetryTime | date:'HH:mm dd/LL/yy' }} </p>
    </div>
    <div class="retry">
      <button class="retry-button" [disabled]="retryButtonDisabled" (click)="retry()"><b>Retry</b></button>
    </div>
  </div>
</div>
