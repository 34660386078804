<div *ngIf="!loading">
    <div class="header">
        We have a number of available payment options to choose from:
    </div>
    <div class="d-flex flex-row payment-options-container">
        <div class="col-4 payment-option ">
            <img (click)="selectFastPayment()" *ngIf="isFastPaymentAvailable" class="payment-image"
                src="../../assets/images/PaymentFast.png" alt="">
            <img *ngIf="!isFastPaymentAvailable" class="payment-image" src="../../assets/images/PaymentFastInactive.png"
                alt="">
            <div class="payment-type faster-payment">Immediate Payment*</div>
            <div *ngIf="isFastPaymentAvailable" class="payment-fee">{{fastPaymentCharge | currency : 'GBP'}}</div>
            <div *ngIf="!isFastPaymentAvailable" [ngClass]="isFastPaymentAvailable ? '' : 'margin-text'"
                 class="payment-availability"> Immediate Payment is not available</div>
            <div *ngIf="isFastPaymentAvailable" (click)="selectFastPayment()"
                [ngClass]="isFastPaymentSelected ? 'check-payment' : 'uncheck-payment'" class="payment-availability">
            </div>
        </div>
        <div class="col-4 payment-option">
            <img (click)="selectNextDayPayment()" *ngIf="isNextDayPaymentAvailable" class="payment-image"
                src="../../assets/images/Payment24H.png" alt="">
            <img *ngIf="!isNextDayPaymentAvailable" class="payment-image"
                src="../../assets/images/Payment24Inactive.png" alt="">
            <div class="payment-type working-day">Next Working Day</div>
            <div *ngIf="isNextDayPaymentAvailable" class="payment-fee">{{nextDayPaymentCharge | currency : 'GBP'}}</div>
            <div *ngIf="!isNextDayPaymentAvailable" [ngClass]="isFastPaymentAvailable ? '' : 'margin-text'"
                class="payment-availability next-day-availability"> Next Working Day
                Payment is not available</div>
            <div *ngIf="isNextDayPaymentAvailable" (click)="selectNextDayPayment()"
                [ngClass]="isNextDayPaymentSelected ? 'check-payment' : 'uncheck-payment'" class="payment-availability">
            </div>
        </div>
        <div class="col-4 payment-option">
            <img (click)="selectBacsPayment()" class="payment-image" src="../../assets/images/PaymentBacs.png" alt="">
            <div class="payment-type four-days">4 Working Days</div>
            <div class="payment-fee">Free</div>
            <div (click)="selectBacsPayment()" [ngClass]="isBacsPaymentSelected ? 'check-payment' : 'uncheck-payment'"
                class="payment-availability"></div>
        </div>
    </div>
    <div class="footer">
        *We'll send your payment to the bank within 15 minutes. Immediate Payments may take up to 2 hours to arrive in your
        account
    </div>
</div>
