import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from '../core/shared.service';
import { DiaryViewTypes } from '../enums/diary-view-types';
import { QuickQuoteComponent } from '../quick-quote/quick-quote.component';
import { AppointmentService } from '../services/appointment.service';
import { NotificationService } from '../services/notification.service';
import { StockService } from '../services/stock.service';
import { WalkInComponent } from '../walk-in/walk-in.component';
import { BuyerCalendarSchedule } from './models/buyer-calendar-schedule.model';
import { DiaryService } from './services/diary.service';
import { BuyerService } from '../services/buyer.service';
import { RoutingService } from '../services/routing-service.service';
import { PurchaseFilter } from '../purchase/models/purchase-filter.model';
import { Purchase } from '../purchase/models/purchase.model';
import { PurchaseService } from '../services/purchase.service';
import { BaseComponentDirective } from '../base/base.component';
import { DiaryVrmSearchComponent } from './diary-vrm-search/diary-vrm-search.component';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
})
export class DiaryComponent extends BaseComponentDirective implements OnInit, OnDestroy, AfterViewInit, OnDestroy {
  view: DiaryViewTypes = DiaryViewTypes.Day;
  diaryView = DiaryViewTypes;

  dayStartHour = 0;
  dayEndHour = 24;
  defaultDisplayStartHour = 9;

  dayHours: number[];
  currentDate: number;
  currentTime: number;
  refreshDate: NodeJS.Timeout;
  isNotInBranch: boolean;
  buyerWeeklySchedule: BuyerCalendarSchedule;
  vrm: string;
  loading: boolean;
  diaryLoading: boolean;
  purchases: Array<Purchase>;
  resultCount: number;
  filters: PurchaseFilter;
  hasSuccessfulSearch: boolean;
  destroy$ = new Subject();
  buyersCount: number;

  constructor(
    public datepipe: DatePipe,
    private appointmentService: AppointmentService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private diaryService: DiaryService,
    private stockService: StockService,
    private notifications: NotificationService,
    private routingService: RoutingService,
    private buyerService: BuyerService,
    private purchaseService: PurchaseService,
    private sharedService: SharedService
  ) {
    super();
  }

  ngOnInit() {
    this.routingService.redirectToAuthTaskIfMobile();
    this.sharedService.setIsManualLookup(false);
    this.dayHours = Array.from(
      Array(this.dayEndHour - this.dayStartHour).keys()
    ).map((x) => x + this.dayStartHour);
    this.vrm = '';
    this.currentDate = Date.now();
    this.currentTime = Date.now();
    this.route.data
      .pipe(
        tap((data) => {
          if (data.view) {
            this.view = data.view;
          }
        }),
        take(1)
      )
      .subscribe();

    this.getBuyerWeeklySchedule();
  }

  ngAfterViewInit(): void {
    this.refreshDate = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshDate);
    super.ngOnDestroy();
  }

  getBuyerWeeklySchedule() {
    this.diaryLoading = true;
    this.appointmentService
      .getBuyerWeeklySchedule(moment(this.currentDate).format('YYYY-MM-DD'))
      .pipe(
        tap((result) => {
          this.buyerWeeklySchedule = result;
          const dayId = moment(this.currentDate).day();
          const siteId = this.appointmentService.getSiteId(this.buyerWeeklySchedule, dayId);
          const site = this.buyerWeeklySchedule.sites.find(x => x.siteId === siteId);
          if (site) {
            this.isNotInBranch = false;
            this.dailyStockCheck(site.siteId);
          } else {
            this.isNotInBranch = true;
          }
          this.diaryService.sendIsNotInBranchValue(this.isNotInBranch);
          this.buyerService.setCurrentSiteInStorage(site);
          this.appointmentService.getBuyers$(site.siteId, moment(this.currentDate).format('YYYY-MM-DD')).pipe(take(1)).subscribe(result => {
            this.buyersCount = result;
          })
          const buyer = this.buyerService.getBuyerFromStorage();
          if (!buyer || buyer.employeeId !== this.buyerWeeklySchedule.buyer.employeeId) {
            this.buyerService.getBuyer$(this.buyerWeeklySchedule.buyer.employeeId).pipe(take(1)).subscribe();
          }
          this.diaryLoading = false;
        }),
        catchError((err) => {
          this.diaryLoading = false;
          this.notifications.dangerToast('Failed to get buyer weekly schedule', err);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  dailyStockCheck(siteId: number) {
    if (this.isNotInBranch) {
      return;
    }

    this.stockService
      .getLatestStockCheck$(siteId)
      .pipe(
        tap((data) => {
          if (this.stockService.isDailyStockCheckRequired(data.created)) {
            this.router.navigate(['/stock']);
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast('Failed to load initial data', err);
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  setView(view: DiaryViewTypes) {
    this.view = view;
  }

  walkIn(isStartingPurchase: boolean) {
    const modalRef = this.redirectToModal(WalkInComponent);
    modalRef.componentInstance.isStartPurchase = isStartingPurchase;
  }

  quickQuote() {
    this.redirectToModal(QuickQuoteComponent);
  }

  redirectToModal(component): NgbModalRef {
    return this.modalService.open(component, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
  }

  search() {
    if (this.vrm.length === 0) {
      this.hasSuccessfulSearch = false;
      this.loading = false;
    } else {
      this.loading = true;
      this.purchaseService
        .searchPurchasesByVrm$(this.vrm)
        .pipe(
          tap((result) => {
            this.loading = false;
            this.purchases = result.results;
            this.resultCount = result.totalResultCount;
            this.searchRouter();
          }),
          catchError((err) => {
            this.loading = false;
            this.notifications.dangerToast('Failed to retrieve purchases', err);
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe();
    }
  }

  searchRouter() {
    if (this.purchases.length > 0) {
      this.hasSuccessfulSearch = true;
      this.vrm = '';
      const modalRef = this.modalService.open(DiaryVrmSearchComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true,
        size: 'sm',
      });
      modalRef.componentInstance.purchases = this.purchases;
    } else {
      this.hasSuccessfulSearch = false;
    }
  }
}
