<div *ngFor="let appointment of dailyAppointments">
  <div class="appointment-container" *ngIf="!loading" [style.top.rem]="appointment.appointmentTopPosition" (click)="showAppointment(appointment.appointmentId)">
    <div class="appointment" [ngClass]="appointment.statusStyle" [style.height.rem]="appointment.appointmentHeight" [style.width.%]="appointment.appointmentWidth" [style.left.%]="appointment.appointmentLeft">
      <div *ngIf="!isWeekView" class="appointment-time-container">
        <div class="appointment-time" [ngClass]="appointment.timeStyle">
          <span style="width: 100%">{{ appointment.startTime.format('HH:mm') }}</span>
        </div>
      </div>
      <div class="appointment-detail-container" [ngClass]="{ 'vrm-week-view' : isWeekView, 'vrm-day-view' : !isWeekView }">
        <span class="appointment-vrm">{{ appointment.vrm }}</span>
      </div>
      <div *ngIf="!isWeekView" class="appointment-detail-container status">
        <span class="appointment-status">{{ appointment.status }}</span>
        <span *ngIf="isBranchView">{{ appointment.assignedTo }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="lunchTopPosition" class="appointment-container" [style.top.rem]="lunchTopPosition" [style.left.%]="lunchSequence" [style.width.%]="lunchWidth">
  <div class="appointment-lunch" [style.height.rem]="lunchHeight">
    <span>LUNCH</span>
  </div>
</div>

<div *ngIf="loading" style="flex: auto;">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</div>