<div class="container">
  <h1>
    There has been an error :(
  </h1>
  <p>{{ errorMessage }}</p>
  <p *ngIf="showDetails">
    We'll be made aware of this error and we'll look into making sure it doesn't happen again.
  </p>
  <p *ngIf="showDetails">However, if you feel this is a stopping you from making a purchase then you should contact IT.</p>
</div>
