import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { QuestionFormatTypes } from '../../enums/question-format-types';

@Component({
  selector: 'app-base-input',
  template: ``
})
export class BaseInputComponent implements OnInit {

  @Input() group: UntypedFormGroup;
  @Input() isInline: number;
  identifier: string;
  label: string;
  explanation: string;
  mask: string;
  formatType: number;
  disabled: boolean;
  minValue: string;
  maxValue: string;
  @Output() change = new EventEmitter<UntypedFormGroup>();
  encrypted: boolean;

  ngOnInit(): void {
    if (this.group) {
      this.identifier = this.group.controls.name.value;
      this.label = this.group.controls.question.value;
      this.explanation = this.group.controls.explanation.value;
      this.mask = this.group.controls.mask.value;
      this.formatType = this.group.controls.formatType.value;
      this.disabled = this.group.controls.disabled.value;
      this.encrypted = this.group.controls.encrypted?.value;
      if (this.group.controls.minValue !== null) {
        this.minValue = this.group.controls.minValue.value;
      }
      if (this.group.controls.maxValue !== null) {
        this.maxValue = this.group.controls.maxValue.value;
      }
    }
  }

  get isHidden(): boolean {
    if (!this.group) {
      return false;
    }
    return this.group.controls.isHidden?.value;
  }

  transformInput($event) {
    if (this.formatType === QuestionFormatTypes.ToUpper) {
      this.group.controls.value.setValue($event.currentTarget.value.toUpperCase());
    }
    if (this.formatType === QuestionFormatTypes.ToInitialCharacterCapitalized) {
      if ($event.currentTarget.value.length > 0) {
        this.group.controls.value.setValue($event.currentTarget.value[0].toUpperCase() + $event.currentTarget.value.slice(1).toLowerCase());
      }
    }
  }

  public isTouchedAndInvalid(ctrl: AbstractControl) {
    return (!ctrl.valid && ctrl.touched);
  }
}
