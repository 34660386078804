import { Component, Input } from '@angular/core';
import { BaseComponentDirective } from '../base/base.component';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent extends BaseComponentDirective {

  private _value = 0;

  @Input()
  set value(value: number) {
    this._value = value;
    this.updatePositionMarker();
  }
  get value(): number {
    return this._value;
  }

  updatePositionMarker() {
    document.getElementById('positionProgress').style.width = this._value.toString() + '%';
    document.getElementById('positionMarker').style.left = this._value.toString() + '%';
    document.getElementById('positionLabel').style.left = 'calc(' + this._value.toString() + '% - 0.5rem)';
  }
}

