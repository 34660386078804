import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '../custom-validators/custom-validators';

@Component({
  selector: 'app-vrm-confirmation-modal',
  templateUrl: './vrm-confirmation-modal.component.html',
  styleUrls: ['./vrm-confirmation-modal.component.scss']
})
export class VrmConfirmationModalComponent implements OnInit {

  @Input() appointmentVrm: string;
  @Output() vrmMatches = new EventEmitter<boolean>();

  vrmEntered: string;
  vrmHasBeenCheckedAndDoesNotMatch: boolean;
  vrmConfirmed: boolean;
  vrmSearchForm: FormGroup;
  displayImageUrl: string;

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.vrmSearchForm = this.formBuilder.group({
      vrm: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(8), Validators.pattern(CustomValidators.vrmRegex)]]
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  checkVrm() {
    this.vrmEntered = this.vrmSearchForm.get('vrm').value;
    const vrmEnteredNoWhitespace = this.vrmEntered.replace(/\s/g, '').toLowerCase();
    const appointmentVrmNoWhitespace = this.appointmentVrm.replace(/\s/g, '').toLowerCase();
    if (vrmEnteredNoWhitespace !== appointmentVrmNoWhitespace) {
      this.vrmHasBeenCheckedAndDoesNotMatch = true;
    } else {
      this.vrmMatches.emit(true);
    }
  }

  vrmIsCorrect() {
    this.vrmConfirmed = true;
    this.vrmHasBeenCheckedAndDoesNotMatch = false;
  }

  reEnterVrm() {
    this.vrmHasBeenCheckedAndDoesNotMatch = false;
    this.vrmConfirmed = false;
  }
}
