<form [formGroup]="valuationQuestionsForm" class="questionsForm mt-2">
  <div class="form-section mb-4">
    <div class="form-label">Enter vehicle mileage and then take a picture of the dashboard</div>
    <div class="row">
      <div class="col-8 position-relative">
        <input id="mileageInput" type="text" class="form-control input-text" placeholder="Enter Mileage"
          formControlName="mileage" pattern="^[0-9]+([0-9]{0,0})?$" (ngModelChange)="preventInputOnTablet($event)" maxlength="8" mask="separator"
          thousandSeparator="," required autocomplete="off" />
        <span class="placeholder placeholder-indented">
          Enter Mileage
        </span>
        <span class="placeholder-line placeholder-line-indented"></span>
      </div>
      <div class="col-4 flex-container">
        <app-image-input class="child-stretch flex-container flex-grow" [save]="saveNotifier" [parameterName]="'Dashboard'" [quoteStateId]="quoteStateId"
          [imageData]="images" [hasManualImageInput]="true" [group]="null" [requiredImageType]="1">
        </app-image-input>
      </div>
    </div>
  </div>
  <div class="form-section mb-4" *ngIf="requireCustomerName">
    <div class="form-label">Customer's Full Name</div>
    <input id="customerNameInput" type="text" class="form-control input-text input-text-default obfuscated-playback"
      placeholder="Enter Full Name" formControlName="customerName" required autocomplete="off" />
    <span class="placeholder">
      Enter Full Name
    </span>
    <span class="placeholder-line"></span>
  </div>
  <div class="form-section mb-4" *ngIf="requireCustomerName">
    <div class="form-label">Email Address</div>
    <input id="emailInput" type="text" class="form-control input-text input-text-lower obfuscated-playback" placeholder="Enter Email Address"
           formControlName="emailAddress" required autocomplete="off" (click)="scrollModalToTopPosition(10)"/>
    <span class="placeholder">
      Enter Email Address
    </span>
    <span class="placeholder-line"></span>
  </div>
  <div class="form-section mb-4" *ngIf="requireCustomerName">
    <div class="form-label">Mobile Number</div>
    <input id="phoneInput" type="text" class="form-control input-text input-text-default obfuscated-playback" placeholder="Enter Mobile Number"
           formControlName="telephone" required autocomplete="off" (click)="scrollModalToTopPosition(12)"/>
    <span class="placeholder">
      Enter Mobile Number
    </span>
    <span class="placeholder-line"></span>
  </div>
  <div class="walkin-valuation-btn">
    <button class="modal-button button_green" type="button" [disabled]="!isFormValid || !isImageAdded"
      (click)="onNext()"
      [ngClass]="{'active': isFormValid && isImageAdded, 'loading': loading, 'invalid': !isFormValid || !isImageAdded}">
      <span [ngClass]="{'loading': loading, 'invalid': !isFormValid || !isImageAdded}"
        class="next-btn-icon"></span>
      <span class="next-btn-span">Next</span>
    </button>
  </div>
</form>
