import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from '../../core/shared.service';
import { BaseComponentDirective } from '../../base/base.component';
import { NotificationService } from '../../services/notification.service';
import { StockService } from '../../services/stock.service';
import { Stock } from '../models/stock.model';
import { SaveReport } from '../models/save-report.model';
import { BuyerService } from '../../services/buyer.service';

@Component({
  selector: 'app-stock-inventory',
  templateUrl: './stock-inventory.component.html',
  styleUrls: ['./stock-inventory.component.scss'],
})
export class StockInventoryComponent
  extends BaseComponentDirective
  implements OnInit {
  constructor(
    private stockService: StockService,
    private notifications: NotificationService,
    private sharedService: SharedService,
    private buyerService: BuyerService
  ) {
    super();
  }

  @Output() saveReport: EventEmitter<SaveReport> = new EventEmitter();
  @Output() reportData: EventEmitter<Stock> = new EventEmitter();
  loading: boolean;
  stockItems: Array<Stock>;
  runners: Array<Stock>;
  non_runners: Array<Stock>;
  public noVehicleInBranch: boolean;
  showNonRunners: boolean;
  siteId: number;
  sitename: string;

  ngOnInit(): void {
    this.loading = true;
    this.showNonRunners = false;

    const site = this.buyerService.getCurrentSiteFromStorage();
    if (site) {
      this.sitename = site.name;
      this.siteId = site.siteId;
    } else {
      this.sitename = '';
      this.siteId = 0;
    }

    this.loadData();
    this.sharedService.runner.subscribe(() => {
      this.enabledNonRunner();
     });
   }

  loadData() {
    this.stockService
      .getStocks$(this.siteId)
      .pipe(
        tap((response) => {
          this.loading = false;
          if (response.results.length > 0) {
            this.filterNonRunner(response.results);
            this.stockItems = response.results;
            this.reportData.emit(response.results[0]);
          } else {
            this.sharedService.source.emit(false);
            this.noVehicleInBranch = true;
            const stock = new Stock();
            this.reportData.emit(stock);
          }
        }),
        catchError((err) => {
          this.loading = false;
          this.notifications.dangerToast(
            'Failed to retrieve stocks inventory',
            err
          );
          return EMPTY;
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe();
  }

  check(stockItem, checked) {
    const index = this.stockItems.findIndex(
      (item) => stockItem.quoteStateId === item.quoteStateId
    );
    stockItem.leftBranch = checked;
    this.stockItems[index] = stockItem;

    this.report();
  }

  report() {
    this.saveReport.emit({
      checked: this.stockItems.some((element) => element.leftBranch === true || element.markAsNonRunner === true),
      from: 'StockInventory',
    });
  }

  filterNonRunner(stockResults: Array<Stock>) {
    this.runners = stockResults.filter((element) => {
      return (
        !element.nonRunnerWhenPurchased && !element.previouslyFlaggedAsNonRunnerPostPurchase
      );
    });

    this.non_runners = stockResults.filter((element) => {
      return (
        element.nonRunnerWhenPurchased || element.previouslyFlaggedAsNonRunnerPostPurchase
      );
    });
  }

  nonRunner(stockItem, checked) {
    if (!stockItem.nonRunnerWhenPurchased && !stockItem.flaggedAsNonRunnerPostPurchase) {
      stockItem.markAsNonRunner = !checked;
    }
    this.report();
  }

  enabledNonRunner() {
    this.showNonRunners = true;
   }
}
