import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceHistoryInWarrantyCheckRequest } from '../service-history/models/service-history-in-warranty-check-request.model';
import { WarrantyCheckRequest } from '../service-history/models/warranty-check-request.model';
import { WarrantyCheckResult } from '../service-history/models/warranty-check-result.model';
import { catchError } from 'rxjs/operators';
import { AnswerValue } from '../purchase/models/answer-value.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceHistoryService {

  constructor(private http: HttpClient) { }

  checkVehicleWarrantyStatus$(warrantycheck: WarrantyCheckRequest): Observable<WarrantyCheckResult> {
    const body = new HttpParams()
      .set('firstRegisteredOrManufacturedDate', warrantycheck.firstRegisteredOrManufacturedDate.toString())
      .set('quoteStateId', warrantycheck.quoteStateId.toString())
      .set('manufacturerName', warrantycheck.manufacturer)
      .set('vehicleMileage', warrantycheck.vehicleMileage.toString());

    return this.http.post<WarrantyCheckResult>(`${environment.ppxPrivateApi}api/servicehistory/checkwarranty`, body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }).pipe(
        catchError(err => throwError(err))
      );
  }

  calculateServiceHistory$(quoteStateId: number, answers: Array<AnswerValue>): Observable<number> {
    return this.http.post<number>(`${environment.ppxPrivateApi}api/servicehistory/${quoteStateId}`, answers).pipe(
      catchError(err => throwError(err))
    );
  }

  getInWarrantyServiceHistory$(quoteStateId: number): Observable<ServiceHistoryInWarrantyCheckRequest> {
    return this.http.get<ServiceHistoryInWarrantyCheckRequest>(`${environment.ppxPrivateApi}api/servicehistory/inwarranty/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  saveInWarrantyServiceHistory$(serviceHistory: ServiceHistoryInWarrantyCheckRequest): Observable<ServiceHistoryInWarrantyCheckRequest> {
    return this.http.post<ServiceHistoryInWarrantyCheckRequest>(`${environment.ppxPrivateApi}api/servicehistory/inwarranty`, serviceHistory).pipe(
      catchError(err => throwError(err))
    );
  }

}
