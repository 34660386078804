<div>
  <ng-template #loadingOrError>
    <div class="mb-3 text-danger" *ngIf="loadingError$ | async; else loading">
      An error occurred when verifying billing details.
    </div>
  </ng-template>
  <button class="btn btn-primary my-1" (click)="verifyBankDetails()" [disabled]="verifyBankDetailsDisabled">
    Verify bank details
  </button>
  <div *ngIf="!loading">
    <div style="padding-top: 1rem;">
        <div class="row" *ngFor="let detail of billingDetails">
          <div class="col-3">{{ detail.parameterName }}:</div>
          <div class="col-9">{{ detail.value }}</div>
        </div>
    </div>
  </div>
  <div class="warning" [hidden] = "isCorrect || needsVerification">
    The bank details entered are not valid. Please check and try again.
  </div>
</div>
