<div class="grid">
  <div class="child header">Plate Date</div>
  <div class="child header">VRM</div>
  <div class="child header">Plate Has Finance</div>
  <div class="child header">VRM Stolen</div>
  <div class="child header">Plate Has Damage</div>
  <div class="child header">Plate Security Watch</div>
  <div class="child header">Imported</div>
  <div class="child header">Northern Ireland</div>

  <div class="child">{{ plate.plateDate | blankDate }}</div>
  <div class="child">{{ plate.vrm }}</div>
  <div class="child"><img [src]="booleanIcon(plate.finance)" alt="Has finance"></div>
  <div class="child"><img [src]="booleanIcon(plate.stolen)" alt="Is stolen"></div>
  <div class="child"><img [src]="booleanIcon(plate.damage)" alt="Is damaged"></div>
  <div class="child"><img [src]="booleanIcon(plate.securityWatch)" alt="Security watch"></div>
  <div class="child"><img [src]="booleanIcon(vehicle.imported)" alt="Imported"></div>
  <div class="child"><img [src]="booleanIcon(vehicle.niRegistered || vehicle.possiblyNIPlate)" alt="NI registered"></div>
</div>

<div class="grid-mobile">
  <div class="child header">Plate Date</div>
  <div class="child header">VRM</div>
  <div class="child">{{ plate.plateDate | blankDate }}</div>
  <div class="child">{{ plate.vrm }}</div>
  <div class="child header">Plate Has Finance</div>
  <div class="child header">VRM Stolen</div>
  <div class="child"><img [src]="booleanIcon(plate.finance)" alt="Has finance"></div>
  <div class="child"><img [src]="booleanIcon(plate.stolen)" alt="Is stolen"></div>
  <div class="child header">Plate Has Damage</div>
  <div class="child header">Plate Security Watch</div>
  <div class="child"><img [src]="booleanIcon(plate.damage)" alt="Is damaged"></div>
  <div class="child"><img [src]="booleanIcon(plate.securityWatch)" alt="Security watch"></div>
  <div class="child header">Imported</div>
  <div class="child header">Northern Ireland</div>
  <div class="child"><img [src]="booleanIcon(vehicle.imported)" alt="Imported"></div>
  <div class="child"><img [src]="booleanIcon(vehicle.niRegistered || vehicle.possiblyNIPlate)" alt="NI registered"></div>
</div>
