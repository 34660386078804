<div class="d-flex vrm-displayed-number"  *ngIf="!isHpiModal" [ngClass]="{ 'center' : isStartPurchase,'' : !isStartPurchase, 'purchase-background': isPurchaseMenu, '': !isPurchaseMenu }">
  <div class="vrm">
    {{ vrm }}
  </div>
</div>

<div class="d-flex vrm-displayed-number-hpi" *ngIf="isHpiModal" [ngClass]="isStartPurchase ? 'center' : ''">
  <div class="vrm-hpi" (click)="selectPlate(vrm)">
    {{ vrm }}
  </div>
</div>