<div *ngIf="!modalOpened" class="task-container">
  <div class="row">
    <div class="col-6 d-none d-lg-flex task-date">{{ currentTime | date: "d MMMM y HH:mm" }}</div>
    <div class="col-6 ps-0 pe-0 task-date date-pad onlyMobile">{{ currentTime |
      date: "d MMMM y" }}</div>

    <div class="col-6 ps-0 pe-0 right date-pad sort-mobile onlyMobile">
      <img (click)="toggleMobileFilter();" *ngIf="!filterActive" src="../../../assets//icons/outlined/filter-3x.png"
        class="resize-image" style="margin-right: 1rem;" alt="">
      <img (click)="toggleMobileFilter();" *ngIf="filterActive"
        src="../../../assets//icons/outlined/filter-active-3x.png" class="resize-image" style="margin-right: 1rem;" alt="">
      <img (click)="toggleMobileSort();" *ngIf="!mobileSortOpened"
        src="../../../assets//icons/outlined/arrow-down.png" class="resize-image" alt="">
      <img (click)="toggleMobileSort();" *ngIf="mobileSortOpened"
        src="../../../assets//icons/outlined/arrow-up.png" class="resize-image" alt="">
    </div>

    <div class="col-6 right task-sort d-none d-lg-flex">
      <div>
        <div *ngIf="hasMultipleRegionGroups" ngbDropdown container="body" #theDropdown="ngbDropdown" (openChange)="toggleFilter()">
          <input readonly="readonly" class="filter-dropdown"
            [ngClass]="{opened: filterOpened , 'blue-roundel': isAnyRegionSelected == true}" placeholder="Filter"
            autocomplete="off" autocorrect="off" ngbDropdownToggle />
          <div ngbDropdownMenu class="filter-dropdown-menu pre-scrollable">
            <div class="header">
              <h3 class="filter-title">Region Filter</h3>
              <button (click)="applyFilters();theDropdown.close();" type="button" class="button-apply">
                <span class='button-text'>Apply</span>
              </button>
            </div>
            <div [ngClass]="{ 'min-width-item': regionArrays.length > 3}" class="item-container ">
              <div class="item" *ngFor="let region of regionArrays" id="{{ region }}">
                <div class="division-name">{{region.divisionName}}</div>
                <div class="region-names" *ngFor="let regionNameItem of region.regionNameArray;index as index">
                  <button class="button check-box-design collapsible-checkbox-button"
                    (click)="toggleSelectRegion(region,index);" [id]="index">
                    <div [ngClass]="regionNameItem.selected ? 'show-checked' : 'hide-checked'"></div>
                  </button>
                  <label class="button-label" for="{{ index }}">
                    {{regionNameItem.regionName }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-flex" ngbDropdown #theDropdown="ngbDropdown" (openChange)="toggleSort()">
        <input readonly="readonly" class="task-dropdown" [ngClass]="sortOpened ? 'opened' : ''" placeholder="Sort By"
          autocomplete="off" autocorrect="off" ngbDropdownToggle />
        <div ngbDropdownMenu class="pre-scrollable">
          <button (click)="onSortChange(authSortType);theDropdown.close();" *ngFor="let authSortType of authSortTypes;"
            id="{{ authSortType }}" ngbDropdownItem>{{ authSortType }}</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mobileSortOpened" style="flex-direction: column;" class="d-inline-flex d-lg-none">
    <div *ngFor="let authSortType of authSortTypes; let i = index;" class="row sort-opened-mobile">
      <div class="sort-text">{{ authSortType }}</div>
      <img [ngClass]="sortIndex == i ? 'no-display' : ''" (click)="onSortChange(authSortType,i);"
        src="../../../assets/icons/outlined/enabled-3x.png" class="resize-image" alt="">
      <img *ngIf="sortIndex == i" (click)="onSortChange(authSortType,i);"
        src="../../../assets/icons/outlined/enabled-purple.png" class="resize-image" alt="">
    </div>
  </div>
  <div *ngIf="mobileSortOpened" class="d-flex d-lg-none row ms-0 me-0 ps-0 pe-0">
    <br>
  </div>
  <div *ngIf="mobileFilterOpened">
    <div *ngIf="hasMultipleRegionGroups" (openChange)="toggleFilter()">
      <div *ngFor="let region of regionArrays" id="{{ region }}">
        <div class="divisions-container">
          <div class="division-name-mobile" [ngClass]="region.selected ? 'text-green' : ''">{{region.divisionName}}
          </div>
          <img *ngIf="region.opened && !region.selected" (click)="expandFilterMobile(region);"
            src="../../../assets/icons/outlined/arrow-down-black.png" class="filter-images" alt="">
          <img *ngIf="!region.opened && !region.selected" (click)="expandFilterMobile(region);"
            src="../../../assets/icons/outlined/arrow-right-black.png" class="filter-images" alt="">
          <img *ngIf="region.opened && region.selected" (click)="expandFilterMobile(region);"
            src="../../../assets/icons/outlined/arrow-down-green.png" class="filter-images" alt="">
          <img *ngIf="!region.opened && region.selected" (click)="expandFilterMobile(region);"
            src="../../../assets/icons/outlined/arrow-right-green.png" class="filter-images" alt="">
        </div>
        <div class="regions-box" *ngIf="region.opened">
          <div class="region-names-mobile" *ngFor="let regionNameItem of region.regionNameArray;index as index">
            <button class="button check-box-design collapsible-checkbox-button"
              (click)="toggleSelectRegionMobile(region,index);" [id]="index">
              <div [ngClass]="regionNameItem.selected ? 'show-checked-mobile' : 'hide-checked'"></div>
            </button>
            <label class="button-label" for="{{ index }}">
              {{regionNameItem.regionName }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none d-lg-block">
    <div *ngIf="tableHasData" class="row task-grid-header">
      <div class="col-2">Auth Type</div>
      <div class="col-2">Vehicle Make & Model</div>
      <div class="col-2">Buyer's Name</div>
      <div class="col-1">Branch</div>
      <div class="col-2">Region</div>
      <div class="col-1">Time Requested</div>
      <div class="col-fit">Claimed</div>
      <div class="col-1">Claimed By</div>
    </div>
    <div class="row task-grid-row-auth" *ngFor="let task of pagedAuthTasks; let i = index">
      <div class="risk" [ngClass]="{
            'green-risk': task.risk == 'Low' || task.risk == 'LowToMedium',
            'orange-risk': task.risk == 'Medium' || task.risk == 'MediumToHigh',
            'red-risk': task.risk == 'High'
          }">
      </div>
      <div (click)="actionAuthTask(task)" class="col-2 text ">{{ task.authType }}</div>
      <div (click)="actionAuthTask(task)" class="col-2 text ">
        <div class="vrm-text">{{ task.make + ' ' + task.model}}</div>
      </div>
      <div (click)="actionAuthTask(task)" class="col-2 text ">{{ task.buyersName }}</div>
      <div (click)="actionAuthTask(task)" class="col-1 text ">
        <div class="branch-text">{{ task.branch}}</div>
      </div>
      <div (click)="actionAuthTask(task)" class="col-2 text">{{ task.region }}</div>
      <div (click)="actionAuthTask(task)" class="col-1 text">{{ task.timeRequiredString }}</div>
      <div class="col-fit text">
        <div (click)="claimAuthTask(task)" [ngClass]="task.claimed ? 'show-checked-claimed' : 'hide-checked-claimed'">
        </div>
      </div>
      <div (click)="actionAuthTask(task)" class="col-1 col-claimed-by text">{{ task.claimedBy }}</div>

    </div>
  </div>
  <div class="d-block d-lg-none" [ngClass]="mobileFilterOpened ? 'add-margin' : ''">
    <div class="row" [ngClass]="task.expanded ? 'mobile-table-expanded' : 'mobile-table'"
      *ngFor="let task of pagedAuthTasks; let i = index">
      <div (click)="openTaskDetails(task)" class="risk"
        [ngClass]="{
            'green-risk': task.risk == 'Low' || task.risk == 'LowToMedium',
            'orange-risk': task.risk == 'Medium' || task.risk == 'MediumToHigh',
            'red-risk': task.risk == 'High'
          }">
      </div>
      <div class="content ps-0 pe-0 col-115" (touchstart)="onTouchStart(task)" (touchend)="onTouchEnd()">
        <div (click)="openTaskDetails(task)" class="column-title-mobile top-item pe-0 col-10">
          <div>
            Auth Type
          </div>
        </div>
        <div (click)="openTaskDetails(task)" class="col-2 date-hour">
          <div>{{ task.timeRequiredString }}</div>
        </div>
        <div (click)="openTaskDetails(task)" class="column-data-mobile col-9">
          <div class="text-overflow-mobile-table">{{ task.authType }}</div>
        </div>
        <div (click)="openTaskDetails(task)" class="column-title-mobile col-12">Vehicle Make & Model</div>
        <div (click)="openTaskDetails(task)" class="column-data-mobile col-9">
          <div class="text-overflow-mobile-table">{{ task.make + ' ' + task.model}}</div>
        </div>
        <div class="col-3 see-more">
          <div class="see-more-div" *ngIf="!task.expanded && !task.claimed" (click)="expand(task);">
            See More
          </div>
          <div *ngIf="task.claimed" [ngClass]="task.claimed ? 'show-mobile-claimed' : ''">
          </div>
        </div>
        <div *ngIf="task.expanded" class="row expanded-content">
          <div (click)="openTaskDetails(task)" class="column-title-mobile col-12">Buyer's Name</div>
          <div (click)="openTaskDetails(task)" class="column-data-mobile">
            <div class="text-overflow-mobile-table">{{ task.buyersName }}</div>
          </div>
          <div (click)="openTaskDetails(task)" class="column-title-mobile col-12">Branch</div>
          <div (click)="openTaskDetails(task)" class="column-data-mobile col-9">
            <div class="text-overflow-mobile-table">{{ task.branch}}</div>
          </div>
          <div class="col-3 see-more">
            <p (click)="expand(task);" class="see-less">
              See Less
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-pager [pageCount]="pageCount"></app-pager>
    <div class="no-task-message" *ngIf="!tableHasData && canAuthorise">
    There’s currently no Auth Tasks that need reviewing. You may need to refresh to see new Auth Tasks.
    </div>
    <div class="no-task-message" *ngIf="!canAuthorise">
      You don't have permission to approve any authorisation requests.
  </div>
</div>

<div *ngIf="modalOpened">
  <p class="back-button" (click)="backToTasks();">
    < Back to Tasks Stream</p>
      <app-auth-task-details [appointmentId]="appointmentId" [authTask]="authTaskSent" [buyerExpLevel]="buyerExpLevel">
      </app-auth-task-details>
</div>