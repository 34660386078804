import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../core/auth-service';
import { RoleGuardService } from '../role-guard.service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedService } from '../core/shared.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { DiaryService } from '../diary/services/diary.service';
import { NotificationService } from '../services/notification.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { ViewportService } from '../services/viewport.service';
import { NavigationHistoryService } from '../core/navigation-history.service';
import { BaseComponentDirective } from '../base/base.component';
import { TaskCounterService } from '../services/task-counter.service';
import { CounterTypes } from '../enums/counter-types';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss'],
})
export class SideMenuComponent extends BaseComponentDirective  implements OnInit, AfterViewInit {
  canReviewPurchases: boolean;
  canViewUpjar: boolean;
  isMenuOpen: boolean;
  userName: string;
  currentPage: string;
  version: string = environment.version;
  public stockCheckRequired = false;
  isNotInBranch: boolean;
  isHidden = false;
  private readonly _destroying$ = new Subject<void>();

  userTaskCount = 0;
  authTaskCount = 0;

  public constructor(
    private notifications: NotificationService,
    private diaryService: DiaryService,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private location: Location,
    private sharedService: SharedService,
    private msalBroadcastService: MsalBroadcastService,
    private roleGuardService: RoleGuardService,
    private viewportService: ViewportService,
    private navigationHistory: NavigationHistoryService,
    private taskCounterService: TaskCounterService
  ) {
    super();
    this.setUser();
  }

  public ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setUser();
      });
    const savedMenuOption = localStorage.getItem('PPXMenuOpen');
    if (savedMenuOption === '0') {
      this.isMenuOpen = false;
    } else {
      this.isMenuOpen = true;
    }
    this.getIsNotInBranchValue();
    this.sharedService.source.subscribe((value) => {
      this.stockCheckRequired = value;
    });

    this.viewportService.getStickyContainersVisibleState().pipe(
      takeUntil(this._destroying$),
      catchError(() => {
        return EMPTY;
      })
    )
    .subscribe((result) => {
      this.isHidden = !result;
    });

    this.taskCounterService.counter$()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe((counter) => {
        switch(counter.type) {
          case CounterTypes.USERTASK:
            this.userTaskCount = counter.value;
            break;
          case CounterTypes.AUTHTASK:
            this.authTaskCount = counter.value;
            break;
        }
      });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentPage = this.location.path();
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentPage = this.location.path();
      });

    this.taskCounterService.update(CounterTypes.USERTASK);
    this.taskCounterService.update(CounterTypes.AUTHTASK);
  }

  private setUser() {
    this.userName = this.authService.getUser()?.name;
    this.canReviewPurchases = this.roleGuardService.hasRole(AuthService.viewReviewPurchases);
    this.canViewUpjar = this.roleGuardService.hasRole(AuthService.viewUpjar);
  }

  getIsNotInBranchValue() {
    this.diaryService
      .getIsNotInBranchValue()
      .pipe(
        takeUntil(this._destroying$),
        catchError((err) => {
          this.notifications.dangerToast(
           'Unable to retrieve is in branch value',
            err
          );
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.isNotInBranch = result;
      });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      localStorage.setItem('PPXMenuOpen', '1');
    } else {
      localStorage.setItem('PPXMenuOpen', '0');
    }
  }

  logout() {
    this.clearIds();
    const modalRef = this.modalService.open(ConfirmationComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'sm',
    });
    modalRef.componentInstance.title = 'Logout';
    modalRef.componentInstance.message = `Are you sure that you want to log out?`;
    modalRef.componentInstance.failButton = 'No';
    modalRef.componentInstance.successButton = 'Yes';

    modalRef.result.then(
      () => {
        this.authService.logout();
      },
      () => {
        this.modalService.dismissAll();
      }
    );
  }

  refresh(): void {
    this.clearIds();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  clearIds() {
    this.navigationHistory.setId(null);
  }
}
