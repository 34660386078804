export class PurchaseFilter {
    vrm: string;
    username: string;
    siteName: string;
    outcomeDesc: string;
    outcome: string;
    dateFrom: Date;
    dateTo: Date;
    maxResults: number;
    pageNumber: number;
    filterExpiry: number;
}
