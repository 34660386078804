import { ServiceRecord } from './service-record.model';

export class ServiceHistoryInWarrantyCheckRequest {
  services: Array<ServiceRecord>;
  recommendedMileage: number;
  recommendedMonths: number;
  lastServiceDate: Date;
  lastServiceMonthInterval: number;
  ageOfVehicleInMonths: number;
  noServiceRecords: boolean;
  mileage: number;
  quoteStateId: number;
}
