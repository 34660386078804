<div class="quiz">
  <div *ngIf="isLoading" class="text-center">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!isLoading && !isAlreadyDone && !isError">
    <img class="mb-2 mt-2" src="../../assets/images/qotd.png" width="160" height="40" alt="Quiz of the day logo">
    <form [formGroup]="quizForm">
      <div *ngFor="let question of quiz.quizQuestions; let i = index">
        <app-quiz-question [question]="question" [form]="quizForm" [mark]="question.mark">
        </app-quiz-question>
      </div>
      <div [hidden]="!isPass && !isFail" class="result-banner">
        <div class="flex-container">
          <div>Score: {{ score }}/{{ quiz.numberOfQuestionsRequested }}</div>
          <div class="ml-3">({{ formattedAttempt() }} attempt)</div>
          <div class="ml-3" [hidden]="!isPass">Pass</div>
          <div class="ml-3 fail" [hidden]="!isFail">Fail</div>
        </div>
        <div class="mt-3" [hidden]="!isCompleted || !isFail">
          The Start Of Day Quiz helps us assess your understanding of key elements of our business. As you were
          unsuccessful in answering today's on {{ quiz.maxAttemptsAllowed }} occasions, the management team has been
          notified in order for them to organise the support you need.
        </div>
      </div>
      <div class="text-center">
        <button [hidden]="isPass || isFail" class="btn button_green quiz-button" (click)="onSubmit()"
          [disabled]="!quizForm.valid">Submit</button>
        <button [hidden]="isPass || (!isPass && !isFail) || isCompleted" class="btn button_green quiz-button"
          (click)="onRetryClick()">Retry</button>
        <button [hidden]="!isPass && !isCompleted" class="btn button_green quiz-button"
          (click)="onCloseClick()">Close</button>
      </div>
    </form>
  </div>
  <div *ngIf="isAlreadyDone" class="result-banner m-4">
    Quiz for today has already been done
  </div>
  <div *ngIf="isError" class="m-4 result-banner fail">
    An error occurred while processing the quiz of the day.<br>
    Please continue with your normal daily tasks, do not contact support.
  </div>
</div>