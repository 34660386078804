import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CounterValue } from '../models/counter-value.model';
import { CounterTypes } from '../enums/counter-types';
import { TaskService } from './task.service';
import { take, takeUntil } from 'rxjs/operators';
import { SignalREventService } from './signal-revent.service';

@Injectable({
  providedIn: 'root',
})
export class TaskCounterService implements OnDestroy {

  counterSubject: Subject<CounterValue>;
  onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private taskService: TaskService,
    private signalREventService: SignalREventService
  ) { 
    this.counterSubject = new Subject();

    this.signalREventService.authorizationRequestReceived$
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.update(CounterTypes.AUTHTASK);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.counterSubject.complete();
  }

  counter$(): Observable<CounterValue> {
    return this.counterSubject.asObservable();
  }

  update(counterType: CounterTypes) {
    switch(counterType) {
      case CounterTypes.USERTASK:
        this.getUserTaskCount();
        break;
      case CounterTypes.AUTHTASK:
        this.getAuthTaskCount();
        break;
    }
  }

  private getUserTaskCount() {
    this.taskService.getTaskCount$()
      .pipe(
        take(1)
      )
      .subscribe((result) => {
        const value: CounterValue = {
          type: CounterTypes.USERTASK,
          value: result
        };
        this.counterSubject.next(value);
      });
  }

  private getAuthTaskCount() {
    this.taskService.getAuthTaskCount$()
      .pipe(
        take(1)
      )
      .subscribe((result) => {
        const value: CounterValue = {
          type: CounterTypes.AUTHTASK,
          value: result
        };
        this.counterSubject.next(value);
      });
  }
}