import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthorizationRequestNotification } from '../authorization/models/authorization-request-notification.model';

@Injectable({
  providedIn: 'root'
})
export class SignalREventService {
  private authorizationRequestReceivedSource = new Subject<AuthorizationRequestNotification>();

  constructor() { }

  authorizationRequestReceived$ = this.authorizationRequestReceivedSource.asObservable();

  authorizationRequestReceived(authorizationRequestNotification: AuthorizationRequestNotification) {
    this.authorizationRequestReceivedSource.next(authorizationRequestNotification);
  }

}
