<div *ngIf="!loading" class="container mt-4 obfuscated-playback">
  <div *ngIf="(source === 'WBAC' || source === 'WBAV') && hasDamages">
    <p class="header-title">Customer Added Damage</p>
    <div [innerHTML]="customerContent"></div>
  </div>
  <p class="header-title">WBAC Added Damage</p>
  <div [innerHTML]="reportContent"></div>
  <div *ngIf="!customerSignature" class="centered">
    <span class="green">Vendor:&nbsp;</span>I confirm this is an accurate description of my vehicle
    <button type="button" (click)="sign()" class="btn signature-btn">
      <span>Vendor's Signature</span>
      <img src="../../assets/icons/buttons/pencil.png" alt="" />
    </button>
  </div>
  <div *ngIf="customerSignature" class="centered v-end">
    <span class="green">Vendor:&nbsp;</span>I confirm this is an accurate description of my vehicle
    <img [src]="customerSignature.url" class="signature-img" alt="" />
  </div>
  <div class="small-footer">
    Once you have signed to confirm the vehicle condition report, please hand this back to the Branch Manager
  </div>
</div>
