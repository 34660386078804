import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {

  constructor(private http: HttpClient) { }

  completePurchase$(quoteStateId): Observable<boolean> {
    return this.http.post<boolean>(`${environment.ppxPrivateApi}api/Migration/${quoteStateId}`, null).pipe(
      catchError(err => throwError(err))
    );
  }

  resubmit$(quoteStateId): Observable<void> {
    return this.http.post<void>(`${environment.ppxPrivateApi}api/Migration/resubmit/${quoteStateId}`, null).pipe(
      catchError(err => throwError(err))
    );
  }
}
