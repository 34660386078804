import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleLookupStatusTypes } from '../../../app/enums/vehicle-lookup-status-types';

@Component({
  selector: 'app-hpi-retry',
  templateUrl: './hpi-retry.component.html',
  styleUrls: ['./hpi-retry.component.scss']
})
export class HpiRetryComponent {

  constructor(private modal: NgbActiveModal) { }

  @Input() vrm: string;
  @Input() appointmentId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() hpiSuccess: EventEmitter<any> = new EventEmitter();

  lastRetryTime: Date;
  retryButtonDisabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thingToRetry$: Observable<any>;

  retry() {
    this.retryButtonDisabled = true;
    this.lastRetryTime = new Date();

    this.thingToRetry$.pipe(
      tap((result) => {
        if (result) {
          let statusId: number;
          const lookupProviderStatus = result.vehicleIdentifyProviderStatus;
          if (result.hpiStatusId != null) {
            statusId = result.hpiStatusId;
          } else if (lookupProviderStatus?.statusId != null) {
            statusId = lookupProviderStatus?.statusId;
          }

          if (!lookupProviderStatus?.providerDown && statusId === VehicleLookupStatusTypes.Success) {
            this.hpiSuccess.emit(result);
            this.modal.close();
          }
        }
      }),
      catchError(() => {
        return EMPTY;
      }),
      take(1)
    ).subscribe();

    setTimeout(() => {
      this.retryButtonDisabled = false;
    }, 5000);
  }

  close() {
    this.modal.close();
  }
}
