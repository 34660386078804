import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Purchase } from '../../purchase/models/purchase.model';
import { AppointmentDetailsComponent } from '../../appointment-details/appointment-details.component';
import { AuthService } from '../../core/auth-service';

@Component({
  selector: 'app-diary-vrm-search',
  templateUrl: './diary-vrm-search.component.html',
  styleUrls: ['./diary-vrm-search.component.scss'],
})
export class DiaryVrmSearchComponent implements OnInit {
  @Input() purchases: Array<Purchase>;
  hasSelection: boolean;
  closedFromOK = false;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private modalService: NgbModal
  ) {}

  private _selectedPurchase: Purchase;
  public get selectedPurchase(): Purchase {
    return this._selectedPurchase;
  }
  public set selectedPurchase(v: Purchase) {
    this._selectedPurchase = v;
  }

  ngOnInit(): void {
    const count = this.purchases.length;
    if (count === 1) {
      this.selectPurchase(this.purchases[0]);
      this.closedFromOK = true;
      this.close();
    }
  }

  get getPurchases(): Array<Purchase> {
    return this.purchases;
  }

  get count(): number {
    return this.purchases.length;
  }

  closeOK(): void {
    this.closedFromOK = true;
    this.close();
  }

  close(): void {
    const username = this.authService
      .getUser()
      ?.username.substring(0, this.authService.getUser()?.username.indexOf('@'));
    this.activeModal.close();
    if (this.hasSelection === true && this.closedFromOK === true) {
      const modalRef = this.modalService.open(AppointmentDetailsComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true,
        size: 'xl',
      });

      modalRef.componentInstance.appointmentId = this.selectedPurchase.appointmentId;
      if (username.toUpperCase() !== this.selectedPurchase.username.toUpperCase()) {
        modalRef.componentInstance.hideActions = true;
      }
      modalRef.result.then(
        () => {},
        () => {}
      );
    }
  }

  selectPurchase(selectedPurchase: Purchase) {
    this.hasSelection = true;
    this.selectedPurchase = selectedPurchase;
  }
}
