<div class="dialog">
  <div class="modal-header">
    <img class="modal-close" (click)="activeModal.dismiss()" />
  </div>
  <div class="modal-body">
    <img class="modal-body__image mt-4" src="{{ displayImageUrl }}" />
    <div class="modal-body__message mt-4 mb-4">{{ message }}</div>
    <div ngif="messageItems.length > 0" >
      <div *ngFor="let item of messageItems">
        <span class="modal-body__list mt-4 mb-4">{{ item }}</span>
      </div>
    </div>
    <div class="modal-body__message mt-4 mb-4">{{ messageFooter }}</div>
    <br>
    <div>
      <button class="modal-body__button-confirm button_green"  (click)="activeModal.close()">{{ isYesNo ? 'Yes' : 'Ok' }}</button>
      <span class="modal-body__button-spacer" *ngIf="isYesNo"></span>
      <button class="modal-body__button-confirm button_red" [ngClass]="{'modal-body__small-button': isYesNo}" *ngIf="isYesNo" (click)="activeModal.dismiss()">No</button>
    </div>
  </div>
</div>
