import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject } from 'rxjs';
import { catchError, tap, takeUntil, take } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { SharedService } from '../core/shared.service';
import { NotificationService } from '../services/notification.service';
import { StockService } from '../services/stock.service';
import { DailyStockComponent } from './daily-stock/daily-stock.component';
import { StockInventoryComponent } from './stock-inventory/stock-inventory.component';
import { RoutingService } from '../services/routing-service.service';
import { StockInventory } from './models/stock-inventory.model';
import { Stock } from './models/stock.model';
import { AuthService } from '../core/auth-service';
import { BuyerService } from '../services/buyer.service';
import { StockCheck } from './models/stock-check.model';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
})
export class StockComponent implements OnInit, OnDestroy {
  constructor(
    private stockService: StockService,
    private notifications: NotificationService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private confirmDialogService: ConfirmDialogService,
    private authService: AuthService,
    private routingService: RoutingService,
    private buyerService: BuyerService
  ) { }

  @ViewChild(DailyStockComponent) dailyStockComponent: DailyStockComponent;
  @ViewChild(StockInventoryComponent)
  stockInventoryComponent: StockInventoryComponent;
  isDailyStockView = true;
  enableSaveReportBtn: boolean;
  username: string;
  stockCheckCompleted: Date;
  stockCheckCompletedBy: string;
  from: string;
  stockCheckId: number;
  siteId: number;
  sitename: string;
  noVehicleInStock: boolean;
  newStockItem: boolean;

  public isSavedToday: boolean;
  destroy$ = new Subject();

  ngOnInit(): void {
    this.routingService.redirectToAuthTaskIfMobile();
    this.isDailyStockView = true;
    this.from = 'DailyStock';

    const site = this.buyerService.getCurrentSiteFromStorage();
    if (site) {
      this.sitename = site.name;
      this.siteId = site.siteId;
    } else {
      this.sitename = '';
      this.siteId = 0;
    }

    this.stockService
      .getLatestStockCheck$(this.siteId)
      .pipe(
        tap((data) => {

          if (this.stockService.isDailyStockCheckRequired(data.created)) {
            this.enableSaveReportBtn = true;
          } else {
            this.stockCheckId = data.stockCheckId;
            this.stockCheckCompleted = data.created;
            this.stockCheckCompletedBy = data.username;
            this.isSavedToday = true;
            this.stockInventory();
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast('Failed to load initial data', err);
          return EMPTY;
        })
      )
      .subscribe();

    this.sharedService.source
      .pipe(
        tap((value) => {
          this.noVehicleInStock = value;
        }),
        catchError((err) => {
          this.notifications.dangerToast('Failed to load initial data', err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  saveReport() {
    if (this.from === 'DailyStock' && this.isSavedToday) {
      return;
    }

    if (this.from === 'DailyStock') {
      this.stockService
        .saveDailyStockCheck$(this.siteId, this.dailyStockComponent.stockItems)
        .subscribe((response: StockCheck) => {
          this.username = response.username;
          this.stockCheckId = response.stockCheckId;
          this.stockCheckCompleted = response.created;
          this.stockCheckCompletedBy = response.username;
          this.enableSaveReportBtn = false;
          this.stockCheck(response.created);
        });
    } else if (this.from === 'StockInventory'
        && (this.stockInventoryComponent.stockItems.some((v) => v.leftBranch === true || v.markAsNonRunner === true))
    ) {
      const inventory = new StockInventory();
      inventory.siteId = this.siteId;
      inventory.siteName = this.sitename;
      inventory.items = this.stockInventoryComponent.stockItems.filter((v) => v.leftBranch === true || v.markAsNonRunner === true );
      this.stockService.saveStockInventory$(inventory).subscribe(() => {
        this.enableSaveReportBtn = false;
        this.stockInventoryComponent.loadData();
      });
    } else {
      this.stockCheck(new Date());
      this.enableSaveReportBtn = false;
    }
  }

  stockCheck(created: Date) {
    const isStockCheckRequired = this.stockService.isDailyStockCheckRequired(created);
    if (!isStockCheckRequired) {
      this.isSavedToday = true;
    }

    this.sharedService.source.emit(!this.isSavedToday);
  }

  dailyStock() {
    if (!this.isSavedToday) {
      return;
    }

    this.isDailyStockView = true;
    this.from = 'DailyStock';

    this.enableSaveReportBtn = false;
  }

  stockInventory() {
    if (!this.isSavedToday) {
      return;
    }

    this.isDailyStockView = false;
    this.from = 'StockInventory';

    this.enableSaveReportBtn = false;
  }

  enableReport(event) {
    this.enableSaveReportBtn = event.checked;
    this.from = event.from;
  }

  enableNonRunner() {
    this.sharedService.runner.emit(true);
  }

  showSaveReportModal() {
    const isDailyStock = this.from === 'DailyStock';
    if (isDailyStock) {
      if (this.dailyStockComponent.stockItems.length > 0) {
        const nonRunners = this.dailyStockComponent.stockItems.filter(x => x.markAsNonRunner);
        if (nonRunners.length > 0) {
          this.confirmDialogService.saveStockStockCheckConfirmation(nonRunners.length, nonRunners).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.saveReport();
            this.confirmDialogService.saveDailyStockCheck().pipe(take(1)).subscribe(() => {
              this.modalService.dismissAll();
            });
          });
        } else {
          this.saveReport();
          this.confirmDialogService.saveDailyStockCheck().pipe(take(1)).subscribe(() => {
            this.modalService.dismissAll();
          });
        }
      } else {
        this.saveReport();
        this.confirmDialogService.saveDailyStockCheck().pipe(take(1)).subscribe(() => {
          this.modalService.dismissAll();
        });
      }
    } else {
      const nonRunners = this.stockInventoryComponent.stockItems.filter(x => x.markAsNonRunner);
      this.confirmDialogService.saveStockInventory(nonRunners.length, nonRunners).pipe(take(1)).subscribe(() => {
        this.saveReport();
        this.modalService.dismissAll();
      });
    }
  }

  reportData(stock: Stock) {
    if (stock.username) {
      this.username = stock.username;
    } else {
      this.username = this.authService.getUser()?.name;
    }

    if (stock.quoteStateId) {
      this.enableSaveReportBtn = false;
    }
  }
}

// buyer object  app api based on the region id and buyer object region roll colection, call api every time.
