import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take, tap } from 'rxjs/operators';
import { NotPurchasedReason } from '../not-purchased-reasons/models/not-purchased-reason.model';
import { CancellationReasonModel } from './models/cancellation-reason.model';
import { CancellationReasonsService } from './services/cancellation-reasons.service';

@Component({
  selector: 'app-cancellation-reasons',
  templateUrl: './cancellation-reasons.component.html',
  styleUrls: ['./cancellation-reasons.component.scss']
})

export class CancellationReasonsComponent implements OnInit {
  @Input() isCancellationReasonsSent: boolean;
  @Output() cancellationReason = new EventEmitter<CancellationReasonModel>();
  @Output() competitorName = new EventEmitter<string>();

  notPurchasedReason: NotPurchasedReason[];
  subReasonsList: NotPurchasedReason[];

  constructor(
    private activeModalService: NgbActiveModal,
    private cancellationReasonsService: CancellationReasonsService
  ) { }

  ngOnInit(): void {
    if (this.isCancellationReasonsSent) {
      this.cancellationReasonsService.getNotPurchasedReasons()
        .pipe(
          tap(valueReceived => {
            this.notPurchasedReason = valueReceived;
          }),
          take(1),
        ).subscribe();
    }
    this.subReasonsList = this.cancellationReasonsService.subReasonsList;
  }

  onCompetitorNameSelection(event) {
    if (event) {
      const competitorName = event.target.name;
      this.competitorName.emit(competitorName);
      this.closeCurrentModal();
    }
  }

  onCancellationReasonSelection(event) {
    let reasonId: number;
    if (event) {
      const cancellationReason = event.target.name;
      reasonId = event.target.value;
      reasonId = +reasonId;
      const reason = new CancellationReasonModel(reasonId, cancellationReason);
      this.cancellationReason.emit(reason);
      this.closeCurrentModal();
    }
  }

  closeCurrentModal() {
    this.activeModalService.close();
  }
}
