export class ModalHelper {
  static scrollModalToTopPosition(topPositionInRem: number) {
    const newTopPosition = ModalHelper.calculateNewTopPosition(topPositionInRem);
    setTimeout(function () {
      document.querySelector('.modal').scrollTo({ top: newTopPosition, behavior: 'smooth' });
    }, 500);
  }

  // scrolls the modal back to the top of the screen and
  // prevents pushing elements up by onscreen keyboard when focusing on inputs
  static setModalPositionForCustomerQuestions() {
    setTimeout(function () {
      const modal =  document.querySelector('.modal');
      if (modal) {
        modal.scrollTo({ top: 0, behavior: 'smooth' });
        modal.setAttribute('style', 'top: 0.1rem');
      }
    }, 300);
  }

  static calculateNewTopPosition(topPositionInRem: number): number {
    const screenPixelDensity = window.devicePixelRatio;
    const html = document.querySelector('html');
    const fontSize = window.getComputedStyle(html).fontSize.replace('px', '');
    return topPositionInRem * screenPixelDensity * Number(fontSize);
  }
}
