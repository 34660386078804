import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY } from 'rxjs';
import { catchError, debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { CancellationReasonModel } from '../cancellation-reasons/models/cancellation-reason.model';
import { CancellationReasonsService } from '../cancellation-reasons/services/cancellation-reasons.service';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { CustomValidators } from '../custom-validators/custom-validators';
import { NotificationService } from '../services/notification.service';
import { ToDoService } from '../services/to-do.service';
import { CancellationReasonTypes } from './enums/cancellation-reason-types';
import { BaseComponentDirective } from '../base/base.component';

@Component({
  selector: 'app-cancellation-purchase',
  templateUrl: './cancellation-purchase.component.html',
  styleUrls: ['./cancellation-purchase.component.scss']
})
export class CancellationPurchaseComponent extends BaseComponentDirective implements OnInit {
  @Input() quoteStateId: number;
  @Input() valuationAmount: number;

  cancellationReasonTypes = CancellationReasonTypes;
  isLoading: boolean;
  competitorNameValue: string;
  isButtonEnabled = false;
  cancellationReason: CancellationReasonModel;
  desiredFinalPrice: number;
  purchaseCancelForm: UntypedFormGroup;
  comments = '';


  constructor(
    private todoService: ToDoService,
    private cancellationReasonsService: CancellationReasonsService,
    private modalService: NgbModal,
    private notificationsService: NotificationService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router
  ) {
    super();
  }
  ngOnInit(): void {
    this.cancellationReason = new CancellationReasonModel(null, '');
    this.purchaseCancelForm = new UntypedFormGroup({
      desiredPrice: new UntypedFormControl('', { validators: Validators.required, asyncValidators: [CustomValidators.validateNumber(this.valuationAmount + 1, null)] }),
      comments: new UntypedFormControl('', [ Validators.required, Validators.maxLength(2000)]),
    });


    this.purchaseCancelForm.valueChanges.pipe(
        debounceTime(600),
        takeUntil(this.componentDestroyed)
      ).subscribe(form => {
        this.comments = String(form.comments);
        if (this.cancellationReason.reasonId === null || form.comments === null || form.comments.length === 0 || form.comments.length > 2000) {
          this.isButtonEnabled = false;
          return;
        } else { this.isButtonEnabled = true; }

        if (this.cancellationReason.cancellationReason === this.cancellationReasonTypes.finalPrice ||
          this.cancellationReason.cancellationReason === this.cancellationReasonTypes.competitor && this.competitorNameValue) {
            if (form.desiredPrice && (Number(form.desiredPrice) > this.valuationAmount)) {
              this.isButtonEnabled = true;
              this.desiredFinalPrice = Number(form.desiredPrice);
            } else {
              this.isButtonEnabled = false;
            }
            console.log(`Price: ${this.desiredFinalPrice}, button: ${this.isButtonEnabled}`);
        }
      });
  }

  moveCursorToEnd() {
    const el = document.getElementById('desiredPriceInputId') as HTMLInputElement;
    el.setSelectionRange(-1, -1);
    el.focus();
  }

  blockDotCharacter(event: KeyboardEvent) {
    event.preventDefault();
  }

  cancelPurchase(shouldEnable: boolean) {
    this.isLoading = true;
    if (shouldEnable) {
      this.todoService.notPurchase$(this.quoteStateId, this.cancellationReason.reasonId, this.desiredFinalPrice, this.comments).pipe(
        tap(() => {
          this.router.navigate(['/']);
          this.dismissAllModals();
          this.confirmDialogService.confirmPurchaseCancellation().pipe(take(1)).subscribe();
          this.isLoading = false;
        }),
        take(1),
        catchError(err => {
          this.notificationsService.dangerToast('Failed to cancel purchase', err);
          return EMPTY;
        }),
      ).subscribe();
    }
  }

  displayCancellationReasons() {
    const modalRef = this.cancellationReasonsService.openCancellationReasonsComponent();
    this.purchaseCancelForm.reset();
    modalRef.componentInstance.isCancellationReasonsSent = true;
    modalRef.componentInstance.cancellationReason.pipe(
      tap(valueReceived => {
        this.cancellationReason = valueReceived as CancellationReasonModel;
        if (this.cancellationReason.cancellationReason === this.cancellationReasonTypes.competitor || this.cancellationReasonTypes.finalPrice) {
          this.isButtonEnabled = false;
          this.competitorNameValue = null;
        }
        return this.cancellationReason;
      }),
      take(1),
    ).subscribe();
  }

  displayCancellationSubReasons() {
    const modalRef = this.cancellationReasonsService.openCancellationReasonsComponent();
    this.purchaseCancelForm.reset();
    modalRef.componentInstance.competitorName.pipe(
      tap(valueReceived => {
        if (valueReceived) {
          this.isButtonEnabled = false;
          this.competitorNameValue = valueReceived as string;
          return this.competitorNameValue;
        }
      }),
      take(1),
    ).subscribe();
  }

  dismissAllModals() {
    this.modalService.dismissAll();
  }
}
