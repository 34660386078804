import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-searchable-dropdown-input',
  templateUrl: './searchable-dropdown-input.component.html',
  styleUrls: ['../input.component.scss']
})
export class SearchableDropdownInputComponent extends BaseInputComponent implements OnInit {

  @Input() options: Array<KeyValuePair>;
  @Input() financePage;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() item: any;

  altGroup = new UntypedFormGroup({
    list: new UntypedFormControl(null)
  });

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.financePage) {
      super.ngOnInit();
    }
    this.altGroup.controls.list.setValidators([Validators.required, this.group.controls.value.validator]);

    this.group.controls.value.valueChanges.subscribe(() => {
      this.setEnabledState();
    });
    this.setEnabledState();

    if (this.group.controls.value.value) {
      this.altGroup.controls.list.setValue(this.group.controls.value.value);
    }
    if (this.item) {
      this.altGroup.controls.list.setValue(this.item.financeCompanyName);
    }
  }

  setEnabledState() {
    if (this.group.controls.value.enabled) {
      this.altGroup.controls.list.enable({ emitEvent: false });
    } else {
      this.altGroup.controls.list.disable({ emitEvent: false });
      this.altGroup.controls.list.setValue(null);
    }
  }

  get datalistId() {
    return `dl${this.identifier}`;
  }

  valueChanged(event) {
    const value = event.srcElement.value;
    if (this.financePage) {
      if (value !== '') {
        this.group.controls.value.setValue(value);
      } else {
        this.group.controls.value.setValue(null);
      }
    } else {
      const option = this.options.find(x => x.value === value);
      if (option) {
        this.group.controls.value.setValue(option.key);
      } else {
        this.group.controls.value.setValue(null);
      }
    }
    this.change.emit(this.group);
  }

}
