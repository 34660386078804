import { Component, Input, OnInit } from '@angular/core';
import { MileageRecord } from '../models/mileage-record.model';
import { MotProviderLogoUrlTypes } from '../enums/mot-provider-logo-url-types';

@Component({
  selector: 'app-mot-history',
  templateUrl: './mot-history.component.html',
  styleUrls: ['./mot-history.component.scss']
})

export class MotHistoryComponent implements OnInit {
  @Input() provider: string;
  @Input() mileageData: Array<MileageRecord>;
  @Input() hasDiscrepancy: boolean;
  @Input() showMotHistory: boolean;

  dvlaDropdownTitle = 'DVSA (MOT History)';
  nmrDropdownTitle = 'NMR Mileage Records';
  bcaDropdownTitle = 'BCA Mileage Records';

  dropdownTitle: string;
  providerLogo: string;

  ngOnInit(): void {
    this.setProviderDetails();
  }

  setProviderDetails() {
    switch (this.provider) {
      case 'dvsa': {
        this.providerLogo = MotProviderLogoUrlTypes.dvsa;
        this.dropdownTitle = this.dvlaDropdownTitle;
        return;
      }
      case 'nmr': {
        this.providerLogo = MotProviderLogoUrlTypes.nmr;
        this.dropdownTitle = this.nmrDropdownTitle;
        return;
      }
      case 'bca': {
        this.providerLogo = MotProviderLogoUrlTypes.bca;
        this.dropdownTitle = this.bcaDropdownTitle;
        return;
      }
    }
  }
}
