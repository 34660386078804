import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({ providedIn: 'root' })
export class AuthService {

  public static viewTodoList = 'TodoList.View';
  public static viewReviewPurchases = 'ReviewPurchases.View';
  public static migratePurchases = 'ReviewPurchases.Migrate';
  public static viewUpjar = 'Upjar.View';
  public static exemptFromQuiz = 'Quiz.Exempt';

  constructor(private msalService: MsalService) {
  }

  logout(): void {
    this.msalService.logout();
  }

  getUser(): AccountInfo {
   const currentAccounts = this.msalService.instance.getAllAccounts();
   return currentAccounts[0];
  }

  getUsername(): string {
    const account = this.getUser();
    return account ? account.username.replace('@webuyanycar.com', '').toLocaleLowerCase() : '';
  }
}
