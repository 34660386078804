import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hpi-modal',
  templateUrl: './hpi-modal.component.html',
  styleUrls: ['./hpi-modal.component.scss']
})
export class HpiModalComponent {
  @Input() title: string;
  @Input() vrm: string;
  @Input() displayImageUrl: string;
  @Input() message: string;
  @Input() isYesNo = false;
  @Input() isOk = false;
  @Input() isYes = false;

  isAltVrm: string;
  message2 = 'If it is correct please choose the VRM that matches the V5';

  constructor(public activeModal: NgbActiveModal) { }

  closeModal(): void {
    this.activeModal.close(true);
  }

  dismissModal(): void {
    this.activeModal.dismiss();
  }

  altCloseModal(): void {
    this.activeModal.close(false);
  }
}
