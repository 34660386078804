<div class="container-fluid main-page ">
  <div class="top-nav-bar-container ">
    <div class="top-nav-bar">
      <div class="task-btns">
        <a class="nav-button" (click)="setView(taskView.User)" [ngClass]="{ 'selected':view === taskView.User }">
          <img src="../../assets/icons/tasks/buyer.svg" alt="">
          <span class="button-text" [ngClass]="{ 'selected':view === taskView.User }">My Tasks</span>
        </a>
        <a *ngIf="hasRegionRoles" class="nav-button" (click)="setView(taskView.Auth)"
          [ngClass]="{ 'selected':view === taskView.Auth }">
          <img src="../../assets/icons/tasks/auth.svg" alt="">
          <span class="button-text" [ngClass]="{ 'selected':view === taskView.Auth }">Auth Tasks</span>
        </a>
      </div>
      <div class="top-nav-btns d-lg-flex d-none">
        <a class="nav-button-right " *ngIf="false">
          <img src="../../assets/icons/tasks/viewCompleted.svg" alt="">
          <span class="button-text">View Completed</span>
        </a>
        <a class="nav-button-right refresh " (click)="refreshData()">
          <img src="../../assets/icons/tasks/refresh.svg" alt="">
          <span class="button-text">Refresh</span>
        </a>
        <div ngbDropdown *ngIf="view !== taskView.Auth" class="dropdown popout">
          <button ngbDropdownToggle id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"
            class="popout-btn">
            <img src="../../assets/icons/buttons/popout.png" alt="">
          </button>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem [routerLink]="" (click)="searchVrm()" class="dropdown-item popout-btn float-btn">
              <img src="../../assets/icons/buttons/search-disabled.png" alt="" />
              <span class="float-btn-text">Search VRM</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container [ngSwitch]="view">
    <div *ngSwitchCase="taskView.User">
      <app-task-list-user>
      </app-task-list-user>
    </div>
    <div *ngSwitchCase="taskView.Auth" class="page-container">
      <app-task-list-auth [buyerObjectAuth]="buyerObject" [allRegionsObj]="allRegions">
      </app-task-list-auth>
    </div>
    <div *ngSwitchCase="taskView.Retrieve" class="page-container">
      <app-task-list-retrieve [vrm]="vrm" (setView)="setView($event)">
      </app-task-list-retrieve>
    </div>
  </ng-container>
</div>