import { Component, Input, OnInit } from '@angular/core';
import { AnswerValue } from '../../purchase/models/answer-value.model';

@Component({
  selector: 'app-optional-extras-display',
  templateUrl: './optional-extras-display.component.html',
  styleUrl: './optional-extras-display.component.scss'
})


export class OptionalExtrasDisplayComponent implements OnInit {
  
  @Input() optionalExtras: Array<AnswerValue>;
  
  ngOnInit(): void {
    this.optionalExtras.forEach(element => {
      if (element.value === 'true') {
        element.value = 'Yes'
      }
      if (element.value === 'false') {
        element.value = 'No'
      }
      if (element.displayValue === 'true') {
        element.displayValue = 'Yes'
      }
      if (element.displayValue === 'false') {
        element.displayValue = 'No'
      }
    });
  }

}
