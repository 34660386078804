import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {

  private stickyContainersVisible = new BehaviorSubject(true);

  setStickyContainersVisibleState(value: boolean) {
    this.stickyContainersVisible.next(value);
  }

  getStickyContainersVisibleState() {
    return this.stickyContainersVisible.asObservable();
  }
}
