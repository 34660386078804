export class Upjar {
    appointmentId: number;
    adjustment: number;
    newOffer: number;
    notes: string;

    constructor(appointmentId: number, newOffer: number, adjustment: number, notes: string) {
        this.appointmentId = appointmentId;
        this.newOffer = newOffer;
        this.adjustment = adjustment;
        this.notes = notes;
    }
}
