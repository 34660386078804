export interface ConfirmOptions {
  message: string;
  displayImageUrl: string;
  yesAndNoButtons: boolean;
  closeAllWindows: boolean;
}

export function createConfirmOptions(message: string, displayImageUrl: string, yesAndNoButtons: boolean, closeAllWindows?: boolean): ConfirmOptions {
  return {
    message: message,
    displayImageUrl: displayImageUrl,
    yesAndNoButtons: yesAndNoButtons,
    closeAllWindows: closeAllWindows ? true : false
  };
}
