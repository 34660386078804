export class RequiredDocument {
    documentName: string;
    present: boolean;
    editable: boolean;
    stopsPurchase: boolean;
    question: string;
    yesValue: string;
    noValue: string;

    constructor(documentName: string, present: boolean, editable: boolean, stopsPurchase: boolean,
        question: string, yesValue: string, noValue: string) {
        this.documentName = documentName;
        this.present = present;
        this.editable = editable;
        this.stopsPurchase = stopsPurchase;
        this.question = question;
        this.yesValue = yesValue;
        this.noValue = noValue;
    }
}
