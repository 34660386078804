import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-confirm-dialog-list',
  templateUrl: './confirm-dialog-list.component.html',
  styleUrls: ['./confirm-dialog-list.component.scss']
})

export class ConfirmDialogListComponent {
  @Input() message: string;
  @Input() isYesNo = false;
  @Input() displayImageUrl: string;
  @Input() messageItems: Array<string>;
  @Input() messageFooter: string;

  constructor(public activeModal: NgbActiveModal) { }
}


