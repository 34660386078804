export class QuizSubmission {
  quizAttemptId: number;
  buyerId: number;
  answers: Array<QuizAnswer>;
  startedOn: Date;
  endedOn: Date;
}

export class QuizAnswer {
  questionId: number;
  choiceId: number;
}

export class QuizFailure {
  username: string;
}
