import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() extraMessage: string;
  @Input() failButton: string;
  @Input() successButton: string;
  @Input() alternateButton: string;

  constructor(private modal: NgbActiveModal) { }

  closeModal(): void {
    this.modal.close(true);
  }

  dismissModal(): void {
    this.modal.dismiss();
  }

  altCloseModal(): void {
    this.modal.close(false);
  }
}
