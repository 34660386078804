<div class="view-header-container">
  <div class="view-header">
    <div class="view-information-field">
      <div class="row height-100-percent">
        <div class="col">
          <div class="current-time-field">
            <div class="time-header">Current Time</div>
            <div class="time">{{ currentTime | date: 'HH:mm' }}</div>
          </div>
        </div>
        <div class="col">
          <div class="branch-field">
            <div class="branch-view" *ngIf="isBranchView">BRANCH VIEW</div>
            <div class="site-name" [ngClass]="{'no-branch': isNotInBranch, 'branch': isBranchView}">{{ siteName }}</div>
        </div>
        </div>
        <div class="col" *ngIf="isBranchView"></div>
        <div class="col" *ngIf="!isBranchView">
          <a class="change-date-btn" (click)="changeDate(scrollTypes.Next)">
            <span class="date-next"></span>
          </a>
          <a class="change-date-btn today" (click)="changeDate(scrollTypes.Current)">
            <span class="nav-button-text">Today</span>
          </a>
          <a class="change-date-btn previous" (click)="changeDate(scrollTypes.Previous)">
            <span class="date-previous"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="time-text">Time</div>
    <div class="date-field" [ngClass]="{'branch': isBranchView}">
      <div class="date">{{ currentDate | date: "d MMMM y" }}</div>
      <div class="date week-day">{{ currentDate | date: "EEEE" }}</div>
    </div>
  </div>
</div>

<div *ngIf="!isNotInBranch" class="calendar-container">
  <app-time-marker *ngIf="calendarHeight"
                   [dayHours]="dayHours"
                   [dayStartHour]="dayStartHour"
                   [dayEndHour]="dayEndHour"
                   [calendarHeight]="calendarHeight">
  </app-time-marker>

  <div class="d-flex flex-row calendar" #calendar>
    <div class="d-flex flex-column">
      <div *ngFor="let hour of dayHours">
        <div class="calendar-hour-row">
          <div id="hour-row-{{ hour }}" class="hour-label">
            <hr *ngIf="hour !== dayHours[0]" />
            <div>{{ hour }}:00</div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-appointments-content" [ngClass] ="{'branch': isBranchView}">
      <div class="appointment-container px-1">
        <app-day-view-appointments *ngIf="dayDetails"
                                   [dailyAppointments]="dayDetails.dailyAppointments"
                                   [lunchTopPosition]="dayDetails.lunchTopPosition"
                                   [lunchHeight]="dayDetails.lunchHeight"
                                   [lunchSequence]="dayDetails.lunchLeft"
                                   [lunchWidth]="dayDetails.lunchWidth"
                                   [isBranchView]="isBranchView">
        </app-day-view-appointments>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isNotInBranch" class='error-container'>
  <app-diary-view-error [isNotInBranch]='isNotInBranch'></app-diary-view-error>
</div>