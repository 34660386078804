<div class="book-appointment" [hidden]="isHidden">
  <div class="modal-header">
    <h4 *ngIf="!isReschedule" class="modal-title text-center">Book Appointment</h4>
    <h4 *ngIf="isReschedule" class="modal-title text-center">Reschedule Appointment</h4>
    <img class="modal-close" (click)="cancelProcess()" alt="Close modal">
  </div>
  <div *ngIf="!noAppointmentsAvailable; else emptySlots" class="view">
    <div [ngSwitch]="view">

      <!-- Appointment Slots view -->
      <div *ngSwitchCase="bookAppointmentView.AppointmentSlotsView">
        <app-appointment-slots [appointmentSlots]="appointmentSlots"
                               [maximumValueAppointmentDelayInDays]="maximumValueAppointmentDelayInDays"
                               (selectedSite)="setSelectedSite($event)"
                               (selectedDate)="setSelectedDate($event)"
                               (appointmentSlotSelected)="onAppointmentSlotSelection()">
        </app-appointment-slots>
      </div>

      <!-- Time Slots view -->
      <div *ngSwitchCase="bookAppointmentView.TimeSlotsView">
        <app-time-slots [selectedSite]="selectedSite"
                        [selectedDate]="selectedDate"
                        [timeSlots]="timeSlots"
                        (selectedTime)="setSelectedTime($event)">
        </app-time-slots>
      </div>

      <!-- Book appointment summary -->
      <div *ngIf="selectedSiteDetails">
        <div *ngSwitchCase="bookAppointmentView.AppointmentSummary">
          <app-appointment-summary [walkInLookupData]="walkInLookupData"
                                   [selectedSiteDetails]="selectedSiteDetails"
                                   [selectedSite]="selectedSite"
                                   [selectedDate]="selectedDate"
                                   [selectedTime]="selectedTime">
          </app-appointment-summary>
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptySlots>
    <div class="no-branches-available">
      Sorry, we couldn't find that postcode. Please try again.
    </div>
  </ng-template>
  <div *ngIf="appointmentSlots" class="button">
    <button class="button__back button__back--left modal-button mt-3 mb-4" [ngClass]="{ 'button__back--centered' : view === bookAppointmentView.TimeSlotsView, 'loading' : isLoadingBackButton }" (click)="onBackClick()" [disabled]="isLoadingBackButton" [hidden]="noAppointmentsAvailable">Back</button>
    <button *ngIf="view === bookAppointmentView.AppointmentSlotsView || noAppointmentsAvailable" class="button__postcode mt-3 mb-4" [ngClass]="{ 'button__postcode--centered': noAppointmentsAvailable }" (click)="onPostcodeLookup()">New Postcode Lookup</button>
    <button *ngIf="view === bookAppointmentView.AppointmentSummary" class="button__confirm-appointment modal-button button_green" [ngClass]="{ 'loading' : isBookingAppointment }" (click)="onConfirmAppointment()" [disabled]="isBookingAppointment">Confirm Appointment</button>
  </div>
</div>
