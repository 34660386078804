import { LabelValue } from '../models/label-value.model';

export class VehicleListValue {
  public QuoteId: number;
  public QuoteGuid: string;
  public ImageUrl: string;
  public Manufacturer: string;
  public Colour: string;
  public Model: string;
  public CapCodeId: string;
  public EngineSize: string;
  public Vrm: string;
  public RegYear: string;
  public CapCode: string;
  public DefaultVehicleImageUrl = '../../assets/images/unknownCar.png';
  public FuelType: string;
  public HasSalvage: boolean;

  constructor(vehicleLabelValueLists: LabelValue[], quoteId: number, quoteGuid: string, selectedFuelType: string, hasSalvage: boolean) {
    this.QuoteId = quoteId;
    this.QuoteGuid = quoteGuid;
    this.Model = vehicleLabelValueLists.find((x) => x.label === 'Model').value;
    this.Colour = vehicleLabelValueLists.find((x) => x.label === 'Colour').value;
    this.Manufacturer = vehicleLabelValueLists.find((x) => x.label === 'Manufacturer').value;
    this.ImageUrl = vehicleLabelValueLists.find((x) => x.label === 'ImageUrl').value;
    this.CapCodeId = vehicleLabelValueLists.find((x) => x.label === 'GuideBookId').value;
    this.EngineSize = vehicleLabelValueLists.find((x) => x.label === 'Engine Size').value;
    this.Vrm = vehicleLabelValueLists.find((x) => x.label === 'Vrm').value;
    this.RegYear = `${vehicleLabelValueLists.find(x => x.label === 'Year').value}/${vehicleLabelValueLists.find(x => x.label === 'RegLetter').value}`;
    this.CapCode = vehicleLabelValueLists.find(x => x.label === 'GuideBookCode').value;
    this.FuelType = selectedFuelType;
    this.HasSalvage = hasSalvage;

    if (!this.ImageUrl) {
      this.ImageUrl = this.DefaultVehicleImageUrl;
    }
  }
}
