import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { QuizOfTheDayService } from '../../services/quizoftheday.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuizGuard  {
  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private quizOfTheDayService: QuizOfTheDayService,
    private router: Router
    ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        map(() => {
          if (this.quizOfTheDayService.isQuizRequired()) {
            return this.router.parseUrl('/quiz');
          } else {
            return true;
          }
        }
      )
    );
  }
}
