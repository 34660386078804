import { Component, OnInit, Input } from '@angular/core';
import { BaseComponentDirective } from '../base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { LookupService } from '../services/lookup.service';
import { EMPTY } from 'rxjs';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-customer-guarantees',
  templateUrl: './customer-guarantees.component.html'
})

export class CustomerGuaranteesComponent extends BaseComponentDirective implements OnInit {
  @Input() group: UntypedFormGroup;
  pricePromise: string;
  loading: boolean;

  constructor(private lookupService: LookupService, private notifications: NotificationService, private loadingService: LoadingService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.lookupService.getSettings$('Quote').pipe(
      tap(result => {
        this.pricePromise = result['PricePromiseLengthInDays'];
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        if (!this.pricePromise) {
          throw new Error('Price promise does not have a value');
        }
      }),
      catchError(err => {
        this.notifications.dangerToast('Unable to retrieve price promise.', err);
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        return EMPTY;
      }), takeUntil(this.componentDestroyed)
    ).subscribe(() => {
    });
  }

  confirmAppraisalChecked(isChecked) {
    if (isChecked) {
      this.group.controls.value.setValue(isChecked);
    } else {
      this.group.controls.value.setValue(null);
    }
  }
}
