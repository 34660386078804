<div class="form-group" [formGroup]="altGroup">
  <label [attr.for]="identifier" *ngIf="label">{{ label }}</label>
  <div class="input-group question-label" ngbDropdown #theDropdown="ngbDropdown">
    <input readonly="readonly" class="form-control dropDownInput input-text" [ngClass]="{ 'half-width': isInline, 'full-width': !isInline }" [attr.placeholder]="explanation" formControlName="list"
      autocomplete="off" autocorrect="off" ngbDropdownToggle />
      <span class="placeholder">{{ explanation }}</span>
      <span class="placeholder-line"></span>
    <div ngbDropdownMenu class="pre-scrollable">
      <button (click)="valueChanged(option.key);theDropdown.close();" *ngFor="let option of options" id="{{option.key}}" ngbDropdownItem>{{ option.value }}</button>
    </div>
  </div>
</div>
