import { Note } from './note.model';
import { ValuationSummary } from '../../damage/models/valuation-summary.model';
import { AnswerValue } from '../../purchase/models/answer-value.model';
import { DocumentScan } from '../../purchase/models/document-scan.model';
import { VehicleImage } from '../../purchase/models/vehicle-image.model';
import { AuthorizationRequest } from '../../authorization/models/authorization-request.model';
import { HpiCondition } from '../../vehicle-check-hpi/models/hpi-condition.model';

export class Appointment {
    quoteStateId: number;
    quoteId: number;
    quoteGuid: string;
    valuationGuid: string;
    appointmentTime: Date;
    canStartTime: Date;
    customerName: string;
    customerPhone: string;
    customerEmail: string;
    postcode: string;
    source: string;
    vrm: string;
    make: string;
    model: string;
    derivative: string;
    colour: string;
    mileage: number;
    engineSize: string;
    registrationDate: Date;
    bookId: number;
    capId: number;
    capCode: string;
    vpGrade: string;
    notes: Array<Note>;
    isStarted: boolean;
    isEnded: boolean;
    canReschedule: boolean;
    isLate: boolean;
    canCallCustomer: boolean;
    completedStates: Array<string>;
    isVehiclePurchased: boolean;
    condition: boolean;
    finance: boolean;
    stolen: boolean;
    vehiclePriceOffered: number;
    valuationDate: Date;
    hasTheftMaker: boolean;
    valuation: ValuationSummary;
    answers: Array<AnswerValue>;
    vehicleAge: string;
    vin: string;
    vehicleCheckId: number;
    documents: Array<DocumentScan>;
    vehicleImages: Array<VehicleImage>;
    authorizationRequests: Array<AuthorizationRequest>;
    altVrm: string;
    noHpiData: boolean;
    hasSalvage: boolean;
    hpiStatusId: number;
    assignedTo: string;
    conditionRecords: Array<HpiCondition>;
    previousMake: string;
    previousModel: string;
    previousDerivative: string;
    previousColour: string;
    previousEngineSize: string;
    previousBookId: number;
    previousCapId: number;
    previousCapCode: string;
    previousFuelType: string;
    previousImageUrl: string;
    previousRegistrationDate: Date;
    vehiclePriceOfferedBeforeFlex: number;
    suggestedWriteOffOption: string;
    chosenWriteOffOption: string;
    pricePromiseExpiryDate: Date;

    constructor() {
        this.customerName = '';
        this.customerPhone = '';
        this.source = '';
        this.vrm = '';
        this.make = '';
        this.model = '';
        this.derivative = '';
        this.colour = '';
        this.mileage = 0;
        this.engineSize = '';
        this.capId = 0;
        this.capCode = '';
        this.vpGrade = '';
        this.notes = new Array<Note>();
        this.canCallCustomer = false;
        this.isStarted = false;
        this.isEnded = false;
        this.canReschedule = false;
        this.isLate = false;
        this.completedStates = new Array<string>();
        this.altVrm = '';
    }
}
