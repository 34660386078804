<form [formGroup]="vrmSearchForm" (ngSubmit)="searchVrm()" class="search-form">
  <input id="vrmInput" type="text" class="form-control input-text" [placeholder]="placeholder"
    [ngClass]="!noInput ? !vrmSearchForm.valid ? 'invalid' : '' : ''" (keyup.enter.f.valid)="searchVrm()"
    formControlName="vrm" (ngModelChange)="allowOnlyAlphanumerics($event)" required tabindex="-1" autocomplete="off"
    maxlength="8" />
  
  <span class="placeholder-line"></span>
  <button class="search-button modal-button button_green" type="submit" [disabled]="!vrmSearchForm.valid"
    [ngClass]="{'invalid':!vrmSearchForm.valid, 'loading': loading}">
    Search
  </button>
</form>