<div *ngFor="let record of records">
  <div class="grid obfuscated-playback">
    <div class="child header">Vehicle Description</div>
    <div class="child header">Start Date</div>
    <div class="child header">Type</div>
    <div class="child header">Company</div>

    <div class="child">{{ record.vehicleDescription }}</div>
    <div class="child">{{ record.agreementDate | blankDate }}</div>
    <div class="child">{{ record.agreementType }}</div>
    <div class="child">{{ record.financeCompany }}</div>

    <div class="child header">Recorded Against</div>
    <div class="child header">Term</div>
    <div class="child header">Reference</div>
    <div class="child header">Contact Number</div>
    
    <div class="child">{{ record.recordedAgainst }}</div>
    <div class="child">{{ record.agreementTerm }}</div>
    <div class="child">{{ record.agreementReference }}</div>
    <div class="child">{{ record.contactNumber }}</div>
  </div>
</div>
