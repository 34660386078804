import { Component, HostListener, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['../input.component.scss']
})
export class TextInputComponent extends BaseInputComponent implements OnInit {

  inputMode: string;
  showMaskTyped: boolean;
  isInputDisabled: boolean;
  isPreFilledAnswer: boolean;
  isAutocapitalized: string;
  keyboardType: string;
  maxLength: number;
  minLength: number;

  showWarning: boolean;
  warningMessage: string;
  warningBorder: boolean;

  constructor(private eventsService: EventsService) {
    super();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent() {
    this.eventsService.valueChanged.emit(true);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.group) {
      this.inputMode = this.group.controls.dataType.value === 'number' ? 'numeric' : 'text';
      this.keyboardType = this.group.controls.keyboardType.value;
      if (this.group.controls.warning.value) {
        this.evaluateWarning();
      }
    }
    if (!this.keyboardType) {
      this.keyboardType = 'text';
    }
    if (!this.maxValue) {
      this.maxLength = 255;
    } else {
      this.maxLength = parseInt(this.maxValue, 10);
    }
    if (this.minValue) {
      this.minLength = parseInt(this.minValue, 10);
    }
    this.showMaskTyped = this.mask != null;
    this.isInputDisabled = this.disabled;
    this.setInputFormatType();
    if (this.group && this.group.value.value != null) {
      this.isPreFilledAnswer = true;
    }
  }

  valueChanged() {
    this.evaluateWarning();
    this.change.emit(this.group);
    // this.eventsService.valueChanged.emit(true);
    if (this.isPreFilledAnswer) {
      this.isPreFilledAnswer = false;
    }
  }

  evaluateWarning() {
    const warning = this.group.controls.warning.value;

    let showWarning = false;

    if (warning && this.group.value.value) {
      switch (warning.operator) {
        case 'not equals':
          showWarning = warning.value.trim().toLowerCase() !== this.group.value.value.trim().toLowerCase();
          break;
        case 'equals':
          showWarning = warning.value.trim().toLowerCase() === this.group.value.value.trim().toLowerCase();
          break;
        default:
          // No warning
      }
    }

    if (showWarning && this.group.valid) {
      this.showWarning = true;
      this.warningMessage = warning.message;
      this.warningBorder = warning.warningBorder;
    } else {
      this.showWarning = false;
      this.warningMessage = '';
      this.warningBorder = false;
    }
  }

  setInputFormatType() {
    if (this.formatType === 0) {
      this.isAutocapitalized = 'characters';
      return;
    }
    this.isAutocapitalized = 'none';
  }

  keypress($event) {
    if ($event.currentTarget.value.length > this.maxLength - 1) {
      if ($event.key !== 'Delete' && $event.key !== 'Backspace') {
        $event.target.blur();
      }
    }
  }
}
