import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StockResult } from '../stock/models/stock-details.model';
import { Stock } from '../stock/models/stock.model';
import { StockCheck } from '../stock/models/stock-check.model';
import { StockInventory } from '../stock/models/stock-inventory.model';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  constructor(private http: HttpClient) {}

  initCheck$(): Observable<StockCheck> {
    const url = `${environment.ppxPrivateApi}api/Stock/initCheck`;
    return this.http
      .get<StockCheck>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  getLatestStockCheck$(siteId: number): Observable<StockCheck> {
    const url = `${environment.ppxPrivateApi}api/Stock/${siteId}/StockChecks/Latest`;
    return this.http
      .get<StockCheck>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  isDailyStockCheckRequired(lastCheckDate: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return new Date(lastCheckDate) < today;
  }

  getStocks$(siteId: number): Observable<StockResult> {
    const url = `${environment.ppxPrivateApi}api/Stock/${siteId}`;
    return this.http
      .get<StockResult>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  getStockCheckVehicles$(stockCheckId: number): Observable<StockResult> {
    const url = `${environment.ppxPrivateApi}api/Stock/StockChecks/${stockCheckId}/Vehicles`;
    return this.http
      .get<StockResult>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  saveDailyStockCheck$(siteId: number, stockResult: Array<Stock>): Observable<StockCheck> {
    return this.http
      .post<StockCheck>(
        `${environment.ppxPrivateApi}api/Stock/${siteId}/StockChecks`,
        stockResult,
        {}
      )
      .pipe(catchError((err) => throwError(err)));
  }

  saveStockInventory$(stockInventory: StockInventory) {
    return this.http
      .post(
        `${environment.ppxPrivateApi}api/Stock/saveStockInventory`,
        stockInventory,
        {}
      )
      .pipe(catchError((error) => throwError(error)));
  }

  saveNonRunners$(stockResult: Array<Stock>) {
    return this.http
      .post(
        `${environment.ppxPrivateApi}api/Stock/saveNonRunners`,
        stockResult,
        {}
      )
      .pipe(catchError((err) => throwError(err)));
  }
}
