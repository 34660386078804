export class TimeSlot {
  s: Date;  // start time
  startTime: string;
  slotIds: Array<number>;
  thresholdToQualify: number;
  employeeId: number;
  isBooked: boolean;

  constructor(startTime: string, isBooked: boolean, slotIds: Array<number> = null) {
    this.startTime = startTime;
    this.isBooked = isBooked;
    this.slotIds = slotIds;
  }
}
