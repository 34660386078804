import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { SharedService } from '../../core/shared.service';
import { BaseComponentDirective } from '../../base/base.component';
import { NotificationService } from '../../services/notification.service';
import { StockService } from '../../services/stock.service';
import { Stock } from '../models/stock.model';
import { SaveReport } from '../models/save-report.model';
import { BuyerService } from '../../services/buyer.service';

@Component({
  selector: 'app-daily-stock',
  templateUrl: './daily-stock.component.html',
  styleUrls: ['./daily-stock.component.scss'],
})
export class DailyStockComponent
  extends BaseComponentDirective
  implements OnInit {
  constructor(
    private stockService: StockService,
    private notifications: NotificationService,
    private sharedService: SharedService,
    private buyerService: BuyerService
  ) {
    super();
  }
  @Output() saveReport: EventEmitter<SaveReport> = new EventEmitter();
  @Output() reportData: EventEmitter<Stock> = new EventEmitter();
  @Input() isSaved: boolean;
  @Input() stockCheckId: number;
  stockItems: Array<Stock>;
  public noVehicleInBranch: boolean;
  loading: boolean;
  showNonRunners: boolean;
  btnState: boolean;
  siteId: number;
  sitename: string;

  ngOnInit(): void {
    this.loading = true;
    this.showNonRunners = false;

    const site = this.buyerService.getCurrentSiteFromStorage();
    if (site) {
      this.sitename = site.name;
      this.siteId = site.siteId;
    } else {
      this.sitename = '';
      this.siteId = 0;
    }

    setTimeout(() => {
      this.loadData();
      this.sharedService.runner.subscribe(() => {
        this.enabledNonRunner();
       });
    }, 300);
  }

  loadData() {
    if (!this.isSaved) {
      this.stockService
        .getStocks$(this.siteId)
        .pipe(
          tap((response) => {
            this.loading = false;
            this.stockItems = response.results;
            if (response.results && response.results.length > 0) {
              this.clearHereFlag(this.stockItems);
              this.reportData.emit(response.results[0]);
            } else {
              this.sharedService.source.emit(false);
              this.noVehicleInBranch = true;
              const stock = new Stock();
              this.reportData.emit(stock);
            }
          }),
          catchError((err) => {
            this.loading = false;
            this.notifications.dangerToast('Failed to retrieve daily stocks', err);
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
    } else {
      this.stockService
        .getStockCheckVehicles$(this.stockCheckId)
        .pipe(
          tap((response) => {
            this.loading = false;
            this.stockItems = response.results;
            if (response.results.length > 0) {
              this.reportData.emit(response.results[0]);
            } else {
              this.sharedService.source.emit(false);
              this.noVehicleInBranch = true;
              const stock = new Stock();
              this.reportData.emit(stock);
            }
          }),
          catchError((err) => {
            this.loading = false;
            this.notifications.dangerToast('Failed to retrieve completed stock check', err);
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
    }
  }

  clearHereFlag(stockItems: Array<Stock>) {
    stockItems.forEach(item => {
      item.here = null;
    });
  }

  nonRunner(stockItem, checked) {
    if (this.isSaved) {
      return;
    }
    if (!stockItem.nonRunnerWhenPurchased && !stockItem.flaggedAsNonRunnerPostPurchase && stockItem.here) {
      stockItem.markAsNonRunner = !checked;
    }
  }

  here(stockItem, checked) {
    if (this.isSaved) {
      return;
    }

    if (!stockItem.Here && !stockItem.nonRunnerWhenPurchased && !stockItem.flaggedAsNonRunnerPostPurchase) {
      stockItem.markAsNonRunner = false;
    }

    const index = this.stockItems.findIndex(
      (item) => stockItem.quoteStateId === item.quoteStateId
    );
    stockItem.here = checked;
    stockItem.stockCheckCreated = new Date();
    this.stockItems[index] = stockItem;

    this.report();
    this.changeBtnState();
  }

  report() {
    this.saveReport.emit({
      checked: this.stockItems.every((element) => element.here != null),
      from: 'DailyStock',
    });
  }

  changeBtnState() {
    if (this.stockItems.every((element) => element.here != null)) {
      this.btnState = true;
    } else {
      this.btnState = false;
    }
  }

  enabledNonRunner() {
   this.showNonRunners = true;
  }
}
