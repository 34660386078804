<div class="container-fluid">
  <div [hidden]="isBusy">
    <h2 class="page-header">{{ getAreaHeading() }}</h2>
    <div class="d-lg-flex flex-row">
      <div class="col-6">
        <h4 class="page-subheader">Condition Report</h4>
        <ng-container *ngIf="selectedArea">
          <select class="form-control area-select" [value]="selectedArea.vehicleAreaId" (change)="selectDamageArea($event)" [disabled]="!canProceed">
            <option [value]="area.vehicleAreaId" *ngFor="let area of getActiveAreas()" selected="selectedArea.vehicleAreaId === area.vehicleAreaId">
               {{ getAreaNameWithCount(area) }} 
            </option>
          </select>
          <div *ngIf="!selectedArea.faults || selectedArea.faults.length === 0" class="ml-1 mt-1">
            No condition
          </div>
          <table class="table table-sm" *ngIf="selectedArea.faults && selectedArea.faults.length > 0">
            <tr *ngFor="let fault of selectedArea.faults">
              <td [@fault]='fault.isNew ? "new" : ""'>{{ fault.componentName }}</td>
              <td [@fault]='fault.isNew ? "new" : ""'>{{ fault.faultName }}</td>
              <td>
                <button class="btn btn-sm btn-danger btn-remove" placement="top" (click)="removeDamageItem(fault, true)">
                  <span class="fa fa-times-circle"></span>
                </button>
              </td>
            </tr>
          </table>
        </ng-container>
      </div>
      <div class="col-6">
        <ng-template appDamageArea></ng-template>
      </div>
    </div>
  </div>
</div>
