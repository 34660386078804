import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { VehicleType } from '../damage/models/vehicle-type.model';
import { Fault } from '../damage/models/fault.model';
import { VehicleArea } from '../damage/models/vehicle-area.model';
import { catchError } from 'rxjs/operators';
import { ValuationSummary } from '../damage/models/valuation-summary.model';
import { AppraisalContent } from '../damage/models/appraisal-content.model';
import { Signature } from '../invoice/models/signature.model';

@Injectable({
  providedIn: 'root'
})
export class DamageService {
  constructor(
    private http: HttpClient
  ) { }

  getDamageMatrix$(): Observable<Array<Fault>> {
    return this.http.get<Fault[]>(`${environment.ppxPrivateApi}api/Damage/`).pipe(
      catchError(err => throwError(err))
    );
  }

  postDamage$(quoteStateId: number, faults: Fault[]): Observable<ValuationSummary> {
    return this.http.post<ValuationSummary>(`${environment.ppxPrivateApi}api/Damage/${quoteStateId}/`, faults)
      .pipe(
      catchError(err => throwError(err))
    );
  }

  getVehicleTypes$(): Observable<Array<VehicleType>> {
    return this.http.get<Array<VehicleType>>(`${environment.ppxPrivateApi}api/Damage/vehicleComponents`).pipe(
      catchError(err => throwError(err))
    );
  }

  getVehicleAreas$(vehicleTypeName: string): Observable<Array<VehicleArea>> {
    const promise = new Observable<Array<VehicleArea>>((observer) => {
      this.getVehicleTypes$().subscribe(res => {
        const vehicleType = res.find(function (element) {
          return element.name === vehicleTypeName;
        });
        observer.next(vehicleType.areas);
    }, err => {
        observer.error(err);
      });
    });
    return promise;
  }

  getAppraisalReportContent$(quoteStateId: number): Observable<AppraisalContent> {
    return this.http.get<AppraisalContent>(`${environment.ppxPrivateApi}api/damage/appraisalreport/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  generateAppraisalReport$(quoteStateId: number, signatureImage: Signature): Observable<string> {
    return this.http.post<string>(`${environment.ppxPrivateApi}api/damage/appraisalreport/${quoteStateId}`,
      signatureImage,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        catchError(err => throwError(err))
      );
  }

  getExistingDamageFields$(quoteStateId: number): Observable<Array<Fault>> {
    return this.http.get<Array<Fault>>(`${environment.ppxPrivateApi}api/damage/existingdamagefields/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }
}
