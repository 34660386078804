export interface ConfirmOptionsList {
  message: string;
  displayImageUrl: string;
  yesAndNoButtons: boolean;
  messageItems: Array<string>;
  messageFooter: string;
}

export function createConfirmOptionsWithMessageItems(message: string,
            displayImageUrl: string,
            yesAndNoButtons: boolean,
            messageItems: Array<string>,
            messageFooter): ConfirmOptionsList {
  return {
    message: message,
    displayImageUrl: displayImageUrl,
    yesAndNoButtons: yesAndNoButtons,
    messageItems: messageItems,
    messageFooter: messageFooter
  };
}
