<div class="appointment mb-3">
  <div>{{ selectedSite.siteName}}</div>
  <div>{{ selectedDate.date | date: 'EEEE d LLLL y' }}</div>
</div>
<div class="instruction mb-3">Please select a time for the appointment:</div>
<ng-scrollbar visibility="always" class="scrollbar">
  <div class="d-flex flex-wrap slots">
    <div *ngFor="let timeSlot of timeSlots">
      <button class="slots__time" [ngClass]="{ 'slots__time--booked' : timeSlot.isBooked }" (click)="onTimeSlotSelection(timeSlot)">{{ timeSlot.startTime }}</button>
    </div>
  </div>
</ng-scrollbar>
