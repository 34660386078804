import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[]
  };
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService  {

  constructor(private authService: MsalService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole: string = route.data.expectedRole;
    const account: Account = this.authService.instance.getAllAccounts()[0];
    if (account === null || account === undefined) {return false; }
    if (!account.idTokenClaims?.roles) {
      window.alert('Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      return false;
    } else if (!account.idTokenClaims?.roles?.includes(expectedRole.toString())) {
      window.alert('You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      return false;
    }
    return true;
  }

  hasRole(role: string) {
    const account: Account = this.authService.instance.getAllAccounts()[0];
    if (account === null || account === undefined) { return false; }
    if (!account.idTokenClaims?.roles || !account.idTokenClaims?.roles?.includes(role.toString())) {
      return false;
    }
    return true;
  }
}
