import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { AuthorizationRequestService } from '../services/authorization-request.service';
import { NotificationService } from '../services/notification.service';
import { AuthorizationStatus } from './enums/authorization-status';
import { AuthorizationRequest } from './models/authorization-request.model';

@Component({
  selector: 'app-authorization-request',
  templateUrl: './authorization-request.component.html',
  styleUrls: ['./authorization-request.component.scss']
})
export class AuthorizationRequestComponent implements OnInit {
  @Input() authorizationRequests: Array<AuthorizationRequest>;

  @Output() result: EventEmitter<AuthorizationRequest> = new EventEmitter();

  status: AuthorizationStatus;
  lastRefresh: Date;
  canRefresh = false;
  canClose = false;
  hasNewRequests = false;

  getStatus = AuthorizationRequestComponent.getStatusFromRequest;

  static getStatusFromRequest(authorizationRequest: AuthorizationRequest): AuthorizationStatus {
    if (authorizationRequest.approved) {
      return AuthorizationStatus.Approved;
    } else if (authorizationRequest.declined) {
      return AuthorizationStatus.Declined;
    } else {
      return AuthorizationStatus.AwaitingApproval;
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private authorizationRequestService: AuthorizationRequestService,
    private notifications: NotificationService
    ) {
  }

  ngOnInit(): void {
    this.hasNewRequests = this.authorizationRequests.some(a => a.isNew);
    if (this.anyRequestsPending) {
      this.canRefresh = true;
    }
    if (this.allRequestsAuthorized) {
      this.canClose = true;
    }
  }

  async refresh() {
    this.canRefresh = false;
    this.lastRefresh = new Date();

    const pendingRequestIds = this.authorizationRequests
                                  .filter(a => !a.approved && !a.declined)
                                  .map(a => a.authorizationRequestId);

    this.authorizationRequestService.getRequests$(pendingRequestIds)
      .pipe(
        tap((result) => {
          for (const authResponse of result) {
            const authorizationRequest = this.authorizationRequests.find(a => a.authorizationRequestId === authResponse.authorizationRequestId);

            authorizationRequest.approved = authResponse.approved;
            authorizationRequest.declined = authResponse.declined;
            authorizationRequest.authorizedBy = authResponse.authorizedBy;
            if (authorizationRequest.approved || authorizationRequest.declined) {
              authorizationRequest.isNew = false;
            }
          }
          if (this.allRequestsAuthorized) {
            this.canClose = true;
          } else {
            setTimeout(() => { this.canRefresh = true; }, 5000);
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast('Failed to get authorization request statuses', err);
          setTimeout(() => { this.canRefresh = true; }, 5000);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
  }

  close(): void {
    this.activeModal.close(this.authorizationRequests);
  }

  getStatusClass(authorizationRequest: AuthorizationRequest): string {
    const status = AuthorizationRequestComponent.getStatusFromRequest(authorizationRequest);
    switch (status) {
      case AuthorizationStatus.AwaitingApproval: return 'awaiting';
      case AuthorizationStatus.Approved: return 'approved';
      case AuthorizationStatus.Declined: return 'declined';
      default: return '';
    }
  }

  getAuthorizationTypeDescription(authorizationTypeId) {
    return AuthorizationRequestService.getAuthorizationTypeDescription(authorizationTypeId);
  }

  get allRequestsAuthorized(): boolean {
    return this.authorizationRequests.every(a => a.approved || a.declined);
  }

  get anyRequestsPending(): boolean {
    return this.authorizationRequests.some(a => !a.approved && !a.declined);
  }
}
