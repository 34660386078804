export enum AppointmentOutcomeTypes {
  ConfirmationNeeded = 'Confirmation Needed',
  VehiclePurchased = 'Vehicle Purchased',
  VehicleNotPurchased = 'Vehicle Not Purchased',
  PreAppointmentConfirmed = 'Pre Appointment Confirmed',
  PurchaseStarted = 'Purchase Started',
  PurchaseCancelled = 'Appointment Cancelled',
  NoShow = 'No Show',
  Late = 'Customer Late',
  PurchaseRolledBack = 'Purchase Rolled Back'
}
