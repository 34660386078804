import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { ImageService } from '../../services/image.service';
import { ImageToSave } from '../models/image-to-save.model';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../../base/base.component';
import { ModalInputComponent } from '../../modal-input/modal-input.component';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { ActivatedRoute } from '@angular/router';
import { ImageTypes } from '../../enums/image-types';
import { PathFinderService } from '../../services/path-finder.service';
import { AuthorizationRequestService } from '../../services/authorization-request.service';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent extends BaseComponentDirective implements OnInit {
  @ViewChild('cameraInput', { read: ElementRef, static: true }) cameraInput: ElementRef;
  @ViewChild('fileInput', { read: ElementRef, static: true }) fileInput: ElementRef;

  @Input() hasManualImageInput: Observable<boolean>;
  @Input() label: string;
  @Output() finalisedNotifier = new EventEmitter<UntypedFormGroup>();
  @Input() group: UntypedFormGroup;
  @Input() quoteStateId: number;
  @Input() save: Subject<number>;
  @Input() displayImages: boolean;
  @Input() parameterName: string;
  @Output() busyNotifier = new EventEmitter<boolean>();
  @Input() requiredImageType: number;
  @Input() questionForm: UntypedFormGroup;

  isFromPurchaseMenu: boolean;
  allowAddImage: boolean;
  images: Array<ImageToSave>;
  imagesToSave: Array<ImageToSave> = new Array<ImageToSave>();
  imagesToDelete: Array<number> = new Array<number>();
  selectedImage: ImageToSave;
  selectedIndex: number;
  isManualInput: boolean;
  previewString: SafeUrl;
  previewTitle: string;
  isCameraInput: boolean;
  imageType: number;
  addImageLoading: boolean;
  isAdditionalImage: boolean;
  isPostPurchase: boolean;
  inProgress: boolean;
  hasMismatch: boolean;
  authCheck: UntypedFormGroup;
  imageTypes: typeof ImageTypes;


  @Input()
  set imageData(imgs: Array<ImageToSave>) {
    this.images = imgs;
    this.resetImages();
    if (imgs) {
      imgs.forEach((img) => {
        const image = new ImageToSave();
        const paramName = img.parameterName ? img.parameterName : img.documentName;
        image.documentScanId = img.documentScanId;
        image.imageNumber = img.imageNumber ? img.imageNumber : 0;
        image.documentName = img.documentName;
        image.parameterName = img.parameterName;
        image.stampCode = img.stampCode;
        image.fileName = img.fileName;
        image.parameterName = this.parameterName ? this.parameterName : paramName;
        image.tag = img.tag;
        image.threshold = img.threshold;
        if (img.imgAsBase64String) {
          image.imgAsBase64String = img.imgAsBase64String;
          image.thumbnailUrl = img.thumbnailUrl;
        }
        this.addImage(image);
      });
    }
  }

  constructor(
    public imageService: ImageService,
    private pathFinder: PathFinderService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private authorizationRequestService: AuthorizationRequestService,
    private notifications: NotificationService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.imageType = ImageTypes.ScannedDocument;
    this.imageTypes = ImageTypes;

    this.allowAddImage = this.group
      ? this.group.controls.allowAddImage.value
      : true;

    this.route.queryParamMap
      .pipe(
        tap((params) => {
          const imageParam = params.get('imageType');

          const value = params.get('isFromPurchaseMenu');
          this.isFromPurchaseMenu = value ? value.toLocaleLowerCase() === 'true' : false;

          if (imageParam) {
            this.imageType = parseInt(imageParam, 10);
            this.isPostPurchase = true;
          } else {
            this.imageType = this.requiredImageType === ImageTypes.VehiclePhoto
              ? ImageTypes.VehiclePhoto
              : ImageTypes.ScannedDocument;
            this.isPostPurchase = false;
          }
        }),
        takeUntil(this.componentDestroyed)
      ).subscribe();

      this.getImages();

      this.save.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
        this.imageService.addImagesToSave(this.imagesToSave);
        this.imageService.addImagesToDelete(this.imagesToDelete);
      });

      this.imageService.saveDbImage.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
        this.imageService.removeImagesToSave();
        this.imageService.addImagesToSave(this.imagesToSave);
        this.imageService.addImagesToDelete(this.imagesToDelete);
      });

      this.authCheck = this.getFormControl('V5AuthCheck');
      if (this.authCheck) {
        this.authCheck.controls.value.setValue('false');
      }
  }

  get imageData() {
    return this.imagesToSave;
  }

  getFormControl(parameterName: string): UntypedFormGroup {
    if (this.questionForm) {
      return this.questionForm.get('items')['controls'].filter(x => x.value.name === parameterName)[0];
    }
    return null;
  }

  getImages() {
    if (this.group && this.quoteStateId) {
      if (this.imageType === ImageTypes.VehiclePhoto) {
        this.getVehicleImages();
      } else {
        this.getScannedDocuments();
      }
    }
  }

  getVehicleImages() {
    this.imageService.getExistingVehicleImages$(this.quoteStateId)
    .pipe(
      catchError((err) => {
        this.notifications.dangerToast(
          'An error occurred while trying to get existing images.',
          err
        );
        this.group.controls.value.setValue(null);
        this.finalisedNotifier.emit(this.group);
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
    .subscribe((result) => {
      if (result) {
        result.forEach(element => {
          const index = this.imagesToSave.findIndex(x => x && element.description === x.documentName);
          if (index !== -1) {
            this.imagesToSave[index].documentScanId = element.vehicleImageId;
            this.imagesToSave[index].fileName = element.fileName;
            this.imagesToSave[index].imgAsBase64String = element.imgAsBase64String.toString();
            this.imagesToSave[index].thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(
              element.imgAsBase64String.toString()
            );
            this.imagesToSave[index].isSaved = true;
          }
        });
      }
      this.checkIfAllImagesAdded();
    });
  }

  getScannedDocuments() {
    this.imageService.getExistingImages$(this.quoteStateId)
    .pipe(
      catchError((err) => {
        this.notifications.dangerToast(
          'An error occurred while trying to get existing images.',
          err
        );
        this.group.controls.value.setValue(null);
        this.finalisedNotifier.emit(this.group);
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
    .subscribe((result) => {
      if (this.displayImages && this.imageType === ImageTypes.ScannedDocument) {
        this.addImagesFromAnsweredQuestions(result);
      } else {
        const imagesForRequiredDocTypes = result.filter(
          (r) => r.parameterName === this.parameterName && !r.fileName.endsWith('.pdf')
        );
        let index = 0;
        imagesForRequiredDocTypes.forEach((img) => {
          const image = new ImageToSave();
          image.imageNumber = img.imageNumber;
          image.documentName = img.documentName;
          image.parameterName = img.parameterName;
          image.stampCode = img.stampCode;
          image.documentScanId = img.documentScanId;
          image.fileName = img.fileName;
          image.imgAsBase64String = img.imgAsBase64String;
          image.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(
            img.imgAsBase64String
          );
          image.tag = img.tag;
          image.threshold = img.threshold;
          image.recognitionResult = img.recognitionResult;
          image.isSaved = true;
          this.addImageWithBase64String(image, index);
          index++;
        });

        this.getAuthRequestsAndSetMismatch();

        this.isManualInput = false;
        if (imagesForRequiredDocTypes.length === 0) {
          if (
            this.hasManualImageInput &&
            this.group.controls.value.value === '1'
          ) {
            this.manualInputSelected();
          } else {
            this.group.controls.value.setValue(null);
            this.finalisedNotifier.emit(this.group);
          }
        } else {
          this.checkIfAllImagesAdded();
        }
      }
    });
  }

  checkAuthorization() {
    if (this.authCheck) {
      if (this.hasMismatch) {
        this.authCheck.controls.value.setValue('true');
      } else {
        this.authCheck.controls.value.setValue('false');
      }
    }
  }

  getAuthRequestsAndSetMismatch() {
    this.authorizationRequestService.getRequestByQuoteStateId$(this.quoteStateId).pipe(
      catchError((err) => {
        this.notifications.dangerToast(
          'An error occurred while trying to get auth request by quoteStateId.',
          err
        );
        return EMPTY;
      }),
      takeUntil(this.componentDestroyed)
    )
    .subscribe((requests) => {
      const check = this.imagesToSave.some(x => x.recognitionResult <= x.threshold && x.recognitionResult != null);
      const authRequests = requests;
      if (authRequests && authRequests.length > 0) {
        const request = requests.slice(-1)[0];
        if (check && request && request.approved == null) {
          this.setMismatch();
          this.hasMismatch = true;
          this.checkForMismatch();
        }
      } else {
        if (check) {
          this.setMismatch();
          this.hasMismatch = true;
          this.checkForMismatch();
        }
      }
    });

  }

  addImagesFromAnsweredQuestions(imgs: ImageToSave[]) {
    let index = 0;
    imgs.forEach(img => {
      if (!img.fileName.endsWith('.pdf')) {
        const image = new ImageToSave();
        image.documentName = img.documentName;
        image.stampCode = img.stampCode;
        image.parameterName = img.parameterName;
        image.tag = img.tag;
        image.threshold = img.threshold;
        image.documentScanId = img.documentScanId;
        image.imgAsBase64String = img.imgAsBase64String;
        image.thumbnailUrl = this.sanitizer.bypassSecurityTrustUrl(
          img.imgAsBase64String
        );
        image.recognitionResult = img.recognitionResult;
        image.extension = img.fileName;
        image.hasMismatch = img.hasMismatch;
        image.isSaved = true;
        this.addImageWithBase64String(image, index);
        index += 1;
      }
    });

    const questions = this.pathFinder.getAnsweredImageQuestions();
    questions.forEach((question) => {
      question.images.forEach((element) => {
        const existingIndex = this.imagesToSave
          .map((obj) => ({ documentName: obj.documentName, stampCode: obj.stampCode, parameterName: obj.parameterName }))
          .findIndex((x) => x.documentName === element.documentName && x.stampCode === element.stampCode || x.parameterName === question.parameterName && x.stampCode === 'Unknown');
          if (element.isVideo) {
          // Do nothing. Get rid of Video image
          return;
        }
          if (existingIndex === -1) {
          const image = new ImageToSave();
          image.documentName = element.documentName;
          image.stampCode = element.stampCode;
          image.parameterName = question.parameterName;
          image.tag = element.tag;
          image.threshold = element.threshold;
          image.hasMismatch = element.hasMismatch;
          image.recognitionResult = element.recognitionResult;
          this.addImageWithBase64String(image, index);
          index += 1;
        } else {
          this.imagesToSave[existingIndex].documentName = element.documentName;
        }
      });
    });

    this.getAuthRequestsAndSetMismatch();

    this.checkIfAllImagesAdded();
  }

  onAddImageClick(): void {
    if (this.displayImages) {
      this.showModal();
    } else {
      this.isAdditionalImage = true;
      const image = new ImageToSave();
      image.imageNumber = this.imagesToSave.length + 1;
      image.documentName = this.imagesToSave[0].documentName;
      image.stampCode = this.imagesToSave[0].stampCode;
      image.parameterName = this.imagesToSave[0].parameterName;

      const index = this.imagesToSave.length;
      this.openCamera(image, index);
    }
  }
  get isHidden(): boolean {
    if (!this.group) {
      return false;
    }
    return this.group.controls.isHidden.value;
  }

  get isDashboardImageUploaded() {
    return (
      this.parameterName === 'Dashboard' && this.imagesToSave[0].thumbnailUrl
    );
  }

  addImageToDelete(documentScanId: number) {
    this.imagesToDelete.push(documentScanId);
    if (!this.group) {
      this.imageService.addImagesToDelete(this.imagesToDelete);
    }
    this.pathFinder.hasAuthorization = false;
  }

  addImage(image) {
    this.imagesToSave.push(image);
  }

  openCamera(image, i) {
    if (!this.inProgress && !image.hasMismatch) {
      this.inProgress = true;
      this.isManualInput = false;
      this.selectedImage = image;
      this.selectedIndex = i;
      this.cameraInput.nativeElement.click();
    }
  }

  openFile(image, i) {
    if (!this.inProgress && !image.hasMismatch) {
      this.inProgress = true;
      this.isManualInput = false;
      this.selectedImage = image;
      this.selectedIndex = i;
      this.fileInput.nativeElement.click();
    }
  }

  manualInputSelected() {
    this.isManualInput = true;
    for (let i = this.imagesToSave.length - 1; i >= 0; i -= 1) {
      this.deleteImage(this.imagesToSave[i]);
    }
    this.group.controls.value.setValue('1');
    this.finalisedNotifier.emit(this.group);
  }

  cancelFileUpload() {
    this.inProgress = false;
  }

  onFileSelected(event) {
    if (this.isAdditionalImage) {
      this.addImageLoading = true;
    }
    const target = event.target || event.srcElement;
    const image = target.files[0];
    const imgToSave = new ImageToSave();
    if (!this.addImageLoading) {
      this.imagesToSave[this.selectedIndex].isLoading = true;
    }
    const imageExtension =
      '.' +
      image.type.substring(image.type.indexOf('/') + 1, image.type.length);
    const fileTypeAllowed = imageExtension === '.png' || imageExtension === '.jpg' || imageExtension === '.jpeg';
    if (!fileTypeAllowed) {
      this.notifications.warningToast(
        'File to upload must be an image'
      );
    }
    if (fileTypeAllowed && this.checkUniqueName(image.name)) {
      this.reduceFileSize(image, 500 * 1024, 800, 800, 0.9, (blob) => {
        if (!blob.name) {
          blob.name = image.name;
        }
        if (this.allowAddImage || this.checkUniqueName(blob.name)) {
          imgToSave.image = blob;
          imgToSave.thumbnailUrl = this.createUrl(blob);
          const reader = new FileReader();
          reader.onloadend = () => {
            imgToSave.imgAsBase64String = reader.result.toString();
            imgToSave.imageNumber = this.selectedImage.imageNumber;
            imgToSave.documentName = this.selectedImage.documentName;
            imgToSave.parameterName = this.selectedImage.parameterName;
            imgToSave.stampCode = this.selectedImage.stampCode;
            imgToSave.extension = imageExtension;
            imgToSave.fileName = this.getFileName(imgToSave);
            imgToSave.tag = this.selectedImage.tag;
            imgToSave.threshold = this.selectedImage.threshold;
            if (imgToSave.tag) {
              this.imageService.getImageRecognition$(imgToSave, this.quoteStateId).pipe(
                tap((res) => {
                  if (this.areAllImagesAdded()) {
                    // Create unique id for auth value parameter
                    const u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
                    const guid = [u.substring(0, 8), u.substring(8, 4), '4000-8' + u.substring(13, 3), u.substring(16, 12)].join('-');
                    const v5Picture = this.getFormControl('V5Picture');
                    if (v5Picture) {
                      v5Picture.value.value = guid;
                    }

                    this.group.controls.value.setValue(guid);
                    this.finalisedNotifier.emit(this.group);
                  }
                  imgToSave.recognitionResult = res.probability;
                  imgToSave.hasMismatch = res.hasMismatch;
                  this.checkForMismatch();
                }),
                catchError(err => {
                  this.notifications.dangerToast('Failed to get image recognition', err);
                  return EMPTY;
                }),
                takeUntil(this.componentDestroyed)
              ).subscribe();
            }
            this.addImageWithBase64String(imgToSave, this.selectedIndex);
            this.imageService.imageAdded.emit(true);
            this.checkIfAllImagesAdded();
            this.imagesToSave[this.selectedIndex].isLoading = false;
            this.addImageLoading = false;
            this.isAdditionalImage = false;
            this.inProgress = false;
          };
          reader.readAsDataURL(blob);
        }
      });
    } else {
      this.addImageLoading = false;
      this.isAdditionalImage = false;
      this.inProgress = false;
      this.imagesToSave[this.selectedIndex].isLoading = false;
    }
    this.checkIfAllImagesAdded();
    target.value = '';
  }

  checkIfAllImagesAdded() {
    if (this.group) {
      if (this.areAllImagesAdded()) {
        this.group.controls.value.setValue('0');
      } else {
        this.group.controls.value.setValue(null);
      }
      this.finalisedNotifier.emit(this.group);
      if (this.displayImages) {
        this.imageService.allImagesAdded.emit(this.areAllImagesAdded());
      }
    }
  }

  setMismatch() {
    this.imagesToSave.forEach(image => {
      if (image.recognitionResult != null && image.recognitionResult < image.threshold) {
        image.hasMismatch = true;
      }
    });
  }

  checkForMismatch() {
      let mismatch = false;
      this.imagesToSave.forEach(element => {
        if (element.hasMismatch) {
          mismatch = true;
        }
      });
      if (mismatch) {
        this.hasMismatch = true;
      } else {
        this.hasMismatch = false;
      }
      this.checkAuthorization();
    }

  reduceFileSize(file, acceptFileSize, maxWidth, maxHeight, quality, callback) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const scope = this;
    const img = new Image();

    img.onerror = function () {
      URL.revokeObjectURL(img.src);
      callback(file);
    };
    img.onload = function () {
      URL.revokeObjectURL(img.src);
      scope.getOrientation(file, (orientation) => {
        let w = img.width,
          h = img.height;
        let scale =
          orientation > 4
            ? Math.min(maxHeight / w, maxWidth / h, 1)
            : Math.min(maxWidth / w, maxHeight / h, 1);
        // Don't upscale if small image
        if (file.size <= acceptFileSize && scale > 1) {
          scale = 1;
        }
        h = Math.round(h * scale);
        w = Math.round(w * scale);

        const canvas = scope.imgToCanvasWithOrientation(img, w, h);
        canvas.toBlob(
          function (blob) {
            callback(blob);
          },
          'image/jpeg',
          quality
        );
      });
    };
    img.src = URL.createObjectURL(file);
  }

  checkUniqueName(fileName: string): boolean {
    const existing = this.imagesToSave.find(function (element) {
      return element.image?.fileName === fileName;
    });
    if (existing) {
      this.notifications.warningToast(
        'An image with this name has already been added'
      );
    }
    return !existing;
  }

  getFileName(imageToSave: ImageToSave): string {
    const timestamp = new Date().getTime();
    let fileName = imageToSave.stampCode.toString() + '-' + imageToSave.parameterName + '-' + timestamp.toString() + imageToSave.extension;
    if (this.allowAddImage) {
      let index = 1;
      while (this.imagesToSave.some((img) => img.fileName === fileName)) {
        fileName = imageToSave.stampCode.toString() + '-' + imageToSave.parameterName + ' (' + index.toString() + ')' + imageToSave.extension;
        index++;
      }
    }
    return fileName;
  }

  imgToCanvasWithOrientation(img, rawWidth, rawHeight) {
    const canvas = document.createElement('canvas');
    canvas.width = rawWidth;
    canvas.height = rawHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
    return canvas;
  }

  createUrl(photo) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(photo));
  }

  getOrientation(file: File, callback: (x: number) => void) {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent) => {
      if (!event.target) {
        return;
      }

      const fileReader = event.target as FileReader;
      const view = new DataView(fileReader.result as ArrayBuffer);

      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2);
      }

      return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  }

  showPreview(photo, content) {
    if (photo.documentScanId) {
      if (this.imageType === ImageTypes.VehiclePhoto) {
        this.imageService
          .getImagePreview$(photo.documentScanId, ImageTypes.VehiclePhoto)
          .pipe(
            catchError((err) => {
              this.notifications.dangerToast(
                'An error occurred while trying to get existing images.',
                err
              );
              return EMPTY;
            }),
            takeUntil(this.componentDestroyed)
          )
          .subscribe((result) => {
            this.previewString =
              this.sanitizer.bypassSecurityTrustUrl(
                result.imgAsBase64String
              );
            this.previewTitle = result.description;
            this.modalService.open(content, { size: 'lg' });
          });
      } else if (this.imageType === ImageTypes.ScannedDocument) {
        this.imageService
          .getImagePreview$(photo.documentScanId, ImageTypes.ScannedDocument)
          .pipe(
            catchError((err) => {
              this.notifications.dangerToast(
                'An error occurred while trying to get existing images.',
                err
              );
              return EMPTY;
            }),
            takeUntil(this.componentDestroyed)
          )
          .subscribe((result) => {
            this.previewString = this.sanitizer.bypassSecurityTrustUrl(
              result.imgAsBase64String
            );
            this.previewTitle = photo.documentName;
            this.modalService.open(content, { size: 'lg' });
          });
      }
    } else {
      this.previewString = photo.thumbnailUrl;
      this.previewTitle = photo.documentName;
      this.modalService.open(content, { size: 'lg' });
    }
  }

  deleteImage(photo) {
    photo.image = null;
    photo.imgAsBase64String = null;
    photo.thumbnailUrl = null;
    photo.hasMismatch = false;
    this.addImageToDelete(photo.documentScanId);
    if (this.canBeDeleted(photo)) {
      this.removeImage(photo);
    }

    if (!this.isManualInput) {
      this.checkIfAllImagesAdded();
    }
    this.imageService.imageAdded.emit(false);
    this.checkForMismatch();
  }

  canBeDeleted(photo: ImageToSave) {
    if (this.displayImages || this.allowAddImage) {
      return this.imagesToSave.filter(item => item.documentName === photo.documentName).length > 1;
    } else {
      return false;
    }
  }

  removeImage(imageToDelete: ImageToSave) {
    const index = this.imagesToSave.indexOf(imageToDelete);
    this.imagesToSave.splice(index, 1);
  }

  addImageWithBase64String(image: ImageToSave, index: number) {
    if (index > -1) {
      this.imagesToSave[index] = image;
    }
  }

  resetImages() {
    this.imagesToSave = new Array<ImageToSave>();
  }

  areAllImagesAdded() {
    if (this.imageType === ImageTypes.VehiclePhoto) {
      return true;
    } else if (this.isPostPurchase) {
      return this.imagesToSave.some((i) => i.imgAsBase64String);
    } else {
      return this.imagesToSave.every((i) => i.imgAsBase64String);
    }
  }

  showModal() {
    const options = new Array<KeyValuePair>();
    let index = 0;
    this.imagesToSave.forEach(img => {
      if (!options.find(x => x.value === img.documentName)) {
        options.push(new KeyValuePair(index.toString(), img.documentName));
      }
      index += 1;
    });
    if (this.requiredImageType === ImageTypes.ScannedDocument && !options.find(x => x.value === 'Other')) {
      options.push(new KeyValuePair('-1', 'Other'));
    }
    if (this.requiredImageType === ImageTypes.ScannedDocument && !options.find(x => x.value === 'Keys')) {
      options.push(new KeyValuePair('-2', 'Keys'));
    }


    const modalRef = this.modalService.open(ModalInputComponent, {
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.title =
      this.imageType === ImageTypes.ScannedDocument
        ? 'Please select additional documents:'
        : 'Please select additional vehicle images:';
    modalRef.componentInstance.close = true;
    modalRef.componentInstance.options = options;

    modalRef.result.then(
      (result) => {
        const resIndex = parseInt(result, 10);
        const image = new ImageToSave();
        if (resIndex === -1) {
          image.documentName = 'Other';
          image.stampCode = 'Unknown';
          image.parameterName = 'Other';
        } else if (resIndex === -2) {
          image.documentName = 'Keys';
          image.stampCode = 'K';
          image.parameterName = 'Keys';
        } else {
          image.documentName = this.imagesToSave[resIndex].documentName;
          image.stampCode = this.imagesToSave[resIndex].stampCode;
          image.parameterName = this.imagesToSave[resIndex].parameterName;
        }
        this.imagesToSave.push(image);
        this.imageService.allImagesAdded.emit(this.areAllImagesAdded());
      },
      () => { }
    );
  }

  get checkDoItLaterButton() {
    if (this.imagesToSave.length === 0) {
      return false;
    }
    return (
      this.imagesToSave.length % 5 === 4 || this.imagesToSave.length % 5 === 0
    );
  }

  get checkAddImageButton() {
    if (this.imagesToSave.length === 0) {
      return false;
    }
    return this.imagesToSave.length % 5 === 0;
  }

  get showUploadButton() {
    return this.displayImages && this.requiredImageType === ImageTypes.ScannedDocument;
  }
}
