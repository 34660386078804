import { QuestionTransition } from './question-transition.model';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { Warning } from './warning';
import { Condition } from './condition.model';
import { ImageToSave } from '../../images/models/image-to-save.model';

export class Question {
  parameterName: string;
  displayQuestion: string;
  explanation: string;
  dataType: string;
  condition: Condition;
  controlType: number;
  regEx: string;
  value: string;
  displayValue: string;
  hiddenValue: string;
  isHidden: boolean;
  isRequired: boolean;
  options: Array<KeyValuePair>;
  transitions: Array<QuestionTransition>;
  damageOptionKey: string;
  informedAnswer: string;
  disabled: boolean;
  minValue: string;
  maxValue: string;
  formatType: number;
  mask: string;
  keyboardType: string;
  inlineWithPrevious: number;
  warning: Warning;
  images: ImageToSave[];
  hasManualImageInput: boolean;
  allowAddImage: boolean;
  requiredDocument: string;
  missingDocument: string;
  encrypted: boolean;
  addedFromAnswers: number;
  answerNeedsImage: number;

  constructor(parameterName: string, value: string) {
    this.parameterName = parameterName;
    this.value = value;
  }
}
