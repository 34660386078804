<div *ngIf="!loading" class="container valuation-container">
  <div class="row">
    <div class="col-6">
      <p class="title">Valuation <span *ngIf="hasDamageCost">*</span> </p>
    </div>
    <div class="col-3 price">{{ valuation.vehiclePriceOfferedPlusVat | currency : 'GBP' }}
      <span class="bonus" *ngIf="valuation.hasUpjar">Upjar Applied</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6 valuation-title">
      <p class="valuation-title">Admin Fee</p>
    </div>
    <div class="col-3 price-red">
      <p>{{valuation.adminFee | currency: 'GBP'}}</p>
    </div>
  </div>
  <div class="row" *ngIf="hasVat">
    <div class="col-6 valuation-title">
      <p class="valuation-title">VAT Amount</p>
    </div>
    <div class="col-2 price">
      <p>{{ valuation.vat | currency : 'GBP' }}</p>
    </div>
  </div>
  <ng-container *ngIf="valuation.outstandingFinance != 0">
    <div class="row">
      <div class="col-6 valuation-title">
        <p class="valuation-title">Outstanding Finance</p>
      </div>
      <div class="col-3 price-red">
        <p>{{valuation.outstandingFinance | currency : 'GBP'}}</p>
      </div>
    </div>
  </ng-container>

  <div class="row"></div>
  <div class="row" *ngIf="!hasVat">
    <div class="col-12 valuation-title-offer">Your Offer:</div>
  </div>
  <div class="row" *ngIf="!hasVat">
    <div class="col-12"
      [ngClass]="{'valuation-offer-red' : valuation.customerPaymentAmount < 0, 'valuation-offer-green' : valuation.customerPaymentAmount > 0 }">
      <p>{{valuation.customerPaymentAmount | currency : 'GBP'}}</p>
    </div>
    </div>
    <div class="row" *ngIf="hasVat">
      <div class="col-6 valuation-title-offer">Your Offer inc. VAT:</div>
      <div class="col-6 valuation-title-offer">Your Offer ex. VAT:</div>
    </div>
    <div class="row" *ngIf="hasVat">
      <div class="col-6"
        [ngClass]="{'valuation-offer-red' : valuation.customerPaymentAmount < 0, 'valuation-offer-green' : valuation.customerPaymentAmount > 0 }">
        <p>{{valuation.customerPaymentAmount | currency : 'GBP'}}</p>
      </div>
      <div class="col-6"
        [ngClass]="{'valuation-offer-red' : valuation.offerPriceWithoutVat < 0, 'valuation-offer-green' : valuation.offerPriceWithoutVat > 0 }">
        <p>{{valuation.offerPriceWithoutVat | currency : 'GBP'}}</p>
      </div>
    </div>

  <ng-container *ngIf="valuation.customerPaymentAmount < 0">
    <div class="container align-items">
      <div class="row">
        <div class="col-12 items">
          <a [href]="paymentUrl" (click)="takePayment()" class="modal-button button_green" target=”_blank”>
            Take Payment Now
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 items">
          <p class="quote">Purchase ID: {{valuation.quoteStateId}}</p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="hasDamageCost">
    <div class="row">
      <div class="col-12 items">
        <p class="condition-report">* = Condition Report Costs deducted</p>
      </div>
    </div>
  </ng-container>
</div>
