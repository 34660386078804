export class WalkInLookupData {
  quoteStateId: number;
  quoteId: number;
  quoteGuid: string;
  valuationGuid: string;
  vrm: string;
  altVrm: string;
  mileage: number;
  previousOwners: number;
  serviceHistoryId: number;
  emailOptIn: boolean;
  isVatRegistered: boolean;
  customerName: string;
  postcode: string;
  emailAddress: string;
  telephoneNumber: string;
  appointmentDate: Date;
  appointmentSlotIds: Array<number>;
  colour: string;
  vehicleImageUrl: string;
  isManualLookup: boolean;

  constructor(quoteId: number, quoteGuid: string, vrm: string, mileage: number, previousOwners: number, serviceHistoryId: number, emailOptIn: boolean,
    isVatRegistered: boolean, customerName: string, postcode: string, emailAddress: string, telephoneNumber: string) {
    this.quoteId = quoteId;
    this.quoteGuid = quoteGuid;
    this.vrm = vrm;
    this.mileage = mileage;
    this.previousOwners = previousOwners;
    this.emailOptIn = emailOptIn;
    this.isVatRegistered = isVatRegistered;
    this.customerName = customerName;
    this.postcode = postcode;
    this.emailAddress = emailAddress;
    this.telephoneNumber = telephoneNumber;
    this.serviceHistoryId = serviceHistoryId;
  }
}
