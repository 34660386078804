<div *ngIf="(!isHidden && group) || displayImages">
    <div [innerHTML]="label "class="question-label"></div>
    <div class="wrapper">
        <div *ngFor="let image of imagesToSave; let i = index;" class="wrapper-item">
            <div>
                <div class="image-input" [ngClass]="{ 'no-image': image.thumbnailUrl == null }">
                    <div [hidden]="image.thumbnailUrl" (click)="openCamera(image, i)" class="document-name">
                        {{ image.documentName }}
                    </div>
                    <img class="obfuscated-playback" [hidden]="!image.thumbnailUrl" [src]="image.thumbnailUrl" alt="" [ngClass]="'thumbnail'"
                         (click)="showPreview(image, preview)" />
                    <span *ngIf="!isPostPurchase || isFromPurchaseMenu || !image.isSaved" class="obfuscated-playback" [hidden]="!image.thumbnailUrl" class="delete-icon thumbnail-delete"
                          (click)="deleteImage(image)"></span>
                </div>
                <div class="btn-box">
                    <button class="camera-button" (click)="openCamera(image, i)"
                        [ngClass]="{ 'mini-button': showUploadButton, 'loading': image.isLoading, 'active': !isManualInput && image.thumbnailUrl, 'isTaken': image.thumbnailUrl != null && !image.hasMismatch, 'inProgress': inProgress, 'hasMismatch': image.hasMismatch }"
                        [disabled]="image.thumbnailUrl != null && !image.hasMismatch">
                        <span [hidden]="image.isLoading" class="camera-btn-icon"
                            [ngClass]="{ 'active': !isManualInput, 'isTaken': image.thumbnailUrl != null && !image.hasMismatch, 'inProgress': inProgress, 'camera-btn-icon-red': image.hasMismatch}"></span>
                    </button>
                    <button class="camera-button upload-button" *ngIf="showUploadButton" (click)="openFile(image, i)"
                        [ngClass]="{ 'mini-button': showUploadButton, 'loading': image.isLoading, 'active': !isManualInput && image.thumbnailUrl, 'isTaken': image.thumbnailUrl != null && !image.hasMismatch, 'inProgress': inProgress, 'hasMismatch': image.hasMismatch }"
                        [disabled]="image.thumbnailUrl != null && !image.hasMismatch">
                        <span [hidden]="image.isLoading" class="camera-btn-icon upload-btn-icon"
                            [ngClass]="{ 'active': !isManualInput, 'isTaken': image.thumbnailUrl != null && !image.hasMismatch, 'inProgress': inProgress, 'upload-btn-icon-red': image.hasMismatch}"></span>
                    </button>
                </div>
            </div>
        </div>
        <button [disabled]="!areAllImagesAdded() && !displayImages" [ngClass]="{ 'loading': addImageLoading, 'action-button-no-margin': checkAddImageButton}" [hidden]="!allowAddImage"
            class="action-button" (click)="onAddImageClick()">
            <span>Add Image</span>
        </button>
        <button [hidden]="!hasManualImageInput" [ngClass]="{ 'active': isManualInput, 'action-button-no-margin': checkDoItLaterButton }" class="action-button"
            (click)="manualInputSelected()">
            <span>Do It Later</span>
        </button>
    </div>
    <p *ngIf="hasMismatch" class="mismatch-warning-text">**Please take a quality photo of the V5**</p>

    <ng-container [ngSwitch]="imageType">
      <div *ngSwitchCase="imageTypes.VehiclePhoto">
        <p class="message">Please ensure that photos contain only car images and no customer document images</p>
      </div>
      <div *ngSwitchCase="imageTypes.ScannedDocument">
        <p *ngIf="displayImages" class="message doc">Please upload all mandatory document images before continuing</p>
      </div>
    </ng-container>
</div>


<div *ngIf="isDashboardImageUploaded  && !displayImages">
    <div class="row flex-grow">
        <span *ngFor="let image of imagesToSave" class="flex-container col-12">
            <span class="image-container margin-left">
                <img [hidden]="!image.thumbnailUrl" [src]="image.thumbnailUrl" alt="" class="thumbnail-lg"
                    (click)="showPreview(image, preview)" />
                <span [hidden]="!image.thumbnailUrl" class="delete-icon thumbnail-delete-dashboard "
                    (click)="deleteImage(image)"></span>
            </span>
        </span>
    </div>

</div>

<div *ngIf="!group && !displayImages" class="button-grow">
    <button *ngIf="!isDashboardImageUploaded" type="button" class="modal-button image-dashboard flex-grow"
    (click)="openCamera(imagesToSave[0], 0)" [ngClass]="{ 'active': isCameraInput }">
    <span class="camera-btn-icon" [ngClass]="{ 'active': isCameraInput }"></span>
</button>
</div>


<input #cameraInput hidden type="file" capture="camera" accept="image/*" id="cameraInput" name="cameraInput"
    (change)="onFileSelected($event)" (cancel)="cancelFileUpload()">
<input #fileInput hidden type="file" accept="image/*" id="fileInput" name="fileInput"
    (change)="onFileSelected($event)" (cancel)="cancelFileUpload()">

<ng-template #preview let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center">{{ previewTitle }}</h4>
    </div>
    <div class="modal-body obfuscated-playback">
        <img [src]="previewString" alt="" width="100%" />
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="modal.close()" ngbAutofocus>Close</button>
    </div>
</ng-template>



