export class AnswerValue {
    parameterName: string;
    value: string;
    displayValue: string;
    isRolledBack: boolean;
    missingDocument: string;
    isAppointmentValue: boolean;
    isOptionalExtra: boolean;

    constructor(paramName: string, value: string, displayValue: string, missingDocument: string, isAppointmentValue: boolean, isOptionalExtra: boolean) {
        this.parameterName = paramName;
        this.value = value;
        this.displayValue = displayValue;
        this.missingDocument = missingDocument;
        this.isAppointmentValue = isAppointmentValue;
        this.isOptionalExtra = isOptionalExtra;
    }
}
