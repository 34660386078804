import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { ListInputIconTypes } from '../../enums/list-input-icon-types';
import { KeyValuePair } from '../../models/key-value-pair.model';

@Component({
  selector: 'app-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['../input.component.scss'],
})
export class ListInputComponent extends BaseInputComponent implements OnInit, AfterContentInit {
  @Input() options: Array<KeyValuePair>;
  @Input() vertical = false;
  @Input() boldLabel = false;
  @Input() customClass = '';

  markInvalid: boolean;
  defaultAnswer: string;
  optionIcon: string;
  disabled = false;
  readOnly = false;

  ngOnInit() {
    super.ngOnInit();
    this.markInvalid = false;
    this.defaultAnswer = this.group.value.value;
    this.readOnly = this.group.value.readOnly ?? false;
    if (this.checkFormControl('PreviousOwners')) {
      this.checkPreviousOwners();
    }
  }

  ngAfterContentInit() {
    const value = this.group.value.value;
    if (value) {
      if (!this.isOptionEnabled(value)) {
        setTimeout(() => {
          this.group.patchValue({
            value: null,
          });
        });
      }
    }
  }

  isOptionEnabled(optionValue: string) {
    if (!this.minValue) {
      return true;
    }
    const value = Number(optionValue);
    if (isNaN(value)) {
      return true;
    }
    return value >= parseInt(this.minValue, 10);
  }

  checkValid() {
    if (this.group.controls.value.value) {
      this.markInvalid = this.isTouchedAndInvalid(this.group.controls.value);
    }
  }

  checkFormControl(parameterName: string) {
    return this.group.value.name === parameterName;
  }

  checkPreviousOwners() {
    if (this.group.value.value < '99') {
      this.group.controls.value.setErrors({ invalid: false });
    } else {
      this.group.controls.value.setValue(null);
    }
  }

  removeInvalidMark() {
    this.markInvalid = false;
  }

  valueChanged() {
    this.change.emit(this.group);
  }

  getOptionIcon(optionValue: string) {
    if (optionValue === 'Camera') {
      this.optionIcon = ListInputIconTypes.camera;
      return this.optionIcon;
    } else {
      this.optionIcon = '';
      return this.optionIcon;
    }
  }

  get informedAnswer(): string {
    return this.group.value.informedAnswer;
  }
}
