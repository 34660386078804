import { Component, Input } from '@angular/core';
import { HpiFinance } from '../models/hpi-finance.model';

@Component({
  selector: 'app-finance-records',
  templateUrl: './finance-records.component.html',
  styleUrls: ['./finance-records.component.scss'],
})
export class FinanceRecordsComponent {
  @Input() records: Array<HpiFinance>;
}
