import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ImageTypes } from '../enums/image-types';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ImageToSave } from '../images/models/image-to-save.model';
import { DocumentFileInfo } from '../images/models/document-file-info.model';
import { ImageForRecognition } from '../images/models/image-for-recognition.model';
import { VehicleImage } from '../purchase/models/vehicle-image.model';
import { PredictionResult } from '../images/models/prediction-result.model';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private http: HttpClient) {}

  imagesToSave: Array<ImageToSave> = new Array<ImageToSave>();
  imagesToDelete: Array<number> = new Array<number>();
  public imageAdded = new EventEmitter<boolean>();
  public allImagesAdded = new EventEmitter<boolean>();
  public saveDbImage = new EventEmitter<boolean>();

  getExistingImages$(quoteStateId: number): Observable<Array<ImageToSave>> {
    const url = `${environment.ppxPrivateApi}api/image/getimages/${quoteStateId}`;
    return this.http
      .get<Array<ImageToSave>>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  getExistingVehicleImages$(quoteStateId: number) {
    const url = `${environment.ppxPrivateApi}api/image/vehicle/${quoteStateId}`;
    return this.http
      .get<Array<VehicleImage>>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  getImagePreview$(id: number, imageType) {
    const url = `${environment.ppxPrivateApi}api/image/getImagePreview/${id}/${imageType}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(url).pipe(catchError((err) => throwError(err)));
  }

  getImageRecognition$(image: ImageToSave, quoteStateId: number) {
    const imageObject = new ImageForRecognition();
    imageObject.fileName = image.fileName;
    imageObject.imgAsBase64 = image.imgAsBase64String.split(',')[1];
    imageObject.tag = image.tag;
    imageObject.threshold = image.threshold;
    const url = `${environment.ppxPrivateApi}api/Image/ImageRecognition/${quoteStateId}`;
    return this.http.post<PredictionResult>(url, imageObject,
      {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  deleteImages$(quoteStateId: number, images: Array<string>) {
    return this.http
      .post<string>(
        `${environment.ppxPrivateApi}api/image/vehicle/delete/${quoteStateId}`,
        images,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
        }
      )
      .pipe(catchError((err) => throwError(err)));
  }

  submitImages$(
    quoteStateId: number,
    requiredImage: number,
    isPostPurchase: boolean
  ): Observable<Array<number>> {

    const formData = new FormData();
    for (const imageToDelete of this.imagesToDelete) {
      if (imageToDelete) {
        formData.append(`deleteIds`, imageToDelete.toString());
      }
    }

    // filter for duplicates
    this.imagesToSave = this.imagesToSave.filter(function(item, pos, self) {
      return self.indexOf(item) === pos;
  });

    for (let index = 0; index < this.imagesToSave.length; index++) {
      if (this.imagesToSave[index].image && !this.imagesToSave[index].isSaved) {
        let fileName: string;
        if (this.imagesToSave[index].fileName) {
          fileName = this.imagesToSave[index].fileName;
        } else {
          fileName = `${this.imagesToSave[index].stampCode}-${
            this.imagesToSave[index].parameterName
          }-${index + 1}${this.imagesToSave[index].extension}`;
        }

        formData.append(
          `image${index}`,
          this.imagesToSave[index].image,
          fileName
        );
        formData.append(
          'fileInfo',
          JSON.stringify(
            new DocumentFileInfo(
              this.imagesToSave[index].parameterName ??
                this.imagesToSave[index].documentName,
              this.imagesToSave[index].stampCode,
              fileName,
              this.imagesToSave[index].tag,
              this.imagesToSave[index].threshold,
              this.imagesToSave[index].recognitionResult
            )
          )
        );
      }
    }
    let url = '';
    if (requiredImage) {
      if (requiredImage === ImageTypes.VehiclePhoto) {
        url = `${environment.ppxPrivateApi}api/image/vehicle/${quoteStateId}/${isPostPurchase}`;
      } else {
        url = `${environment.ppxPrivateApi}api/image/save/${quoteStateId}/${isPostPurchase}`;
      }
    } else {
      url = `${environment.ppxPrivateApi}api/image/save/${quoteStateId}/${isPostPurchase}`;
    }

    return this.http
      .post<Array<number>>(url, formData)
      .pipe(catchError((err) => throwError(err)));
  }

  addImagesToSave(imagesToSave) {
    this.imagesToSave.push(...imagesToSave);
  }

  removeImagesToSave() {
    this.imagesToSave = new Array<ImageToSave>();
  }

  addImagesToDelete(imagesToDelete) {
    this.imagesToDelete.push(...imagesToDelete);
  }

  removeImagesToDelete() {
    this.imagesToDelete = new Array<number>();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitVideoChunk$(quoteStateId: number, chunk: any, index: number): Observable<void> {
    const formData = new FormData();
    formData.append('video', chunk);
    return this.http
      .post<void>(`${environment.ppxPrivateApi}api/image/video/${quoteStateId}/${index}`, formData)
      .pipe(catchError((err) => throwError(err)));
  }

  completeVideoUpload$(quoteStateId: number): Observable<void> {
    return this.http
      .post<void>(`${environment.ppxPrivateApi}api/image/video/complete/${quoteStateId}`, null)
      .pipe(catchError((err) => throwError(err)));
  }

  recordVideoSubmissionFailure$(quoteStateId: number): Observable<boolean> {
    return this.http
      .post<boolean>(`${environment.ppxPrivateApi}api/image/video/attempt/${quoteStateId}`, null)
      .pipe(catchError((err) => throwError(err)));
  }

  getVideo$(quoteStateId: number): Observable<VehicleImage> {
    const url = `${environment.ppxPrivateApi}api/image/video/${quoteStateId}`;
    return this.http.get<VehicleImage>(url).pipe(catchError((err) => throwError(err)));
  }
}
