import { AppointmentOutcomeTypes } from '../../enums/appointment-outcome-types';
import { Appointment } from '../models/appointment.model';
import { Moment } from 'moment';

export class AppointmentsHelper {

  static setAppointmentStartPosition(startTime: Moment, calendarHeight: number, numberOfHours: number) {
    return ((calendarHeight / numberOfHours) * startTime.hours() + (calendarHeight / (numberOfHours * 60)) * startTime.minutes());
  }

  static setAppointmentLeft(sequence: number, numberOfOverlaps: number): number {
    if (sequence === -1) {
      return 0;
    }
    return sequence * (100 / numberOfOverlaps);
  }

  static setAppointmentHeight(appointmentLength: number, pixelPerMinuteCount: number): number {
    return appointmentLength * pixelPerMinuteCount;
  }

  static setAppointmentWidth(numberOfOverlaps: number): number {
    if (numberOfOverlaps === -1) {
      return 100;
    }
    return 100 / numberOfOverlaps;
  }

  static setStatusStyle(status: string, isWalkIn: boolean): string {
    switch (status) {
      case AppointmentOutcomeTypes.ConfirmationNeeded: {
        return 'appointment-confirmation-required';
      }
      case AppointmentOutcomeTypes.PreAppointmentConfirmed: {
        if (isWalkIn) {
          return 'appointment-walkin';
        }
        return 'appointment-pre-appointment-confirmed';
      }
      case AppointmentOutcomeTypes.PurchaseStarted: {
        if (isWalkIn) {
          return 'appointment-walkin';
        }
        return 'appointment-pre-appointment-confirmed';
      }
      case AppointmentOutcomeTypes.VehiclePurchased: {
        return 'appointment-purchased';
      }
      case AppointmentOutcomeTypes.VehicleNotPurchased: {
        return 'appointment-not-purchased';
      }
      case AppointmentOutcomeTypes.NoShow: {
        return 'appointment-no-show';
      }
      case AppointmentOutcomeTypes.Late: {
        return 'appointment-late';
      }
      case AppointmentOutcomeTypes.PurchaseRolledBack: {
        return 'appointment-purchase-rolled-back';
      }
      default: {
        return 'appointment-pre-appointment-confirmed';
      }
    }
  }

  static setTimeStyle(status: string, isWalkin: boolean): string {
    switch (status) {
      case AppointmentOutcomeTypes.ConfirmationNeeded: {
        return 'confirmation-needed-time';
      }
      case AppointmentOutcomeTypes.PreAppointmentConfirmed:
      case AppointmentOutcomeTypes.PurchaseStarted: {
        if (isWalkin) {
          return 'walkin-time';
        }
        return 'purchase-started-time';
      }
      case AppointmentOutcomeTypes.VehiclePurchased: {
        return 'purchased-time';
      }
      case AppointmentOutcomeTypes.VehicleNotPurchased: {
        return 'not-purchased-time';
      }
      case AppointmentOutcomeTypes.NoShow: {
        return 'no-show-time';
      }
      case AppointmentOutcomeTypes.Late: {
        return 'late-time';
      }
      case AppointmentOutcomeTypes.PurchaseRolledBack: {
        return 'purchase-rolled-back-time';
      }
      default: {
        return 'purchase-started-time';
      }
    }
  }

  static findOverlappingAppointments(dailyAppointments: Array<Appointment>) {
    dailyAppointments.sort((a, b) => {
      return this.calculateMinutes(a.startTime) - this.calculateMinutes(b.startTime);
    });

    for (let i = 0; i < dailyAppointments.length; i++) {
      this.compareAppointments(dailyAppointments, dailyAppointments[i], dailyAppointments[i + 1], i);
    }
  }

  static compareAppointments(dailyAppointments: Array<Appointment>, firstAppointment: Appointment, secondAppointment: Appointment, index: number) {
    if (secondAppointment) {
      const firstAppStartTime = this.calculateMinutes(firstAppointment.startTime);
      // subtract 1 min from firstAppEndTime to prevent appointments from splitting when the first app ends at the same time as the second app starts
      const firstAppEndTime = this.calculateMinutes(firstAppointment.endTime) - 1;
      const secondAppStartTime = this.calculateMinutes(secondAppointment.startTime);

      if (secondAppStartTime >= firstAppStartTime && secondAppStartTime < firstAppEndTime
        || firstAppEndTime >= secondAppStartTime && secondAppStartTime >= firstAppStartTime) {
        if (firstAppointment.appSequence < 0) {
          firstAppointment.appSequence = 0;
          secondAppointment.appSequence = 1;
        } else {
          secondAppointment.appSequence = firstAppointment.appSequence + 1;
        }
      } else {
        if (firstAppointment.appSequence >= 0) {
          for (let i = index; i >= index - firstAppointment.appSequence; i--) {
            dailyAppointments[i].appOverlaps = firstAppointment.appSequence + 1;
          }
        }
      }
    }
    if (firstAppointment.appSequence >= 0) {
      for (let i = index; i >= index - firstAppointment.appSequence; i--) {
        dailyAppointments[i].appOverlaps = firstAppointment.appSequence + 1;
      }
    }
  }

  static calculateMinutes(time: Moment): number {
    return time.hours() * 60 + time.minutes();
  }
}
