<div class="manual-lookup-container">
  <img class="modal-close" (click)="closeModal()" alt="Dismiss modal"/>
  <div class="modal-header">
    <h4 class="modal-title">Manual Lookup</h4>
  </div>
  <form [formGroup]="manualLookupForm" (ngSubmit)="onSubmitManualLookupForm()">
    <label>Vehicle Type</label>
    <div class="vehicle-type">
      <input (change)="onVehicleSelection($event)" id="car" type="radio" value="1" formControlName="vehicleType" required />
      <label for="car" [ngClass]="{ 'selected-option' : selectedVehicleType == 1 }">Car</label>
      <input (change)="onVehicleSelection($event)" id="van" type="radio" value="2" formControlName="vehicleType" required />
      <label for="van" [ngClass]="{ 'selected-option' : selectedVehicleType == 2 }">Van</label>
    </div>
    <div>
      <label [ngClass]="{ 'disabled-state' : selectedVehicleType == null }" for="make">Make</label>
      <div *ngIf="isMakeLoading">
        <input class="empty-select form-control" type="text" />
        <span class="loading-anim"></span>
      </div> 
      <select [hidden]="isMakeLoading" (change)="onSelectedManufacturer($event)" id="manufacturer" formControlName="make" class="form-control">
        <option label="Select Make" disabled></option>
        <option [value]="make.key" *ngFor="let make of manufactureList | async">
          {{ make.value }}
        </option>
      </select>
    </div>
    <div>
      <label [ngClass]="{ 'disabled-state' : selectedManufacturer == null }" for="model">Model</label>
      <div *ngIf="isModelLoading">
        <input class="empty-select form-control" type="text" />
        <span class="loading-anim"></span>
      </div>
      <select [hidden]="isModelLoading" (change)="onSelectedModel($event)" id="model" formControlName="model" class="form-control">
        <option label="Select Model" disabled></option>
        <option [value]="model.key" *ngFor="let model of modelList | async">
          {{ model.value }}
        </option>
      </select>
    </div>
    <div>
      <label [ngClass]="{ 'disabled-state' : selectedModel == null }" for="fuel-type">Fuel Type</label>
      <div *ngIf="isFuelTypeLoading">
        <input class="empty-select form-control" type="text" />
        <span class="loading-anim"></span>
      </div>
      <select [hidden]="isFuelTypeLoading" (change)="onSelectedFuelType($event)" id="fuel-type" formControlName="fuelType" class="form-control">
        <option label="Select Fuel" disabled></option>
        <option [value]="fuel.key" *ngFor="let fuel of fuelTypeList">
          {{ fuel.value }}
        </option>
      </select>
    </div>
    <div>
      <label [ngClass]="{ 'disabled-state' : selectedFuelType == null }" for="year-or-registration-letter">Year/Reg letter</label>
      <div *ngIf="isRegLetterLoading">
        <input class="empty-select form-control" type="text" />
        <span class="loading-anim"></span>
      </div>
      <select [hidden]="isRegLetterLoading" (change)="onSelectedYearOrRegLetter($event)" id="year-or-registration-letter"
              formControlName="yearOrRegistrationLetter" class="form-control">
        <option label="Select Year/Reg letter" disabled></option>
        <option [value]="year.key" *ngFor="let year of registrationYearList | async">
          {{ year.value }}
        </option>
      </select>
    </div>
    <div class="colour">
      <div class="w-50 me-2">
        <label [ngClass]="{ 'disabled-state' : selectedYearOrRegLetter == null }" for="colour">Colour</label>
        <div *ngIf="isColourLoading">
          <input class="empty-select-colour form-control" type="text" />
          <span class="loading-anim-colour"></span>
        </div> 
        <select [hidden]="isColourLoading" (change)="onSelectedColour($event)" id="colour" formControlName="colour" class="form-control me-1">
          <option label="Select Colour" disabled></option>
          <option [value]="colour.key" *ngFor="let colour of colourList | async">
            {{ colour.value }}
          </option>
        </select>
      </div>
      <div class="w-50 ms-2">
        <label class="w-100" [ngClass]="{ 'disabled-state' : selectedColour == null }" id="metallic-paint">Metallic Paint</label>
        <div class="option-group">
        <input class="form-control" (change)="onMetallicPaintSelected($event)" id="metallic-paint-yes" type="radio" value="0"
              formControlName="metallicPaint" />
        <label class="metallic-paint-label me-1" for="metallic-paint-yes" [ngClass]="{ 'selected-option' : selectedMetallicPaint == 0, 'disabled' : selectedColour == null }">Yes</label>
        <input class="form-control" (change)="onMetallicPaintSelected($event)" id="metallic-paint-no" type="radio" value="1"
              formControlName="metallicPaint" />
        <label class="metallic-paint-label" for="metallic-paint-no" [ngClass]="{ 'selected-option' : selectedMetallicPaint == 1, 'disabled' : selectedColour == null }">No</label>
        </div>
      </div>
    </div>
    <div>
      <label [ngClass]="{ 'disabled-state' : selectedMetallicPaint == null }" for="derivative">Derivative</label>
      <div *ngIf="isDerivativeLoading">
        <input class="empty-select form-control" type="text" />
        <span class="loading-anim"></span>
      </div>
      <select [hidden]="isDerivativeLoading" (change)="onSelectedDerivative($event)" id="derivative-selector" formControlName="derivative" class="form-control">
        <option label="Select Derivative" disabled></option>
        <option [value]="derivative.key" *ngFor="let derivative of derivativeList | async">
          {{ derivative.value }}
        </option>
      </select>
    </div>
      <button [ngClass]="{'active':manualLookupForm.valid, 'invalid':!manualLookupForm.valid, 'loading': loading}" type="submit" class="modal-button confirm-btn" [disabled]="!manualLookupForm.valid || loading">
      Confirm
    </button>
  </form>
</div>
