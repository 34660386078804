/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: 'b0277d9b-e1ae-4e62-bed3-216313be233b', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/105bdac5-7239-4dbb-8c0c-dbdd9b3f1144', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: window.location.origin, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true
    }
  }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

export const protectedResources = {
  privateApiDesktop: {
    endpoint: 'https://ppx-service-private.webuyanycar.*',
    scopes: ['api://a6bc64a3-0b5e-4885-be8c-246e58d4558e/access_as_user'],
  },
  privateApiAzure: {
    endpoint: 'https://app-ppx-webapi-private-*',
    scopes: ['api://a6bc64a3-0b5e-4885-be8c-246e58d4558e/access_as_user'],
  },
  frontDoorPrivateApiAzure: {
    endpoint: 'https://ppx-private-service.webuyanycar.*',
    scopes: ['api://a6bc64a3-0b5e-4885-be8c-246e58d4558e/access_as_user'],
  },
  privateApiTablet: {
    endpoint: 'https://localhost:4446/api/*',
    scopes: ['a6bc64a3-0b5e-4885-be8c-246e58d4558e/access_as_user'],
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: []
};

export const roles = {
};
