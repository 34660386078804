<div *ngIf="!loading" class="container mt-4 obfuscated-playback">
  <div [innerHTML]="purchaseDetails.d2DetailsHtml"></div>
  <div class="row">
    <div class="col-12 mb-2 mt-2">
      <div *ngIf="customerSignature" class="mb-2">
        <div class="row">
          <div class="col-6">Vendor Signature</div>
          <div class="col-6">Date: {{ today | date:'dd/MM/yyyy' }}</div>
        </div>
        <img class="signature-size" [src]="customerSignature.url" alt="" />
      </div>
      <div *ngIf="!customerSignature" class="mb-2">
        <div class="row">
          <div class="col-6">
            <button type="button" (click)="customerSign()" class="btn btn-primary btn-block">Vendor Signature</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>