import { Injectable } from '@angular/core';
import { ErrorMessageModel } from '../models/error-message-model';

@Injectable({
  providedIn: 'root'
})
export class DiaryViewErrorService {

  buildErrorMessage(errorMessage: ErrorMessageModel) {
    return errorMessage;
  }
}
