<div class="view-container" style="height: auto;" *ngIf="!noVehicleInBranch && !loading">
  <table class="table-view text-center table-bordered border-primary">
    <thead class="table-view header">
      <tr>
        <th>VRM</th>
        <th>Description</th>
        <th>Colour</th>
        <th>Here</th>
        <th>Not Here</th>
        <th *ngIf="showNonRunners === true">NR</th>
      </tr>
    </thead>
    <tbody class="table-view">
      <tr *ngFor="let item of stockItems">
        <td class="vrm">{{ item.vrm }}</td>
        <td>{{ item.model }} {{ item.make }} - {{ item.derivative }}</td>
        <td class="colour">{{ item.colour }}</td>
        <td class="checks" (click)="here(item, true)" clickable>
          <div
            *ngIf="item.here != null"
            [ngClass]="item.here ? 'showCheckTick' : 'hideCheckTick'"
          ></div>
        </td>
        <td class="checks" (click)="here(item, false)">
          <div
            *ngIf="item.here != null"
            [ngClass]="item.here ? 'hideUncheckTick' : 'showUncheckTick'"
          ></div>
        </td>
        <td *ngIf="showNonRunners === true" [ngClass]="{'enabled': item.here == true, 'inactive' : item.here == false}" class="checks" (click)="nonRunner(item, item.markAsNonRunner)">
          <div 
           [ngClass]="{ 'showNonRunner' : item.nonRunnerWhenPurchased || item.previouslyFlaggedAsNonRunnerPostPurchase || item.markAsNonRunner}"
          ></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="text-green" *ngIf="btnState && !isSaved">
  You now need to tap on the Save Report Button at the top
</div>

<div *ngIf="loading" class="no-data-container">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</div>

<div *ngIf="noVehicleInBranch" class="no-data-container">
  <h5>There are currently no vehicles in this branch</h5>
</div>
