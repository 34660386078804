<div class="modal-header">
  <h4 class="modal-title text-center obfuscated-playback">{{ title }}</h4>
</div>
<div class="modal-body obfuscated-playback">
  <div>{{ message }}</div>
  <div *ngIf="extraMessage">{{ extraMessage }}</div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" *ngIf="alternateButton" (click)="altCloseModal()">{{ alternateButton }}</button>
  <button class="btn btn-danger" *ngIf="failButton" (click)="dismissModal()">{{ failButton }}</button>
  <button class="btn btn-success" *ngIf="successButton" (click)="closeModal()">{{ successButton }}</button>
</div>
