<div class="vrm-error-container">
  <div>
    <img class="modal-close" (click)="closeModal()" alt="Dismiss modal"/>
  </div>
  <div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
  </div>
  <div class="warning-box" *ngIf="unableToPurchase">
    <img class="warning-image" src="../../../../assets/images/warning.png" alt="Warning" />
  </div>
  <div class="registration-plate">
    <app-registration-plate [vrm]="vrm"></app-registration-plate>
  </div>
  <div *ngIf="!missingAltVrm && !unableToPurchase && !missingVrmRelationship && !highValueRequiresAppointment" class="vrm-error">
    <div>We can't seem to match the vehicle with HPI.</div>
    <div>Has the vehicle used another VRM?</div>
  </div>
  <div *ngIf="missingAltVrm" class="vrm-error">
    If this vehicle has not been used under another VRM, then either it's an input error and you can re-enter VRM by tapping the button below.
  </div>
  <div *ngIf="missingVrmRelationship" class="vrm-error">
    We are unable to find a link between the VRMs provided.
  </div>
  <div *ngIf="unableToPurchase" class="vrm-error">
    We will be unable to purchase the vehicle due to no HPI Data.
  </div>
  <div *ngIf="highValueRequiresAppointment"class="vrm-error">
    Due to this vehicle's value, we cannot purchase the vehicle at the moment. Please book a future appointment.
  </div>
  <div class="button-bar">
    <button *ngIf="!missingAltVrm && !unableToPurchase && !missingVrmRelationship && !highValueRequiresAppointment" class="no-hpi-button red-btn left-hand-btn" (click)="noAltVrm()">No</button>
    <button *ngIf="!missingAltVrm && !unableToPurchase && !missingVrmRelationship && !highValueRequiresAppointment" class="no-hpi-button right-hand-btn" (click)="yesAltVrm()">Yes</button>
    <button *ngIf="missingAltVrm || missingVrmRelationship" class="no-hpi-button clear-btn left-hand-btn" (click)="vrmCorrect()">VRM is Correct</button>
    <button *ngIf="missingAltVrm || missingVrmRelationship" class="no-hpi-button right-hand-btn" (click)="reenterVrm()">Re-Enter VRM</button>
    <button *ngIf="unableToPurchase" class="no-hpi-button" (click)="closeModal()">Ok</button>
    <button *ngIf="highValueRequiresAppointment" class="no-hpi-button" (click)="startBookingAppointment()">Book Appointment</button>
  </div>
</div>
