import { AuthorizationTypes } from '../enums/authorization-types';

export class AuthorizationRequest {
  authorizationRequestId: number;
  quoteStateId: number;
  requested: Date;
  requestedBy: string;
  authorizationTypeId: AuthorizationTypes;
  value: string;
  approved: Date;
  declined: Date;
  authorizedBy: string;
  claimed: boolean;
  claimedBy: string;
  isNew: boolean;

  constructor(quoteStateId: number, authorizationType: AuthorizationTypes, value: string, requestedBy: string) {
    this.quoteStateId = quoteStateId;
    this.authorizationTypeId = authorizationType;
    this.value = value;
    this.requested = new Date();
    this.requestedBy = requestedBy;
  }
}
