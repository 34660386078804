import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { SiteNameAbbreviationHelper } from '../../../helpers/site-name-abbreviation-helper';
import { SiteOnDate } from '../../models/site-on-date.model';
import { SiteWithSlots } from '../../models/site-with-slots.model';
import { SitesAndSlotsByPostcode } from '../../models/sites-and-slots-by-postcode.model';

@Component({
  selector: 'app-appointment-slots',
  templateUrl: './appointment-slots.component.html',
  styleUrls: ['./appointment-slots.component.scss']
})

export class AppointmentSlotsComponent implements OnInit {
  @Input() appointmentSlots: SitesAndSlotsByPostcode;
  @Input() maximumValueAppointmentDelayInDays: number;
  @Output() selectedSite = new EventEmitter<SiteWithSlots>();
  @Output() selectedDate = new EventEmitter<SiteOnDate>();
  @Output() appointmentSlotSelected = new EventEmitter<boolean>();

  siteDays: Array<Date>;

  ngOnInit() {
    this.siteDays = new Array<Date>();
    const currentDate = moment();
    this.siteDays.push(currentDate.toDate());
    for (let i = 0; i < 6; i++) {
      currentDate.add(1, 'days');
      this.siteDays.push(currentDate.toDate());
    }
  }

  abbreviateSiteName(siteName: string) {
    if (typeof siteName !== 'undefined') {
      return SiteNameAbbreviationHelper.abbreviateSiteName(siteName);
    } else {
      return siteName;
    }
  }

  convertToMiles(distanceInMeters: number) {
    return distanceInMeters * 0.000621371192;
  }

  onAppointmentSlotSelection(selectedSite: SiteWithSlots, selectedSiteDate: SiteOnDate) {
    if (this.isBranchClosed(selectedSiteDate) || selectedSiteDate.totalAppointmentSlotsCount === 0) {
      return;
    }

    this.selectedSite.emit(selectedSite);
    this.selectedDate.emit(selectedSiteDate);
    this.appointmentSlotSelected.emit(true);
  }

  isBranchClosed(siteDate: SiteOnDate) {
    return siteDate.appointmentsCount === 0 && siteDate.totalAppointmentSlotsCount === 0;
  }

  anySlotsInDay(siteDateIndex: number, site: SiteWithSlots) {
    let slotsToday = true;
    if (site != null) {
        slotsToday = site.siteDates[siteDateIndex].totalAppointmentSlotsCount > 0;
    } else {
      this.appointmentSlots.sites.forEach(s => {
        if (s.siteDates[siteDateIndex].totalAppointmentSlotsCount === 0) {
          slotsToday = false;
        }
      });
    }
    return slotsToday;
  }
}
