import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class CustomValidators {
  static vrmRegex = /^[A-Za-z0-9]+$/;
  static postcodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
  static telephoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
  static emailAddressRegex = /^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9-]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z-]{2,}(?:[a-zA-Z]*[a-zA-Z])?/;
  static customerNameRegex = /^.{0,50}$/;

  static validatePattern(input: AbstractControl, regex: RegExp): boolean {
    if (regex.test(input.value)) {
      return null;
    }
    return true;
  }

  static validateRegex(regex: RegExp): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return timer(600).pipe(
        switchMap(() => {
          return of(CustomValidators.validatePattern(control, regex));
        }),
        map(isValid => {
          return isValid ? { validateRegex: true } : null;
        })
      );
    };
  }

  static validateNumberRange(input: AbstractControl, min: number, max: number): boolean {
    const value = Number(input.value);
    if (min && (isNaN(value) || value < min)) {
      return true;
    } else if (max && (isNaN(value) || value > max)) {
      return true;
    }
    return null;
  }

  static validateNumber(min: number, max: number): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return timer(600).pipe(
        switchMap(() => {
          return of(CustomValidators.validateNumberRange(control, min, max));
        }),
        map(isValid => {
          return isValid ? { validateNumber: true } : null;
        })
      );
    };
  }
}
