<div class="container">
  <div *ngIf="!loading">
    <div *ngIf="isPricePromiseVoid" class="price-promise-void text-center">
      This vehicle is no longer in Price Promise due to:
      <span class="bold">{{ pricePromiseVoidReason }}</span>
    </div>
    <div *ngIf="isWebSource && hasDamages">
      <p class="header-title">Customer Added Damage</p>
      <table class="appraisalTable" aria-label="Customer Damage Items">
        <thead>
          <tr class="header-purple">
            <th class="cell">Zone</th>
            <th class="cell">Component</th>
            <th class="cell">Fault</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let fault of customerDamageItems">
            <td class="cell">{{ fault.zoneName }}</td>
            <td class="cell">{{ fault.componentName }}</td>
            <td class="cell">{{ fault.faultName }}</td>
            <td class="cell flex-amount"></td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <div [formGroup]="damageGroup">
      <ng-container *ngIf="!isWebSource()">
        <p class="header-title"> WBAC Damage </p>
      </ng-container>
      <ng-container *ngIf="isWebSource()">
        <div class="tabstrip">
          <button type="radio" class="tab" [ngClass]="{
              'active': currentTab === 0,
              'inactive': currentTab !== 0
            }"
            (click)="currentTab = 0">WBAC Damage</button>
          <button type="radio" class="tab" [ngClass]="{
              'active': currentTab === 1,
              'inactive': currentTab !== 1
            }"
            (click)="currentTab = 1">WBAC Deductions</button>
        </div>
      </ng-container>

      <ng-container *ngIf="currentTab === 0">
        <table class="appraisalTable" [ngClass]="{'no-top-margin': isWebSource()}" aria-label="Damage Items">
          <thead>
            <tr class="header">
              <th class="cell">Zone</th>
              <th class="cell">Component</th>
              <th class="cell">Fault</th>
              <th class="cell">Cost<span *ngIf="valuation.isVatRegistered">&nbsp;exc. VAT</span></th>
              <th class="cell flex-amount" *ngIf="isFlexing">Flex</th>
            </tr>
          </thead>
          <tbody formArrayName="damageItems">
            <tr *ngFor="let fault of damageGroup.value.damageItems; let damageIndex=index; trackBy: trackByFn"
              [formGroupName]="damageIndex">
              <td class="cell">{{ fault.zoneName }}</td>
              <td class="cell">{{ fault.componentName }}</td>
              <td class="cell">{{ fault.faultName }}</td>
              <td class="cell">{{ fault.cost | currency : 'GBP' }}</td>
              <td class="cell flex-amount" *ngIf="isFlexing">
                <ng-container *ngIf="isFlexing && fault.canFlex && fault.cost > 0">
                  <input type="tel" class="form-control flex-input" formControlName="flex" max="{{fault.cost}}"
                    (change)="checkZero($event)" (keyup.enter)="confirmEntry($event)" />
                </ng-container>
                <ng-container *ngIf="isFlexing && !fault.canFlex">
                  {{ fault.flex | currency : 'GBP' }}
                </ng-container>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"></td>
              <td class="footer-label">Total Condition Costs</td>
              <td class="total-damage-cost">({{ valuation.totalDamageCost | currency : 'GBP' }})</td>
              <td *ngIf="isFlexing">
                <button class="btn btn-primary btn-sm recalculate-btn"
                  [ngClass]="{'loading': recalculating}" [disabled]="!this.damageGroup.valid || !needsRecalculation"
                  (click)="recalculate()">Recalculate
                </button>
              </td>
            </tr>
            <tr *ngIf="isFlexing">
              <td colspan="2"></td>
              <td class="footer-label">Total Flex</td>
              <td class="fw-bold" [ngClass]="{ 'flex-exceeded': isMaxFlexExceeded}">
                {{ valuation.totalFlexAmount | currency : 'GBP' }}
              </td>
            </tr>
            <tr *ngIf="isFlexing">
              <td colspan="2"></td>
              <td class="footer-label">Total Condition Costs after Flex</td>
              <td class="total-damage-cost">({{ totalAppraisalValue | currency : 'GBP' }})</td>
            </tr>
            <tr class="mt-2">
              <td colspan="2"></td>
              <td class="footer-label mt-2">Vehicle Grade</td>
              <td class="vehicle-grade">Grade {{valuation.grade}}</td>
            </tr>
            <tr class="mt-2" *ngIf="valuation.isVatRegistered">
              <td colspan="2"></td>
              <td class="footer-label mt-2">VAT Amount</td>
              <td class="vehicle-grade" *ngIf="!valuation.isVatRegistered">{{ valuation.totalDamageCost * 0.2 | currency : 'GBP' }}</td>
              <td class="vehicle-grade" *ngIf="valuation.isVatRegistered">{{ totalAppraisalValue * 0.2 | currency : 'GBP' }}</td>
            </tr>
          </tfoot>
        </table>
      </ng-container>

    <ng-container *ngIf="currentTab === 1">
      <table class="appraisalTable no-top-margin" aria-label="Factors affecting proceamage Items">
        <thead>
          <tr class="header">
            <th class="cell">Description</th>
            <th class="cell">Used for Website Valuation</th>
            <th class="cell">Current Assessment</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let factor of valuation.factorsAffectingPrice;">
            <td class="cell">{{ factor.description }}</td>
            <td class="cell">{{ factor.initialValue }}</td>
            <td class="cell">{{ factor.currentValue }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="cell other-factors" colspan="3">The price may also be affected by other factors not currently displayed</td>
          </tr>
        </tfoot>
      </table>
    </ng-container>

  <div *ngIf="hasValidPricePromise()">
      <p class="header-title"> Price Promise </p>
      <table class="appraisalTable" aria-label="Price Promise">
        <thead>
          <tr class="price-promise-header">
            <th class="cell">Reason</th>
            <th class="cell">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="cell">Adjustment to initial price</td>
            <td class="fw-bold vehicle-grade-col">+{{ valuation.pricePromise | currency : 'GBP' }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card bg-warning mb-1" *ngIf="valuation.flexAuthRequired">
      <div class="card-body">
        Authorisation is required for this amount of flex
      </div>
    </div>

    <app-offer-display class="mb-2" [valuation]="valuation"></app-offer-display>

    <div class="btn-div" *ngIf="!isFlexing && canFlex">
      <button class="btn btn-not-for-me" (click)="flexIt()">It's Not For Me</button>
    </div>
  </div>
</div>
