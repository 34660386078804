<div class="page-content">
  <ng-container *ngIf="!loading">
    <div *ngIf="success" class="purchased-message">Vehicle has been purchased</div>
    <ng-container *ngIf="!success">
      <div class="purchased-message centered">This purchase has not been completed correctly</div>
      <div class="purchased-message centered">Please start the purchase again</div>
      <div class="centered">
        <button class="btn modal-button restart-button" [ngClass]="{'loading': processing}" (click)="restart()">
          Restart Purchase&nbsp;
          <img *ngIf="!processing" class="restart-button-icon" src="../../assets/icons/buttons/restart.png" alt="restart">
        </button>
      </div>
    </ng-container>
  </ng-container>
  <h2 class="mb-3"></h2>
</div>
