import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LoadingService {

    public loading = new EventEmitter<boolean>();

}
