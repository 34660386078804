<div class="modal-dark">
    <div class="modal-header">
      <div class="modal-header__text">Please select: </div>
      <img class="modal-close x-icon-white" (click)="close()" alt='x-icon-white'>
    </div>
    <div class="modal-body">
        <div class="selection" *ngFor="let option of options">
          <input type="radio" [name]="option" [value]='option'
                 (change)="onSelectionChange($event)" [checked]="selectedOption === option">
          <label class="selection__option" [for]="option">{{ option }}</label>
        </div>
    </div>
  </div>
