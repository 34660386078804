<div class="container">
  <ng-template #loading>
    <app-loading-wbac-animation></app-loading-wbac-animation>
  </ng-template>
  <div *ngIf="(purchaseSummary$ | async) as purchase; else loading">
    <h2 class="display-4">{{ purchase.vrm }}</h2>
    <div ngbAccordion #accordion="ngbAccordion">

      <div ngbAccordionItem="infoPanel" [collapsed]="false">
        <h4 ngbAccordionHeader>
          <button ngbAccordionButton>Purchase Details</button>
        </h4>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="card mb-3">
                    <div class="card-header fw-bold">Status</div>
                    <div class="card-body">
                      <table class="table table-borderless table-sm">
                        <tr *ngFor="let status of purchase.statusTransitions">
                          <td>{{ status.created | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                          <td *ngIf="status.extraInfo">{{ status.description }} ({{ status.extraInfo }})</td>
                          <td *ngIf="!status.extraInfo">{{ status.description }}</td>
                          <td>{{ status.username }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="card mb-3">
                    <div class="card-header fw-bold">Valuation</div>
                    <div class="card-body obfuscated-playback">
                      <div *ngIf="!purchase.valuation">
                        Valuation has not been done for this purchase
                      </div>
                      <div *ngIf="purchase.valuation">
                        <table class="table table-borderless table-sm">
                          <tr>
                            <td>Customer</td>
                            <td>{{ purchase.valuation.customerName }}</td>
                          </tr>
                          <tr>
                            <td>Quote Date</td>
                            <td>{{ purchase.valuation.quoteDate | date: 'shortDate' }}</td>
                          </tr>
                          <tr>
                            <td>VRM</td>
                            <td>{{ purchase.valuation.vrm }}</td>
                          </tr>
                          <tr>
                            <td>Buyer's Name</td>
                            <td>{{ purchase.valuation.buyersName }}</td>
                          </tr>
                          <tr>
                            <td>Site</td>
                            <td>{{ purchase.valuation.siteName }}</td>
                          </tr>
                          <tr>
                            <td>Original Offer</td>
                            <td>{{ purchase.valuation.originalVehiclePriceOffered | currency:'GBP' }}</td>
                          </tr>
                          <tr>
                            <td>Recorded Mileage</td>
                            <td>{{ purchase.valuation.recordedMileage }}</td>
                          </tr>
                          <tr>
                            <td>Grade</td>
                            <td>{{ purchase.valuation.grade }}</td>
                          </tr>
                          <tr>
                            <td>Previous Owners</td>
                            <td>{{ purchase.valuation.previousOwnersDescription }}</td>
                          </tr>
                          <tr>
                            <td>Service History</td>
                            <td>{{ purchase.valuation.serviceHistoryDescription }}</td>
                          </tr>
                          <tr>
                            <td>Private Plate</td>
                            <td>{{ purchase.valuation.privatePlate }}</td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <hr>
                            </td>
                          </tr>
                          <tr *ngIf="purchase.valuation.damage?.length">
                            <td colspan="2">
                              Condition Items ({{purchase.valuation.damage.length}})
                            </td>
                          </tr>
                          <tr *ngFor="let damage of purchase.valuation.damage">
                            <td>{{damage.componentName}} {{damage.faultName}}</td>
                            <td>{{damage.cost| currency:'GBP'}}</td>
                          </tr>
                          <tr *ngIf="purchase.valuation.damage?.length">
                            <td colspan="2">
                              <hr>
                            </td>
                          </tr>
                          <tr *ngIf="purchase.valuation.totalFlexAmount > 0">
                            <td>Flex</td>
                            <td>{{ purchase.valuation.totalFlexAmount | currency:'GBP' }}</td>
                          </tr>
                          <tr *ngIf="purchase.valuation.totalFlexAmount > 0">
                            <td colspan="2">
                              <hr>
                            </td>
                          </tr>
                          <tr>
                            <td class="fw-bold">Offer Price</td>
                            <td class="fw-bold">{{ purchase.valuation.vehiclePriceOffered | currency:'GBP' }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="card">
                    <div class="card-header fw-bold">Answers</div>
                    <div class="card-body obfuscated-playback">
                      <table class="table table-borderless table-sm">
                        <tr *ngFor="let answer of purchase.answers">
                          <td>{{ answer.parameterName }}</td>
                          <td>{{ answer.displayValue }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="vehicleCheckPanel">
        <h4 ngbAccordionHeader>
          <button ngbAccordionButton>Vehicle Details</button>
        </h4>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="purchase.vehicleCheckId">
                <app-vehicle-check [quoteStateId]="purchase.quoteStateId" [vrm]="purchase.vrm"
                  [vehicleDetails]="vehicleDetails" [mileage]="mileage"></app-vehicle-check>
              </div>
              <div *ngIf="!purchase.vehicleCheckId">
                Vehicle check has not been done for this purchase
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="mileagePanel">
        <h4 ngbAccordionHeader>
          <button ngbAccordionButton>Mileage Records</button>
        </h4>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="purchase.vehicleCheckId">
                <app-mileage-display [quoteStateId]="purchase.quoteStateId" [vrm]="purchase.vrm" [mileage]="mileage"
                  [mileageDecision]="mileageDecision"></app-mileage-display>
              </div>
              <div *ngIf="!purchase.vehicleCheckId">
                Vehicle check has not been done for this purchase
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="documentPanel">
        <h4 ngbAccordionHeader>
          <button ngbAccordionButton>Mandatory Images</button>
        </h4>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="!purchase.documents || purchase.documents.length === 0">
                No documents exist for this purchase
              </div>
              <button class="btn btn-success my-1 mb-4" *ngIf="purchase.isPurchased" (click)="editDocuments()">
                Add Mandatory Images
                <span class="fa fa-camera"></span>
              </button>
              <div class="row align-items-end" *ngIf="purchase.documents && purchase.documents.length > 0">
                <div class="col-lg-4 col-md-6 col-sm-12 mb-5 summaryImage" *ngFor="let doc of purchase.documents">
                  <div *ngIf="!isPdf(doc.filePath)">
                    <img [src]="doc.imgAsBase64String ?? doc.url" [alt]="doc.stampCode" class="largeThumbnail"
                      (click)="showPreview(doc, doc.stampName, preview)" />
                  </div>
                  <div *ngIf="isPdf(doc.filePath)">
                    <a [href]="doc.imgAsBase64String" [download]="doc.fileName">
                      <img class="mediumThumbnail" src="../../assets/images/pdf.svg">
                    </a>
                  </div>
                  <div class="mt-2"><strong>{{ doc.documentTypeName }}</strong></div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="imagePanel">
        <h4 ngbAccordionHeader>
          <button ngbAccordionButton>Vehicle Images</button>
        </h4>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="!purchase.vehicleImages || purchase.vehicleImages.length === 0">
                No images exist for this purchase
              </div>
              <button class="btn btn-success my-1 mb-4" *ngIf="purchase.isPurchased" (click)="editVehicleImages()">
                Add Images
                <span class="fa fa-camera"></span>
              </button>
              <div class="row align-items-end" *ngIf="purchase.vehicleImages && purchase.vehicleImages.length > 0">
                <div class="col-lg-4 col-md-6 col-sm-12 mb-5 summaryImage" *ngFor="let image of purchase.vehicleImages">
                  <img [src]="image.imgAsBase64String" [alt]="image.description" class="largeThumbnail"
                    (click)="showPreview(image, image.description, preview)" />
                </div>
              </div>
              <div *ngIf="purchase.hasAppraisalVideo" class="video-container">
                <div *ngIf="loadingVideo" class="video-loading">
                  <app-loading-wbac-animation></app-loading-wbac-animation>
                </div>
                <img src="../../../assets/images/video.png" alt="video" (click)="showVideo(videoPreview)">
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="authTasksPanel" *ngIf="authRequests && authRequests.length > 0">
        <h4 ngbAccordionHeader>
          <button ngbAccordionButton>Auth Tasks</button>
        </h4>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template ngbPanelContent>

              <table>
                <thead class="">
                  <tr>
                    <th class="table-value header">Auth Type</th>
                    <th class="table-value header">Authoriser</th>
                    <th class="table-value header">Time Requested</th>
                    <th class="table-value header">Time Approved</th>
                    <th class="table-value header">Time Declined</th>
                  </tr>
                </thead>
                <tbody *ngFor="let request of authRequests">
                  <tr class="">
                    <td class="table-value">{{ getAuthorizationTypeDescription(request.authorizationTypeId) }}</td>
                    <td class="table-value">{{ request.authorizedBy }}</td>
                    <td class="table-value">{{ request.requested | date: 'short' }}</td>
                    <td class="table-value">{{ request.approved | date: 'short' }}</td>
                    <td class="table-value">{{ request.declined | date: 'short' }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #preview let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{ previewStamp }}</h4>
  </div>
  <div class="modal-body">
    <img [src]="previewString" width="100%" />
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="modal.close()" ngbAutofocus>Close</button>
  </div>
</ng-template>

<ng-template #videoPreview let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{ previewStamp }}</h4>
  </div>
  <div class="modal-body">
    <video [src]="previewString" width="100%" playsinline="false" controls controlslist="nodownload"
      disablepictureinpicture="true"></video>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="modal.close()" ngbAutofocus>Close</button>
  </div>
</ng-template>