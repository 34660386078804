<div *ngIf="isScrollbarRequired" class="multiple-derivatives-display-number">
  There are {{ vehicleDescriptions.length }} derivatives available to choose from:
</div>
<div class='multiple-derivatives-container'>
  <ng-scrollbar visibility="always" [disableInteraction]="!isScrollbarRequired">
    <div class="derivative-list" *ngFor="let derivative of vehicleDescriptions">
      <app-vehicle-details class="derivative"
                           [ngClass]="!isScrollbarRequired ? 'no-scrollbar' : ''"
                           [vrm]="vrm"
                           [vehicleDescription]="derivative">
      </app-vehicle-details>
      <input type="radio"
             name="radiogroup"
             value="vehicle"
             class="derivative-selection"
             (change)="selectDerivative(derivative)" />
    </div>
  </ng-scrollbar>
</div>
<div class="multiple-derivatives-buttons mt-4">
  <button [ngClass]="{'active':isDerivativeSelected, 'invalid':!isDerivativeSelected, 'loading': loading}" class="modal-button multiple-derivatives-button next-btn ml-2 py-3" [disabled]="!isDerivativeSelected || loading" (click)="proceedWithSelectedDerivative()">
    <span [ngClass]="{'loading': loading, 'invalid':!isDerivativeSelected}" class="next-icon"></span>
    <span class="next-text">Next</span>
  </button>
</div>
