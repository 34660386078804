import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ComponentFactory, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { PathFinderService } from '../../services/path-finder.service';
import { BaseComponentDirective } from '../../base/base.component';
import { DamageService } from '../../services/damage.service';
import { NotificationService } from '../../services/notification.service';
import { DamageAreaComponent } from '../damage-area/damage-area.component';
import { DamageDirective } from '../damage.directive';
import { Fault } from '../models/fault.model';
import { VehicleArea } from '../models/vehicle-area.model';
import { UntypedFormGroup } from '@angular/forms';
import { ConfirmDialogService } from '../../confirm-dialog/services/confirm-dialog.service';
import { ValuationSummary } from '../models/valuation-summary.model';
import { Question } from '../../user-input/models/question.model';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-damage-total',
  templateUrl: './damage-total.component.html',
  styleUrls: ['../damage.scss'],
  animations: [
    trigger('fault', [
      transition('void => new', [
        style({ backgroundColor: 'yellow' }),
        animate(1000, style({ backgroundColor: 'white' }))
      ]),
    ])
  ]
})
export class DamageTotalComponent extends BaseComponentDirective implements OnInit {

  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Input() questionForm: UntypedFormGroup;
  @Input() answers: Question[];
  vehicleAreas: Array<VehicleArea>;
  existingFaults: Array<Fault> = new Array<Fault>();
  selectedArea: VehicleArea;
  activeAccordionId: string;
  damageMatrix: Fault[];
  allAreasVisited = false;
  isBusy = true;
  canProceed = true;
  damageAreaFactory: ComponentFactory<DamageAreaComponent>;
  damageAreaComponent: DamageAreaComponent;
  @ViewChild(DamageDirective, { static: true }) damageArea: DamageDirective;

  conditionAuthControl: UntypedFormGroup;
  engineAuthControl: UntypedFormGroup;

  constructor(private damageService: DamageService,
    private notifications: NotificationService,
    private pathFinder: PathFinderService,
    private confirmService: ConfirmDialogService,
    private loadingService: LoadingService,
    componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.damageAreaFactory = componentFactoryResolver.resolveComponentFactory(DamageAreaComponent);
  }

  ngOnInit() {
    this.damageService.getDamageMatrix$().subscribe(res => {
      this.damageService.getExistingDamageFields$(this.quoteStateId).pipe(
        tap(faults => {
          this.existingFaults = faults;
          this.damageMatrix = res;
          this.damageService.getVehicleAreas$(this.pathFinder.getAppraisalVehicleType()).pipe(takeUntil(this.componentDestroyed)).subscribe(areas => {
            this.isBusy = false;
            this.loadingService.loading.emit(this.isBusy);
            this.vehicleAreas = areas;
            this.loadDamageArea(areas[0]);
            this.pathFinder.registerTransitionEvent(onComplete => {
              this.clearNewMarkers();
              this.loadNextArea(onComplete);
            });
            this.pathFinder.registerStepbackInterceptor(() => {
              const index = this.vehicleAreas.findIndex(area => area.description === this.selectedArea.description);
              if (index === 0) {
                return true;
              } else {
                this.clearNewMarkers();
                this.loadDamageArea(this.vehicleAreas[index - 1]);
                return false;
              }
            });
          });
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to retrieve appraisal report', err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    });
  }

  getFormControl(parameterName: string): UntypedFormGroup {
    return this.questionForm.get('items')['controls'].filter(x => x.value.name === parameterName)[0];
  }

  getActiveAreas() {
    return this.vehicleAreas.filter(function (area) { return area.isActive; });
  }

  loadDamageArea(area: VehicleArea) {
    this.selectedArea = area;
    this.selectedArea.isActive = true;
    this.activeAccordionId = this.getAccordionPanelId(area);
    const containerRef = this.damageArea.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(this.damageAreaFactory);
    this.damageAreaComponent = componentRef.instance;
    componentRef.instance.vehicleArea = area;
    componentRef.instance.damageMatrix = this.damageMatrix.filter(f => area.components.find(x => x.componentId === f.componentId));
    componentRef.instance.faultSelector.pipe(takeUntil(this.componentDestroyed)).subscribe(fault => this.addDamageItem(fault));
    const index = this.vehicleAreas.findIndex(vehicleArea => {
      return vehicleArea.description === this.selectedArea.description;
    }) + 1;
    this.allAreasVisited = index === this.vehicleAreas.length;
    this.existingFaults.forEach(element => {
      element.zoneName = element.vehicleZoneName;
      element.zoneId = element.vehicleZoneId;
      if (area.components.find(x => x.componentId === element.componentId)) {
        if (!element.alreadyLoaded) {
          this.damageAreaComponent.faultSelected(element);
          element.alreadyLoaded = true;
        }
      }
    });
    this.checkHasEnoughDamage(this.selectedArea);
  }

  checkHasEnoughDamage(area: VehicleArea) {
    if (area.isSingleSelection) {
      this.canProceed = area.faults && area.faults.length === 1;
      if (this.canProceed) {
        this.group.controls.value.setValue(true);
      } else {
        this.group.controls.value.setValue(null);
      }
    } else {
      this.canProceed = true;
      this.group.controls.value.setValue(true);
    }
  }

  selectDamageArea(event) {
    this.clearNewMarkers();
    const areaId = parseInt(event.srcElement.value, 10);
    this.loadDamageArea(this.vehicleAreas.find(area => area.vehicleAreaId === areaId));
  }

  loadNextArea(onComplete: (damageFinalised: boolean) => void) {
    if (this.allAreasVisited) {
      this.confirmService.showYesNoConfirmation('Are you sure you want to finalise the vehicle condition?', () => {
        this.submitDamage(onComplete);
      }, () => {
        onComplete(false);
      });
    } else {
      const index = this.vehicleAreas.findIndex(area => area.description === this.selectedArea.description) + 1;
      if (index < this.vehicleAreas.length) {
        this.loadDamageArea(this.vehicleAreas[index]);
        this.allAreasVisited = index === this.vehicleAreas.length - 1;
      }
      onComplete(false);
    }
  }

  submitDamage(onComplete: (damageFinalised: boolean) => void) {
    const faults = new Array<Fault>();
    this.vehicleAreas.forEach(area => {
      if (area.faults) {
        area.faults.forEach(fault => {
          faults.push(fault);
        });
      }
    });
    this.damageService.postDamage$(this.quoteStateId, faults).pipe(
      tap((result) => {
        this.pathFinder.appendAnswer('VideoRequiredForCap', result.additionalVehicleCheck.engineVideo.toString());
        this.pathFinder.setValuationAmount(result);
        this.checkAdditionalEngineChecks(result);
        onComplete(true);
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to submit damage', err);
        onComplete(false);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  moveNext() {
    this.clearNewMarkers();
    this.loadNextArea(null);
  }

  cloneFault(fault: Fault) {
    const damageItem = new Fault();
    damageItem.zoneId = fault.zoneId;
    damageItem.zoneName = fault.zoneName;
    damageItem.componentId = fault.componentId;
    damageItem.componentName = fault.componentName;
    damageItem.faultId = fault.faultId;
    damageItem.faultName = fault.faultName;
    damageItem.isNew = true;
    return damageItem;
  }

  addDamageItem(fault: Fault) {
    if (!this.selectedArea.faults) {
      this.selectedArea.faults = new Array<Fault>();
    }
    if (this.selectedArea.isSingleSelection) {
      const existingFault = this.selectedArea.faults.find(f => f.componentId === fault.componentId);
      if (existingFault) {
        this.removeDamageItem(existingFault, false);
      }
    }
    this.clearNewMarkers();
    this.selectedArea.faults.unshift(this.cloneFault(fault));
    this.checkHasEnoughDamage(this.selectedArea);
  }

  checkAdditionalEngineChecks(valuation: ValuationSummary) {
    // Check if on dodgy list
    if (valuation.additionalVehicleCheck.engineAuthorization) {
      if (this.pathFinder.isValuationAmountAboveCategoryThreshold(valuation.vehiclePriceOffered)
        && this.pathFinder.isMileageAboveCapAuthThreshold(null)) {
          this.pathFinder.appendAnswer('AdditionalEngineChecks', 'true');
          return;
        }
    }
    // Check just absolute value
    if (this.pathFinder.isValuationAmountAboveThreshold(valuation.vehiclePriceOffered)) {
      this.pathFinder.appendAnswer('AdditionalEngineChecks', 'true');
    }
  }

  clearNewMarkers() {
    if (this.selectedArea.faults) {
      this.selectedArea.faults.forEach(fault => {
        fault.isNew = false;
      });
    }
  }

  removeDamageItem(fault: Fault, notifyArea: boolean) {
    this.selectedArea.faults.splice(this.selectedArea.faults.indexOf(fault), 1);
    if (notifyArea) {
      this.damageAreaComponent.onDeleteFault(fault);
    }
    this.checkHasEnoughDamage(this.selectedArea);
  }

  getAreaHeading() {
    if (this.selectedArea) {
      return this.selectedArea.description;
    }
    return '';
  }

  getAreaNameWithCount(area: VehicleArea) {
    let faultCount = 0;
    if (area.faults) {
      faultCount = area.faults.length;
    }
    return `${area.description} * ${faultCount}`;
  }

  getAccordionPanelId(area: VehicleArea) {
    return `panel-${area.vehicleAreaId}`;
  }

  toggleActiveArea(event) {
    if (event.panelId !== this.activeAccordionId) {
      const areaId = parseInt(event.panelId.replace('panel-', ''), 10);
      this.loadDamageArea(this.vehicleAreas.find(area => area.vehicleAreaId === areaId));
    }
  }
}
