import { Component, OnInit, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { UntypedFormGroup, ValidatorFn, AbstractControl, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { KeyValuePair } from '../../models/key-value-pair.model';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['../input.component.scss']
})
export class DropdownInputComponent extends BaseInputComponent implements OnInit {

  _options: Array<KeyValuePair>;

  @Input() set options(value: Array<KeyValuePair>) {
    this._options = value;
    this.setValue(this.group.controls.value.value);
  }

  get options(): Array<KeyValuePair> {
    return this._options;
}

  altGroup = new UntypedFormGroup({
    list: new UntypedFormControl(null)
  });

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.altGroup.controls.list.setValidators([ Validators.required, this.group.controls.value.validator, this.listValidator() ]);
    this.group.controls.value.valueChanges.subscribe(() => {
      this.setEnabledState();
    });
    this.setEnabledState();
  }

  setEnabledState() {
    if (this.group.controls.value.enabled) {
      this.altGroup.controls.list.enable({ emitEvent: false });
    } else {
      this.altGroup.controls.list.disable({ emitEvent: false });
      this.altGroup.controls.list.setValue(null);
    }
  }

  setValue(key) {
    if (this.options) {
      const option = this.options.find(x => x.key === key);
      if (option) {
        this.altGroup.controls.list.setValue(option.value);
        this.group.controls.value.setValue(key);
      } else {
        this.group.controls.value.setValue(null);
      }
    }
  }

  valueChanged(key) {
    this.setValue(key);
    this.change.emit(this.group);
  }

  listValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.options || !control.value) {
        return null;
      }
      const option = this.options.find(x => x.value === control.value);
      if (!option) {
        return {'invalid': {value: control.value}};
      }
      return null;
    };
  }
}
