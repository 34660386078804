import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { QuizFailure, QuizSubmission } from '../quiz/models/quiz-submission.model';
import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth-service';
import { RoleGuardService } from '../role-guard.service';
import { QuizOfTheDay } from '../quiz/models/quiz-of-the-day.model';

@Injectable({
  providedIn: 'root',
})

export class QuizOfTheDayService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private roleGuardService: RoleGuardService
  ) {
  }

  isQuizRequired(): boolean {
    if (this.roleGuardService.hasRole(AuthService.exemptFromQuiz)) {
      return false;
    }

    const completed = sessionStorage.getItem('quiz-completed');
    if (completed === this.completedStamp()) {
      return false;
    }

    return true;
  }

  quizCompleted(): void {
    sessionStorage.setItem('quiz-completed', this.completedStamp());
  }

  private completedStamp(): string {
    return `${new Date().toLocaleDateString()}-${this.authService.getUsername()}`;
  }

  getQuiz$(buyerId: number): Observable<QuizOfTheDay> {
    return this.http.get<QuizOfTheDay>(`${environment.ppxPrivateApi}api/quizoftheday/${buyerId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  submitQuiz$(quizSubmission: QuizSubmission): Observable<boolean> {
    return this.http.post<boolean>(`${environment.ppxPrivateApi}api/quizoftheday`, quizSubmission).pipe(
      catchError(err => throwError(err))
    );
  }

  emailOnFailure$(quizFailure: QuizFailure): Observable<boolean> {
    return this.http.post<boolean>(`${environment.ppxPrivateApi}api/quizoftheday/emailonfailure`, quizFailure).pipe(
      catchError(err => throwError(err))
    );
  }
}
