<div *ngIf="vehicleArea.isSingleSelection" [formGroup]="faultGroup" class="ps-3">
  <div formArrayName="items" *ngFor="let item of faultGroup.get('items')['controls']; let i = index;">
    <div [formGroupName]="i">
      <div class="form-group" [formGroup]="item">
        <h4>{{ faultGroup.value.items[i].name }}</h4>
        <div class="d-grid gap-2" ngbRadioGroup name="faults{{ faultGroup.value.items[i].id }}">
          <label ngbButtonLabel class="btn list-button"
            [ngClass]="{'active' : selectedFault?.faultId === fault.faultId}"
            *ngFor="let fault of getSingleSelectionFaultsForComponent(faultGroup.value.items[i].id)">
            <input ngbButton type="radio" formControlName="value" [value]="fault.faultId" (change)="faultSelected(fault)">
            {{ fault.faultName }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!vehicleArea.isSingleSelection" class="ps-3">
  <div *ngIf="!displayOther && !pickFault">
    <h4 class="page-subheader">Component</h4>
    <div class="d-grid gap-2">
      <button class="btn btn-info btn-damage" *ngFor="let component of favouriteComponents"
              (click)="componentSelected(component.componentId)">
        {{ component.componentName }}
      </button>
      <button class="btn btn-secondary btn-damage" (click)="showMore()" *ngIf="otherComponents.length > 0">
        More...
      </button>
    </div>
  </div>
  <div *ngIf="displayOther && !pickFault" class="ps-3">
    <h4 class="page-subheader">Component</h4>
    <div class="d-grid gap-2">
      <button class="btn btn-info btn-damage" *ngFor="let component of otherComponents"
            (click)="componentSelected(component.componentId)">
        {{ component.componentName }}
      </button>
      <span (click)="back()" class="link" *ngIf="favouriteComponents.length > 0"><span class="fa fa-chevron-left me-1 mt-3"></span>Back</span>
    </div>
  </div>
  <div *ngIf="!displayOther && pickFault" class="ps-3">
    <h4 class="page-subheader">Fault</h4>
    <div class="d-grid gap-2">
      <button class="btn btn-primary btn-damage" *ngFor="let fault of favouriteFaults"
            (click)="faultSelected(fault)">
        {{ fault.faultName }}
      </button>
      <button class="btn btn-secondary btn-damage" (click)="showMore()" *ngIf="otherFaults.length > 0">
        More...
      </button>
      <span (click)="componentUnselected()" class="link"><span class="fa fa-chevron-left me-1 mt-3"></span>Back</span>
    </div>
  </div>
  <div *ngIf="displayOther && pickFault" class="ps-3">
    <h4 class="page-subheader">Fault</h4>
    <div class="d-grid gap-2">
      <button class="btn btn-primary btn-damage" *ngFor="let fault of otherFaults"
            (click)="faultSelected(fault)">
        {{ fault.faultName }}
      </button>
      <span (click)="back()" class="link" *ngIf="favouriteFaults.length > 0"><span class="fa fa-chevron-left me-1 mt-3"></span>Back</span>
      <span (click)="componentUnselected()" class="link" *ngIf="favouriteFaults.length === 0"><span class="fa fa-chevron-left me-1 mt-3"></span>Back</span>
    </div>
  </div>
</div>
