import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { InvoiceContent } from '../invoice/models/invoiceContent.model';
import { Signature } from '../invoice/models/signature.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getInvoiceDetails$(quoteStateId: number): Observable<InvoiceContent> {
    return this.http.get<InvoiceContent>(`${environment.ppxPrivateApi}api/Invoice/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  generateInvoice$(quoteStateId: number, signatureImages: Array<Signature>): Observable<string> {
    return this.http.post<string>(`${environment.ppxPrivateApi}api/Invoice/${quoteStateId}`,
      signatureImages,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        catchError(err => throwError(err))
      );
  }

  regenerateInvoice$(): Observable<string> {
    return this.http.post<string>(`${environment.ppxPrivateApi}api/Invoice/regenerateInvoice`,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        catchError(err => throwError(err))
      );
  }
}
