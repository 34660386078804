import { Component, Input, OnInit } from '@angular/core';
import { MileageService } from '../services/mileage.service';
import { UntypedFormGroup } from '@angular/forms';
import { MileageCheckResponse } from './models/mileage-check-response.model';
import { Observable, EMPTY, Subject, forkJoin } from 'rxjs';
import { tap, catchError, takeUntil, take, debounceTime } from 'rxjs/operators';
import { BaseComponentDirective } from '../base/base.component';
import { NotificationService } from '../services/notification.service';
import { LoadingService } from '../services/loading.service';
import { MileageRecord } from './models/mileage-record.model';
import { MotCheckResponse } from './models/mot-check-response.model';
import { Page } from '../user-input/models/page.model';
import { KeyValuePair } from '../models/key-value-pair.model';

@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.scss',
              '../inputs/input.component.scss']
})

export class MileageComponent extends BaseComponentDirective implements OnInit {

  @Input() vrm: string;
  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Input() questionForm: UntypedFormGroup;
  @Input() page: Page;

  hasDvsaDiscrepancy: boolean;
  hasNmrDiscrepancy: boolean;
  hasBcaDiscrepancy: boolean;
  hasMileageDiscrepancy: boolean;
  calculatedDecision: string;
  motUrl: string;

  mileageComplete = new Subject<boolean>();
  motcomplete = new Subject<boolean>();

  complete$: Observable<[boolean, boolean]>;
  motCheck$: Observable<MotCheckResponse>;
  mileageCheck$: Observable<MileageCheckResponse>;

  nmrMileage: Array<MileageRecord>;
  bcaMileage: Array<MileageRecord>;
  dvsaMileage: Array<MileageRecord>;

  possiblyNIPlate: boolean;
  showDvsaHistory: boolean;
  showNmrHistory: boolean;
  showBcaHistory: boolean;

  discrepancyControl: UntypedFormGroup;
  mileageControl: UntypedFormGroup;
  mileageDecisionControl: UntypedFormGroup;
  decisionOverrideControl: UntypedFormGroup;

  allowedDecisionOptions: Array<KeyValuePair>;

  constructor(
    private mileageService: MileageService, 
    private notifications: NotificationService, 
    private loadingService: LoadingService) {
      super();
  }

  ngOnInit() {
    this.motUrl = `https://www.check-mot.service.gov.uk/results?registration=${this.vrm}&checkRecalls=true`;

      this.loadingService.loading.emit(true);
    if (this.questionForm) {
      this.discrepancyControl = this.getFormControl('HasMileageDiscrepancy');
      this.mileageControl = this.getFormControl('Mileage');
      this.mileageDecisionControl = this.getFormControl('MileageDecision');
      this.decisionOverrideControl = this.getFormControl('DecisionOverride');
      this.allowedDecisionOptions = this.page.questions[2].options;
    }
    this.createMileageAndMotHistoryObservables();
    this.motCheck$.pipe(takeUntil(this.componentDestroyed)).subscribe();
    this.mileageCheck$.pipe(takeUntil(this.componentDestroyed)).subscribe();
    this.complete$
      .pipe(take(1))
      .subscribe(() => {
        this.updateMileageDiscrepancy();
        this.subscribeToControlChanges();
      this.loadingService.loading.emit(false);
      });
  }

  getFormControl(parameterName: string): UntypedFormGroup {
    return this.questionForm.get('items')['controls'].filter(x => x.value.name === parameterName)[0];
  }

  createMileageAndMotHistoryObservables() {
    this.mileageComplete = new Subject<boolean>();
    this.motcomplete = new Subject<boolean>();

    this.complete$ = forkJoin([
      this.mileageComplete,
      this.motcomplete
    ]);

    this.mileageCheck$ = this.mileageService.mileageCheck$(this.quoteStateId).pipe(
      tap(result => {
        this.possiblyNIPlate = result.possiblyNIPlate;
        this.nmrMileage = result.nmrMileageRecords;
        this.bcaMileage = result.bcaMileageRecords;
        this.mileageComplete.next(true);
        this.mileageComplete.complete();
      }),
      catchError(err => {
        if (this.group) {
          this.group.controls.value.setValue(true);
        }
        this.notifications.dangerToast('There was a problem finding mileage history for this vehicle. Please retry, or proceed with a mileage discrepancy.', err);
        this.mileageComplete.next(true);
        this.mileageComplete.complete();
        return EMPTY;
      }),
      takeUntil(this.retryButtonPressedOrComponentDestroyed$)
    );

    this.motCheck$ = this.mileageService.doMotHistoryCheck$(this.quoteStateId).pipe(
      tap(result => {
        this.dvsaMileage = result.dvlaMileageRecords;
        this.motcomplete.next(true);
        this.motcomplete.complete();
      }),
      catchError(err => {
        if (this.group) {
          this.group.controls.value.setValue(true);
        }
        this.notifications.dangerToast('There was a problem finding DVSA mileage for this vehicle. Click Try Again to retry.', err);
        this.motcomplete.next(true);
        this.motcomplete.complete();
        return EMPTY;
      }),
      takeUntil(this.retryButtonPressedOrComponentDestroyed$)
    );
  }

  subscribeToControlChanges() {
    this.mileageControl.valueChanges.pipe(
      debounceTime(500),
      tap(() => {
        this.updateMileageDiscrepancy();
        this.updateDiscrepancyOverride();
      }),
      takeUntil(this.componentDestroyed))
      .subscribe();

    this.mileageDecisionControl.valueChanges.pipe(
      tap(() => {
        this.updateDiscrepancyOverride();
      }),
      takeUntil(this.componentDestroyed))
      .subscribe();
  }

  updateMileageDiscrepancy() {
    this.hasMileageDiscrepancy = false;
    this.hasNmrDiscrepancy = false;
    this.hasDvsaDiscrepancy = false;
    this.hasBcaDiscrepancy = false;

    this.calculatedDecision = '1';

    if (this.bcaMileage && this.bcaMileage.length > 0
        && (this.bcaMileage.filter(bca => bca.hasDiscrepancy).length > 0 || this.bcaMileage[0].mileageReading > (Number(this.mileageControl.value.value) || 0))) {
      this.hasBcaDiscrepancy = true;
      this.showBcaHistory = true;
    }

    if (this.nmrMileage && this.nmrMileage.length > 0
        && (this.nmrMileage.filter(nmr => nmr.hasDiscrepancy).length > 0 || this.nmrMileage[0].mileageReading > (Number(this.mileageControl.value.value) || 0))) {
      this.hasNmrDiscrepancy = true;
      this.showNmrHistory = true;
    }

    if (this.dvsaMileage && this.dvsaMileage.length > 0
        && (this.dvsaMileage.filter(dvsa => dvsa.hasDiscrepancy).length > 0 || this.dvsaMileage[0].mileageReading > (Number(this.mileageControl.value.value) || 0))) {
      this.hasDvsaDiscrepancy = true;
      this.showDvsaHistory = true;
      this.calculatedDecision = '204';
    }

    this.updateAllowedDecisionOptions();

    this.hasMileageDiscrepancy = this.hasDvsaDiscrepancy;
    if (this.discrepancyControl) {
      this.discrepancyControl.controls.value.setValue(this.hasMileageDiscrepancy);
    }
    if (this.mileageDecisionControl) {
      // Check existing decision still allowed
      if (this.mileageDecisionControl.value.value && !this.allowedDecisionOptions.some(o => o.key === this.mileageDecisionControl.value.value)) {
        this.mileageDecisionControl.controls.value.setValue(this.calculatedDecision);
      }
      // Preselect decision if no existing value
      if (!this.mileageDecisionControl.value.value) {
        this.mileageDecisionControl.controls.value.setValue(this.calculatedDecision);
      }
    }

    this.mileageDecisionControl.controls.informedAnswer.setValue(this.calculatedDecision);
  }

  updateDiscrepancyOverride() {
    const value = this.mileageDecisionControl.value.value;
    if (this.isBetterThanCalculated(value)) {
      this.decisionOverrideControl.controls.value.setValue(value);
    } else {
      this.decisionOverrideControl.controls.value.setValue(null);
    }
  }

  isBetterThanCalculated(value: string): boolean {
    const priority = ['1', '205', '204'];
    return priority.indexOf(value) < priority.indexOf(this.calculatedDecision);
  }

  updateAllowedDecisionOptions() {
    switch (this.calculatedDecision) {
      case '1':
      case '204':
        this.allowedDecisionOptions = this.page.questions[2].options.filter(o => ['1', '204', '205'].includes(o.key));
        break;

      default:
        this.allowedDecisionOptions = this.page.questions[2].options;
        break;
    }
  }
}
