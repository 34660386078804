import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../core/auth-service';
import { TaskViewTypes } from './enums/task-view-types';
import { NotificationService } from '../services/notification.service';
import { TaskListAuthComponent } from './task-list-auth/task-list-auth.component';
import { TaskListUserComponent } from './task-list-user/task-list-user.component';
import { BuyerService } from '../services/buyer.service';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { Buyer } from '../models/buyer.model';
import { EMPTY, Observable, Subject } from 'rxjs';
import { PagerService } from '../pager/services/pager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskListRetrieveComponent } from './task-list-retrieve/task-list-retrieve.component';
import { VrmInputModalComponent } from '../search-vrm/vrm-input-modal/vrm-input-modal.component';
import { SignalREventService } from '../services/signal-revent.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy, AfterViewInit {
  view: TaskViewTypes = TaskViewTypes.User;
  loading: boolean;
  taskView = TaskViewTypes;
  username: string;
  currentTime: number;
  refreshDate: NodeJS.Timeout;
  hasRegionRoles: boolean;
  public buyerObject: Buyer;
  currentView: string;
  vrm: string;
  destroy$ = new Subject();

  constructor(
    private buyerService: BuyerService,
    private authService: AuthService,
    private pagerService: PagerService,
    private modalService: NgbModal,
    private notifications: NotificationService,
    private signalREventService: SignalREventService
    ) { }


  @ViewChild(TaskListAuthComponent) taskListAuthComponent: TaskListAuthComponent;
  @ViewChild(TaskListUserComponent) taskListUserComponent: TaskListUserComponent;
  @ViewChild(TaskListRetrieveComponent, {static: false}) taskListRetrieveComponent: TaskListRetrieveComponent;


  ngOnInit(): void {
    this.loading = true;
    this.username = this.authService.getUser()?.username.split('@')[0].toLowerCase();
    this.currentTime = Date.now();

  }

  ngOnDestroy() {
    clearInterval(this.refreshDate);
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.getBuyerObject().pipe(take(1)).subscribe(() => {
      const regionIds = this.getRegionsIds();
      this.signalREventService.authorizationRequestReceived$.pipe(takeUntil(this.destroy$)).subscribe((authorizationRequestNotification) => {
        if (regionIds.includes(authorizationRequestNotification.regionId.toString())) {
          this.refreshData();
        }
      });
    });
    this.refreshDate = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
  }

  getBuyerObject(): Observable<Buyer> {
    return this.buyerService.getBuyerByUsername$(this.username).pipe(
      tap(() => {
        this.buyerObject = this.buyerService.getBuyerFromStorage();
        const regionIds = this.getRegionsIds();
          if (regionIds.length > 0) {
            this.setView(TaskViewTypes.Auth);
          }
        if (regionIds.length > 0) {
          this.hasRegionRoles = true;
        } else {
          this.hasRegionRoles = false;
        }
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to retrieve buyer object`, err);
        return EMPTY;
      }),
      take(1)
    );
  }

  getRegionsIds() {
    const employeeRegRoles = this.buyerObject.employee.employeeRegionRoles;
    const regionIds = new Array<string>();
    if (employeeRegRoles !== null && employeeRegRoles !== undefined) {
      employeeRegRoles.forEach(element => {
        if (element.roleId === 6) {
          regionIds.push(element.regionId.toString());
        }
      });
      return regionIds;
    }
  }

  setView(view: TaskViewTypes) {
    this.pagerService.setPageNumber(1);
    this.view = view;
  }

  refreshData() {
    if (this.view === TaskViewTypes.User) {
      this.taskListUserComponent.retrieveTasks();
    } else if (this.view === TaskViewTypes.Auth) {
      const regionIds = this.getRegionsIds();
      this.taskListAuthComponent.getRegionFilterForUsername();
      this.taskListAuthComponent.retrieveAuthTasks();
      this.taskListAuthComponent.getRegionsForUser(regionIds);
    } else if (this.view === TaskViewTypes.Retrieve) {
      this.taskListRetrieveComponent.retrieveTasks();
    }
  }

  searchVrm() {
    const modalRef = this.modalService.open(VrmInputModalComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'md'
    });
    modalRef.result.then(
      (result) => {
        this.vrm = result;
        this.setView(TaskViewTypes.Retrieve);
      },
      () => {}
    );
  }
}
