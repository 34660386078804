import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentValuationDetails } from './models/appointment-valuation-details';

@Component({
  selector: 'app-appointment-valuation-details',
  templateUrl: './appointment-valuation-details.component.html',
  styleUrls: ['./appointment-valuation-details.component.scss']
})
export class AppointmentValuationDetailsComponent {

  @Input() appointmentValuationDetails: AppointmentValuationDetails;

  constructor(private modal: NgbActiveModal) { }

  get capData() {
    let data = '';
    if (this.appointmentValuationDetails) {
      switch (this.appointmentValuationDetails.bookId) {
        case 1:
          data = `Car - ${this.appointmentValuationDetails.capId}`;
          break;

        case 2:
          data = `Van - ${this.appointmentValuationDetails.capId}`;
          break;
      }
    }
    if (data === '') {
      data = `Not known`;
    }

    return data;
  }


  closeModal() {
    this.modal.close();
  }

}
