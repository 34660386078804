import { Component, Input } from '@angular/core';
import { ValuationSummary } from '../damage/models/valuation-summary.model';

@Component({
  selector: 'app-offer-display',
  templateUrl: './offer-display.component.html',
  styleUrls: ['./offer-display.component.scss']
})
export class OfferDisplayComponent {

  @Input() valuation: ValuationSummary;

  get priceOffered(): number {
    return this.valuation.vehiclePriceOfferedPlusVat - this.valuation.adminFee;
  }

  get priceOfferedBeforeFlex(): number {

    let totalFlexAmount = this.valuation.totalFlexAmount;
    if (this.valuation.isVatRegistered) {
      totalFlexAmount = this.valuation.totalFlexAmount * (1 + this.valuation.vatRate / 100);
    }
    return this.valuation.vehiclePriceOfferedPlusVat - totalFlexAmount - this.valuation.adminFee;
  }

  get showOriginalOffer() {
    return this.priceOffered !== this.priceOfferedBeforeFlex;
  }

  get noVatPriceOffered(): number {
    return this.valuation.vehiclePriceOffered - this.noVatAdminFee;
  }

  get noVatPriceOfferedBeforeFlex(): number {
    return this.valuation.vehiclePriceOffered - this.noVatFlexAmount - this.noVatAdminFee;
  }

  get noVatFlexAmount(): number {
    return this.valuation.totalFlexAmount;
  }

  get noVatAdminFee(): number {
    return this.valuation.adminFee / (1 + this.valuation.vatRate / 100);
  }

}
