import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Appointment } from '../diary/models/appointment.model';
import { AppointmentService } from '../services/appointment.service';
import { BookAppointmentService } from './services/book-appointment.service';
import { BookAppointmentViewTypes } from './enums/book-appointment-view-types';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { NotificationService } from '../services/notification.service';
import { Site } from '../diary/models/site.model';
import { SitesAndSlotsByPostcode } from './models/sites-and-slots-by-postcode.model';
import { SiteOnDate } from './models/site-on-date.model';
import { SiteWithSlots } from './models/site-with-slots.model';
import { TimeSlot } from './models/time-slot.model';
import { ValuationSummary } from '../damage/models/valuation-summary.model';
import { WalkInLookupData } from '../walk-in/models/walk-in-lookup-data.model';
import moment from 'moment';
import { PostcodeLookupService } from '../postcode-lookup/services/postcode-lookup.service';
import { TaskTypes } from '../task-list/enums/task-types';
import { TaskService } from '../services/task.service';
import { SharedService } from '../core/shared.service';
import { ActiveSiteDays } from './models/active-site-days.model';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss']
})

export class BookAppointmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() appointmentSlots: SitesAndSlotsByPostcode;
  @Input() walkInLookupData: WalkInLookupData;
  @Input() valuation: ValuationSummary;
  @Input() bookId: number;
  @Input() maximumValueAppointmentDelayInDays: number;
  @Input() cancelledAppointment: boolean;
  @Input() isReschedule: boolean;
  @Output() backToValuationQuestions = new EventEmitter<boolean>();
  @Output() requiresManualValuation = new EventEmitter<boolean>();
  @Output() newPostcode = new EventEmitter<string>();

  view: BookAppointmentViewTypes;
  bookAppointmentView = BookAppointmentViewTypes;

  selectedSite: SiteWithSlots;
  selectedSiteDetails: Site;
  selectedDate: SiteOnDate;
  selectedTime: TimeSlot;
  timeSlots: Array<TimeSlot>;

  motExpiryDate: string;
  valuationSummary: ValuationSummary;
  isLoadingBackButton: boolean;
  isBookingAppointment: boolean;
  goToNextView: boolean;
  isHidden: boolean;
  noAppointmentsAvailable: boolean;
  destroy$ = new Subject();
  activeSites = new Array<ActiveSiteDays>();
  activeSiteIds = new Array<number>();
  todayDate = new Date();

  constructor(
    private appointmentService: AppointmentService,
    private bookAppointmentService: BookAppointmentService,
    private confirmDialogService: ConfirmDialogService,
    private notifications: NotificationService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private postcodeLookupService: PostcodeLookupService,
    private taskService: TaskService,
    private router: Router,
    private sharedService: SharedService) { }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit() {
    if (this.appointmentSlots.sites.length > 0 && !this.valuation.requiresManualValuation) {
      this.view = BookAppointmentViewTypes.AppointmentSlotsView;
      this.motExpiryDate = this.bookAppointmentService.getMotExpiryDateValue();
      this.valuationSummary = this.bookAppointmentService.getValuationSummary();
      this.getBuyerActiveSites();
    } else {
      this.noAppointmentsAvailable = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.valuation.requiresManualValuation) {
      setTimeout(() => {
        this.goBackToValuationQuestions();
        this.requiresManualValuation.emit(true);
      });
    }
  }

  setSelectedSite(selectedSite: SiteWithSlots) {
    this.selectedSite = selectedSite;
  }

  setSelectedDate(selectedDate: SiteOnDate) {
    this.selectedDate = selectedDate;
  }

  setSelectedTime(selectedTime: TimeSlot) {
    this.selectedTime = selectedTime;
    this.getSelectedSiteDetails();
  }

  onAppointmentSlotSelection() {
    this.getAppointmentsForSelectedSiteAndDate$().pipe(take(1)).subscribe(() => {
      this.populateTimeSlots();
      this.nextView();
    });
  }

  getAppointmentSlots$(): Observable<SitesAndSlotsByPostcode> {
    return this.appointmentService.getAppointmentSlots$(this.walkInLookupData.postcode, this.valuationSummary.vehiclePriceOffered, this.motExpiryDate, this.bookId)
      .pipe(
        tap(result => {
          this.appointmentSlots = result;
          if (this.isReschedule) {
            for (let index = 0; index < this.appointmentSlots.sites.length; index++) {
              const element = this.appointmentSlots.sites[index];
              if (!this.activeSiteIds.includes(element.siteId)) {
                this.appointmentSlots.sites.splice(index, 1);
                index--;
              }
            }

            const sundayDt = this.bookAppointmentService.nextDate(0);
            this.appointmentSlots.sites.forEach(site => {
              site.siteDates.forEach(siteDate => {
                let disable = true;
                const formattedDate = new Date(siteDate.date);
                const dayId = formattedDate.getDay();
                this.activeSites.forEach(element => {
                  if (element.day === dayId && element.siteId === site.siteId && formattedDate < sundayDt) {
                    disable = false;
                  }
                });
                if (disable) {
                  siteDate.appointmentsCount = 1;
                  siteDate.totalAppointmentSlotsCount = 0;
                }
              });
            });
          }
        }),
        catchError(err => {
          this.notifications.dangerToast('failed to get appointment slots', err);
          this.isLoadingBackButton = false;
          return EMPTY;
        })
      );
  }

  getAppointmentsForSelectedSiteAndDate$(): Observable<Array<Appointment>> {
    this.timeSlots = new Array<TimeSlot>();
    if (!this.selectedSite) {
      return EMPTY;
    }
    return this.appointmentService.getAppointmentsForSiteAndDate$(this.selectedSite.siteId, this.selectedDate.date.toString())
      .pipe(
        tap(result => {
          if (result) {
            const currentTime = moment();
            const bookedTimeSlotsToDisplay = new Array<TimeSlot>();
            const activeBookedTimeSlots = result.filter(x => moment(x.appointmentTime) > currentTime);
            activeBookedTimeSlots.forEach(x => bookedTimeSlotsToDisplay.push(new TimeSlot(moment(x.appointmentTime).format('HH:mm'), true)));
            this.timeSlots = Array.from(new Set(bookedTimeSlotsToDisplay));
          }
        }),
        catchError(err => {
          this.notifications.dangerToast(`failed to get appointments for siteId ${this.selectedSite.siteId}, date ${this.selectedDate.date}`, err);
          this.isLoadingBackButton = false;
          return EMPTY;
        })
      );
  }

  populateTimeSlots() {
    this.selectedDate.timeSlots.forEach(x => this.timeSlots.push(new TimeSlot(x.s.toString().replace(':00', ''), false, x.slotIds)));
    this.timeSlots.sort((a, b) => moment(a.startTime, 'HH:mm').valueOf() - moment(b.startTime, 'HH:mm').valueOf());
  }

  getSelectedSiteDetails() {
    this.selectedSiteDetails = null;
    this.appointmentService.getSite$(this.selectedSite.siteId)
      .pipe(
        tap(result => {
          this.selectedSiteDetails = result;
        }),
        catchError(err => {
          this.notifications.dangerToast(`failed to get site details for siteId ${this.selectedSite.siteId}`, err);
          return EMPTY;
        }),
        take(1)
      ).subscribe(() => {
        this.nextView();
      });
  }

  nextView() {
    switch (this.view) {
      case BookAppointmentViewTypes.AppointmentSlotsView:
        this.view = BookAppointmentViewTypes.TimeSlotsView;
        return;
      case BookAppointmentViewTypes.TimeSlotsView:
        this.view = BookAppointmentViewTypes.AppointmentSummary;
        return;
    }
  }

  backView() {
    switch (this.view) {
      case BookAppointmentViewTypes.TimeSlotsView:
        this.view = BookAppointmentViewTypes.AppointmentSlotsView;
        return;
      case BookAppointmentViewTypes.AppointmentSummary:
        this.view = BookAppointmentViewTypes.TimeSlotsView;
        return;
    }
  }

  onBackClick() {
    this.isLoadingBackButton = true;
    if (this.view === BookAppointmentViewTypes.TimeSlotsView) {
      this.getAppointmentSlots$().pipe(take(1)).subscribe(() => {
        this.isLoadingBackButton = false;
        this.backView();
      });
    } else if (this.view === BookAppointmentViewTypes.AppointmentSummary) {
      this.checkIfTimeSlotsStillAvailable();
    } else if (this.view === BookAppointmentViewTypes.AppointmentSlotsView) {
      this.goBackToValuationQuestions();
    }
  }

  checkIfTimeSlotsStillAvailable() {
    this.getAppointmentSlots$().pipe(
      tap(() => {
        this.selectedSite = this.appointmentSlots.sites.filter(x => x.siteId === this.selectedSite.siteId)[0];
        if (!this.selectedSite) {
          this.isLoadingBackButton = false;
          this.view = BookAppointmentViewTypes.AppointmentSlotsView;
          return EMPTY;
        }
        this.selectedDate = this.selectedSite.siteDates.filter(x => x.date === this.selectedDate.date)[0];
      }),
      switchMap(() => this.getAppointmentsForSelectedSiteAndDate$()),
      take(1)
    ).subscribe(() => {
      if (this.selectedSite) {
        this.isLoadingBackButton = false;
        this.backView();
        this.populateTimeSlots();
        const availableTimeSlots = this.timeSlots.filter(x => x.isBooked === false);
        this.view = availableTimeSlots.length === 0 ? BookAppointmentViewTypes.AppointmentSlotsView : this.view;
      }
    });
  }

  goBackToValuationQuestions() {
    this.isLoadingBackButton = false;
    this.activeModal.close();
    this.backToValuationQuestions.emit(true);
  }

  onConfirmAppointment() {
    this.isBookingAppointment = true;
    const time = moment(this.selectedTime.startTime, 'hh:mm:ss');
    const hour = time.hour();
    const minutes = time.minute();
    const duration = moment.duration({ hour: hour, minute: minutes });
    const appointmentDate = moment.utc(this.selectedDate.date).add(duration).toDate();
    this.walkInLookupData.appointmentDate = appointmentDate;
    this.walkInLookupData.valuationGuid = this.valuationSummary.valuationGuid;
    this.walkInLookupData.appointmentSlotIds = this.selectedTime.slotIds;
    this.walkInLookupData.isManualLookup = this.sharedService.getIsManualLookup();
    if (this.cancelledAppointment) {
      this.appointmentService.bookNewAppointmentFromCancelledTask$(this.walkInLookupData).pipe(
        catchError(err => {
          this.notifications.dangerToast('Failed to book appointment', err);
          return EMPTY;
        }), take(1)).subscribe(() => {
          this.isBookingAppointment = false;
          this.displayConfirmation();
        });
    } else {
      if (this.bookAppointmentService.taskType === TaskTypes.AppointmentNotPurchased) {
        this.taskService.notPurchasedAppointment$(this.bookAppointmentService.appointmentId)
          .pipe(
            catchError((err) => {
              this.notifications.dangerToast('Failed to complete task', err);
              return EMPTY;
            }),
            take(1)
          ).subscribe();
      }
      this.appointmentService.bookNewAppointment$(this.walkInLookupData).pipe(
        catchError(err => {
          this.notifications.dangerToast('Failed to book appointment', err);
          return EMPTY;
        }), take(1)).subscribe(() => {
          this.isBookingAppointment = false;
          this.displayConfirmation();
        });
    }
  }

  onPostcodeLookup() {
    this.isHidden = true;
    this.postcodeLookupService.openPostcodeLookupModal();
    this.postcodeLookupService.isPostcodeModalClosed$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isHidden = false;
      });

    this.postcodeLookupService.performNewPostcodeSearch$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(postcode => {
        this.newPostcode.emit(postcode);
        this.activeModal.close();
      });
  }

  displayConfirmation() {
    this.refreshView();
    this.modalService.dismissAll();
    this.confirmDialogService.confirmBookAppointment()
      .pipe(take(1))
      .subscribe();
  }

  cancelProcess() {
    this.confirmDialogService.confirmCancellation()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.modalService.dismissAll();
      });
  }

  refreshView(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.router.navigate(['/']);
  }

  getBuyerActiveSites() {
    this.appointmentService.getBuyerWeeklySchedule(moment(this.todayDate).format('YYYY-MM-DD')).pipe(
      tap(result => {
        const sites = result.sites.map(x => x.siteDays.map(y => [y.siteId, y.dayId]));
        sites.forEach(element => {
          element.forEach(field => {
            this.activeSites.push(new ActiveSiteDays(field[0], field[1]));
          });
        });
        this.activeSiteIds = [...new Set(this.activeSites.map(item => item.siteId))];
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to get buyer weekly schedule', err);
        return EMPTY;
      }), take(1)).subscribe();
  }
}
