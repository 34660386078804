import { Component, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../base/base.component';
import { Question, QuizOfTheDay } from './models/quiz-of-the-day.model';
import { QuizOfTheDayService } from '../services/quizoftheday.service';
import { BuyerService } from '../services/buyer.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuizQuestionMark } from '../enums/quiz-question-mark.enum';
import { QuizFailure, QuizAnswer, QuizSubmission } from './models/quiz-submission.model';
import { Buyer } from '../models/buyer.model';
import { AuthService } from '../core/auth-service';
import { NumberHelper } from '../helpers/number-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent extends BaseComponentDirective implements OnInit {

  isLoading: boolean;
  isError: boolean;
  quiz: QuizOfTheDay;
  quizForm: UntypedFormGroup;
  isCompleted: boolean;
  isAlreadyDone: boolean;

  score: number;
  isPass: boolean;
  isFail: boolean;

  buyer: Buyer;

  constructor(
    private buyerService: BuyerService,
    private quizOfTheDayService: QuizOfTheDayService,
    private authService: AuthService,
    private router: Router
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {

    this.isLoading = true;

    try {
      // Get buyer
      this.buyer = this.buyerService.getBuyerFromStorage();
      if (!this.buyer) {
        const username = this.authService.getUsername();
        const buyer = await this.buyerService.getBuyerByUsername$(username).toPromise();
        this.buyer = buyer;
      }

      await this.getQuiz();

    } catch (error) {
      this.isError = true;
      console.error(error);
    }

    this.isLoading = false;
  }

  async getQuiz() {
    // Get quiz for buyer
    this.quiz = await this.quizOfTheDayService.getQuiz$(this.buyer.employeeId).toPromise();
    if (this.quiz.isCompleted) {
      this.isAlreadyDone = true;
      this.quizOfTheDayService.quizCompleted();
    } else {
      this.quizForm = this.createControls(this.quiz.quizQuestions);
      if (!this.quiz.quiz.startedOn) {
        this.quiz.quiz.startedOn = new Date();
      }
    }
  }

  createControls(questions: Array<Question>) {
    const controls = {};
    questions.forEach(question => {
      question.key = `q${question.questionId}`;
      controls[question.key] = new UntypedFormControl('', Validators.required);
    });

    return new UntypedFormGroup(controls);
  }

  async onSubmit() {

    await this.markQuiz();

    const failure = new QuizFailure();
    failure.username = this.buyer.employee.userName;

    const submission = new QuizSubmission();
    submission.quizAttemptId = this.quiz.quiz.quizAttemptId;
    submission.buyerId = this.quiz.quiz.buyerId;
    submission.startedOn = this.quiz.quiz.startedOn;
    submission.endedOn = new Date();
    submission.answers = new Array<QuizAnswer>();
    this.quiz.quizQuestions.forEach(question => {
      const response = new QuizAnswer();
      response.questionId = question.questionId;
      response.choiceId = this.quizForm.controls[question.key].value;
      submission.answers.push(response);
    });

    await this.quizOfTheDayService.submitQuiz$(submission).toPromise();

    if (this.quiz.currentAttempt >= this.quiz.maxAttemptsAllowed) {
      this.isCompleted = true;
    }
  }

  async onRetryClick() {

    await this.getQuiz();

    // Reset flags
    this.score = undefined;
    this.isFail = undefined;
    this.isPass = undefined;
  }

  async onCloseClick() {
    this.quizOfTheDayService.quizCompleted();
    if (this.isFail) {
      const failure = new QuizFailure();
      failure.username = this.buyer.employee.userName;
      // await this.quizOfTheDayService.emailOnFailure$(failure).toPromise();
    }
    this.router.navigateByUrl('/');
  }

  private async markQuiz() {
    this.quiz.quizQuestions.forEach(question => {
      const response = this.quizForm.controls[question.key].value;
      const selectedOption = question.choices.find(option => option.choiceId === response);
      if (selectedOption.correct) {
        question.mark = QuizQuestionMark.Correct;
      } else {
        question.mark = QuizQuestionMark.Incorrect;
      }
    });

    this.score = this.quiz.quizQuestions.filter(q => q.mark === QuizQuestionMark.Correct).length;

    if (this.quiz.quizQuestions.every(q => q.mark === QuizQuestionMark.Correct)) {
      this.isPass = true;
    } else {
      this.isFail = true;
    }
  }

  formattedAttempt(): string {
    return NumberHelper.toOrdinal(this.quiz.currentAttempt);
  }
}
