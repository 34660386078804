<div class="form-group radio-group" [formGroup]="form">
  <div class="question-label">
    <label class="m-2" [attr.for]="question.key">{{ question.text }}</label>
    <img class="question-mark" [hidden]="!isCorrect()" src="../../assets/icons/stock/check.png" alt="tick">
    <img class="question-mark" [hidden]="!isIncorrect()" src="../../assets/icons/stock/uncheck.png" alt="cross">
  </div>
  <div [attr.id]="identifier" ngbRadioGroup>
    <div *ngFor="let option of question.choices">
      <label ngbButtonLabel class="radio-option-label">
        <input ngbButton type="radio" [formControlName]="question.key" name="{{ question.key }}" [value]="option.choiceId" (change)="valueChanged()">
        {{ option.text }}
      </label>
    </div>
  </div>
</div>