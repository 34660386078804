<div *ngFor="let record of records">
  <div class="grid">
    <div class="child header">Vehicle Description</div>
    <div class="child header">Inspection Date</div>
    <div class="child header">Inspection Location</div>

    <div class="child">{{ record.vehicleDescription }}</div>
    <div class="child">{{ record.inspectionDate | blankDate }}</div>
    <div class="child">{{ record.inspectionLocation }}</div>

    <div class="child header">Recorded Against</div>
    <div class="child header">Incident Description</div>
    <div class="child header">Contact Number</div>

    <div class="child">{{ record.recordedAgainst }}</div>
    <div class="child">{{ record.incidentDescription }}</div>
    <div class="child">{{ record.contactNumber }}</div>
  </div>
</div>