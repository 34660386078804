import { OnDestroy, Directive } from '@angular/core';
import { Subject, merge, Observable } from 'rxjs';

@Directive({
  // tslint:disable-next-line
   selector: 'app-base'
})

export class BaseComponentDirective implements OnDestroy {

  // takeUntil parameters. See coding-guidelines.md
  componentDestroyed: Subject<boolean>;
  retryButtonPress: Subject<boolean>;
  retryButtonPressedOrComponentDestroyed$: Observable<boolean>;

  localStorageKey: string;

  constructor() {
    this.componentDestroyed = new Subject<boolean>();
    this.retryButtonPress = new Subject<boolean>();

    this.retryButtonPressedOrComponentDestroyed$ = merge(
      this.componentDestroyed,
      this.retryButtonPress
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(null);
    this.componentDestroyed.complete();
  }

  confirmEntry(event) {
    event.target.blur();
  }
}
