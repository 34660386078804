<div class="d-flex">
  <div class="branch-header">
    <span class="branch-header__text">Branch</span>
  </div>
  <div class="d-flex flex-row dates-header">
    <div *ngFor="let day of siteDays" class="dates-header__text">
      <span class="dates-header__day">{{ day | date: 'EEEE' }}</span>
      <div class="dates-header__date">{{ day | date: 'shortDate' }}</div>
    </div>
  </div>
</div>
<div class="d-flex flex-row mb-2">
  <div class="d-flex flex-column mt-3">
    <div *ngFor="let site of appointmentSlots.sites" class="d-flex flex-row">
      <div class="branch">
        <div class="branch__text">
          <div class="branch__name">{{ abbreviateSiteName(site.siteName) }}</div>
          <div class="branch__distance">{{ convertToMiles(site.distanceInMeters )  | number:'1.0-0' }} Miles</div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column mt-3">
    <div *ngFor="let site of appointmentSlots.sites" class="d-flex flex-row">
      <div *ngFor="let siteDate of site.siteDates; let s = index">
        <button *ngIf="anySlotsInDay(s, site) && !isBranchClosed(siteDate)" class="appointment" (click)="onAppointmentSlotSelection(site, siteDate)" tabindex="-1">
            <div class="appointment__number">{{ siteDate.totalAppointmentSlotsCount }}</div>
            <div class="appointment__text">Appointments Free</div>
        </button>
        <div *ngIf="s >= maximumValueAppointmentDelayInDays && (siteDate.totalAppointmentSlotsCount === 0 || isBranchClosed(siteDate))">
          <div class="appointment" [ngClass]="{ 'appointment--no-appointments' :  siteDate.totalAppointmentSlotsCount === 0, 'appointment--branch-closed' : isBranchClosed(siteDate) }">
            <div *ngIf="!isBranchClosed(siteDate)" class="appointment__number">0</div>
            <div *ngIf="!isBranchClosed(siteDate)" class="appointment__text">Appointments Free</div>
          </div>
        </div>
        <div *ngIf="maximumValueAppointmentDelayInDays > s && !anySlotsInDay(s, site)">
          <div class="appointment appointment--not-today">
            <div class="appointment__text">No Appointments*</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-row mb-2 not-today" *ngIf="maximumValueAppointmentDelayInDays > 0 && !anySlotsInDay(0, null)">
  <div class="not-today-msg">* Due to the vehicle criteria, we can not provide an appointment on this day.</div>
</div>
