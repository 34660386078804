import moment, { Moment } from 'moment';

export class Appointment {
  appointmentId: number;
  appointmentTime: Date;
  dayId: number;
  startTime: Moment;
  endTime: Moment;
  isWalkIn: boolean;
  isTrade: boolean;
  siteId: number;
  status: string;
  vrm: string;
  statusStyle: string;
  timeStyle: string;
  appointmentTopPosition: number;
  appointmentHeight: number;
  appointmentWidth: number;
  appointmentLeft: number;
  appSequence: number;
  appOverlaps: number;
  assignedTo: string;

  constructor(appointment?: Appointment, appointmentLength?: number) {
    if (appointment && appointmentLength) {
      this.appointmentId = appointment.appointmentId;
      this.appointmentTime = appointment.appointmentTime;
      this.dayId = moment(this.appointmentTime).day();
      this.startTime = moment(this.appointmentTime);
      this.endTime = moment(this.appointmentTime).add(appointmentLength, 'minutes');
      this.siteId = appointment.siteId;
      this.isWalkIn = appointment.isWalkIn;
      this.isTrade = appointment.isTrade;
      this.status = appointment.status;
      this.vrm = appointment.vrm;
      this.assignedTo = appointment.assignedTo;
    }
    this.appSequence = -1;
    this.appOverlaps = -1;
  }
}
