import { Component, Input } from '@angular/core';
import { HpiStolen } from '../models/hpi-stolen.model';

@Component({
  selector: 'app-stolen-records',
  templateUrl: './stolen-records.component.html',
  styleUrls: ['./stolen-records.component.scss']
})
export class StolenRecordsComponent {

  @Input() records: Array<HpiStolen>;

}
