<div class="">
  <div class="modal-header">
    <span class="modal-title text-center">Appointments</span>
    <img class="modal-close"  (click)="close()" alt="" />
  </div>
  <div class="modal-body">
    <div class="header">
      
      <span >
         Select appointment using radio button
      </span>
    </div>
    <ng-scrollbar class="white-fill scroll" >
      
        <div class="fixed-width" *ngFor="let purchase of purchases">
          <input placeholder="VRM" type="radio" class="row" tabindex="-1"
                 name="purchase.quoteStateId"
                 value="purchase.quoteStateId"
                 (change)="selectPurchase(purchase)">
                 
          <span>
            {{purchase.appointmentDate | date: 'shortDate' }} |
            {{purchase.vrm}} |
            {{purchase.status}} 
            
          </span>
        </div>
      
    </ng-scrollbar>
    
    <div class="">
      <div class="">
        <button class="modal-button retrieve-btn " (click)="closeOK()"
            [disabled]="!hasSelection" [ngClass]="{'disabled': !hasSelection, 'button_green': hasSelection}">
          <span>OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
