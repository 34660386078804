import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-select',
  templateUrl: './modal-select.component.html',
  styleUrls: ['./modal-select.component.scss']
})

export class ModalSelectComponent {
  @Input() options: Array<string>;

  constructor(private activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.dismiss();
  }

  onSelectionChange(event) {
    this.activeModal.close(event.target.value);
  }
}
