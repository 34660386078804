<div class="view-container" *ngIf="!noVehicleInBranch && !loading">
  <table [ngClass]="runners.length ? 'table-view text-center table-bordered' : 'table-view-nonRunners text-center' ">
    <thead class="table-view header">
      <tr>
        <th>VRM</th>
        <th>Description</th>
        <th>Colour</th>
        <th>Stock Date</th>
        <th>Purchaser</th>
        <th>Left Branch</th>
        <th *ngIf="showNonRunners === true">NR</th>
      </tr>
    </thead>
    <tbody class="table-view">
      <tr *ngFor="let item of runners">
        <td class="vrm">{{ item.vrm }}</td>
        <td>{{ item.model }} {{ item.make }} - {{ item.derivative }}</td>
        <td class="colour-date">{{ item.colour }}</td>
        <td class="colour-date">{{ item.stockDate | date: 'dd/MM/yy' }}</td>
        <td class="purchaser">{{ item.purchaser }}</td>
        <td class="here" (click)="check(item, !item.leftBranch)" clickable>
          <div
            [ngClass]="item.leftBranch ? 'showCheckTick' : 'hideCheckTick'"
          ></div>
        </td>
        <td *ngIf="showNonRunners === true" [ngClass]="{'enabled': item.here == true, 'inactive' : item.here == false}" class="checks" (click)="nonRunner(item, item.markAsNonRunner)">
          <div 
           [ngClass]="{ 'showNonRunner' : item.nonRunnerWhenPurchased || item.previouslyFlaggedAsNonRunnerPostPurchase || item.markAsNonRunner}"
          ></div>
        </td>
      </tr>
      <tr *ngIf="!runners.length">
        <td colspan="6" class="no-running-message">No running vehicles currently in stock</td>
      </tr>
    </tbody>
  </table>

  <div class="non-runner" *ngIf="non_runners.length">
    <p class="non-runner--title">Non-Runners</p>
    <table class="table-view text-center table-bordered border-primary">
      <thead class="table-view header-nonRunner">
        <tr>
          <th>VRM</th>
          <th>Description</th>
          <th>Colour</th>
          <th>Stock Date</th>
          <th>Purchaser</th>
          <th>Left Branch</th>
        </tr>
      </thead>
      <tbody class="table-view">
        <tr *ngFor="let item of non_runners">
          <td class="vrm">{{ item.vrm }}</td>
          <td>{{ item.model }} {{ item.make }} - {{ item.derivative }}</td>
          <td class="colour-date">{{ item.colour }}</td>
          <td class="colour-date">{{ item.stockDate | date: 'dd/MM/yy' }}</td>
          <td class="purchaser">{{ item.purchaser }}</td>
          <td class="here" (click)="check(item, !item.leftBranch)" clickable>
            <div
              [ngClass]="item.leftBranch ? 'showCheckTick' : 'hideCheckTick'"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="loading" class="no-data-container">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</div>

<div *ngIf="noVehicleInBranch" class="no-data-container">
  <h5>There are currently no vehicles in this branch</h5>
</div>
