<div class="page-content page-content-after-header task-container">
  <div class="row">
    <div class="col-6 task-date">{{ currentTime | date: "d MMMM y HH:mm" }}</div>
    <div class="col-6 right task-sort">
      <div ngbDropdown #theDropdown="ngbDropdown" (openChange)="toggleSort()">
        <input readonly="readonly" class="task-dropdown" [ngClass]="sortOpened ? 'opened' : ''" placeholder="Sort By"
          autocomplete="off" autocorrect="off" ngbDropdownToggle />
        <div ngbDropdownMenu class="pre-scrollable">
          <button (click)="onSortChange(sortType);theDropdown.close();" *ngFor="let sortType of sortTypes"
            id="{{ sortType }}" ngbDropdownItem>{{ sortType }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row task-grid-row outline margin-bottom" *ngIf="endOfDayTask">
    <div class="col-2 task-type" [ngClass]="getTaskClass(endOfDayTask)">{{ endOfDayTask.taskType }}</div>
    <div class="col-8 low-padding">Please confirm that your stock inventory is updated, transport are aware of any non-runners, outstanding confirm calls have been attempted, and keys/documents will be removed from the secured branch.</div>
    <div class="col-2"><button (click)="completeEndOfDayTask()" class="end-of-day-task-button button_green">Complete <img alt="" class="resize-image" src="../../../assets/icons/outlined/tick.png"></button></div>
  </div>
  <div class="row task-grid-header">
    <div class="col-1" *ngIf="isEditing">Select All</div>
    <div class="col-2">Task Type</div>
    <div class="col-3">Customer's Name</div>
    <div class="col-2">Date & Time of Appointment</div>
    <div [ngClass]="isEditing ? 'col-2' : 'col-3'">VRM</div>
    <div class="col-2">Branch</div>
  </div>
  <div class="row task-grid-row" *ngFor="let task of pagedTasks; let i = index" (click)="actionTask(task)">
    <div class="col-1" *ngIf="isEditing"></div>
    <div class="col-2 task-type" [ngClass]="getTaskClass(task)">{{ task.taskType }}</div>
    <div class="col-3 obfuscated-playback">{{ task.customerName }}</div>
    <div class="col-2">{{ task.appointmentDate | date: "dd/MM/yyyy HH:mm" }}</div>
    <div [ngClass]="isEditing ? 'col-2' : 'col-3'">{{ task.vrm }}</div>
    <div class="col-2">{{ task.branch }}</div>
  </div>
  <app-pager [pageCount]="pageCount"></app-pager>
</div>
