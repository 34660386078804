<div class="modal-header">
    <h4 class="modal-title text-center">Address Manual Entry</h4>
    <img class="modal-close" (click)="dismissModal()" alt="Close modal">
</div>
<div class="modal-body">
    <input type="text" style="display:none" ngbAutofocus />
    <div class="container" [formGroup]="addressForm">
      <div class="row">
        <div class="col-12" *ngIf="includeCompanyName">
          <div class="question-item">
            <label class="question-label" for="company">Company Name *</label>
            <div class="input-group">
              <input placeholder="Company Name" class="form-control input-text obfuscated-playback" type="text"
                formControlName="company" autocomplete="off" autocorrect="off" tabindex="1" />
              <span class="placeholder">Company Name</span>
              <span class="placeholder-line"></span>
            </div>
          </div>
        </div>
        <div class="col-6">
            <div class="question-item">
              <label class="question-label" for="address1">Address Line 1 *</label>
              <div class="input-group">
                <input placeholder="Address Line 1" class="form-control input-text obfuscated-playback" type="text"
                  formControlName="address1" autocomplete="off" autocorrect="off" tabindex="2" />
                <span class="placeholder">Address Line 1</span>
                <span class="placeholder-line"></span>
              </div>
            </div>
        </div>
        <div class="col-6">
            <div class="question-item">
              <label class="question-label" for="city">Town/City</label>
              <div class="input-group">
                <input placeholder="Town/City" class="form-control input-text obfuscated-playback" type="text"
                  formControlName="city" autocomplete="off" autocorrect="off" tabindex="5" />
                <span class="placeholder">Town/City</span>
                <span class="placeholder-line"></span>
              </div>
            </div>
        </div>
        <div class="col-6">
            <div class="question-item">
              <label class="question-label" for="address2">Address Line 2</label>
              <div class="input-group">
                <input placeholder="Address Line 2" class="form-control input-text obfuscated-playback" type="text"
                  formControlName="address2" autocomplete="off" autocorrect="off" tabindex="3" />
                <span class="placeholder">Address Line 2</span>
                <span class="placeholder-line"></span>
              </div>
            </div>
        </div>
        <div class="col-6">
            <div class="question-item">
              <label class="question-label" for="county">County</label>
              <div class="input-group">
                <input placeholder="County" class="form-control input-text obfuscated-playback" type="text"
                  formControlName="county" autocomplete="off" autocorrect="off" tabindex="6" />
                <span class="placeholder">County</span>
                <span class="placeholder-line"></span>
              </div>
            </div>
        </div>
        <div class="col-6">
            <div class="question-item">
              <label class="question-label" for="address3">Address Line 3</label>
              <div class="input-group">
                <input placeholder="Address Line 3" class="form-control input-text obfuscated-playback" type="text"
                  formControlName="address3" autocomplete="off" autocorrect="off" tabindex="4" />
                <span class="placeholder">Address Line 3</span>
                <span class="placeholder-line"></span>
              </div>
            </div>
        </div>
        <div class="col-6">
            <div class="question-item">
              <label class="question-label" for="postcode">Postcode *</label>
              <div class="input-group">
                <input placeholder="Postcode" class="form-control input-text text-upper obfuscated-playback" type="text"
                  formControlName="postcode" autocomplete="off" autocorrect="off" tabindex="7" />
                <span class="placeholder">Postcode</span>
                <span class="placeholder-line"></span>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="centered">
      <button class="postcode-button modal-button button_green" type="button" [disabled]="!addressForm.valid"
        [ngClass]="{ 'invalid':!addressForm.valid }" (click)="confirmAddress()">
        <span>Ok</span>
      </button>
    </div>
</div>