export class NumberHelper {
  static isNumeric(value?: string | number): boolean
  {
     return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
  }

  static toOrdinal(input: number): string {

    if (input > 3 && input < 21) {
      return input.toString() + 'th';
    }

    switch (input % 10) {
      case 1: return input.toString() + 'st';
      case 2: return input.toString() + 'nd';
      case 3: return input.toString() + 'rd';
      default: return input.toString() + 'th';
    }
  }
}
