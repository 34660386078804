<div class="modal-header">
  <img class="modal-close" (click)="dismissModal()" alt="Close modal">
  <h4 class="modal-title text-center">New Postcode Lookup</h4>
</div>
<div class="modal-body modal-body__postcode">
  <form [formGroup]="postcodeLookupForm" class="search-form" (ngSubmit)="search()">
    <input type="text"
           class="form-control input-text text-upper obfuscated-playback"
           (keyup.enter.f.valid)="search()"
           formControlName="postcodeField"
           autocomplete="off"
           tabindex="-1"
           required />
    <span class="placeholder">Enter Postcode</span>
    <span class="placeholder-line"></span>
    <button type="submit"
            class="search-button modal-button button_green"
            [ngClass]="{ 'invalid' : !postcodeLookupForm.valid, 'loading' : loading }"
            [disabled]="!postcodeLookupForm.valid || loading">
      Search
    </button>
  </form>
  <button class="modal-button button__back mt-4" (click)="onBackClick()">Back</button>
</div>
