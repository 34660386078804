<div class='modal-header'>
    <img (click)="dismissAllModals()" class='modal-close' alt="Close modal">
    <h4 class='modal-title'>Cancel Purchase</h4>
</div>
<div class='modal-body'>
  <div class='input'>
    <p class='input__description bold'>Reason for cancellation</p>
    <p (click)='displayCancellationReasons()'
       [ngClass]="cancellationReason.cancellationReason ? 'input--selected' : 'input--empty'">
      {{cancellationReason.cancellationReason}}
    </p>
  </div>
  <div class='input' *ngIf="cancellationReason.cancellationReason === cancellationReasonTypes.competitor">
    <p class='input__description  bold'>Name of Competitor</p>
    <p (click)='displayCancellationSubReasons()'
       [ngClass]="competitorNameValue ? 'input--selected' : 'input--empty'">
      {{competitorNameValue}}
    </p>
  </div>
  <form [formGroup]="purchaseCancelForm">
    <div class='form'>
      <div   *ngIf="cancellationReason.cancellationReason === cancellationReasonTypes.finalPrice || cancellationReason.cancellationReason === cancellationReasonTypes.competitor">
        <input maxlength='8' (keydown.dot)="blockDotCharacter($event)" class='input-text input-text-height' mask="separator" thousandSeparator=","
              [value]="this.purchaseCancelForm.get('desiredPrice').value | currency:'GBP':'symbol':'1.0'" (click)='moveCursorToEnd()'
              formControlName='desiredPrice' type="text" name='desiredPriceInput' id='desiredPriceInputId' tabindex="-1"
              required placeholder="Enter the customer’s final desired price">

        <span class="placeholder"
              [ngClass]="cancellationReason.cancellationReason === cancellationReasonTypes.competitor ? 'form__competitor' : 'form__final-price'">
          Enter the customer’s final desired price
        </span>
      </div>
      <div *ngIf="this.cancellationReason.reasonId !== null">
        <div class="input box p-3">
          <div class="notes-text">
            Notes
          </div>
          <textarea class="input-area" [value]="this.purchaseCancelForm.get('comments').value"
                    formControlName='comments' type="text" name='commentsInput' id='commentsInputId' tabindex="-1"
                    required placeholder="Add a comment here"></textarea>
        </div>
      </div>
    </div>
   
  </form>
  <button class='modal-button' (click)="cancelPurchase(isButtonEnabled)" [ngClass]="{
                'modal-button--enabled': isButtonEnabled,
                 'modal-button--disabled': !isButtonEnabled,
                  'loading' : isLoading}">
    Cancel Purchase
  </button>
</div>
