import { SafeUrl } from '@angular/platform-browser';
import { ImageTypes } from '../../enums/image-types';

export class ImageToSave {
    imageNumber: number;
    type: ImageTypes;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    image: any;
    stampCode: string;
    documentScanId: number;
    documentName: string;
    parameterName: string;
    isSaved: boolean;
    extension: string;
    imgAsBase64String: string;
    thumbnailUrl: SafeUrl;
    fileName: string;
    isLoading: boolean;
    hasMismatch: boolean;
    tag: string;
    threshold: number;
    recognitionResult: number;
    isVideo: boolean;
}
