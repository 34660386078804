import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vrm-input-modal',
  templateUrl: './vrm-input-modal.component.html',
  styleUrls: ['./vrm-input-modal.component.scss']
})
export class VrmInputModalComponent {

  constructor(private modal: NgbActiveModal) { }

  dismissModal() {
    this.modal.dismiss();
  }

  enterVrm(vrm: string) {
    this.modal.close(vrm);
  }
}
