import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Valuation } from '../../walk-in/models/valuation.model';
import { LookupService } from '../../services/lookup.service';
import { NotificationService } from '../../services/notification.service';
import { ValuationService } from '../../services/valuation.service';

@Component({
  selector: 'app-previous-valuations-lookup',
  templateUrl: './previous-valuations-lookup.component.html',
  styleUrls: ['./previous-valuations-lookup.component.scss'],
})

export class PreviousValuationsLookupComponent implements OnInit {
  @Input() vrm: string;
  @Output() hasPreviousValuations = new EventEmitter<boolean>();
  @Output() isloadingEvent = new EventEmitter<boolean>();
  @Output() selectedPreviousValuationEvent = new EventEmitter<Valuation>();
  @Output() pricePromiseEmitter = new EventEmitter<string>();

  previousValuations$: Observable<Array<Valuation>>;
  pricePromise$: Observable<string>;
  pricePromise: string;
  hasValuations: boolean;

  constructor(
    private valuationService: ValuationService,
    private lookupService: LookupService,
    private notifications: NotificationService
  ) { }

  ngOnInit(): void {
    this.getPreviousValuations();
    this.getPricePromiseInDays();
  }

  getPreviousValuations() {
    this.hasValuations = false;
    this.previousValuations$ = this.valuationService.getPreviousValuations$(this.vrm)
      .pipe(
        map(result => {
          if (result.previousValuations.length > 0) {
            this.hasValuations = true;
          }
          this.hasPreviousValuations.emit(this.hasValuations);
          this.isloadingEvent.emit(false);
          return result.previousValuations;
        }),
        catchError((err) => {
          this.isloadingEvent.emit(false);
          this.notifications.dangerToast('Failed to get previous valuations', err);
          return EMPTY;
        })
      );
  }

  getPricePromiseInDays() {
    this.pricePromise$ = this.lookupService
      .getSettings$('Quote')
      .pipe(
        map(result => {
          this.pricePromise = result['PricePromiseLengthInDays'];
          this.pricePromiseEmitter.emit(this.pricePromise);
          this.isloadingEvent.emit(false);
          return this.pricePromise;
        }),
        catchError((err) => {
          this.notifications.dangerToast('Unable to retrieve price promise.', err);
          this.isloadingEvent.emit(false);
          return EMPTY;
        })
      );
  }

  selectValuation(selectedValuation: Valuation) {
    this.selectedPreviousValuationEvent.emit(selectedValuation);
  }
}
