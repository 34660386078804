import { Component, Input } from '@angular/core';
import { Site } from '../../../diary/models/site.model';
import { WalkInLookupData } from '../../../walk-in/models/walk-in-lookup-data.model';
import { SiteOnDate } from '../../models/site-on-date.model';
import { SiteWithSlots } from '../../models/site-with-slots.model';
import { TimeSlot } from '../../models/time-slot.model';

@Component({
  selector: 'app-appointment-summary',
  templateUrl: './appointment-summary.component.html',
  styleUrls: ['./appointment-summary.component.scss']
})

export class AppointmentSummaryComponent {
  @Input() walkInLookupData: WalkInLookupData;
  @Input() selectedSiteDetails: Site;
  @Input() selectedSite: SiteWithSlots;
  @Input() selectedDate: SiteOnDate;
  @Input() selectedTime: TimeSlot;
}
