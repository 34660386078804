import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponentDirective } from '../base/base.component';
import { NotificationService } from '../services/notification.service';
import { FinanceAgreement } from './models/finance-agreement.model';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY } from 'rxjs';
import { VehicleCheckService } from '../services/vehicle-check.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { HpiFinance } from '../vehicle-check-hpi/models/hpi-finance.model';
import { FinanceAgreementService } from '../services/finance-agreement.service';
import { DatePipe } from '@angular/common';
import { PathFinderService } from '../services/path-finder.service';
import { KeyValuePair } from '../models/key-value-pair.model';
import { BlackHorseCompanies } from './enums/black-horse-companies';
import { LoadingService } from '../services/loading.service';
import { NumberHelper } from '../helpers/number-helper';
import { VehicleCheck } from '../vehicle-check-hpi/models/vehicle-check.model';

@Component({
  selector: 'app-outstanding-finance',
  templateUrl: './outstanding-finance.component.html',
  styleUrls: ['./outstanding-finance.component.scss'],
})
export class OutstandingFinanceComponent extends BaseComponentDirective implements OnInit {
  @Input() vrm: string;
  @Input() group: UntypedFormGroup;
  @Input() questionForm: UntypedFormGroup;
  @Input() quoteStateId: number;
  loading: boolean;
  hasFinance: boolean;
  isFinanceExpiryDateValid: boolean;
  isFinanceAmountValid: boolean;
  addFinance: boolean;
  agreements = Array<UntypedFormGroup>();
  items = new Array<KeyValuePair>();
  financeRecords = new Array<HpiFinance>();
  minDate: string;
  serviceCounter = 0;

  outstandingFinanceAgreements: UntypedFormGroup;
  dropdownValue = new UntypedFormGroup({
    value: new UntypedFormControl(null),
  });

  constructor(
    private financeAgreementService: FinanceAgreementService,
    private vehicleCheckService: VehicleCheckService,
    private notifications: NotificationService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    private pathFinder: PathFinderService,
    private loadingService: LoadingService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);

    this.loading = true;
    this.outstandingFinanceAgreements = this.formBuilder.group({
      name: 'financeAgreements',
      financeAgreements: this.formBuilder.array([]),
    });
    this.dropdownValue.controls.value.setValidators([Validators.required, this.group.controls.value.validator]);

    this.getFinanceRecords();
    this.initializeBlackHorseCompanies();
    this.minDate = this.datepipe.transform(this.addWorkDays(new Date(), 5), 'yyyy-MM-dd');

    this.pathFinder.registerTransitionEvent((onComplete) => {
      if (!this.hasFinance) {
        this.confirmFinance(false).result.then(
          () => {
            onComplete(true);
          },
          () => {
            onComplete(false);
          }
        );
      } else {
        this.confirmFinance(true).result.then(
          () => {
            const financeAgreement = this.outstandingFinanceAgreements.value.financeAgreements.filter(
              (x) => x.isNewLine || x.financeAgreementId === null
            );
            this.financeAgreementService
              .saveFinanceAgreements$(this.quoteStateId, financeAgreement)
              .pipe(takeUntil(this.componentDestroyed))
              .subscribe(() => {
                onComplete(true);
              });
          },
          () => {
            onComplete(false);
          }
        );
      }
    });
  }

  initializeBlackHorseCompanies() {
    const companies = Object.values(BlackHorseCompanies);
    companies.forEach((company) => {
      this.items.push(new KeyValuePair(company, company));
    });
  }

  getFormControl(parameterName: string): UntypedFormGroup {
    return this.questionForm.get('items')['controls'].filter((x) => x.value.name === parameterName)[0];
  }

  getFinanceRecords() {
    this.vehicleCheckService
      .getVehicleCheck$(this.vrm, this.quoteStateId, false)
      .pipe(
        tap((result) => {
          this.financeAgreementService
            .getFinanceAgreements$(this.quoteStateId)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((res) => {
              if (res.length > 0) {
                this.hasFinance = true;
                const allFinanceRecords = this.getAllFinanceRecords(result);
                for (const financeRecord of res) {
                  financeRecord.isNewLine = false;
                  financeRecord.isHpiFinance = allFinanceRecords.some(
                    (x) => x.agreementReference === financeRecord.agreementNumber
                  );
                  this.agreements.push(this.createFinanceAgreement(financeRecord));
                }
              } else {
                const allFinanceRecords = this.getAllFinanceRecords(result);
                this.financeRecords = this.financeAgreementService.removeFinanceAgreementDuplicates(allFinanceRecords);

                const hpiAnswer = this.getFormControl('HasHpiFinance');
                if (hpiAnswer) {
                  if (this.hasFinance) {
                    hpiAnswer.controls.value.setValue(true);
                  } else {
                    hpiAnswer.controls.value.setValue(null);
                  }
                }
              }
              this.loading = false;
              this.populateFinanceAgreements();
              if (res.length > 0) {
                this.validate();
              }
            });
        }),
        catchError((err) => {
          this.notifications.dangerToast('Failed to retrieve finance agreements from HPI.', err);
          return EMPTY;
        }),
        takeUntil(this.retryButtonPressedOrComponentDestroyed$)
      )
      .subscribe(() => {
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        this.populateFinanceAgreements();
      });
  }

  trackByFn(index, item) {
    return item.indexNo;
  }

  populateFinanceAgreements() {
    if (this.financeRecords) {
      for (const financeRecord of this.financeRecords) {
        if (this.financeAgreementService.isFinanceAgreementRemoved(financeRecord.agreementReference)) {
          continue;
        }
        const financeAgreement = new FinanceAgreement();
        financeAgreement.agreementNumber = financeRecord.agreementReference;
        financeAgreement.agreementTypeCode = financeRecord.agreementTypeCode;
        financeAgreement.financeCompanyName = financeRecord.financeCompany;
        financeAgreement.isNewLine = false;
        financeAgreement.isHpiFinance = true;
        this.agreements.push(this.createFinanceAgreement(financeAgreement));
      }
    }

    if (this.agreements !== undefined || this.agreements.length > 0) {
      this.agreements.forEach((agreement) => {
        const indexNo = agreement.controls['indexNo'].value;
        const agreementNumber = agreement.controls['agreementNumber'].value;
        const isHpiFinance = agreement.controls['isHpiFinance'].value;
        const finance: { indexNo: number; agreementNumber: string; isRemoved: boolean; isHpiFinance: boolean } = {
          indexNo: indexNo,
          agreementNumber: agreementNumber,
          isRemoved: false,
          isHpiFinance: isHpiFinance,
        };
        if (!this.financeAgreementService.checkForduplicatesFinance(agreementNumber)) {
          this.financeAgreementService.addFinanceAgreement(finance);
        }
      });
    }

    this.outstandingFinanceAgreements = this.formBuilder.group({
      name: 'financeAgreements',
      financeAgreements: this.formBuilder.array(this.agreements),
    });
    if (this.outstandingFinanceAgreements.value.financeAgreements.length === 0) {
      this.hasFinance = false;
    }
    if (this.hasFinance) {
      this.group.controls.value.setValue(null);
    } else {
      this.group.controls.value.setValue('false');
    }
    if (this.agreements.length === 0) {
      this.hasFinance = false;
    }
  }

  getAllFinanceRecords(result: VehicleCheck) {
    const finance = new Array<HpiFinance>();
    for (const plate of result.vehicles[0].plate) {
      if (plate.finance) {
        this.hasFinance = true;
        for (const financeRecord of plate.financeRecords) {
          finance.push(financeRecord);
        }
      }
    }
    return finance;
  }

  confirmFinance(hasFinance: boolean) {
    const title = 'Confirm finance';
    let message = '';
    if (hasFinance) {
      message = `Are you sure the finance information you have entered is correct?`;
    } else {
      message = `Are you sure there is no finance for this vehicle?`;
    }
    return this.createConfirmationComponent(title, message);
  }

  removeFinance(index, financeAgreement) {
    const title = 'Are you sure you want to remove the finance agreement?';
    const message = `If the finance is recently settled you should enter £0.00 for the amount and the correct expiry date. You should also scan the settlement/clearance letter.`;
    this.createConfirmationComponent(title, message).result.then(
      () => {
        if (this.financeAgreementService.financeAgreements.length > 0) {
          this.financeAgreementService.updateFinanceAgreement(financeAgreement.indexNo);
        }

        const agreements = this.outstandingFinanceAgreements.get('financeAgreements') as UntypedFormArray;
        agreements.removeAt(index);
        this.serviceCounter--;
        if (this.outstandingFinanceAgreements.value.financeAgreements.length === 0) {
          this.hasFinance = false;
          this.dropdownValue.controls.value.setValue(true);
        }
        if (financeAgreement.financeAgreementId) {
          this.financeAgreementService
            .deleteFinanceAgreement$(financeAgreement.financeAgreementId)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe();
        }
        this.validate(financeAgreement.indexNo);
      },
      () => {}
    );
  }

  createConfirmationComponent(title: string, message: string) {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.failButton = 'No';
    modalRef.componentInstance.successButton = 'Yes';

    return modalRef;
  }

  addNewLine() {
    const agreements = this.outstandingFinanceAgreements.get('financeAgreements') as UntypedFormArray;
    agreements.push(this.createFinanceAgreement());
    this.outstandingFinanceAgreements.updateValueAndValidity();

    if (!this.hasFinance) {
      this.hasFinance = this.outstandingFinanceAgreements.value.financeAgreements.length > 0;
    }
    this.group.controls.value.setValue(null);
  }

  createFinanceAgreement(financeAgreement: FinanceAgreement = null) {
    let agreement: UntypedFormGroup;
    if (financeAgreement) {
      agreement = this.formBuilder.group({
        financeCompanyName: financeAgreement.financeCompanyName,
        agreementNumber: financeAgreement.agreementNumber,
        expiryDate: financeAgreement.expiryDate,
        amount: financeAgreement.amount,
        isNewLine: false,
        agreementTypeCode: financeAgreement.agreementTypeCode,
        indexNo: this.serviceCounter,
        financeAgreementId: financeAgreement.financeAgreementId,
        isHpiFinance: financeAgreement.isHpiFinance,
      });
    } else {
      agreement = this.formBuilder.group({
        financeCompanyName: null,
        agreementNumber: '',
        expiryDate: null,
        amount: null,
        isNewLine: true,
        agreementTypeCode: '',
        indexNo: this.serviceCounter,
        isHpiFinance: false,
      });
    }

    this.serviceCounter++;

    agreement.controls.financeCompanyName.setValidators([
      Validators.required,
      Validators.pattern('^.+$'),
      Validators.maxLength(256),
    ]);
    agreement.controls.agreementNumber.setValidators([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9s]+$'),
      Validators.maxLength(64),
    ]);
    agreement.controls.expiryDate.setValidators(Validators.required);
    agreement.controls.amount.setValidators([Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]);

    return agreement;
  }

  validate(indexNo: number = null) {
    if (this.financeAgreementService.financeAgreements.length > 0 && this.outstandingFinanceAgreements.valid) {
      const hpiAnswer = this.getFormControl('HasHpiFinance');
      if (hpiAnswer && this.financeAgreementService.financeAgreements[indexNo]) {
        this.checkHpiFinance(hpiAnswer);
      } else {
        this.group.controls.value.setValue(this.hasFinance.toString());
      }
    } else if (this.outstandingFinanceAgreements.valid) {
      this.group.controls.value.setValue(this.hasFinance.toString());
    } else {
      this.group.controls.value.setValue(null);
    }
  }

  checkHpiFinance(hpiAnswer: UntypedFormGroup) {
    const hpiRecordRemoved = this.financeAgreementService.financeAgreements[0].isRemoved;
    const isHpiRecord = this.financeAgreementService.financeAgreements[0].isHpiFinance;
    const outstandingFinanceLength = this.outstandingFinanceAgreements.value.financeAgreements.length;

    // Existing HPI finance, no new added finance
    if ((isHpiRecord && outstandingFinanceLength > 0 && !hpiRecordRemoved) || (!isHpiRecord && outstandingFinanceLength > 0)) {
      hpiAnswer.controls.value.setValue(null);
      this.group.controls.value.setValue(true.toString());
    }
    //  Existing HPI finance, and delete it, no new added finance
    if (hpiRecordRemoved && isHpiRecord && outstandingFinanceLength === 0) {
      hpiAnswer.controls.value.setValue(true.toString());
      this.group.controls.value.setValue(false.toString());
    }

    // Existing HPI finance, delete it, new added finance,
    if (hpiRecordRemoved && isHpiRecord && outstandingFinanceLength > 0) {
      hpiAnswer.controls.value.setValue(true.toString());
      this.group.controls.value.setValue(true.toString());
    }

    // No Hpi finance, added new finance than delete
    if (!isHpiRecord && outstandingFinanceLength === 0) {
      hpiAnswer.controls.value.setValue(null);
      this.group.controls.value.setValue(false.toString());
    }
  }

  isPositiveAndHasFixedPrecision(event) {
    if (!NumberHelper.isNumeric(event.key) && event.key !== '.') {
      return false;
    }


    const decimalIndex = event.target.value.indexOf('.');
    if (decimalIndex > -1 && event.target.value.substr(decimalIndex + 1).length === 2) {
      return false;
    }
  }

  addWorkDays(startDate: Date, daysToAdd: number): Date {
    const dow = startDate.getDay();
    if (dow === 0) {
      daysToAdd++;
    }
    if (dow + daysToAdd >= 6) {
      const remainingWorkDays = daysToAdd - (5 - dow);
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        if (remainingWorkDays % 5 === 0) {
          daysToAdd -= 2;
        }
      }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
  }

  updateFinanceAgreement(financeAgreement: FinanceAgreement) {
    if (
      financeAgreement.financeAgreementId &&
      financeAgreement.financeCompanyName &&
      financeAgreement.agreementNumber &&
      financeAgreement.expiryDate &&
      financeAgreement.amount
    ) {
      this.financeAgreementService
        .updateFinanceAgreement$(financeAgreement)
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe();
    }
    this.validate(financeAgreement.indexNo);
  }

  updateAgreement(index: number) {
    this.outstandingFinanceAgreements.controls.financeAgreements.value[index].financeCompanyName =
      this.dropdownValue.controls.value.value;

    this.outstandingFinanceAgreements.value.financeAgreements[index].financeCompanyName =
      this.dropdownValue.controls.value.value;
    const agreements = this.outstandingFinanceAgreements.get('financeAgreements') as UntypedFormArray;
    const control = agreements.at(index);
    const financeCompanyName = control['controls']['financeCompanyName'];
    financeCompanyName.setValue(this.dropdownValue.controls.value.value);
    this.updateFinanceAgreement(control.value);
    this.validate(index);
  }
}
