
export class AddressSummary {
    id: string;
    type: string;
    text: string;
    description: string;

    company: string;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    province: string;
    postalCode: string;
  }

