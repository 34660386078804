<div class="input-group" [formGroup]="group">
  <div *ngIf="loading" style="flex: auto;">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <h2>Let's have a look at your car</h2>
    <br>
    <h3>This is our guarantee</h3>
    <div class="mt-1" style="font-size: large">
      <div class="customerGuaranteeRow">
        <div>
          <input class="customerGuaranteeCheckbox" #firstCondition type="checkbox"
            (change)="confirmAppraisalChecked(firstCondition.checked && secondCondition.checked && thirdCondition.checked)">
        </div>
        <div class="ms-3">
          We guarantee our online valuations for {{ pricePromise }} days.
        </div>
      </div>
      <div class="customerGuaranteeRow" [hidden]="!firstCondition.checked">
        <div>
          <input class="customerGuaranteeCheckbox" #secondCondition type="checkbox"
            (change)="confirmAppraisalChecked(secondCondition.checked && thirdCondition.checked)">
        </div>
        <div class="ms-3">
          If your car is in the condition you say it is, we promise to pay the price we quote.
        </div>
      </div>
      <div class="customerGuaranteeRow" [hidden]="!firstCondition.checked || !secondCondition.checked">
        <div>
          <input class="customerGuaranteeCheckbox" #thirdCondition type="checkbox"
            (change)="confirmAppraisalChecked(thirdCondition.checked)">
        </div>
        <div class="ms-3">
          Don't worry, if we jointly identify further discrepancies, we will be happy to remove those items from our
          inspection if you can rectify them to a professional standard. If you rectify them within the {{ pricePromise }}
          Day Guarantee period, you'll get the initial valuation quoted online.
        </div>
      </div>
    </div>
  </div>
</div>