import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { KeyValuePair } from '../../models/key-value-pair.model';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['../input.component.scss']
})
export class RadioInputComponent  extends BaseInputComponent implements OnInit, AfterContentInit {

  @Input() options: Array<KeyValuePair>;
  @Input() vertical = false;

  disabled = false;

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterContentInit() {
    const value = this.group.value.value;
    if (value) {
      if (!this.isOptionEnabled(value)) {
        setTimeout(() => {
          this.group.patchValue({
            value: null
          });
        });
      }
    }
  }

  isOptionEnabled(optionValue: string) {
    if (!this.minValue) {
      return true;
    }
    const value = Number(optionValue);
    if (isNaN(value)) {
      return true;
    }
    return value >= parseInt(this.minValue, 10);
  }

  valueChanged() {
    this.change.emit(this.group);
  }
}
