<div class="container-fluid">
  <div class="row">
    <div class="col-auto replace-padding" *ngIf="showMenu">
      <app-side-menu></app-side-menu>
    </div>
    <div class="col replace-padding main-container">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
