<div class="form-group" [formGroup]="group" [ngClass]="this.isHidden ? 'hidden': ''">
  <label class="question-label extra-padding" [ngClass]="boldLabel ? 'bold' : ''" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
  <div [attr.id]="identifier" class="btn-group-toggle" ngbRadioGroup>
    <label ngbButtonLabel class="btn me-1 list-button"
           [ngClass]="{ 
            'invalid': markInvalid,
            'btn-block': vertical,
            'informed-answer': option.key === informedAnswer, 
            'default-answer': option.key === defaultAnswer, 
            'no-text' : getOptionIcon(option.value) !== '', 
            'more-options' : options.length > 4,
            'active' : group.value.value === option.key,
            'readonly': readOnly,
            'small-two-line' : customClass === 'small-two-line'
            }"
           *ngFor="let option of options">
      <input formControlName="value" type="radio" [value]="option.key" (blur)="checkValid()" (focus)="removeInvalidMark()" (change)="valueChanged()" [disabled]="disabled || !isOptionEnabled(option.value)">
      <span *ngIf="optionIcon === ''">{{ option.value }}</span>
      <img *ngIf="optionIcon !== ''" [ngClass]="optionIcon" alt="Option-Icon">
    </label>
  </div>
</div>
