import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr'; // Import the 'signalR' module
import { environment } from '../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { SignalREventService } from './signal-revent.service';
import { AuthorizationRequestNotification } from '../authorization/models/authorization-request-notification.model';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  constructor(
    private msalService: MsalService,
    private signalREventService: SignalREventService,
    private loggingService: LoggingService
  ) {}
  private hubConnection: HubConnection;

  async connectToSignalR(): Promise<HubConnection> {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account === null || account === undefined) {
      return null;
    }

    const accessTokenRequest = {
      scopes: ['api://a6bc64a3-0b5e-4885-be8c-246e58d4558e/access_as_user'],
      account: account,
    };

    try {
      // Get the access token silently
      // https://learn.microsoft.com/en-us/aspnet/core/signalr/authn-and-authz?view=aspnetcore-8.0
      // Note: The access token is not used directly by the client. It is used by the SignalR client to authenticate with the SignalR hub.
      // Token Exipration: If the token expires while the connection is active we can continue to use the connection until it is closed.
      // From microsoft docs:
      // The client can provide an access token instead of using a cookie.
      // The server validates the token and uses it to identify the user. This validation is done only when the connection is established.
      // During the life of the connection, the server doesn't automatically revalidate to check for token revocation.

      const options = {
        accessTokenFactory: async () => {
          await this.msalService.instance.initialize();
          const result = await this.msalService.acquireTokenSilent(accessTokenRequest).toPromise();
          return result.accessToken;
        }
      };
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.ppxPrivateApi}hubs/notification`, options)
        .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 120000, 240000, 480000, 960000])
        .build();

      await this.hubConnection
        .start()
        .then(() => {
          // HUB Connection started!, lets join the group
          this.hubConnection
            .invoke('JoinAuthRoleGroup')
            .catch((err) => this.loggingService.logError('Signal-r hub error while joining group' + err.toString()));
        })
        .catch((err) => this.loggingService.logError('Signal-r hub error while starting connection: ' + err));

      this.hubConnection.on(
        'receiveauthorizationnotification',
        (authorizationRequestNotification: AuthorizationRequestNotification) => {
          this.loggingService.logInfo(`Signal-r message received for useragent : ${navigator.userAgent} ` + JSON.stringify(authorizationRequestNotification));
          this.signalREventService.authorizationRequestReceived(authorizationRequestNotification);
        }
      );

      this.hubConnection.onclose(error => {
        this.loggingService.logError('Signal-r hub connection closed: ' + (error ? error.toString() : 'No error message available'));
      });

      this.hubConnection.onreconnected(async () => {
        try {
          await this.hubConnection.invoke('JoinAuthRoleGroup');
        } catch (err) {
          this.loggingService.logError('Signal-r hub error while rejoining group' + err.toString());
        }
      });

      return this.hubConnection;
    } catch (error) {
      this.loggingService.logError('Signal-r hub ' + error);
      return null;
    }
  }
}
