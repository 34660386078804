import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../core/auth-service';
import { catchError, take } from 'rxjs/operators';
import { EMPTY, Observable, throwError } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { HttpClient } from '@angular/common/http';
import { LoggingRequest } from '../models/logging-request';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private http: HttpClient,
    private injector: Injector
  ) { }

  logError(error) {
    if (error?.message) {
      const url = `${environment.ppxPrivateApi}api/Logging/error`;
      const request: LoggingRequest = {
        message: error?.message,
        stack: error?.stack
      };

    this.log$(url, request).pipe(
        catchError(err => {
          if (environment.production) {
            const authService = this.injector.get(AuthService);
            const user = authService.getUser();
            Sentry.setUser({ user });
            Sentry.captureException(err);
          } else {
            console.log(err);
          }
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  logInfo(message: string) {
    if (message) {
      const url = `${environment.ppxPrivateApi}api/Logging/info`;
      const request: LoggingRequest = {
        message: message,
        stack: ''
      };

      this.log$(url, request).pipe(
        catchError(err => {
          if (environment.production) {
            const authService = this.injector.get(AuthService);
            const user = authService.getUser();
            Sentry.setUser({ user });
            Sentry.captureException(err);
          } else {
            console.log(err);
          }
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  private log$(url: string, request: LoggingRequest): Observable<void> {
    return this.http.post<void>(url, request).pipe(
      catchError((err) => throwError(err))
    );
  }
}
