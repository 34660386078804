import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FinanceAgreement } from '../outstanding-finance/models/finance-agreement.model';
import { HpiFinance } from '../vehicle-check-hpi/models/hpi-finance.model';

@Injectable({
  providedIn: 'root'
})
export class FinanceAgreementService {

  constructor(private http: HttpClient) { }
  public financeAgreements: {indexNo: number, agreementNumber: string, isRemoved: boolean, isHpiFinance: boolean} [] = [];

  saveFinanceAgreements$(quoteStateId: number, financeAgreements: Array<FinanceAgreement>) {
    return this.http.post(`${environment.ppxPrivateApi}api/FinanceAgreement/saveFinanceAgreements/${quoteStateId}`, financeAgreements, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  getFinanceAgreements$(quoteStateId: number): Observable<Array<FinanceAgreement>> {
    return this.http.get<Array<FinanceAgreement>>(`${environment.ppxPrivateApi}api/FinanceAgreement/financeAgreements/${quoteStateId}`
    ).pipe(
      catchError(err => throwError(err))
    );
  }

  removeFinanceAgreementDuplicates(financeRecords: Array<HpiFinance>): Array<HpiFinance> {
    const uniqueFinanceList = financeRecords.reduce((accumalator, current) => {
      if (!accumalator.some(item => item.agreementReference === current.agreementReference && item.agreementDate === current.agreementDate)) {
        accumalator.push(current);
      }
      return accumalator;
    }, []);

    return uniqueFinanceList;
  }

  updateFinanceAgreement$(financeAgreement: FinanceAgreement) {
    return this.http.post(`${environment.ppxPrivateApi}api/FinanceAgreement/updateFinanceAgreement`, financeAgreement, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  deleteFinanceAgreement$(financeAgreementId: number) {
    return this.http
    .post<number>(
      `${environment.ppxPrivateApi}api/FinanceAgreement/delete`,
      financeAgreementId,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }
    ).pipe(
      catchError(err => throwError(err))
    );
  }

  addFinanceAgreement(financeAgreement) {
    this.financeAgreements.push(financeAgreement);
  }

  updateFinanceAgreement(indexNo) {
    this.financeAgreements.forEach((element, index) => {
      if (element.indexNo === indexNo) {
          this.financeAgreements[index].isRemoved = true;
      }
  });
  }

  isFinanceAgreementRemoved(agreementNumber) {
    if (this.financeAgreements.length > 0 && this.financeAgreements.find(x => x.agreementNumber === agreementNumber)) {
      return this.financeAgreements.find(x => x.agreementNumber === agreementNumber).isRemoved;
    } else if (this.financeAgreements.length > 0 && !this.financeAgreements.find(x => x.agreementNumber === agreementNumber)) {
      return true;
    }
  }

  checkForduplicatesFinance(agreementNumber) {
    return this.financeAgreements.find(x => x.agreementNumber === agreementNumber);
  }
}
