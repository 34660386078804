<div class="previous-valuation-container">
  <ng-scrollbar class="white-fill">
    <div class="header">
      <span>Previous Valuation</span>
      <span *ngIf="hasValuations" class="valuations-available">
        - Select quote using radio button then select retrieve valuation button below
      </span>
    </div>
    <div *ngIf="(previousValuations$ | async) as valuations">
      <div class="valuations" *ngFor="let valuation of valuations">
        <input type="radio"
               name="radiogroup"
               value="valuation"
               (change)="selectValuation(valuation)" />
        <span>
          {{ valuation.createdDate | date: 'shortDate' }} |
          {{ valuation.vehiclePriceOffered | currency:'GBP':'symbol':'1.0-0' }} |
          {{ valuation.sourceDescription }}
        </span>
      </div>
    </div>
    <div *ngIf="!hasValuations && (pricePromise$ | async) as pricePromiseDays" class="no-valuations">
      No previous valuations found within {{ pricePromiseDays }} day promise.
    </div>
  </ng-scrollbar>
</div>
