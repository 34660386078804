<div class="cancellation-reason">
  <div class="modal-header">
    <div class="modal-header__text">Please select: </div>
    <img class="modal-close x-icon-white" (click)="closeCurrentModal()" alt='x-icon-white'>
  </div>
  <div class="modal-body">
    <div *ngIf="isCancellationReasonsSent">
      <div class="selection" *ngFor="let reason of notPurchasedReason">
        <input type="radio" [name]="reason.description" [value]='reason.id'
               (change)="onCancellationReasonSelection($event)">
        <label class="selection__option" [for]="reason.description">{{reason.description}}</label>
      </div>
    </div>
    <div *ngIf="!isCancellationReasonsSent">
      <div class="selection" *ngFor="let subReason of subReasonsList">
        <input type="radio" [value]="subReason.id" [name]="subReason.description"
               (change)='onCompetitorNameSelection($event)'>
        <label class="selection__option" [for]="subReason.description">{{subReason.description}}</label>
      </div>
    </div>
  </div>
</div>