<ng-template #error>
  <div class="mb-3 text-danger" *ngIf="loadingError$ | async">
    An error occurred when finding vehicle.
  </div>
</ng-template>

<div>
  <div *ngIf="(vehicleCheck$ | async) as vehicleCheck; else error">
    <div *ngIf="vehicleCheck.HpiFault">
      <div class="card border-danger m-3">
        <div class="card-body">
          <h2 class="card-title text-danger">Error</h2>
          <p class="text-danger"><strong>There was an error with the hpi check:</strong></p>
          <p class="card-text">Error code: {{ vehicleCheck.hpiFault.errorCode }}</p>
          <p class="card-text">Error Description: {{ vehicleCheck.hpiFault.errorDescription }}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="plateHasMarkers">
      <app-vehicle-marker label="Finance Code" value="Y" *ngIf="outstandingFinance"></app-vehicle-marker>
      <app-vehicle-marker label="Purchase Code" value="11" *ngIf="(stolenRecords && stolenRecords.length > 0) || (theftRecords && theftRecords.length > 0)"></app-vehicle-marker>
      <app-vehicle-marker label="Purchase Code" value="IM" *ngIf="isImported"></app-vehicle-marker>
      <app-vehicle-marker label="Purchase Code" value="NI" *ngIf="vehicleCheck.vehicles[0].niRegistered"></app-vehicle-marker>
      <app-vehicle-marker label="Damage Code" value="86" *ngIf="damageRecords && damageRecords.length > 0"></app-vehicle-marker>
      <app-vehicle-marker label="Damage Code" value="IR" *ngIf="inspectionRecords && inspectionRecords.length > 0"></app-vehicle-marker>
      <app-vehicle-marker label="Damage Code" value="SA" *ngIf="hasSalvage"></app-vehicle-marker>
    </div>

    <div class="collapsible-panel">
      <button type="button" class="button collapsible-toggle" [class.active]="!vehicleDetailsCollapsed" (click)="vehicleDetailsCollapsed = !vehicleDetailsCollapsed">Vehicle
        Details</button>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="vehicleDetailsCollapsed">
        <div class="collapsible-panel-content">
          <app-vehicle-details [mileage]="mileage" [quoteStateId]="quoteStateId" [vrm]="vehicleCheck.vehicles[0].plate[0].vrm" [vehicleDetails]='vehicleDetails'
            [hideDetails]='false'>
          </app-vehicle-details>
        </div>
      </div>
    </div>

    <div class="collapsible-panel mobile">
      <button type="button" class="button collapsible-toggle" disabled>Plate History</button>
      <div class="collapsible-panel-content">
        <app-plate-details [vehicle]="vehicleCheck.vehicles[0]" [plate]="currentPlate"></app-plate-details>
        <div class="plate-navigation" *ngIf="plates && plates.length > 1">
          <button class="plate-nav-btn" (click)="previousPlate()" [disabled]="currentPlateIndex < 1">
            <img src="../../assets//icons/calendar/VectorLeft.png" alt="Previous">
          </button>
          <button class="plate-nav-btn current-plate">Plate {{ currentPlateIndex + 1 }} of {{ plates.length }}</button>
          <button class="plate-nav-btn" (click)="nextPlate()" [disabled]="currentPlateIndex === plates.length - 1">
            <img src="../../assets//icons/calendar/VectorRight.png" alt="Next">
          </button>
        </div>
      </div>
    </div>

    <div class="collapsible-panel alert" *ngIf="outstandingFinance">
      <button type="button" class="button collapsible-toggle" [class.active]="!financeCollapsed" (click)="financeCollapsed = !financeCollapsed">Finance Details</button>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="financeCollapsed">
        <div class="collapsible-panel-content">
          <app-finance-records [records]="financeRecords"></app-finance-records>
        </div>
      </div>
    </div>

    <div class="collapsible-panel alert" *ngIf="hasPurchaseCode">
      <button type="button" class="button collapsible-toggle" [class.active]="!purchaseCodeDetailsCollapsed"
        (click)="purchaseCodeDetailsCollapsed = !purchaseCodeDetailsCollapsed">Purchase Code Details</button>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="purchaseCodeDetailsCollapsed">
        <div class="collapsible-panel-content">
          <div class="purchase-code-comment" *ngIf="vehicleCheck.vehicles[0].imported">
            Purchase Code IM means the car has been imported. You are still able to purchase the vehicle.
          </div>
          <div class="purchase-code-comment" *ngIf="vehicleCheck.vehicles[0].niRegistered">
            Purchase Code NI means the car has come from Northern Ireland. You are still able to purchase the vehicle.
          </div>
          <div class="condition-record-group" *ngIf="stolenRecords && stolenRecords.length > 0">
            <app-stolen-records [records]="stolenRecords"></app-stolen-records>
          </div>
          <div class="condition-record-group" *ngIf="theftRecords && theftRecords.length > 0">
            <app-condition-records [records]="theftRecords"></app-condition-records>
          </div>
        </div>

      </div>
    </div>

    <div class="collapsible-panel alert" *ngIf="(damageRecords && damageRecords.length > 0) || hasSalvage">
      <button type="button" class="button collapsible-toggle" [class.active]="!damageRecordsCollapsed" (click)="damageRecordsCollapsed = !damageRecordsCollapsed">Damage
        Details</button>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="damageRecordsCollapsed">
        <div class="collapsible-panel-content">
          <app-condition-records [records]="damageRecords"></app-condition-records>
          <app-salvaged-records *ngIf="hasSalvage"></app-salvaged-records>
        </div>
      </div>
    </div>

    <div class="collapsible-panel alert" *ngIf="inspectionRecords && inspectionRecords.length > 0">
      <button type="button" class="button collapsible-toggle" [class.active]="!inspectionRecordsCollapsed"
        (click)="inspectionRecordsCollapsed = !inspectionRecordsCollapsed">Inspection Records</button>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="inspectionRecordsCollapsed">
        <div class="collapsible-panel-content">
          <app-inspection-records [records]="inspectionRecords"></app-inspection-records>
        </div>
      </div>
    </div>

    <div *ngIf="vehicleCheck && group" [formGroup]="group">
      <div class="d-block question-label">Has your vehicle been an insurance write off?</div>
      <div ngbRadioGroup class="writeoff-options btn-group-toggle">
          <label ngbButtonLabel class="btn writeoff-option" 
            [ngClass]="{
              'prefilled-answer': isPrefilledAnswer(option.key), 
              'informed-answer': isInformedAnswer(option.key),
              'active' : group.value.value === option.key
            }"
            *ngFor="let option of writeOffOptions">
            <img [src]="getOptionIcon(option.value)" alt="Option">
            <input ngbButton formControlName="value" type="radio" [value]="option.key" (change)="valueChanged(this.group)">
            <div [innerHTML]="getOptionDescription(option.value)"></div>
          </label>
      </div>
    </div>

    <div *ngIf="vehicleCheck && continue" [formGroup]="continue" hidden>
      <label ngbButtonLabel>
        <input ngbButton type="checkbox" formControlName="value" id="chkContinue" (change)="valueChanged(this.continue)" />
        Can purchase
      </label>
    </div>
  </div>

  <div [hidden]="!showTryAgainButton">
    <button #tryAgainButton type="button" class="btn btn-warning btn-block btn-lg my-1">
      Try Again
    </button>
  </div>
</div>