<div class="d-flex flex-row">
  <div class="appointment">
    <div class="d-flex flex-column">
      <div class="appointment__date">Appointment Date & Time</div>
      <div class="date-label d-flex flex-row">
        <div class="date-label__day">Day</div>
        <div class="date-label__date">Date</div>
      </div>
      <div class="date-text d-flex flex-row">
        <div class="date-text__day">{{ selectedDate.date | date: 'EEEE' }}</div>
        <div class="date-text__date">{{ selectedDate.date | date: 'shortDate' }}</div>
      </div>
      <div class="time-label">Time</div>
      <div class="time-text">{{ selectedTime.startTime }}</div>
    </div>
    <div class="vrm d-flex flex-column">
      <div class="vrm__header">VRM</div>
      <div class="vrm__text">{{ walkInLookupData.vrm | uppercase }}</div>
    </div>
  </div>
  <div class="branch">
    <div class="d-flex flex-column">
      <div class="branch__header radius-top">Branch Address</div>
      <div class="branch__text">
        <ul class="branch__details-list">
          <li>{{  selectedSiteDetails.address1 }}</li>
          <li>{{  selectedSiteDetails.address2 }}</li>
          <li>{{  selectedSiteDetails.address3 }}</li>
          <li>{{  selectedSiteDetails.address4 }}</li>
          <li>{{  selectedSiteDetails.postcode }}</li>
        </ul>
      </div>
      <div class="branch__header">Branch Contact Details</div>
      <div class="branch__text branch-contact-details">
        <ul class="branch__details-list">
          <li>{{  selectedSiteDetails.emailAddress }}</li>
          <li>{{  selectedSiteDetails.directPhoneNumber }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="documents">
  <div class="documents__header">Things to bring to your appointment</div>
  <div class="d-flex flex-row">
    <div class="documents__label border">Vehicle Documents</div>
    <div class="documents__label border">Your ID</div>
    <div class="documents__label border">Finance Details</div>
    <div class="documents__label border">Vehicle Accessories</div>
  </div>
  <div class="d-flex flex-row">
    <div class="documents-details radius-left">
      <ul class="documents-details__list">
        <li class="documents-details__item">V5 document (also known as the logbook)</li>
        <li class="documents-details__item">Car's service history if it has one</li>
        <li class="documents-details__item">Valid MOT certificate if it has one</li>
      </ul>
    </div>
    <div class="documents-details">
      <ul class="documents-details__list">
        <li class="documents-details__item">Photographic proof of ID (e.g. driving license - including counterpart, passport)</li>
        <li class="documents-details__item">A proof of address from the last 3 months (e.g. utility bill, council tax bill, bank statement)</li>
      </ul>
    </div>
    <div class="documents-details">
      <ul class="documents-details__list">
        <li class="documents-details__item">Your UK bank details (so we can pay you)</li>
        <li class="documents-details__item">If there is outstanding finance, a letter from the finance company confirming their settlement figure</li>
      </ul>
    </div>
    <div class="documents-details radius-right">
      <ul class="documents-details__list">
        <li class="documents-details__item">All keys for the car, including spares</li>
        <li class="documents-details__item">All accessories that go with the car, locking wheel nut key, radio fascia, sat nav disc, user manuals, remote controls</li>
      </ul>
    </div>
  </div>
</div>
<div class="confirmation-email">
  Confirmation of the appointment will be sent to the customer’s email and by SMS.
</div>
