<div>
  <ng-template #error>
    <div class="mb-3 text-danger" *ngIf="loadingError$ | async">
      There was an error calculating service history, please retry.
    </div>
  </ng-template>
  <div class="row" *ngIf="(serviceHistoryCheck$ | async) as serviceHistoryText; else error">
    <div class="col-lg">
      <div class="mb-5">
        <h4>{{ serviceHistoryText }}</h4>
      </div>
    </div>
  </div>
  <div [hidden]="!(loadingError$ | async)">
    <button #retryButton type="button" class="btn btn-warning btn-block btn-lg my-1">
      Retry
    </button>
  </div>
</div>
