import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ServiceHistoryService } from '../../services/service-history.service';
import { Observable, EMPTY, fromEvent, BehaviorSubject } from 'rxjs';
import { tap, map, catchError, throttleTime, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { BaseComponentDirective } from '../../base/base.component';
import { ServiceHistoryCodes } from '../../enums/service-history-codes';
import { PathFinderService } from '../../services/path-finder.service';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-display-service-history-state',
    templateUrl: './display-service-history-state.component.html'
})
export class DisplayServiceHistoryStateComponent extends BaseComponentDirective implements OnInit, AfterViewInit {

    @Input() quoteStateId: number;
    @Input() group: UntypedFormGroup;
    serviceHistoryId: number;
    serviceHistoryCheck$: Observable<string>;
    loadingError$ = new BehaviorSubject<boolean>(false);

    constructor(private serviceHistoryService: ServiceHistoryService, private notifications: NotificationService, private pathFinder: PathFinderService,
         private loadingService: LoadingService) {
        super();
    }

    @ViewChild('retryButton', { read: ElementRef, static: true }) retryButton: ElementRef;

    ngOnInit() {
        this.loadingService.loading.emit(true);
        this.createDisplayServiceHistoryStateObservable();
    }

    ngAfterViewInit() {
        fromEvent(this.retryButton.nativeElement, 'click').pipe(
            throttleTime(3000),
            takeUntil(this.componentDestroyed)
        ).subscribe(() => {
            this.loadingError$.next(false);
            this.loadingService.loading.emit(false);
            this.retryButtonPress.next(true);
            this.createDisplayServiceHistoryStateObservable();
        });
    }

    createDisplayServiceHistoryStateObservable() {
        const answers = this.pathFinder.getAnswers().filter(element => !element.isRolledBack && element.value);
        this.serviceHistoryCheck$ = this.serviceHistoryService.calculateServiceHistory$(this.quoteStateId, answers).pipe(
            tap(result => {
                this.loadingError$.next(false);
                this.loadingService.loading.emit(false);
                this.group.controls.value.setValue(result);
            }),
            map(result => {
                return this.getServiceHistoryText(result);
            }),
            catchError(err => {
                this.loadingError$.next(true);
                this.loadingService.loading.emit(false);
                this.notifications.dangerToast('Failed to calculate service history, please retry.', err);
                return EMPTY;
            })
        );
    }

    getServiceHistoryText(serviceHistoryId: number) {
        switch (serviceHistoryId.toString()) {
            case ServiceHistoryCodes.Full: return 'This vehicle has full service history';
            case ServiceHistoryCodes.Part: return 'This vehicle has part service history';
            case ServiceHistoryCodes.FirstNotDue: return 'First service not yet due for this vehicle';
            default: return 'This vehicle has no service history';
        }
    }
}
