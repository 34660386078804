<div class="form-group" [formGroup]="group" *ngFor="let optionalExtra of optionalExtras">
    <div class="d-flex dashed-line" style="justify-content: space-between; ">
        <div class="question-title col">
            {{optionalExtra.parameterName}}
        </div>
        <div class="action-buttons">
            <label ngbButtonLabel [ngClass]="{ 
                'active' : optionalExtra.value === true || checkTrue(optionalExtra),
                'default-answer' : optionalExtra.displayValue === 'true'
                }" class="btn me-1 list-button" (click)="valueChanged(optionalExtra.parameterName, true)">
                <span>Y</span>
            </label>
            <button ngbButtonLabel [ngClass]="{ 
                'active' : optionalExtra.value === false || checkFalse(optionalExtra),
                'default-answer' : optionalExtra.displayValue === 'false'
                }" class="btn me-1 list-button" (click)="valueChanged(optionalExtra.parameterName, false)">
                <span>N</span>
            </button>
        </div>
    </div>
</div>