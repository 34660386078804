<div *ngIf="isEditing" class="question-group question-container" [formGroup]="searchForm">
  <div class="question-item inline-question">
    <label class="question-label" for="houseNo">{{ houseNumberLabel }}</label>
    <div class="input-group">
      <input id="houseNo" [attr.placeholder]="houseNumberPlaceholder" class="form-control input-text half-width obfuscated-playback" [ngClass]="{ 'disabled': isManualEntry }"
        type="text" formControlName="houseNo" autocomplete="off" autocorrect="off" />
      <span class="placeholder">{{ houseNumberPlaceholder }}</span>
      <span class="placeholder-line"></span>
    </div>
  </div>
  <div class="question-item inline-question">
    <label class="question-label" [attr.for]="identifier">{{ label }}</label>
    <div class="input-group">
      <input [attr.id]="identifier" [attr.placeholder]="explanation" class="form-control input-text half-width text-upper obfuscated-playback" [ngClass]="{ 'disabled': isManualEntry }"
        type="text" formControlName="postcode" autocomplete="off" autocorrect="off" (change)="transformInput($event)" (keyup.enter)="addressSearch($event)"
        (keyup.backspace)="clearAddress($event)" (keyup.delete)="clearAddress($event)" />
      <span class="placeholder">{{ explanation }}</span>
      <span class="placeholder-line"></span>
    </div>
  </div>
  <div>
    <button class="postcode-button search-button modal-button button_green" type="button" [disabled]="!searchForm.valid"
      [ngClass]="{ 'invalid':!searchForm.valid, 'loading': loading }" (click)="addressSearch(null)">
      <img alt="">
      <span>Search</span>
    </button>
  </div>
</div>
<div [formGroup]="group">
  <div class="question-item mini-section">
    <label class="question-label" for="address">{{ address1Label }}</label>
    <div class="input-group no-stretch">
      <input id="address" class="form-control input-text full-width disabled obfuscated-playback" type="text"
        formControlName="address" autocomplete="off" autocorrect="off" readonly />
    </div>
  </div>
  <div class="question-group">
    <div class="question-item inline-question">
      <label class="question-label" for="postcode">{{ label }}</label>
      <div class="input-group no-stretch">
        <input id="postcode" class="form-control input-text half-width disabled obfuscated-playback" type="text"
          formControlName="postcode" autocomplete="off" autocorrect="off" readonly />
      </div>
    </div>
    <div>
      <button *ngIf="isEditing" class="postcode-button edit-button modal-button clear-button" type="button" (click)="manualLookup()">
        <img alt="">
        <span>Manual Entry</span>
      </button>
      <button *ngIf="!isEditing && canEdit" class="postcode-button edit-button modal-button clear-button" type="button" (click)="editAddress()">
        <img alt="">
        <span>Edit Address</span>
      </button>
    </div>
  </div>
</div>
