import { Component, Input } from '@angular/core';
import { HpiCondition } from '../models/hpi-condition.model';

@Component({
  selector: 'app-condition-records',
  templateUrl: './condition-records.component.html',
  styleUrls: ['./condition-records.component.scss'],
})
export class ConditionRecordsComponent {
  @Input() records: Array<HpiCondition>;
}
