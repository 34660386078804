import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-display-answer',
  templateUrl: './display-answer.component.html'
})
export class DisplayAnswerComponent {

  constructor(private activeModal: NgbActiveModal) { }

  displayValue: string;
  parameterName: string;

  dismissModal() {
    this.activeModal.dismiss();
  }
}
