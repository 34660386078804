<div class="container">
  <div class="progress">
    <div id="positionProgress" class="progress-bar"></div>
  </div>

  <i class="fa fa-circle marker start"></i>
  <i class="fa fa-circle marker end"></i>
  <div class="marker-label start">0%</div>
  <div class="marker-label end">100%</div>

  <i id="positionMarker" class="fa fa-circle marker"></i>
  <div id="positionLabel" class="marker-label" [ngClass]="{'hidden': value < 5 || value > 95}">{{ value | number: '1.0-0' }}%</div>
</div>