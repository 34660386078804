import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { LookupService } from '../../services/lookup.service';
import { take, tap, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { Target } from '../../models/target.model';
import { Vat } from '../../models/vat.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../custom-validators/custom-validators';
import { ManualEntryComponent } from '../postcode-lookup-input/manual-entry/manual-entry.component';
import { AddressSummary } from '../../models/address-summary';

@Component({
  selector: 'app-vat-check-input',
  templateUrl: './vat-check-input.component.html',
  styleUrls: ['../postcode-lookup-input/postcode-lookup-input.component.scss']
})
export class VatCheckInputComponent extends BaseInputComponent implements OnInit, AfterContentInit {

  @Input() quoteStateId: number;
  @Input() questionForm: UntypedFormGroup;

  loading: boolean;
  hasSearched: boolean;
  vatResult: Vat;
  searchForm: UntypedFormGroup;
  resultsForm: UntypedFormGroup;

  nameLabel: string;
  namePlaceholder: string;
  addressLabel: string;
  addressPlaceholder: string;
  postcodeLabel: string;
  postcodePlaceholder: string;

  isManualEntry: boolean;

  constructor(private lookupService: LookupService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const vatNumber = this.group.value.value;

    this.searchForm = this.formBuilder.group({
      vatNumber: [vatNumber, { validators: Validators.required, asyncValidators: [CustomValidators.validateRegex(new RegExp(this.group.controls.regEx.value))] }]
    });

    this.resultsForm = this.formBuilder.group({
      company: [{ value: '', disabled: true }],
      address: [{ value: '', disabled: true }],
      postcode: [{ value: '', disabled: true }]
    });

    const name = this.findQuestion('CompanyName');
    if (name) {
      this.nameLabel = name.get('question').value;
      this.namePlaceholder = name.get('explanation').value;
    } else {
      this.nameLabel = 'Name';
      this.namePlaceholder = 'Name';
    }

    const address = this.findQuestion('AddressLine1');
    if (address) {
      this.addressLabel = address.get('question').value;
      this.addressPlaceholder = address.get('explanation').value;
    } else {
      this.addressLabel = 'Address';
      this.addressPlaceholder = 'Address';
    }

    const postcode = this.findQuestion('Postcode');
    if (postcode) {
      this.postcodeLabel = postcode.get('question').value;
      this.postcodePlaceholder = postcode.get('explanation').value;
    } else {
      this.postcodeLabel = 'Postcode';
      this.postcodePlaceholder = 'Postcode';
    }
  }

  ngAfterContentInit() {
    const vatNumber = this.group.value.value;
    if (vatNumber) {
      const name = this.getValue('CompanyName');
      const address1 = this.getValue('VatAddressLine1');
      const postcode = this.getValue('VatPostcode');
      if (name && address1 && postcode) {
        const address2 = this.getValue('VatAddressLine2');
        const address3 = this.getValue('VatAddressLine3');
        const address4 = this.getValue('VatAddressLine4');
        const address5 = this.getValue('VatAddressLine5');

        this.displayVatAddress(vatNumber, name, address1, address2, address3, address4, address5, postcode);
      }
    }
  }

  findQuestion(name: string) {
    const formArray = this.questionForm.get('items') as UntypedFormArray;
    return formArray.controls.find(x => (x as UntypedFormGroup).controls.name.value === name) as UntypedFormGroup;
  }

  getValue(name: string) {
    const question = this.findQuestion(name);
    if (question) {
      return question.value.value;
    }
    return null;
  }

  displayVatAddress(vatNumber: string, name: string, line1: string, line2: string, line3: string, line4: string, line5: string, postcode: string) {
    this.hasSearched = true;

    let formattedAddress = line1;
    if (line2) {
      formattedAddress = `${formattedAddress}, ${line2}`;
    }
    if (line3) {
      formattedAddress = `${formattedAddress}, ${line3}`;
    }
    if (line4) {
      formattedAddress = `${formattedAddress}, ${line4}`;
    }
    if (line5) {
      formattedAddress = `${formattedAddress}, ${line5}`;
    }

    if (this.searchForm.get('vatNumber').value !== vatNumber) {
      this.searchForm.patchValue({
        vatNumber: vatNumber
      });
    }

    this.resultsForm.patchValue({
      company: name,
      address: formattedAddress,
      postcode: postcode
    });
  }

  setVatAddress(vatResult: Target, isManualEntry: boolean) {
    this.displayVatAddress(vatResult.vatNumber, vatResult.name, vatResult.address.line1, vatResult.address.line2,
      vatResult.address.line3, vatResult.address.line4, vatResult.address.line5, vatResult.address.postcode);
    this.updateAnswers(vatResult, isManualEntry);
    this.change.emit(this.group);
  }

  updateAnswers(vatResult: Target, isManualEntry: boolean) {
    this.group.controls.value.setValue(vatResult.vatNumber);
    this.setAnswerValue('CompanyName', vatResult.name);
    this.setAnswerValue('VatAddressLine1', vatResult.address.line1);
    this.setAnswerValue('VatAddressLine2', vatResult.address.line2);
    this.setAnswerValue('VatAddressLine3', vatResult.address.line3);
    this.setAnswerValue('VatAddressLine4', vatResult.address.line4);
    this.setAnswerValue('VatAddressLine5', vatResult.address.line5);
    this.setAnswerValue('VatPostcode', vatResult.address.postcode);
    this.setAnswerValue('IsManualVatCheck', isManualEntry == null ? null : isManualEntry ? 'true' : 'false');
  }

  updateManualAnswers(vatNumber: number, address: AddressSummary, isManualEntry: boolean) {
    this.group.controls.value.setValue(vatNumber);
    this.setAnswerValue('CompanyName', address.company);
    this.setAnswerValue('VatAddressLine1', address.line1);
    this.setAnswerValue('VatAddressLine2', address.line2);
    this.setAnswerValue('VatAddressLine3', address.line3);
    this.setAnswerValue('VatAddressLine4', address.city);
    this.setAnswerValue('VatAddressLine5', address.province);
    this.setAnswerValue('VatPostcode', address.postalCode);
    this.setAnswerValue('IsManualVatCheck', isManualEntry == null ? null : isManualEntry ? 'true' : 'false');
  }

  setAnswerValue(name: string, value: string) {
    const question = this.findQuestion(name);
    if (question) {
      question.controls.value.setValue(value);
    }
  }

  vatSearch($event) {
    if (!this.searchForm.get('vatNumber').valid) {
      return;
    }
    if ($event) {
      $event.srcElement.blur();
    }
    if (!this.loading) {
      this.loading = true;
      this.vatCheck(this.searchForm.get('vatNumber').value);
    }
  }

  vatCheck(vatNumber: string) {
    this.lookupService.verifyVatNumber$(this.quoteStateId, vatNumber).pipe(
      tap(result => {
        this.vatResult = result;
        if (result.target) {
          this.showVatResult(result.target, vatNumber, `Name: ${result.target.name}`, `Address: ${result.target.addressLine}`, false, true);
        } else {
          this.showVatResult(null, vatNumber, 'Lookup failed for VAT number', result.consultationNumber, false, false);
        }
      }),
      catchError(err => {
        this.loading = false;
        const message = 'Lookup failed for VAT number';
        let detail = null;
        if (err.error) {
          let errorMessage = '';
          if (err.error.Message) {
            errorMessage = err.error.Message;
          } else {
            errorMessage = err.error;
          }
          const responseContentIndex = errorMessage.indexOf('Response content : ');
          if (responseContentIndex !== -1) {
            const startSearch = '"message":"';
            let startIndex = errorMessage.indexOf(startSearch, responseContentIndex);
            const endIndex = errorMessage.indexOf('"}', startIndex);
            if (startIndex !== -1 && endIndex !== -1) {
              startIndex = startIndex + startSearch.length;
              detail = errorMessage.substr(startIndex, endIndex - startIndex);
            }
          }
        }
        this.showVatResult(null, vatNumber, message, detail, true, false);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  clearAddress($event) {
    const target = new Target();
    target.vatNumber = $event.currentTarget.value;
    this.setVatAddress(target, null);
  }

  showVatResult(target: Target, vatNumber: string, message1: string, message2: string, includeManualLookup: boolean, includeAccept: boolean) {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.title = `VAT Results for ${vatNumber}`;
    modalRef.componentInstance.message = message1;
    if (message2) {
      modalRef.componentInstance.extraMessage = message2.replace('targetVrn', 'VAT number');
    }
    if (includeManualLookup) {
      modalRef.componentInstance.alternateButton = 'Manual Entry';
    }
    modalRef.componentInstance.failButton = 'Try Again';
    if (includeAccept) {
      modalRef.componentInstance.successButton = 'Confirm';
    }

    modalRef.result.then(result => {
      if (result && target) {
        this.setVatAddress(target, false);
      } else if (result === false) {
        this.manualLookup();
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  manualLookup() {
    const modalRef = this.modalService.open(ManualEntryComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.includeCompanyName = true;
    modalRef.result.then((address: AddressSummary) => {
      this.setManualVatAddress(address);
    }, () => { });
  }

  setManualVatAddress(address: AddressSummary) {
    this.isManualEntry = true;
    const vatNumber = this.searchForm.get('vatNumber').value;
    this.updateManualAnswers(vatNumber, address, this.isManualEntry);
    this.displayVatAddress(vatNumber, address.company, address.line1, address.line2,
      address.line3, address.city, address.province, address.postalCode);
    this.change.emit(this.group);
  }

}
