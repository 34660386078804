<div class="container-fluid main-page main-page-padding">
    <div class="page-title">
      <h2 class="page-header">Upjar</h2>
        <div class="button-container">
            <button class="header-btn search-btn" (click)="searchVrm()">
              <img src="../../assets/icons/buttons/search-disabled.png" alt='search'>Search VRM
            </button>
            <button class="header-btn" [disabled]="!changed" (click)="cancel()">
              <img *ngIf="changed" src="../../assets/icons/buttons/cancel-grey.png" alt='cancel'>
              <img *ngIf="!changed" src="../../assets/icons/buttons/cancel-grey-disabled.png" alt='cancel'>
              Cancel
            </button>
            <button class="header-btn small-action-btn" [ngClass]="{'loading':saving}" [disabled]="!changed || !valid" (click)="save()">
              <img *ngIf="!saving && changed && valid" src="../../assets/icons/buttons/save.png" alt='save'>
              <img *ngIf="!saving && (!changed || !valid)" src="../../assets/icons/buttons/save-disabled.png" alt='save'>
              Save
            </button>
        </div>
      
    </div>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4 pad-bottom ps-0 pe-0">
                    <div class="pad-right rounded-edge data-table">
                        <div class="modal-data-label modal-data-top-left modal-data-top-right">
                            Name
                        </div>
                        <div class="modal-data py-2 obfuscated-playback">
                            {{ purchase.customerName }}
                        </div>
                        <div class="modal-data-label">
                            Email
                        </div>
                        <div class="modal-data py-2 obfuscated-playback">
                            {{ purchase.customerEmail }}
                        </div>
                        <div class="modal-data-label">
                            Appointment Branch
                        </div>
                        <div class="modal-data py-2 modal-data-bottom-left modal-data-bottom-right">
                            {{ purchase.branch }}
                        </div>
                    </div>
                </div>
                <div class="col-3 pad-bottom ps-0 pe-0">
                    <div class="white-fill pad-left pad-right rounded-edge data-table">
                        <div class="modal-data-label modal-data-top-left modal-data-top-right">
                            Branch Manager
                        </div>
                        <div class="modal-data py-2">
                            {{ purchase.assignedTo }}
                        </div>
                        <div class="modal-data-label">
                            Date & Time of Appointment
                        </div>
                        <div class="modal-data py-2 double-text">
                            {{ purchase.appointmentTime | date: 'dd/MM/yyyy' }}
                            <br>
                            {{ purchase.appointmentTime | date: 'HH:mm' }}
                        </div>
                        <div class="modal-data-label">
                            RKC
                        </div>
                        <div class="modal-data py-2 modal-data-bottom-left modal-data-bottom-right">
                            <img *ngIf="hasHpiData" [src]="rkc" alt="" class="ticks-style">
                            <img *ngIf="!hasHpiData" src="../../assets/images/No-HPI-Data.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-5 pad-bottom ps-0 pe-0">
                    <div class="white-fill pad-left rounded-edge data-table">
                        <div class="row ms-0 me-0 rounded-edge">
                            <div class="col-6 modal-data-label modal-data-left-col modal-data-top-left">
                                VRM
                            </div>
                            <div class="col-6 modal-data-label modal-data-top-right">
                                Mileage
                            </div>
                            <div class="col-6 modal-data py-2 modal-data-left-col">
                                {{ purchase.vrm }}
                            </div>
                            <div class="col-6 modal-data py-2">
                                {{ purchase.mileage | number }}
                            </div>
                            <div class="col-6 modal-data-label modal-data-left-col">
                                Make & Model
                            </div>
                            <div class="col-6 modal-data-label">
                                VIN
                            </div>
                            <div class="col-6 modal-data py-2 modal-data-left-col double-text">
                                {{ vehicleDescription }}
                            </div>
                            <div class="col-6 modal-data py-2 double-text">
                                {{ purchase.vin }}
                            </div>
                            <div class="col-6 modal-data-label modal-data-left-col">
                                Keepers/HPI
                            </div>
                            <div class="col-6 modal-data-label">
                                MOT Expires
                            </div>
                            <div class="col-6 modal-data py-2 modal-data-left-col modal-data-bottom-left">
                                {{ keepers }}
                            </div>
                            <div class="col-6 modal-data py-2 modal-data-bottom-right">
                                {{ motExpiry | date: 'dd/MM/yyyy' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 pad-top ps-0 pe-0">
                    <div class="pad-right rounded-edge data-table">
                        <div class="row ms-0 me-0 rounded-edge">
                            <div class="col-6 modal-data-label modal-data-left-col modal-data-top-left">
                                Original Price
                            </div>
                            <div class="col-6 modal-data-label modal-data-top-right">
                                Adjustment
                            </div>
                            <div class="col-6 modal-data py-2 modal-data-left-col">
                                {{ originalVehiclePriceOffered | currency : 'GBP' }}
                            </div>
                            <div class="col-6 modal-data py-2">
                                {{ upjarAdjustment | currency : 'GBP' }}
                            </div>
                            <div class="col-12 modal-data-label">
                                New Offer
                            </div>
                            <div class="col-12 modal-data py-2 double-text modal-data-bottom-left modal-data-bottom-right input-container">
                                <input class="form-control input-text offer-text" [ngClass]="{'active': purchase.valuation}" type="text" inputmode="numeric" [formControl]="newOffer"
                                    currencyMask [options]="{ prefix: '£', thousands: ',', precision: '0', allowNegative: 'false', align: 'center' }"
                                    maxlength="10" autocomplete="off" autocorrect="off" (ngModelChange)="onChangeOffer()" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8 pad-top ps-0 pe-0">
                    <textarea class="notes-box pad-left obfuscated-playback" rows="10" placeholder="Add notes here" [formControl]="note" (change)="onChange()"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="text-center">
      <app-loading-wbac-animation></app-loading-wbac-animation>
    </div>
</div>