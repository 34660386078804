import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { AppointmentDetailsComponent } from '../../appointment-details/appointment-details.component';
import { AuthService } from '../../core/auth-service';
import { NotificationService } from '../../services/notification.service';
import { TaskService } from '../../services/task.service';
import { TaskSortTypes } from '../enums/task-sort-types';
import { TaskTypes } from '../enums/task-types';
import { UserTask } from '../models/user-task.model';
import { PagerService } from '../../pager/services/pager.service';
import { NavigationHistoryService } from '../../core/navigation-history.service';
import { TaskCounterService } from '../../services/task-counter.service';
import { CounterTypes } from '../../enums/counter-types';

@Component({
  selector: 'app-task-list-user',
  templateUrl: './task-list-user.component.html',
  styleUrls: ['./task-list-user.component.scss']
})
export class TaskListUserComponent implements OnInit, OnDestroy {
  loading: boolean;
  isEditing: boolean;
  username: string;
  userCanAuthorise: boolean;
  currentTime: number;

  userTasks: Array<UserTask>;
  filteredTasks: Array<UserTask>;
  pagedTasks: Array<UserTask>;

  sortOrder: string;
  public sortTypes = Object.values(TaskSortTypes);
  sortOpened: boolean;

  pageSize = 7;
  currentPage = 1;
  pageCount: number;
  hasEndOfDayTask: boolean;
  endOfDayTask: UserTask;
  destroy$ = new Subject();

  branchTasksImplemented: false;

  constructor(
    private taskService: TaskService, 
    private authService: AuthService,
    private notifications: NotificationService, 
    private modalService: NgbModal, 
    private pagerService: PagerService, 
    private navigationHistory: NavigationHistoryService,
    private counterService: TaskCounterService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.username = this.authService.getUser()?.username.split('@')[0].toLowerCase();
    this.currentTime = Date.now();
    this.sortOrder = TaskSortTypes.TaskType;
    this.retrieveTasks();
  }

  retrieveTasks() {
    this.taskService.getTasks$().pipe(
      tap(result => {
        this.userTasks = result.filter((task) => task.taskType !== TaskTypes.EndOfDay);
        const endOfDayTasks = result.filter((task) => task.taskType === TaskTypes.EndOfDay);
        if (endOfDayTasks.length > 0) {
          this.endOfDayTask = endOfDayTasks[0];
          this.hasEndOfDayTask = true;
        } else {
          this.endOfDayTask = null;
          this.hasEndOfDayTask = false;
        }
        this.filterTasks();
        this.showActionTask();
        this.loading = false;
        this.counterService.update(CounterTypes.USERTASK);
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to go retrieve task list`, err);
        this.loading = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
    this.pagerService.getPageNumber().pipe(
      takeUntil(this.destroy$),
      catchError(() => {
        return EMPTY;
      })
    )
      .subscribe((result) => {
        if (this.filteredTasks) {
          this.onPageChange(result);
        }
      });
  }

  filterTasks() {
    this.filteredTasks = this.sortTasks(this.userTasks);
    const taskCount = this.filteredTasks.length;
    this.pageCount = Math.ceil(taskCount / this.pageSize);
    if (this.currentPage > this.pageCount) {
      this.currentPage = 1;
    }
    this.pageTasks();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  pageTasks() {
    if (this.pageCount === 1) {
      this.pagedTasks = this.filteredTasks;
    } else {
      const start = (this.currentPage * this.pageSize) - this.pageSize;
      if (this.currentPage === this.pageCount) {
        this.pagedTasks = this.filteredTasks.slice(start);
      } else {
        const end = this.currentPage * this.pageSize;
        this.pagedTasks = this.filteredTasks.slice(start, end);
      }
    }
  }

  sortTasks(tasks: Array<UserTask>): Array<UserTask> {
    if (this.sortOrder === TaskSortTypes.AppointmentDate) {
      tasks.sort((a, b) => this.compareByAppointmentDate(a, b));
    } else if (this.sortOrder === TaskSortTypes.TaskType) {
      tasks.sort((a, b) => this.compareByTaskType(a, b));
    }
    return tasks;
  }

  compareByAppointmentDate(a: UserTask, b: UserTask): number {
    if (a.appointmentDate > b.appointmentDate) {
      return 1;
    } else if (b.appointmentDate > a.appointmentDate) {
      return -1;
    } else {
      return 0;
    }
  }

  compareByTaskType(a: UserTask, b: UserTask): number {
    if (b.sortOrder < a.sortOrder) { return 1; }
    if (b.sortOrder > a.sortOrder) { return -1; } else {
      if (b.appointmentDate < a.appointmentDate) { return 1; }
      if (b.appointmentDate > a.appointmentDate) { return -1; }
      return 0;
    }
  }

  onSortChange($event) {
    this.sortOrder = $event;
    this.sortTasks(this.filteredTasks);
    this.pageTasks();
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.pageTasks();
  }

  toggleSort() {
    this.sortOpened = !this.sortOpened;
  }

  completeEndOfDayTask() {
    this.taskService.completeEndOfDay$().pipe(
      tap(result => {
        if (result) {
          this.endOfDayTask = null;
          this.hasEndOfDayTask = false;
        }
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to complete task`, err);
        this.loading = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  getTaskClass(task: UserTask): string {
    if (task.taskType === TaskTypes.ConfirmCall) {
      return 'confirm-call';
    } else if (task.taskType === TaskTypes.NoShow) {
      return 'no-show';
    } else if (task.taskType === TaskTypes.AppointmentNotPurchased) {
      return 'not-purchased';
    } else if (task.taskType === TaskTypes.CustomerContact) {
      return 'customer-contact';
    } else if (task.taskType === TaskTypes.EndOfDay) {
      return 'end-of-day';
    } else {
      return 'other';
    }
  }

  actionTask(task: UserTask) {
    const modalRef = this.modalService.open(AppointmentDetailsComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'xl'
    });

    modalRef.componentInstance.appointmentId = task.appointmentId;
    modalRef.componentInstance.taskId = task.taskId;
    modalRef.componentInstance.taskType = task.taskType;
    modalRef.result.then(() => {
      this.filteredTasks = [];
      this.pagedTasks = [];
      this.retrieveTasks();
    }, () => { });
  }

  showActionTask() {
    const previousUrl = this.navigationHistory.getPreviousUrl();
    if (previousUrl?.split('/').includes('postPurchase')) {
      if (this.navigationHistory.getId()) {
        let found = false;
        while (!found) {
          const task = this.pagedTasks.find(x => x.taskId === this.navigationHistory.getId());
          if (task) {
            found = true;
            this.actionTask(task);
          } else {
            this.currentPage++;
            this.pageTasks();
          }
        }
      }
    }
  }
}
