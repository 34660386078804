import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './services/logging.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  constructor(private loggingService: LoggingService, private router: Router) {
  }

  handleError(error, showDetails: boolean = true): void {
    this.loggingService.logError(error);
    this.router.navigate(['/error'], { replaceUrl: true, state: { message: error.message, showDetails: showDetails } });
  }

  handleErrorNoRedirect(error): void {
    this.loggingService.logError(error);
  }
}

