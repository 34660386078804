    <div class="container">
        <div>
            <h1>Appointment Details</h1>
            <span (click)="closeModal()" class="modal-close"></span>
        </div>
        <div class="row obfuscated-playback">
            <div class="col-4 left">
                Customer
            </div>
            <div class="col-8 right">
                {{ appointmentValuationDetails.customerName }}
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">
                Source
            </div>
            <div class="col-6 right">
                {{ appointmentValuationDetails.source }}
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">
                Quote Date
            </div>
            <div class="col-6 right">
                {{ appointmentValuationDetails.quoteDate }}
            </div>
        </div>
        <div class="row" *ngIf="appointmentValuationDetails.source === 'WBAC' || appointmentValuationDetails.source === 'WBAV'">
            <div class="col-6 left">
                Quote Price
            </div>
            <div class="col-6 right">
                {{ appointmentValuationDetails.originalOfferPrice | currency: 'GBP' }}
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">
                VRM
            </div>
            <div class="col-6 right">
                {{ appointmentValuationDetails.vrm }}
            </div>
        </div>
        <div class="row">
            <div class="col-8 left">
                Recorded Mileage
            </div>
            <div class="col-4 right">
                {{ appointmentValuationDetails.mileage }}
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">
                Service History
            </div>
            <div class="col-6 right">
                {{ appointmentValuationDetails.serviceHistory }}
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">
                Previous Owners
            </div>
            <div class="col-6 right">
                {{ appointmentValuationDetails.previousOwners }}
            </div>
        </div>
        <div [hidden]="!appointmentValuationDetails.privatePlate" class="row">
            <div class="col-6 left">
                Private Plate
            </div>
            <div class="col-6 right">
                Yes
            </div>
        </div>
        <div class="row">
            <div class="col-6 left">
                CAP ID
            </div>
            <div class="col-6 right">
                {{ capData }}
            </div>
        </div>
        <div class="row" *ngIf="appointmentValuationDetails.isPurchaseFinalised">
          <div class="col-6 left">
              Balance
          </div>
          <div *ngIf="appointmentValuationDetails.customerPayment" class="col-6 right" [ngClass]="appointmentValuationDetails.customerPayment < 0 ? 'negative-equity' : ''">
              {{ appointmentValuationDetails.customerPayment | currency : 'GBP' }}
          </div>
          <div *ngIf="!appointmentValuationDetails.customerPayment" class="col-6 right">
            N/A
        </div>
    </div>
      <div class="img-container">
            <img class="logo-img" src="../../assets/images/logo.png" alt="Logo" />
        </div>
    </div>
