import { Component, OnInit, Input, OnDestroy, AfterContentInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { LookupService } from '../../services/lookup.service';
import { take, tap, catchError, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { Address } from '../../models/address';
import { ModalInputComponent } from '../../modal-input/modal-input.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressSummary } from '../../models/address-summary';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ManualEntryComponent } from './manual-entry/manual-entry.component';
import { ConfirmDialogService } from '../../confirm-dialog/services/confirm-dialog.service';

@Component({
  selector: 'app-postcode-lookup-input',
  templateUrl: './postcode-lookup-input.component.html',
  styleUrls: ['./postcode-lookup-input.component.scss']
})
export class PostcodeLookupInputComponent extends BaseInputComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input() questionForm: UntypedFormGroup;

  loading: boolean;
  isEditing: boolean;
  canEdit: boolean;
  searchForm: UntypedFormGroup;

  address1Label: string;
  houseNumberLabel: string;
  houseNumberPlaceholder: string;

  selectedAddress: AddressSummary;
  destroy$ = new Subject();

  constructor(private lookupService: LookupService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private confirmDialogService: ConfirmDialogService) {
    super();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit() {
    super.ngOnInit();
    const postcode = this.group.value.value;
    this.canEdit = !this.group.controls.disabled.value;
    this.isEditing = this.canEdit;

    this.searchForm = this.formBuilder.group({
      houseNo: [''],
      postcode: [postcode, Validators.required]
    });

    this.group.addControl('address', new UntypedFormControl('', Validators.required));
    this.group.addControl('postcode', new UntypedFormControl(''));

    const address1 = this.findQuestion('AddressLine1');
    if (address1) {
      this.address1Label = address1.get('question').value;
    } else {
      this.address1Label = 'Address';
    }

    const houseNo = this.findQuestion('HouseNumber');
    if (houseNo) {
      this.houseNumberLabel = houseNo.get('question').value;
      this.houseNumberPlaceholder = houseNo.get('explanation').value;
    } else {
      this.houseNumberLabel = 'House Number';
      this.houseNumberPlaceholder = 'House Number';
    }
  }

  ngAfterContentInit() {
    const postcode = this.group.value.value;
    if (postcode) {
      const address1 = this.getValue('AddressLine1');
      if (address1) {
        const address2 = this.getValue('AddressLine2');
        const address3 = this.getValue('AddressLine3');
        const address4 = this.getValue('AddressLine4');

        this.displayAddress(address1, address2, address3, address4, postcode);
        this.isEditing = false;
      }
    }
  }

  clearAddress($event) {
    const address = new AddressSummary();
    address.postalCode = $event.currentTarget.value;
    this.setAddress(address);
  }

  findQuestion(name: string) {
    const formArray = this.questionForm.get('items') as UntypedFormArray;
    return formArray.controls.find(x => (x as UntypedFormGroup).controls.name.value === name) as UntypedFormGroup;
  }

  getValue(name: string) {
    const question = this.findQuestion(name);
    if (question) {
      return question.value.value;
    }
    return null;
  }

  addressSearch(event) {
    if (event) {
      event.srcElement.blur();
    }
    if (!this.loading && this.searchForm.get('postcode').value) {
      this.loading = true;
      this.findAddress(this.searchForm.get('postcode').value, this.searchForm.get('houseNo').value, null);
    }
  }

  showModal(postCode: string, addresses: Array<AddressSummary>) {
    const options = new Array<KeyValuePair>();
    if (addresses) {
      addresses
        .sort((a, b) => a.text.localeCompare(b.text))
        .forEach(a => {
          options.push(new KeyValuePair(a.id, `${a.text}${a.description ? ', ' + a.description : ''}`)
        );
      });
    }

    const modalRef = this.modalService.open(ModalInputComponent, {
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.title = 'Select Address';
    modalRef.componentInstance.failButton = 'Cancel';
    modalRef.componentInstance.successButton = 'Ok';
    if (options.length > 0) {
      modalRef.componentInstance.options = options;
    }

    modalRef.result.then(result => {
      if (result) {
          const addressType = addresses.find(a => a.id === result).type;
          if (addressType === 'Address') {
            this.retrieveAddress(result);
          } else {
            this.findAddress(postCode, null, result);
          }
      } else {
        this.loading = false;
        this.confirmDialogService.confirmCancellation()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.manualLookup();
          });
      }
    }, () => {
      this.loading = false;
    });
  }

  findAddress(postcode: string, houseNumber: string, containerId: string) {
    this.lookupService.findAddress$(postcode, houseNumber, containerId).pipe(
      tap(result => {
        if (result.length === 1) {
          this.retrieveAddress(result[0].id);
        } else if (result.length === 0) {
          this.loading = false;
          this.confirmDialogService.confirmManualAddress()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.manualLookup();
            });
        } else {
          this.showModal(postcode, result);
        }
      }),
      catchError(() => {
        this.loading = false;
        this.confirmDialogService.confirmManualAddress()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.manualLookup();
          });
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  retrieveAddress(id: string) {
    this.lookupService.retrieveAddress$(id).pipe(
      tap(result => {
        this.loading = false;
        this.setAddress(result);
      }),
      catchError(() => {
        this.loading = false;
        this.confirmDialogService.confirmManualAddress()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.manualLookup();
          });
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  setAddress(addressLookup: AddressSummary) {
    const address = new Address();
    if (addressLookup.company) {
      this.displayAddress(addressLookup.company, addressLookup.line1, addressLookup.line2, addressLookup.city, addressLookup.postalCode);
      address.line1 = addressLookup.company;
      address.line2 = addressLookup.line1;
      address.line3 = addressLookup.line2;
    } else {
      this.displayAddress(addressLookup.line1, addressLookup.line2, addressLookup.line3, addressLookup.city, addressLookup.postalCode);
      address.line1 = addressLookup.line1;
      address.line2 = addressLookup.line2;
      address.line3 = addressLookup.line3;
    }
    address.line4 = addressLookup.city;
    address.line5 = addressLookup.province;
    address.postcode = addressLookup.postalCode;
    this.updateAnswers(address);

    this.change.emit(this.group);
  }

  displayAddress(line1: string, line2: string, line3: string, line4: string, postcode: string) {
    this.isEditing = false;

    let formattedAddress = line1;
    if (line2) {
      formattedAddress = `${formattedAddress}, ${line2}`;
    }
    if (line3) {
      formattedAddress = `${formattedAddress}, ${line3}`;
    }
    if (line4) {
      formattedAddress = `${formattedAddress}, ${line4}`;
    }

    if (this.searchForm.get('postcode').value !== postcode) {
      this.searchForm.patchValue({
        postcode: postcode
      });
    }

    this.group.patchValue({
      address: formattedAddress,
      postcode: postcode
    });
  }

  updateAnswers(address: Address) {
    const address1 = this.findQuestion('AddressLine1');
    if (address1) {
      address1.controls.value.setValue(address.line1);
    }
    const address2 = this.findQuestion('AddressLine2');
    if (address2) {
      address2.controls.value.setValue(address.line2);
    }
    const address3 = this.findQuestion('AddressLine3');
    if (address3) {
      address3.controls.value.setValue(address.line3);
    }
    const address4 = this.findQuestion('AddressLine4');
    if (address4) {
      address4.controls.value.setValue(address.line4);
    }
    const address5 = this.findQuestion('AddressLine5');
    if (address5) {
      address5.controls.value.setValue(address.line5);
    }
    const postcode = this.findQuestion('Postcode');
    if (postcode) {
      postcode.controls.value.setValue(address.postcode);
    }

    const postcode_d2 = this.findQuestion('Postcode_D2');
    if (postcode_d2) {
      postcode_d2.controls.value.setValue(address.postcode);
    }

    const address1_d2 = this.findQuestion('AddressLine1_D2');
    if (address1_d2) {
      address1_d2.controls.value.setValue(address.line1);
    }

    const address2_d2 = this.findQuestion('AddressLine2_D2');
    if (address2_d2) {
      address2_d2.controls.value.setValue(address.line2);
    }

    const address3_d2 = this.findQuestion('AddressLine3_D2');
    if (address3_d2) {
      address3_d2.controls.value.setValue(address.line3);
    }

    const address4_d2 = this.findQuestion('AddressLine4_D2');
    if (address4_d2) {
      address4_d2.controls.value.setValue(address.line4);
    }
    const address5_d2 = this.findQuestion('AddressLine5_D2');
    if (address5_d2) {
      address5_d2.controls.value.setValue(address.line5);
    }
  }

  manualLookup() {
    const modalRef = this.modalService.open(ManualEntryComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg'
    });
    modalRef.result.then(address => {
      this.setAddress(address);
    }, () => { });
  }

  editAddress() {
    this.isEditing = true;

    this.searchForm.patchValue({
      postcode: '',
      houseNo: ''
    });
    this.searchForm.controls.houseNo.markAsPristine();
    this.searchForm.controls.houseNo.markAsUntouched();
    this.searchForm.controls.postcode.markAsPristine();
    this.searchForm.controls.postcode.markAsUntouched();

    this.group.patchValue({
      address: '',
      postcode: ''
    });
  }
}
