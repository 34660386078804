import { Component, Input, OnInit } from '@angular/core';
import { PurchaseService } from '../services/purchase.service';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { catchError, take, tap } from 'rxjs/operators';
import { PaymentOptions } from './models/payment-options.model';
import { PathFinderService } from '../services/path-finder.service';
import { NotificationService } from '../services/notification.service';
import { EMPTY } from 'rxjs';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {

  @Input() quoteStateId: number;
  @Input() questionForm: UntypedFormGroup;
  @Input() set canLoadContent(canLoad: boolean) {
    this.isDataReady = canLoad ?? true;
    this.getPaymentMethodOptions();
  }

  loading: boolean;
  isDataReady: boolean;
  isInitialised: boolean;

  paymentOption: UntypedFormGroup;
  paymentCharge: UntypedFormGroup;
  paymentDueDate: UntypedFormGroup;
  valuationNextDayPaymentCharge: UntypedFormGroup;
  valuationFasterPaymentCharge: UntypedFormGroup;

  paymentOptionsArray: Array<PaymentOptions> = new Array<PaymentOptions>();
  fastPaymentCharge: number;
  nextDayPaymentCharge: number;

  isFastPaymentSelected = false;
  isNextDayPaymentSelected = false;
  isBacsPaymentSelected = false;
  isFastPaymentAvailable = false;
  isNextDayPaymentAvailable = false;

  constructor(
    private purchaseService: PurchaseService,
    private pathFinderService: PathFinderService,
    private loadingService: LoadingService,
    private notifications: NotificationService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.isInitialised = true;
    this.getFasterPaymentBankCheck();
    this.createPaymentOptionsFormGroup();
    this.getPaymentMethodOptions();
  }

  getPaymentMethodOptions() {
    if (this.isInitialised && this.isDataReady) {
      this.purchaseService.getPaymentOptions$(this.quoteStateId).pipe(
        tap(result => {
          if (result !== null) {
            result.forEach(element => {
              const paymentOption = new PaymentOptions;
              paymentOption.paymentTypeId = element.paymentMethodTypeId;
              paymentOption.paymentName = element.paymentMethodTypeName;
              paymentOption.paymentCharge = element.paymentCharge;
              paymentOption.paymentDueDate = element.dueDate;
              this.paymentOptionsArray.push(paymentOption);
            });
          }
          this.checkPaymentOptionsAvailability(this.paymentOptionsArray);
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
        }),
        catchError(() => {
          this.notifications.warningToast(`Unable to retrieve payment options from Finance. Defaulted to 4 working day payment.`);
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
          const paymentOption = new PaymentOptions;
          paymentOption.paymentCharge = 0.0;
          paymentOption.paymentDueDate = this.addWorkDays(new Date(), 4);
          paymentOption.paymentName = 'BacsPayment';
          paymentOption.paymentTypeId = 3;
          this.paymentOptionsArray.push(paymentOption)
          this.selectBacsPayment();
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  addWorkDays(startDate: Date, daysToAdd: number): Date {
    const dow = startDate.getDay();
    if (dow === 0) {
      daysToAdd++;
    }
    if (dow + daysToAdd >= 6) {
      const remainingWorkDays = daysToAdd - (5 - dow);
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        if (remainingWorkDays % 5 === 0) {
          daysToAdd -= 2;
        }
      }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
  }

  getFasterPaymentBankCheck() {
    const bankCheck = this.getAnswerValue('FastPaymentAllowedByBank');
    if (bankCheck === 'True') {
      return true;
    }
    return false;
  }

  setSelectedPaymentAnswers() {
    let typeName = '';
    let typeId: string;
    let dueDate: Date;
    let charge = 0.0;
    if (this.paymentOptionsArray.length > 0) {
      if (this.isFastPaymentSelected) {
        typeName = 'FastPayment';
      } else if (this.isNextDayPaymentSelected) {
        typeName = 'NextDayPayment';
      } else {
        typeName = 'BacsPayment';
      }
      const type = this.paymentOptionsArray.find(x => x.paymentName === typeName);
      typeId = type.paymentTypeId.toString();
      dueDate = type.paymentDueDate;
      charge = type.paymentCharge;
    } else {
      typeName = 'BacsPayment';
      typeId = '3';
      dueDate = this.addWorkDays(new Date(), 4);
    }
    this.paymentOption.patchValue({ value: typeId });
    this.paymentDueDate.patchValue({ value: dueDate });
    this.paymentCharge.patchValue({ value: charge });
  }

  checkPaymentOptionsAvailability(paymentOptions: Array<PaymentOptions>) {
    const isTrade = this.pathFinderService.isTradePurchase();
    paymentOptions.forEach(element => {
      if (element.paymentName.toLowerCase() === 'fastpayment') {
        if (this.getFasterPaymentBankCheck() && !isTrade) {
          this.isFastPaymentAvailable = true;
          this.fastPaymentCharge = element.paymentCharge;
          this.valuationFasterPaymentCharge.patchValue({ value: element.paymentCharge });
        }
      }
      if (element.paymentName.toLowerCase() === 'nextdaypayment' && !isTrade) {
        this.isNextDayPaymentAvailable = true;
        this.nextDayPaymentCharge = element.paymentCharge;
        this.valuationNextDayPaymentCharge.patchValue({ value: element.paymentCharge });
      }
    });
    if (!this.isFastPaymentAvailable && !this.isNextDayPaymentAvailable) {
      this.selectBacsPayment();
    }
  }

  selectFastPayment() {
    this.isFastPaymentSelected = !this.isFastPaymentSelected;
    this.isBacsPaymentSelected = false;
    this.isNextDayPaymentSelected = false;
    this.setFormStatus();
  }

  selectNextDayPayment() {
    this.isNextDayPaymentSelected = !this.isNextDayPaymentSelected;
    this.isFastPaymentSelected = false;
    this.isBacsPaymentSelected = false;
    this.setFormStatus();
  }

  selectBacsPayment() {
    this.isBacsPaymentSelected = !this.isBacsPaymentSelected;
    this.isNextDayPaymentSelected = false;
    this.isFastPaymentSelected = false;
    this.setFormStatus();
  }

  checkIfFormValid() {
    if (this.isNextDayPaymentSelected || this.isFastPaymentSelected || this.isBacsPaymentSelected) {
      return true;
    } else {
      return false;
    }
  }

  setFormStatus() {
    const isValid = this.checkIfFormValid();
    if (isValid) {
      this.setSelectedPaymentAnswers();
    } else {
      this.paymentOption.patchValue({ value: null });
      this.paymentCharge.patchValue({ value: null });
      this.paymentDueDate.patchValue({ value: null });
    }
  }

  createPaymentOptionsFormGroup() {
    if (this.questionForm) {
      const controlArray = this.questionForm.get('items') as UntypedFormArray;
      this.paymentOption = controlArray.controls[0] as UntypedFormGroup;
      this.paymentCharge = controlArray.controls[1] as UntypedFormGroup;
      this.paymentDueDate = controlArray.controls[2] as UntypedFormGroup;
      this.valuationFasterPaymentCharge = controlArray.controls[3] as UntypedFormGroup;
      this.valuationNextDayPaymentCharge = controlArray.controls[4] as UntypedFormGroup;
    }
  }

  getAnswerValue(parameterName: string) {
    const answer = this.pathFinderService.getAnswerValue(parameterName, null);
    return answer;
  }
}
