import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Note } from '../appointment-details/models/note.model';
import { AuthTask } from '../task-list/models/auth-task.model';
import { UserTask } from '../task-list/models/user-task.model';
import { Regions } from '../task-list/models/region';
import { RegionFilter } from '../task-list/models/region-filter';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  getTasks$(): Observable<Array<UserTask>> {
    const url = `${environment.ppxPrivateApi}api/task`;
    return this.http.get<Array<UserTask>>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getTaskCount$(): Observable<number> {
    const url = `${environment.ppxPrivateApi}api/task/count`;
    return this.http.get<number>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getTasksForVrm$(vrm: string): Observable<Array<UserTask>> {
    const url = `${environment.ppxPrivateApi}api/task/vrm/${vrm}`;
    return this.http.get<Array<UserTask>>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  reassign$(taskId: number, username: string): Observable<void> {
    const body = {};
    const url = `${environment.ppxPrivateApi}api/task/${taskId}/reassign/${username}`;
    return this.http.put<void>(url, body).pipe(
      catchError(err => throwError(err))
    );
  }

  confirm$(appointmentId: number): Observable<Note> {
    const body = {};
    return this.http.post<Note>(`${environment.ppxPrivateApi}api/task/confirm/${appointmentId}`, body).pipe(
      catchError(err => throwError(err))
    );
  }

  late$(appointmentId: number): Observable<Note> {
    const body = {};
    return this.http.post<Note>(`${environment.ppxPrivateApi}api/task/late/${appointmentId}`, body).pipe(
      catchError(err => throwError(err))
    );
  }

  noShow$(appointmentId: number): Observable<Note> {
    const body = {};
    return this.http.post<Note>(`${environment.ppxPrivateApi}api/task/noshow/${appointmentId}`, body).pipe(
      catchError(err => throwError(err))
    );
  }

  completeEndOfDay$(): Observable<boolean> {
    const body = {};
    return this.http.post<boolean>(`${environment.ppxPrivateApi}api/task/completeendofday`, body).pipe(
      catchError(err => throwError(err))
    );
  }

  customerContact$(appointmentId: number): Observable<Note> {
    const body = {};
    return this.http.post<Note>(`${environment.ppxPrivateApi}api/task/customercontact/${appointmentId}`, body).pipe(
      catchError(err => throwError(err))
    );
  }

  getAllRegions$(): Observable<Array<Regions>> {
    const url = `${environment.ppxPrivateApi}api/AuthorizationRequest/regions`;
    return this.http.get<Array<Regions>>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getAuthTasks$(regionIds: Array<string>): Observable<Array<AuthTask>> {
    const url = `${environment.ppxPrivateApi}api/AuthorizationRequest/GetAuthTasks`;
    return this.http.post<Array<AuthTask>>(url, regionIds).pipe(
      catchError(err => throwError(err))
    );
  }

  getAuthTaskCount$(): Observable<number> {
    const url = `${environment.ppxPrivateApi}api/AuthorizationRequest/authtasks/count`;
    return this.http.get<number>(url).pipe(
      catchError(err => throwError(err))
    );
  }

  getRegionsForUser$(regionIds: Array<string>): Observable<Array<Regions>> {
    const url = `${environment.ppxPrivateApi}api/AuthorizationRequest/GetRegionsForUser`;
    return this.http.post<Array<Regions>>(url, regionIds).pipe(
      catchError(err => throwError(err))
    );
  }

  getRegionFilterForUsername$(username: string): Observable<RegionFilter> {
    const url = `${environment.ppxPrivateApi}api/AuthorizationRequest/GetRegionFilter`;
    return this.http.post<RegionFilter>(url, `"${username}"`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  addRegionFilter$(regionFilter: RegionFilter): Observable<RegionFilter> {
    const url = `${environment.ppxPrivateApi}api/AuthorizationRequest/AddRegionFilter`;
    return this.http.post<RegionFilter>(url, regionFilter).pipe(
      catchError(err => throwError(err))
    );
  }

  updateRegionFilter$(regionFilter: RegionFilter): Observable<void> {
    return this.http.post<void>(`${environment.ppxPrivateApi}api/authorizationrequest/UpdateRegionFilter`, regionFilter).pipe(
      catchError(err => throwError(err))
    );
  }

  appointmentCancellation$(appointmentId: number): Observable<Note> {
      const body = {};
      return this.http.post<Note>(`${environment.ppxPrivateApi}api/task/appointmentcancellation/${appointmentId}`, body).pipe(
          catchError(err => throwError(err))
      );
  }

  notPurchasedAppointment$(appointmentId: number): Observable<Note> {
    const body = {};
    return this.http.post<Note>(`${environment.ppxPrivateApi}api/task/notpurchased/${appointmentId}`, body).pipe(
        catchError(err => throwError(err))
    );
  }
}
