<div *ngIf="!hasLookupError" [hidden]=bookingAppointment>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="!multipleVehicleDescriptions && !creatingNewValuation && !isAlt">Quick Quote</h4>
    <h4 class="modal-title" *ngIf="!multipleVehicleDescriptions && !creatingNewValuation && isAlt">Alt VRM</h4>
    <h4 class="modal-title" *ngIf="isManualLookup">&nbsp;- Manual Lookup</h4>
    <h4 class="modal-title" *ngIf="multipleVehicleDescriptions">Multimatch</h4>
    <h4 class="modal-title" *ngIf="!multipleVehicleDescriptions && creatingNewValuation">Book Valuation</h4>
    <img class="modal-close" (click)="dismissModal()" alt="Dismiss modal">
  </div>
  <div class="modal-body quick-quote-width">
    <div class="container">
      <div class="row">

        <!-- vrm search -->
        <div *ngIf="!multipleVehicleDescriptions && !showQuickQuoteValuation && !hasLookupError && !creatingNewValuation"
             class="col-12 replace-padding mb-3">
          <app-search-vrm [loading]="isLoading"
                          [displayVrm]="vrm"
                          [altVrm]="isAlt"
                          (vrm)="setVrm($event)"
                          (vehicleDescriptionsResult)="setVehicleDescriptions($event)"
                          (hasLookupError)="setHasLookupError($event)"
                          (isloading)="setIsLoading($event)"
                          (hasMissingVrmRelationship)="setHasMissingVrmRelationship($event)"
                          (allowManualLookup)="manualLookup($event)">
          </app-search-vrm>
        </div>

        <!-- quick quote valuation -->
        <div class="col-12 replace-padding mb-4 container-quick-quote" *ngIf="showQuickQuoteValuation && !creatingNewValuation && !isLoading">
          <div class="quote-header">Quick Quote</div>
          <div class="quote-amount">{{ vehiclePriceOffered | currency:'GBP':'symbol':'1.0-0'}}</div>
          <div class="quote-price-promise-text">
            This price is not guaranteed, the vehicle would need to complete a full vaulation.<br>
            This is a quick quote with an initial view of the buyer’s grade.
          </div>
        </div>

        <!-- vehicle image -->
        <div *ngIf="vehicleImage && !creatingNewValuation" class="col-12 replace-padding">
          <app-vehicle-image [vehicleImage]="vehicleImage"
                             [defaultVehicleImage]="defaultVehicleImageUrl"
                             [selectedGrade]="selectedGrade"
                             [showGrade]="showQuickQuoteValuation && !creatingNewValuation"
                            >
          </app-vehicle-image>
        </div>

        <!-- registration plate -->
        <app-registration-plate *ngIf="multipleVehicleDescriptions"
                                class="col-12 replace-padding mb-4 center"
                                [vrm]="vrm">
        </app-registration-plate>

        <!-- multiple derivatives -->
        <div *ngIf="multipleVehicleDescriptions" class="col-12 replace-padding mb-1">
          <app-multiple-derivatives [vehicleDescriptions]="multipleVehicleDescriptions"
                                    (selectedDerivativeEmitter)="setSelectedDerivative($event)">
          </app-multiple-derivatives>
        </div>

        <!-- show vehicle details -->
        <div *ngIf="vehicleDescription && !creatingNewValuation" class="col-12 replace-padding mb-3">
          <app-vehicle-details [vrm]="vrm"
                               [vehicleDescription]="vehicleDescription">
          </app-vehicle-details>
        </div>

        <!-- mileage and grade questions -->
        <div *ngIf="vehicleDescription && !showQuickQuoteValuation" class="col-12 replace-padding mb-3">
          <form [formGroup]="quickQuoteForm" (ngSubmit)="getQuickQuote()">
            <div class="form-section">
              <div class="form-label">Enter vehicle mileage</div>
              <input id="mileageInput"
                     type="text"
                     class="form-control input-text"
                     formControlName="mileage"
                     pattern="^[0-9]+([0-9]{0,0})?$"
                     [ngClass]="!this.quickQuoteForm.get('mileage').valid ? 'invalid' : ''"
                     (ngModelChange)="preventInputOnTablet($event)"
                     maxlength="8"
                     mask="separator" thousandSeparator=","
                     required
                     tabindex="-1"
                     autocomplete="off"
                     placeholder="Enter Mileage" />
              <div class="placeholder">
                Enter Mileage
              </div>
              <span class="placeholder-line"></span>
            </div>
            <div class="form-section">
              <div class="form-label">Please select grade of vehicle</div>
              <div class="grade-btn-container">
                <a class="grade-btn" *ngFor="let grade of grades" [ngClass]="{ 'selected-grade':isSelectedGrade(grade), 'last':grade===5 }" (click)="setGrade(grade)">{{ grade }}</a>
              </div>
            </div>
            <div class="quick-quote-btn center mt-5">
              <button class="modal-button button_green" type="submit"
                      [ngClass]="{'active':quickQuoteForm.valid, 'invalid':!quickQuoteForm.valid, 'loading': quickQuoteLoading}"
                      [disabled]="!quickQuoteForm.valid">
                <span class="quick-quote-icon" [ngClass]="{'active':quickQuoteForm.valid, 'invalid':!quickQuoteForm.valid, 'loading': quickQuoteLoading}"></span>
                <span class="quick-quote-span">Get a Quick Quote</span>
              </button>
            </div>
          </form>
        </div>

        <!-- book valuation button -->
        <div class="col-12 replace-padding mt-4" *ngIf="showQuickQuoteValuation && !creatingNewValuation">
          <div class="quick-quote-btn center">
            <button [ngClass]="{'active':quickQuoteForm.valid, 'invalid':!quickQuoteForm.valid, 'loading': bookValuationLoading}" class="modal-button button_green active" type="submit" [disabled]="bookValuationLoading" (click)="bookValuation()">
              <span [ngClass]="{'loading': bookValuationLoading}" class="book-valuation-icon"></span>
              <span class="book-valuation-span">Book Valuation</span>
            </button>
          </div>
        </div>

        <!-- show valuation questions -->
        <div *ngIf="creatingNewValuation" class="col-12 replace-padding">
          <app-valuation-questions [vrm]="vrm"
                                   [altVrm]="altVrm"
                                   [mileage]="mileage"
                                   [bookId]="bookId"
                                   [isQuickQuote]="true"
                                   [quoteId]="vehicleDescription.QuoteId"
                                   [quoteGuid]="vehicleDescription.QuoteGuid"
                                   [vehicle]="this.vehicleDescription"
                                   (bookingAppointment)="setBookingAppointment($event)"
                                   (dismissModal)="dismissModal()">
          </app-valuation-questions>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- vrm error modal -->
<app-lookup-error *ngIf="hasLookupError" [vrm]="vrm" [missingVrmRelationship]="hasMissingVrmRelationship"
 [allowManualLookup]="allowManualLookup" [isStartPurchase]="this.creatingNewValuation" [isQuickQuote]="true"
  (trySearchAgain)="tryAgain($event)">
</app-lookup-error>
