<div *ngIf="!loading" class="container mt-4 obfuscated-playback">
  <div [innerHTML]="purchaseDetails.invoiceDetailsHtml"></div>
  
  <div class="row">
    <div class="col-12 mb-2 mt-2">
      <h4 class="invoiceSubHeading">Purchase Authorisation</h4>
      <div class="mb-4 group">
        <div><i>Authorised for and on behalf of WE BUY ANY CAR Limited</i></div>
        <div *ngIf="buyerSignature">
          <img [src]="buyerSignature.url" alt="Buyer Signature"/>
          <div class="row">
            <div class="col-7">webuyanycar.com Authorised Signature</div>
            <div class="col-5">Date: {{ today | date:'dd/MM/yyyy' }}</div>
          </div>
        </div>
        <div *ngIf="!buyerSignature">
          <div class="row">
            <div class="col-6">
              <button type="button" (click)="buyerSign()" class="btn btn-primary btn-block ">webuyanycar.com Authorised Signature</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2 mt-2">
      <div *ngIf="purchaseDetails.includeVat">
        <strong>THE VAT SHOWN IS YOUR OUTPUT TAX DUE TO HMRC<br />Please refer to the attached Self-Billing Agreement.</strong>
      </div>
      <p>If you have an outstanding finance agreement with Blackhorse Limited, or any finance company part of the Blackhorse Group, You consent to <b>Black Horse Limited</b> issuing a settlement figure to We Buy Any Car Limited (WBAC), for the sole purpose of WBAC purchasing your vehicle and settling outstanding finance payable to Black Horse Limited. WBAC will provide personal associated with your agreement to Black Horse Limited for validation, to ensure the settlement figure is given in relation to the correct finance agreement / vehicle.</p>
      <p>This activity may need repeated should the settlement quotation expire during this transaction. If you wish to withdraw this consent, you can contact Webuyanycar’s Customer Service Team</p>
      <div *ngIf="!purchaseDetails.includeVat">
        <strong>Scheme</strong> - Second Hand Goods
      </div>
      <div>
        We Buy Any Car Ltd will inform DVLA that the vehicle has been sold using their online service during opening hours (7am-7pm). If the online service does not accept your V5 document reference number, or we purchase your vehicle outside 7am-7pm, it is the responsibility of the seller to inform DVLA at https://www.gov.uk/sold-bought-vehicle, or by sending your notification slip which can be detached from the V5 at the point of sale. We Buy Any Car Ltd does not accept responsibility for any fine issued before or after the date of sale.
      </div>
    </div>
  </div>
  <div [innerHTML]="purchaseDetails.selfBillingHtml"></div>
  <div class="row mb-2" *ngIf="purchaseDetails.includeVat">
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <div class="col-4" style="margin-top:5%">Signed by</div>
        <div class="col-8" style="height: 100px;">
          <img *ngIf="buyerSignature" [src]="buyerSignature.url" style="max-width: 250px" alt="Buyer Signature" />
        </div>
        <div class="col-4">On behalf of</div>
        <div class="col-8"><strong>webuyanyvan.com</strong></div>
        <div class="col-4">Date</div>
        <div class="col-8"><strong>{{ purchaseDetails.purchaseDate | date:'dd/MM/yyyy' }}</strong></div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <div class="col-4" style="margin-top:5%">Signed by</div>
        <div class="col-8" style="height: 100px;">
          <img *ngIf="customerSignature" [src]="customerSignature.url" style="max-width: 250px" alt="Customer Signature"/>
        </div>
        <div class="col-4">On behalf of</div>
        <div class="col-8"><strong>{{ purchaseDetails.customer.name }}</strong></div>
        <div class="col-4">Date</div>
        <div class="col-8"><strong>{{ purchaseDetails.purchaseDate | date:'dd/MM/yyyy' }}</strong></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-2 mt-2">
      <div id="invoiceterms" [innerHTML]="purchaseDetails.termsAndConditionsHtml">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2 mt-2">
      <div>I confirm I have arranged removal or redaction of all personal data from all documentation and devices supplied with, or contained in, the Vehicle.</div>
      <div><i>I confirm all the details on this document are correct and agree to the terms and condition of sale.</i></div>
      <div *ngIf="customerSignature" class="mb-2">
        <img [src]="customerSignature.url" alt="Customer Signature"/>
        <div class="row">
          <div class="col-6">Vendor Signature</div>
          <div class="col-6">Date: {{ today | date:'dd/MM/yyyy' }}</div>
        </div>
      </div>
      <div *ngIf="!customerSignature" class="mb-2">
        <div class="row">
          <div class="col-6">
            <button type="button" (click)="customerSign()" class="btn btn-primary btn-block">Vendor Signature</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
