
// Class within the purchase journey question

export class Warning {
  operator: string;
  field: string;
  message: string;
  warningBorder: boolean;
}

// Class as part of the control
// Conversion of field to value is done in user-input.component

export class WarningCondition {
  operator: string;
  value: string;
  message: string;
  warningBorder: boolean;
}
