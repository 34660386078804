<div class="form-group" [ngClass]="{ 'hidden' : this.isHidden }">
  <label class="d-block question-label" [attr.for]="identifier" placement="left" *ngIf="label">{{ label }}</label>
  <div [formGroup]="group" class="input-text date-input">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" [matDatepickerFilter]="boundPickerFilter" formControlName="value" (dateChange)="valueChanged()">
      <mat-label>DD/MM/YYYY</mat-label>
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>
          <img src="../../../assets/icons/calendar/Calendar.png">
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div *ngIf="showWarning" class='warning-label'>{{ warningMessage }}</div>
</div>