import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SiteOnDate } from '../../models/site-on-date.model';
import { SiteWithSlots } from '../../models/site-with-slots.model';
import { TimeSlot } from '../../models/time-slot.model';

@Component({
  selector: 'app-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss']
})

export class TimeSlotsComponent {
  @Input() selectedSite: SiteWithSlots;
  @Input() selectedDate: SiteOnDate;
  @Input() timeSlots: Array<TimeSlot>;
  @Output() selectedTime = new EventEmitter<TimeSlot>();

  onTimeSlotSelection(selectedTimeSlot: TimeSlot) {
    if (selectedTimeSlot.isBooked) {
      return;
    }

    this.selectedTime.emit(selectedTimeSlot);
  }
}
