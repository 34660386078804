<div>
  <ng-template #error>
    <div class="mb-3 text-danger" *ngIf="loadingError$ | async">
      <div class="mb-3 text-danger" *ngIf="vehicleNotFoundError | async">
        Vehicle check was successful but no vehicle was found.
      </div>
      <div class="mb-3 text-danger" *ngIf="vehicleCheckError | async; else g">
        An error occurred while finding vehicle. Please try again
      </div>
      <div class="mb-3 text-danger" *ngIf="warrantyCheckError | async">
        An error occurred when checking warranty status. Please try again
      </div>
    </div>
  </ng-template>
</div>
<div class="collapsible-panel" [ngClass]="{'collapsible-panel__green-border':isInWarranty}"
  *ngIf="vehicleAndWarrantyCheck$ | async as warrantyCheck">
  <button type="button" class="button collapsible-toggle" [class.active]="!isVehicleDetailsCollapsed"
    (click)="collapse.toggle()">Warranty Details</button>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isVehicleDetailsCollapsed">
    <div class="vehicle-detail-container margins width">
      <div class="d-flex flex-row vehicle-detail-container-table">
        <div style="width:100%;">
          <div class="row description description-row">
            <div class="description header">Manufacturer</div>
            <div class="w-100"></div>
            <div class="description value">{{ manufacturer }}</div>
          </div>
          <div class="row description-row">
            <div class="description header">Manufacturer max warranty mileage</div>
            <div class="w-100"></div>
            <div class="description value extended-vehicle-details-page">{{ manufacturerWarrantyMileage | number }} miles</div>
          </div>
        </div>
        <div class="vertical-line"></div>
        <div style="width:100%">
          <div class="row description-row">
            <div class="description header">{{ minDateDescription }}</div>
            <div class="w-100"></div>
            <div class="description value">{{ minDate | date: 'dd/MM/yyyy' }}</div>
          </div>
          <div class="row description-row">
            <div class="description header">Warranty status</div>
            <div class="w-100"></div>
            <div class="description value extended-vehicle-details-page"
              [ngClass]="isInWarranty ? 'value-warranty':'out-of-warranty'">{{warrantyStatusMessage}}</div>
          </div>
        </div>
        <div class="vertical-line"></div>
        <div style="width:100%">
          <div class="row description-row">
            <div class="description header">Age of vehicle</div>
            <div class="w-100"></div>
            <div class="description value">{{ ageInYears }} years old</div>
          </div>
          <div class="row description-row">
            <div class="description header">Manufacturer max warranty age</div>
            <div class="w-100"></div>
            <div class="description value extended-vehicle-details-page">{{ manufacturerWarrantyAge }} years</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isInWarranty && !isVehicleDetailsCollapsed" class="row mileage-between-services">
    <div class="col-6">
      <label>Mileage between services <span class='blue-font'>*</span></label>
      <div class='between-services-field'>
        <app-number-input [group]="mileageBetweenServices" (change)="numberValueChanged($event); checkFirstNotDueStatus();">
        </app-number-input>
      </div>
    </div>
    <div class="col-6">
      <label>Months between services <span class='blue-font'>*</span></label>
      <div class='between-services-field'>
        <app-number-input [group]="monthsBetweenServices" (change)="numberValueChanged($event); checkFirstNotDueStatus();">
        </app-number-input>
      </div>
    </div>
    <div class='modal-small-text'><span class='blue-font'>* = </span> Recommended amounts between
      services</div>
  </div>
</div>
<div class='service-records-container'>
  <div class='bold h2 service-records-container__margins'>
    What service records do you have? (Select as appropriate)
  </div>
  <div>
    <div *ngIf="showFirstServiceNotDue">
      <button type="button" class="button check-box-design collapsible-checkbox-button"
        [class.active]="isFirstServiceNotDue" (click)="toggleFirstNotDue()"></button>
      <span class='service-records-container__warranty-and-services__span'>First service not due</span>
    </div>
    <div>
      <button type="button" class="button check-box-design collapsible-checkbox-button"
        [class.active]="isNoValidServiceRecords" (click)="toggleNoValidServiceRecords()"></button>
      <span class='service-records-container__warranty-and-services__span'>No valid service records</span>
    </div>
    <div>
      <button #electronicServiceHistoryElement type="button" class="button check-box-design collapsible-checkbox-button"
        [class.active]="isElectronicServiceHistory" (click)="toggleElectronicServiceRecords()"></button>
      <span class='service-records-container__warranty-and-services__span'>Electronic service history</span>
    </div>
    <div #collapse="ngbCollapse" [ngbCollapse]="!isElectronicServiceHistory" (ngbCollapseChange)="isElectronicServiceHistory = $event">
      <div class='service-records-container__warranty-and-services__entry'>
        <div class='service-records-container__warranty-and-services__size-and-positioning'>
          <label class='label label-no-service-records'>Number of Records</label>
          <app-number-input [group]="electronicServiceHistory" (change)="numberValueChanged($event)">
          </app-number-input>
        </div>
        <div *ngIf="!isInWarranty"
          class='service-records-container__warranty-and-services__size-and-positioning date-input__margins-and-width'>
          <label class='label label-no-service-records'>Last Service Date</label>
          <app-date-input [group]="electronicServiceHistoryDate" [minDate]="minDate" [maxDate]="maxDate"
            (change)="valueChanged($event)">
          </app-date-input>
        </div>
        <div *ngIf="!isInWarranty"
          class='service-records-container__warranty-and-services__size-and-positioning date-input__margins-and-width'>
          <label class='label label-no-service-records'>Last Service Mileage</label>
          <app-number-input [group]="electronicServiceHistoryMileage" (change)="numberValueChanged($event)">
          </app-number-input>
        </div>
      </div>
    </div>
    <div>
      <button #serviceBookElement type="button" class="button check-box-design collapsible-checkbox-button"
        [class.active]="isServiceBook" (click)="toggleServiceBookRecords()"></button>
      <span class='service-records-container__warranty-and-services__span'>Service book</span>
    </div>
    <div #collapse="ngbCollapse" [ngbCollapse]="!isServiceBook" (ngbCollapseChange)="isServiceBook = $event">
      <div class='service-records-container__warranty-and-services__entry'>
        <div class='service-records-container__warranty-and-services__size-and-positioning'>
          <label class='label label-no-service-records'>Number of Records</label>
          <app-number-input [group]="serviceBook" (change)="numberValueChanged($event)">
          </app-number-input>
        </div>
        <div *ngIf="!isInWarranty"
          class='service-records-container__warranty-and-services__size-and-positioning date-input__margins-and-width'>
          <label class='label label-no-service-records'>Last Service Date</label>
          <app-date-input [group]="serviceBookDate" [minDate]="minDate" [maxDate]="maxDate"
            (change)="valueChanged($event)">
          </app-date-input>
        </div>
        <div *ngIf="!isInWarranty"
          class='service-records-container__warranty-and-services__size-and-positioning date-input__margins-and-width'>
          <label class='label label-no-service-records'>Last Service Mileage</label>
          <app-number-input [group]="serviceBookMileage" (change)="numberValueChanged($event)">
          </app-number-input>
        </div>
      </div>
    </div>
    <div>
      <button #serviceInvoiceElement type="button" class="button check-box-design collapsible-checkbox-button"
        [class.active]="isServiceInvoice" (click)="toggleServiceInvoiceRecords()"></button>
      <span class='service-records-container__warranty-and-services__span'>Service invoice</span>
    </div>
    <div #collapse="ngbCollapse" [ngbCollapse]="!isServiceInvoice" (ngbCollapseChange)="isServiceInvoice = $event">
      <div class='service-records-container__warranty-and-services__entry'>
        <div class='service-records-container__warranty-and-services__size-and-positioning'>
          <label for="serviceInvoice" class='label label-no-service-records'>Number of Records</label>
          <app-number-input [group]="serviceInvoice" (change)="numberValueChanged($event)">
          </app-number-input>
        </div>
        <div *ngIf="!isInWarranty"
          class='service-records-container__warranty-and-services__size-and-positioning date-input__margins-and-width'>
          <label class='label label-no-service-records'>Last Service Date</label>
          <app-date-input [group]="serviceInvoiceDate" [minDate]="minDate" [maxDate]="maxDate"
            (change)="valueChanged($event)">
          </app-date-input>
        </div>
        <div *ngIf="!isInWarranty"
          class='service-records-container__warranty-and-services__size-and-positioning date-input__margins-and-width'>
          <label class='label label-no-service-records'>Last Service Mileage</label>
          <app-number-input [group]="serviceInvoiceMileage" (change)="numberValueChanged($event)">
          </app-number-input>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="requiresRunningInService" class="running-in-container">
  <app-list-input [group]="runningInService" [options]="yesNoOptions" [boldLabel]="true" (change)="valueChanged($event)">
  </app-list-input>
</div>