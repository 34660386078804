import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponentDirective } from '../base/base.component';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { LookupService } from '../services/lookup.service';
import { LoadingService } from '../services/loading.service';
import { EMPTY, Subject } from 'rxjs';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { BankDetails } from './models/bank-details.model';
import { PathFinderService } from '../services/path-finder.service';
import { AnswerValue } from '../purchase/models/answer-value.model';
import { EventsService } from '../services/events.service';
import { Question } from '../user-input/models/question.model';
import { BankVerificationService } from '../services/bank-verification.service';

@Component({
  selector: 'app-bank-verification',
  templateUrl: './bank-verification.component.html',
  styleUrls: ['./bank-verification.component.scss']
})

export class BankVerificationComponent extends BaseComponentDirective implements OnInit {
  @Input() group: UntypedFormGroup;
  @Input() quoteStateId: number;
  @Input() updatedAnswers: Array<Question>;
  @Input() questionForm: UntypedFormGroup;
  @Output() valueChanged = new EventEmitter<UntypedFormGroup>();

  bankDetails: BankDetails;
  billingDetails: Array<Question> = [];
  loadingError$ = new Subject<boolean>();
  isCorrect: boolean;
  isBankVerificationApiAvailable: boolean;
  needsVerification: boolean;
  loading: boolean;
  accountHolderValue: string | AnswerValue;
  bankVerificationService: UntypedFormGroup;
  sortCode: UntypedFormGroup;
  accountNumber: UntypedFormGroup;
  accountHolderName: UntypedFormGroup;
  rollNumber: UntypedFormGroup;
  
  constructor(private lookupService: LookupService,
    private notifications: NotificationService,
    private pathFinder: PathFinderService,
    private loadingService: LoadingService,
    private eventsService: EventsService,
    private bankService: BankVerificationService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.needsVerification = true;
    this.createBankDetailsFormGroup();
    this.eventsService.valueChanged.subscribe(() => {
      this.detailsChanged();
    });
    this.checkSaveAndQuit();
  }

  getAnswer(param: string): string {
    return this.pathFinder.getAnswerValue(param, this.updatedAnswers);
  }

  eraseCurrentDetails() {
    this.sortCode.patchValue({ value: null });
    this.accountNumber.patchValue({ value: null });
    this.rollNumber.patchValue({ value: null });
    this.accountHolderName.patchValue({ value: null });
  }

  verifyBankDetails() {
    if (!this.sortCode.controls.value.value || !this.accountNumber.controls.value.value || !this.accountHolderName.controls.value.value) {
      this.isCorrect = false;
      this.needsVerification = false;
      return;
    }
    this.lookupService.getSettings$('BankVerification').pipe(
      tap(result => {
        if (result['IsBankVerificationApiAvailable'] === 'true') {
          this.bankService.isBankVerificationServiceAvailable.emit(true);
          this.lookupService.verifyBankDetails$(this.accountNumber.controls.value.value, this.sortCode.controls.value.value, this.quoteStateId).pipe(
            tap(details => {
              this.notifications.clearToast();
              this.bankDetails = details;
              this.isCorrect = this.bankDetails.isCorrect;

              if (this.isCorrect) {
                this.getBankDetails();
                this.group.controls.value.setValue(true);
                this.group.value.value = this.bankDetails.bank;
                this.pathFinder.appendAnswer('FastPaymentAllowedByBank', details.fasterPaymentsSupported ? 'True' : 'False');
                this.needsVerification = false;
                this.loading = false;
                this.loadingService.loading.emit(this.loading);
              } else {
                this.needsVerification = false;
                this.loading = false;
                this.loadingService.loading.emit(this.loading);
              }
            }),
            catchError(err => {
              this.loadingError$.next(true);
              this.notifications.dangerToast('An error occurred while trying to verify bank details.', err);
              return EMPTY;
            }), takeUntil(this.componentDestroyed)
          ).subscribe();
        } else {
          this.bankService.isBankVerificationServiceAvailable.emit(false);
          this.group.patchValue({ value: 'No service' });
          this.isBankVerificationApiAvailable = false;
        }
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
      }),
      catchError(err => {
        this.notifications.dangerToast('An error occurred while checking bank verification service availability.', err);
        return EMPTY;
      })
    ).subscribe(() => {
    });
  }

  createBankDetailsFormGroup() {
    if (this.questionForm) {
        const controlArray = this.questionForm.get('items') as UntypedFormArray;
        this.bankVerificationService = controlArray.controls[0] as UntypedFormGroup;
        this.sortCode = controlArray.controls[1] as UntypedFormGroup;
        this.accountNumber = controlArray.controls[2] as UntypedFormGroup;
        this.rollNumber = controlArray.controls[3] as UntypedFormGroup;
        this.accountHolderName = controlArray.controls[4] as UntypedFormGroup;
    }
    this.loading = false;
    this.loadingService.loading.emit(this.loading);
}

  registerTransitionEvent() {
    this.pathFinder.registerTransitionEvent((onComplete => {
      if (this.isCorrect) {
        onComplete(true);
      } else {
          // make text red + message
        onComplete(false);
      }
    }));
  }

  get verifyBankDetailsDisabled() {
    if (this.group && this.isBankVerificationApiAvailable === false) {
      return true;
    }
    if (this.needsVerification) {
      return false;
    }
    return true;
  }

  detailsChanged() {
    this.group.patchValue({ value: null });
    this.needsVerification = true;
    this.billingDetails = [];
  }

  private getBankDetails() {
    if (this.billingDetails.length > 0) {
      return;
    }
    const bankNameObject = new Question(this.group.value.question, this.bankDetails.bank);
    const bankAddressObject = new Question('Bank Address', `${this.bankDetails.contactAddressLine1}, ${this.bankDetails.contactAddressLine2}`);

    this.billingDetails.push(bankNameObject);
    this.billingDetails.push(bankAddressObject);
  }

  checkSaveAndQuit() {
    if (this.getAnswer('BankName') === '' || this.getAnswer('BankName') === 'No service') {
      this.eraseCurrentDetails();
    }
  }
}
