import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { DateRangeCheck } from '../models/date-range-check';

@Injectable({
  providedIn: 'root'
})
export class CapIdDateRangeService {

  constructor(private http: HttpClient) { }

  checkForDateDiscrepancy$(firstRegistered: Date, HPIFirstRegistered: Date): Observable<boolean> {
    const url = `${environment.ppxPrivateApi}api/CapIdDateRange/dateRangeCheck/?firstRegistered=${firstRegistered}&HPIFirstRegistered=${HPIFirstRegistered}`;
    return this.http.get<boolean>(url).pipe(
      catchError((err) => throwError(err)));
  }

  checkForDateDiscrepancyByYearAndLetter$(firstRegistered: string, HPIFirstRegistered: Date): Observable<DateRangeCheck> {
    const url = `${environment.ppxPrivateApi}api/CapIdDateRange/dateRangeCheckYearAndLetter/?firstRegistered=${firstRegistered}&HPIFirstRegistered=${HPIFirstRegistered}`;
    return this.http.get<DateRangeCheck>(url).pipe(
      catchError((err) => throwError(err)));
  }
}
