import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../core/auth-service';
import { NotificationService } from '../../services/notification.service';
import { TaskService } from '../../services/task.service';
import { TaskSortTypes } from '../enums/task-sort-types';
import { TaskTypes } from '../enums/task-types';
import { UserTask } from '../models/user-task.model';
import { PagerService } from '../../pager/services/pager.service';
import { ConfirmDialogService } from '../../confirm-dialog/services/confirm-dialog.service';
import { TaskViewTypes } from '../enums/task-view-types';
import { TaskCounterService } from '../../services/task-counter.service';
import { CounterTypes } from '../../enums/counter-types';

@Component({
  selector: 'app-task-list-retrieve',
  templateUrl: './task-list-retrieve.component.html',
  styleUrls: ['./task-list-retrieve.component.scss']
})
export class TaskListRetrieveComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vrm: string;
  @Output() setView = new EventEmitter<TaskViewTypes>();

  loading: boolean;
  isEditing: boolean;
  username: string;
  userCanAuthorise: boolean;
  currentTime: number;

  userTasks: Array<UserTask>;
  filteredTasks: Array<UserTask>;
  pagedTasks: Array<UserTask>;

  sortOrder: string;
  public sortTypes = Object.values(TaskSortTypes);
  sortOpened: boolean;

  pageSize = 7;
  currentPage = 1;
  pageCount: number;

  destroy$ = new Subject();

  branchTasksImplemented: false;

  constructor(
    private taskService: TaskService,
    private authService: AuthService,
    private notifications: NotificationService,
    private pagerService: PagerService,
    private confirmService: ConfirmDialogService,
    private counterService: TaskCounterService
    ) { }

  ngOnInit(): void {
    this.loading = true;
    this.username = this.authService.getUser()?.username.split('@')[0].toLowerCase();
    this.currentTime = Date.now();
    this.sortOrder = TaskSortTypes.TaskType;
    this.retrieveTasks();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.retrieveTasks();
  }

  retrieveTasks() {
    this.loading = true;
    this.taskService.getTasksForVrm$(this.vrm).pipe(
      tap(result => {
        this.userTasks = result;
        this.filterTasks();
        this.loading = false;
        this.counterService.update(CounterTypes.USERTASK);
      }),
      catchError(err => {
        this.notifications.dangerToast(`Failed to retrieve the tasks for '${this.vrm}'`, err);
        this.loading = false;
        return EMPTY;
      }),
      take(1)
    ).subscribe();
    this.pagerService.getPageNumber().pipe(
      takeUntil(this.destroy$),
      catchError(() => {
        return EMPTY;
      })
    )
      .subscribe((result) => {
        if (this.filteredTasks) {
          this.onPageChange(result);
        }
      });
  }

  filterTasks() {
    this.filteredTasks = this.userTasks.filter((t) => t.assignedTo !== this.username);
    this.filteredTasks = this.sortTasks(this.filteredTasks);
    const taskCount = this.filteredTasks.length;
    this.pageCount = Math.ceil(taskCount / this.pageSize);
    if (this.currentPage > this.pageCount) {
      this.currentPage = 1;
    }
    this.pageTasks();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  pageTasks() {
    if (this.pageCount === 1) {
      this.pagedTasks = this.filteredTasks;
    } else {
      const start = (this.currentPage * this.pageSize) - this.pageSize;
      if (this.currentPage === this.pageCount) {
        this.pagedTasks = this.filteredTasks.slice(start);
      } else {
        const end = this.currentPage * this.pageSize;
        this.pagedTasks = this.filteredTasks.slice(start, end);
      }
    }
  }

  sortTasks(tasks: Array<UserTask>): Array<UserTask> {
    if (this.sortOrder === TaskSortTypes.AppointmentDate) {
      tasks.sort((a, b) => this.compareByAppointmentDate(a, b));
    } else if (this.sortOrder === TaskSortTypes.TaskType) {
      tasks.sort((a, b) => this.compareByTaskType(a, b));
    }
    return tasks;
  }

  compareByAppointmentDate(a: UserTask, b: UserTask): number {
    if (a.appointmentDate > b.appointmentDate) {
      return 1;
    } else if (b.appointmentDate > a.appointmentDate) {
      return -1;
    } else {
      return 0;
    }
  }

  compareByTaskType(a: UserTask, b: UserTask): number {
    if (b.sortOrder < a.sortOrder) { return 1; }
    if (b.sortOrder > a.sortOrder) { return -1; } else {
      if (b.appointmentDate < a.appointmentDate) { return 1; }
      if (b.appointmentDate > a.appointmentDate) { return -1; }
      return 0;
    }
  }

  onSortChange($event) {
    this.sortOrder = $event;
    this.sortTasks(this.filteredTasks);
    this.pageTasks();
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.pageTasks();
  }

  toggleSort() {
    this.sortOpened = !this.sortOpened;
  }


  getTaskClass(task: UserTask): string {
    if (task.taskType === TaskTypes.ConfirmCall) {
      return 'confirm-call';
    } else if (task.taskType === TaskTypes.NoShow) {
      return 'no-show';
    } else if (task.taskType === TaskTypes.AppointmentNotPurchased) {
      return 'not-purchased';
    } else if (task.taskType === TaskTypes.CustomerContact) {
      return 'customer-contact';
    } else {
      return 'other';
    }
  }

  async claimTask(task: UserTask) {
    const confirm = await this.confirmService.showYesNoConfirmationAsync('Are you sure you want to claim this task?');
    if (confirm) {
      await this.taskService.reassign$(task.taskId, this.username).toPromise();
      if (this.filteredTasks.length === 1) {
        this.setView.emit(TaskViewTypes.User);
      } else {
        this.retrieveTasks();
      }
    }
  }
}
