import { Component, Input, OnInit } from '@angular/core';
import { ErrorMessageModel } from '../models/error-message-model';
import { DiaryViewErrorService } from '../services/diary-view-error.service';

@Component({
  selector: 'app-diary-view-error',
  templateUrl: './diary-view-error.component.html',
  styleUrls: ['./diary-view-error.component.scss']
})
export class DiaryViewErrorComponent implements OnInit {
 @Input() isNotInBranch: boolean;
  errorMessageModel: ErrorMessageModel;

  constructor(private diaryViewErrorMessageService: DiaryViewErrorService) { }

  ngOnInit(): void {
    if (this.isNotInBranch) {
      this.createNotInBranchError();
    }
  }

  createNotInBranchError() {
    const header = '';
    const body = 'You don’t seem to be assigned to a branch, please contact your manager.';
    this.errorMessageModel = new ErrorMessageModel(header, body);
    this.diaryViewErrorMessageService.buildErrorMessage(this.errorMessageModel);
  }
}
