<div class="modal-header">
  <img class="modal-close" (click)="dismissModal()" alt="">
</div>
<div class="modal-body">
  <canvas #sigPad width="958" height="365" (mousedown)="onStartDraw($event)" (touchstart)="onStartDraw($event)"
    (mousemove)="onDrawMove($event)" (touchmove)="onDrawMove($event)" class="signature-box"></canvas>
  <div class="signature-text" *ngIf="hasText"><span class="green-text">{{ disclaimerPrefix }} </span>{{ disclaimer }}</div>
  <span class="signature-line"></span>
<div class="button-panel">
  <button class="btn signature-btn button_clear" (click)="clearSignature()">Clear
    <img src="../../assets/icons/buttons/cancel-grey.png" alt="">
  </button>
  <button class="btn signature-btn button_green" [disabled]="!hasDrawn" (click)="acceptSignature()" ngbAutofocus>Confirm
    <img *ngIf="hasDrawn" src="../../assets/icons/buttons/white-arrow-right.png" alt="">
    <img *ngIf="!hasDrawn" src="../../assets/icons/buttons/arrowRight-inactive.png" alt="">
  </button>
</div>
