export class CompletedState {
    stateName: string;
    canGoBack: boolean;
    purchaseSection: number;

    constructor(stateName: string, canGoBack: boolean, purchaseSection: number) {
        this.stateName = stateName;
        this.canGoBack = canGoBack;
        this.purchaseSection = purchaseSection;
    }
}
