<div *ngIf="hasFinance" [hidden]="loading">
  <div>
    <h4>We've spotted there could be some outstanding finance on your vehicle</h4>
    <hr />
    <form [formGroup]="outstandingFinanceAgreements">
      <table class="outstandingFinanceTable obfuscated-playback" formArrayName="financeAgreements"
        aria-label="Finance agreements">
        <thead>
          <tr>
            <th>Finance company</th>
            <th>Agreement number</th>
            <th>Settlement expiration</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of outstandingFinanceAgreements.value.financeAgreements; let i = index; trackBy: trackByFn"
            [formGroupName]="i">
            <td>
              <div class="input-group">
                <app-searchable-dropdown-input style="width: 100%" [financePage]=true [isInline]=true [group]="dropdownValue"
                  [options]="items" [small]="false" (change)="updateAgreement(i)" [item]="item"> </app-searchable-dropdown-input>
                  <input type="text" class="form-control hidden" formControlName="financeCompanyName" [ngModel]="item.financeCompanyName">
              </div>
            </td>
            <td>
              <input type="text" class="form-control" formControlName="agreementNumber" (change)="updateFinanceAgreement(item)"
                placeholder="Agreement Number" [ngModel]="item.agreementNumber">
            </td>
            <td>
              <div class="input-group">
                <input type="date" class="form-control"  [ngModel]="item.expiryDate | date:'yyyy-MM-dd'" min="{{ minDate }}"
                  (change)="updateFinanceAgreement(item)" formControlName="expiryDate">
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="number" class="form-control" name="financeAmount" placeholder="Amount"
                  (change)="updateFinanceAgreement(item)" formControlName="amount"(keypress)="isPositiveAndHasFixedPrecision($event)">
              </div>
            </td>
            <td>
              <button class="btn btn-sm btn-danger" placement="top" (click)="removeFinance(i, item)">
                <span class="fa fa-times-circle"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
<button [hidden]="loading || !hasFinance" class="btn btn-primary my-1" (click)="addNewLine()">
  Add new line
</button>
<div [hidden]="hasFinance || loading" *ngIf="outstandingFinanceAgreements">
  <h4>No outstanding finance found for this vehicle</h4>
  <button [hidden]="loading || hasFinance" class="btn btn-primary my-1" (click)="addNewLine()">
    Add new line
  </button>
</div>
