import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { D2Content } from '../d2-form/models/d2Content.model';
import { Signature } from '../invoice/models/signature.model';

@Injectable({
  providedIn: 'root'
})
export class D2Service {

  constructor(private http: HttpClient) { }

  getD2Details$(quoteStateId: number): Observable<D2Content> {
    return this.http.get<D2Content>(`${environment.ppxPrivateApi}api/D2/${quoteStateId}`).pipe(
      catchError(err => throwError(err))
    );
  }

  generateD2$(quoteStateId: number, signatureImage: Signature): Observable<string> {
    return this.http.post<string>(`${environment.ppxPrivateApi}api/D2/${quoteStateId}`,
      signatureImage,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        catchError(err => throwError(err))
      );
  }
}
