<div *ngFor="let record of records">
  <div class="grid">
    <div class="child header">Interest Date</div>
    <div class="child header">Police Force</div>
    <div class="child header">Contact Number</div>
    <div class="child header">Recorded Against</div>
    <div class="child header">Vehicle Description</div>

    <div class="child">{{ record.interestDate | blankDate }}</div>
    <div class="child">{{ record.policeForce }}</div>
    <div class="child">{{ record.contactNumber }}</div>
    <div class="child">{{ record.recordedAgainst }}</div>
    <div class="child">{{ record.vehicleDescription }}</div>
  </div>
</div>