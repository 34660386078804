import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {
  private previousUrl: string | undefined;
  private id: number;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.previousUrl = this.router.url;
      }
    });
  }

  getPreviousUrl(): string | undefined {
    return this.previousUrl;
  }

  setId(id: number) {
    if (id === null) {
      localStorage.removeItem('id');
    } else {
      localStorage.setItem('id', id.toString());
    }
  }

  getId() {
    return Number(localStorage.getItem('id'));
  }
}
