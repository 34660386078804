import { Question } from './question.model';

export class StateAnswers {
    stateName: string;
    nextStateName: string;
    valuationRequired: boolean;
    nextStateRequiredImages: number;
    answers: Array<Question>;
    isSuspendingPurchase: boolean;
    vCheckRequired: boolean;
    purchaseSection: number; 
}
