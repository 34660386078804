import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleListValue } from './models/vehicle-list-value.model';
import { Valuation } from './models/valuation.model';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { ValuationService } from '../services/valuation.service';
import { NotificationService } from '../services/notification.service';
import { DashMileageModalComponent } from '../dash-mileage-modal/dash-mileage-modal.component';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { WalkInLookupData } from './models/walk-in-lookup-data.model';
import { SharedService } from '../core/shared.service';

@Component({
  selector: 'app-walk-in',
  templateUrl: './walk-in.component.html',
  styleUrls: ['./walk-in.component.scss'],
})
export class WalkInComponent {
  @Input() isStartPurchase: boolean;
  @Input() creatingNewValuation = false;
  @Input() vrm: string;
  @Input() vehicleDescription: VehicleListValue;
  @Input() mileage: number;
  @Input() isQuickQuote: boolean;
  @Input() hasDescription: boolean;
  @Input() vehicleImage: string;

  isLoading: boolean;
  isVRMFound: boolean;
  multipleVehicleDescriptions: Array<VehicleListValue>;
  defaultVehicleImageUrl: string;
  hasPreviousValuations: boolean;
  selectedPreviousValuation: Valuation;
  pricePromise: string;
  hasLookupError: boolean;
  isCreatingPreviousValuationAppointment = false;
  bookingAppointment = false;
  unableToValue = false;
  isAltVrm = false;
  placeholder: string;
  altVrm: string;
  hasMissingVrmRelationship: boolean;
  hasSalvage: boolean;
  allowManualLookup: boolean;
  highValueRequiresAppointment: boolean;
  userData: WalkInLookupData;

  constructor(
    private modalService: NgbModal,
    private valuationService: ValuationService,
    private confirmDialogService: ConfirmDialogService,
    private sharedService: SharedService,
    private notifications: NotificationService
  ) {}

  setSelectedPreviousValuation(selectedPreviousValuation: Valuation) {
    this.selectedPreviousValuation = selectedPreviousValuation;
  }

  retrieveValuation() {
    this.isCreatingPreviousValuationAppointment = true;

    const lookupData = new WalkInLookupData(0, null, this.vrm, this.mileage, null, null, null, null,
      null, null, null, null);
    lookupData.vehicleImageUrl = this.vehicleImage;
    lookupData.colour = this.vehicleDescription.Colour;
    lookupData.altVrm = this.altVrm;
    lookupData.quoteId = this.selectedPreviousValuation.quoteId;
    lookupData.quoteGuid = this.selectedPreviousValuation.quoteGuid;
    lookupData.valuationGuid = this.selectedPreviousValuation.valuationGuid;
    lookupData.isManualLookup = this.sharedService.getIsManualLookup();

    this.valuationService.startPreviousValuationWalkin$(lookupData).pipe(
      tap(result => {
        this.dismissModal();
        const modalRef = this.modalService.open(DashMileageModalComponent, {
          keyboard: false,
          backdrop: 'static',
          centered: true,
          size: 'md'
        });
        modalRef.componentInstance.vrm = this.vrm;
        modalRef.componentInstance.quoteStateId = result.quoteStateId;
        modalRef.result.then(() => { }, () => { });
      }),
      catchError(err => {
        this.isCreatingPreviousValuationAppointment = false;
        this.notifications.dangerToast('Failed to start purchase', err);
        return EMPTY;
      }), take(1)).subscribe();
  }

  newValuation() {
    this.creatingNewValuation = true;
  }

  setPricePromise(pricePromise: string) {
    this.pricePromise = pricePromise;
  }

  manualLookup(allowManualLookup) {
    this.allowManualLookup = allowManualLookup;
  }

  setVehicleDescriptions(vehicleDescriptions: Array<VehicleListValue>) {
    this.hasDescription = false;
    this.vehicleDescription = null;
    this.multipleVehicleDescriptions = null;

    this.isVRMFound = vehicleDescriptions !== null;
    if (vehicleDescriptions) {
      this.hasDescription = true;
      this.vehicleImage = vehicleDescriptions[0].ImageUrl;
      this.defaultVehicleImageUrl = vehicleDescriptions[0].DefaultVehicleImageUrl;
      this.hasSalvage = vehicleDescriptions[0].HasSalvage;
      if (vehicleDescriptions.length === 1) {
        this.vehicleDescription = vehicleDescriptions[0];
      } else if (vehicleDescriptions.length > 1) {
        this.multipleVehicleDescriptions = vehicleDescriptions;
        this.hasPreviousValuations = false;
      }
    }
    this.isLoading = false;
  }

  setSelectedDerivative(selectedDerivative: VehicleListValue) {
    this.hasDescription = true;
    this.multipleVehicleDescriptions = null;
    this.vehicleImage = selectedDerivative.ImageUrl;
    this.defaultVehicleImageUrl = selectedDerivative.DefaultVehicleImageUrl;
    this.vehicleDescription = selectedDerivative;
    this.hasLookupError = false;
    this.hasMissingVrmRelationship = false;
    this.highValueRequiresAppointment = false;
  }

  setVrm(vrm: string) {
    if (this.isAltVrm) {
      this.altVrm = vrm;
    } else {
      this.vrm = vrm;
    }
  }

  setHasLookupError(hasLookupError: boolean) {
    if (hasLookupError) {
      this.hasLookupError = hasLookupError;
      this.isLoading = false;
    }
  }

  setHasMissingVrmRelationship(hasMissingVrmRelationship: boolean) {
    this.hasMissingVrmRelationship = hasMissingVrmRelationship;
    this.hasLookupError = hasMissingVrmRelationship;
    this.isLoading = false;
  }

  setHighValueRequiresAppointment(lookupData: WalkInLookupData) {
    this.highValueRequiresAppointment = true;
    this.hasLookupError = true;
    this.userData = lookupData;
    this.isLoading = false;
  }

  setHasPreviousValuations(hasPreviousValuations: boolean) {
    this.hasPreviousValuations = hasPreviousValuations;
  }

  setIsLoadingForVrmSearch(isLoading: boolean) {
    if (isLoading === true) {
      this.isLoading = isLoading;
      this.creatingNewValuation = false;
    }
    if (this.isVRMFound && isLoading) {
      this.isVRMFound = false;
      this.selectedPreviousValuation = null;
    }
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  tryAgain(isAltVrm: boolean) {
    this.isAltVrm = isAltVrm;
    if (!isAltVrm) {
      this.vrm = null;
    }
    this.hasLookupError = false;
    this.hasMissingVrmRelationship = false;
    this.highValueRequiresAppointment = false;
    this.unableToValue = false;
    this.isVRMFound = false;
    this.hasDescription = false;
    this.vehicleDescription = null;
    this.multipleVehicleDescriptions = null;
    this.creatingNewValuation = false;
  }

  confirmCloseModalDialog() {
    this.confirmDialogService.confirmCancellation().pipe(take(1)).subscribe(() => {
      this.modalService.dismissAll();
    });
  }

  dismissModal() {
    if (this.creatingNewValuation && !this.isStartPurchase && !this.unableToValue) {
      this.confirmCloseModalDialog();
      return;
    }
    this.modalService.dismissAll();
  }

  setBookingAppointment(isBookingAppointment: boolean) {
    this.bookingAppointment = isBookingAppointment;
  }

  setRequiresManualValuation(requiresManualValuation: boolean) {
    this.bookingAppointment = false;
    this.hasLookupError = requiresManualValuation;
    this.unableToValue = requiresManualValuation;
  }

  get bookId() {
    if (this.vehicleDescription?.CapCode?.endsWith('L')) {
      return 2;
    } else {
      return 1;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  switchToAppointment() {
    this.hasLookupError = false;
    this.hasMissingVrmRelationship = false;
    this.highValueRequiresAppointment = false;
    this.isStartPurchase = false;
  }
}
