export class VehicleDetails {
    constructor(
        public manufacturer: string,
        public colour: string,
        public model: string,
        public derivative: string,
        public engineSize: string,
        public bookId: number,
        public capId: number,
        public imageUrl: string,
        public fuelType: string,
        public hasSalvage: boolean,
        public firstRegistered: Date,
        public userEnteredFirstRegisteredDate: string) {
        }
}
