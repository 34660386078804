import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotPurchasedReason } from '../../not-purchased-reasons/models/not-purchased-reason.model';
import { LookupService } from '../../services/lookup.service';
import { NotificationService } from '../../services/notification.service';
import { CancellationReasonsComponent } from '../cancellation-reasons.component';

@Injectable({
  providedIn: 'root'
})
export class CancellationReasonsService {
  subReasonsList: NotPurchasedReason[];

  constructor(private lookupService: LookupService, private modalService: NgbModal, private notificationsService: NotificationService) {
  }

  openCancellationReasonsComponent(): NgbModalRef {
    return this.modalService.open(CancellationReasonsComponent, {
      keyboard: false,
      centered: true,
      windowClass: 'margin-left-for-cancellation-reasons-modal'
    });
  }

  getNotPurchasedReasons(): Observable<NotPurchasedReason[]> {
    return this.lookupService.getNotPurchasedReasons$()
      .pipe(
        tap(valueReceived => {
          this.subReasonsList = valueReceived[1].subReasons;
        }),
        catchError(err => {
          this.notificationsService.dangerToast('An error occurred while trying to receive the cancellation reason && sub reason list value. Please try again', err);
          return EMPTY;
        }));
  }
}
