import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '../../../custom-validators/custom-validators';
import { AddressSummary } from '../../../models/address-summary';

@Component({
  selector: 'app-manual-entry',
  templateUrl: './manual-entry.component.html',
  styleUrls: ['./manual-entry.component.scss']
})
export class ManualEntryComponent implements OnInit {

  addressForm: UntypedFormGroup;

  includeCompanyName: boolean;

  constructor(private modal: NgbActiveModal, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      city: [''],
      county: [''],
      postcode: ['', { validators: Validators.compose([Validators.required, Validators.maxLength(8)]),
        asyncValidators: [CustomValidators.validateRegex(CustomValidators.postcodeRegex)] }]
    });

    if (this.includeCompanyName) {
      this.addressForm.addControl('company', new UntypedFormControl('', Validators.required));
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }

  confirmAddress() {
    const address = new AddressSummary();
    if (this.includeCompanyName) {
      address.company = this.addressForm.get('company').value;
    }
    address.line1 = this.addressForm.get('address1').value;
    address.line2 = this.addressForm.get('address2').value;
    address.line3 = this.addressForm.get('address3').value;
    address.city = this.addressForm.get('city').value;
    address.province = this.addressForm.get('county').value;
    address.postalCode = this.addressForm.get('postcode').value.toUpperCase();
    this.modal.close(address);
  }
}
