import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppointmentValuationDetailsComponent } from '../appointment-valuation-details.component';
import { AppointmentValuationDetails } from '../models/appointment-valuation-details';
import { ValuationService } from '../../services/valuation.service';
import { CustomErrorHandler } from '../../custom-error-handler';

@Injectable({ providedIn: 'root' })
export class AppointmentValuationDetailsService {

    constructor(
      private modalService: NgbModal,
      private valuationService: ValuationService,
      private errorHandler: CustomErrorHandler
      ) { }

    async showAppointmentDetails(appointmentValuationDetails: AppointmentValuationDetails) {

      if (appointmentValuationDetails.isPurchaseFinalised) {
        try {
          const valuationSummary = await this.valuationService.getValuation$(appointmentValuationDetails.quoteStateId).toPromise();
          appointmentValuationDetails.customerPayment = valuationSummary.customerPaymentAmount;
        } catch (error) {
          // Don't redirect out of the purchase journey
          this.errorHandler.handleErrorNoRedirect(error);
          appointmentValuationDetails.customerPayment = undefined;
        }
      }

    // open modal
        const modalRef = this.modalService.open(AppointmentValuationDetailsComponent, {
            keyboard: true,
            backdropClass: 'modal-backdrop-appointment-details',
            modalDialogClass: 'modal-appointment-details'
        });

        modalRef.componentInstance.appointmentValuationDetails = appointmentValuationDetails;
        modalRef.result.then(result => {
            if (result) {
                modalRef.close();
            }
        }, () => {
        });
        return from(modalRef.result).pipe(catchError(() => EMPTY));
    }
}
