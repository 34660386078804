import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from '../../core/shared.service';

@Component({
  selector: 'app-registration-plate',
  templateUrl: './registration-plate.component.html',
  styleUrls: ['./registration-plate.component.scss'],
})
export class RegistrationPlateComponent {
  @Input() vrm: string;
  @Input() isStartPurchase: boolean;
  @Input() isPurchaseMenu: boolean = false;
  @Input() isHpiModal: boolean;
  @Output() plateSelected = new EventEmitter<boolean>();

  constructor(private sharedService: SharedService) {
  }

  selectPlate(vrm: string) {
    this.sharedService.selectedPlate.emit(vrm);
    this.plateSelected.emit(true);
  }
}
