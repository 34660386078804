import { environment as defaultEnvironment } from './environment';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { LogLevel } from '@sentry/types';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor() {
    console.log('AppInitService.constructor() called');
  }

  initProd() {
    return new Promise<void>((resolve) => {
      console.log('AppInitService.init() called');
      const xmlhttp = new XMLHttpRequest(),
        method = 'GET',
        url = './assets/environments/environment.prod.json';
      xmlhttp.open(method, url, false);
      xmlhttp.onload = function () {
        if (xmlhttp.status === 200) {
          console.log('AppInitService Resolved Successfully');
          const x = JSON.parse(xmlhttp.responseText);
          defaultEnvironment.ppxPrivateApi = x.ppxPrivateApi;
          defaultEnvironment.sentry.environment = x.sentry.environment;
          console.log(`AppInitService (xmlhttp.onload) default sentry env set to ${defaultEnvironment.sentry.environment}`);
          resolve(x);
      } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resolve((defaultEnvironment) as any);
        }
      };
      console.log('AppInitService calling xmlhttp.send();');
      xmlhttp.send();
      console.log(`AppInitService (after xmlhttp.send) default sentry env set to ${defaultEnvironment.sentry.environment}`);
      console.log(`AppInitService (after xmlhttp.send) sentry env being set to ${environment.sentry.environment}`);
      Sentry.init({
        dsn: environment.sentry.dsn,
        environment: environment.sentry.environment,
        logLevel: LogLevel.Error,
        ignoreErrors: [
          'Non-Error exception captured'
        ]
      });
      console.log(`AppInitService Sentry.init done, env set to ${environment.sentry.environment}`);
    });
  }

  initDebug() {
    return new Promise<void>((resolve) => {
      resolve();
    });
  }

}


