import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MigrationService } from '../services/migration.service';
import { NotificationService } from '../services/notification.service';
import { EMPTY } from 'rxjs';
import { take, catchError, tap } from 'rxjs/operators';
import { BaseComponentDirective } from '../base/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { PathFinderService } from '../services/path-finder.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss']
})
export class CongratulationsComponent extends BaseComponentDirective implements OnInit {

  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Output() stateReturn = new EventEmitter<string>();

  loading: boolean;
  success: boolean;
  processing: boolean;

  constructor(private migrationService: MigrationService,
    private notifications: NotificationService, private pathFinder: PathFinderService, private loadingService: LoadingService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.notifications.clearToast();
    this.migrationService.completePurchase$(this.quoteStateId).pipe(
      tap(result => {
        if (result) {
          this.vehiclePurchased();
        } else {
          this.success = false;
          this.loading = false;
          this.loadingService.loading.emit(this.loading);
        }
      }),
      catchError(err => {
        let errorMessage = '';
        if (err.message) {
          errorMessage = err.message;
        } else if (err.error) {
          errorMessage = err.error.toString();
        } else {
          errorMessage = err.toString();
        }
        this.notifications.warningToast(errorMessage.substring(0, 600));
        this.vehiclePurchased();
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  vehiclePurchased() {
    this.success = true;
    this.loading = false;
    this.loadingService.loading.emit(this.loading);
    this.group.controls.value.setValue(true);
  }

  restart() {
    if (!this.success && !this.processing) {
      this.processing = true;
      this.pathFinder.rollback$(this.quoteStateId, null, 1).pipe(
        tap(() => {
          this.stateReturn.emit(this.pathFinder.getStartingState().name);
          this.processing = false;
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to restart purchase', err);
          this.processing = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }
}
