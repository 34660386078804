<div class="question-group question-container" [formGroup]="searchForm">
  <div class="question-item inline-question">
    <label class="question-label" [attr.for]="identifier">{{ label }}</label>
    <div class="input-group">
      <input [attr.id]="identifier" [attr.placeholder]="explanation" class="form-control input-text obfuscated-playback" type="number"
        type="text" formControlName="vatNumber" autocomplete="off" autocorrect="off" (keyup.enter)="vatSearch($event)"
        (keyup.backspace)="clearAddress($event)" (keyup.delete)="clearAddress($event)" />
      <span class="placeholder">{{ explanation }}</span>
      <span class="placeholder-line"></span>
    </div>
  </div>
  <div>
    <button class="postcode-button search-button modal-button button_green" type="button" [disabled]="!searchForm.valid"
      [ngClass]="{ 'invalid':!searchForm.valid, 'loading': loading }" (click)="vatSearch(null)">
      <img alt="">
      <span>Search</span>
    </button>
  </div>
</div>
<div *ngIf="hasSearched" [formGroup]="resultsForm">
  <div class="question-item mini-section">
    <label class="question-label" for="company">{{ nameLabel }}</label>
    <div class="input-group no-stretch">
      <input id="company" [attr.placeholder]="namePlaceholder" class="form-control input-text full-width disabled obfuscated-playback" type="text"
        formControlName="company" autocomplete="off" autocorrect="off" />
      <span class="placeholder">{{ namePlaceholder }}</span>
      <span class="placeholder-line address-placeholder-line"></span>
    </div>
  </div>
  <div class="question-item mini-section">
    <label class="question-label" for="address">{{ addressLabel }}</label>
    <div class="input-group no-stretch">
      <input id="address" [attr.placeholder]="addressPlaceholder" class="form-control input-text full-width disabled obfuscated-playback" type="text"
        formControlName="address" autocomplete="off" autocorrect="off" />
      <span class="placeholder">{{ addressPlaceholder }}</span>
      <span class="placeholder-line address-placeholder-line"></span>
    </div>
  </div>
  <div class="question-item mini-section">
    <label class="question-label" for="postcode">{{ postcodeLabel }}</label>
    <div class="input-group no-stretch">
      <input id="postcode" [attr.placeholder]="postcodePlaceholder" class="form-control input-text full-width disabled obfuscated-playback" type="text"
        formControlName="postcode" autocomplete="off" autocorrect="off" />
      <span class="placeholder">{{ postcodePlaceholder }}</span>
      <span class="placeholder-line address-placeholder-line"></span>
    </div>
  </div>
</div>
