import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Fault } from '../models/fault.model';
import { VehicleArea } from '../models/vehicle-area.model';

@Component({
  selector: 'app-damage-area',
  templateUrl: './damage-area.component.html',
  styleUrls: ['../damage.scss']
})
export class DamageAreaComponent implements OnInit {

  @Input() damageMatrix: Array<Fault>;
  @Input() vehicleArea: VehicleArea;
  @Output() faultSelector = new EventEmitter<Fault>();

  favouriteComponents = new Array<Fault>();
  otherComponents = new Array<Fault>();
  favouriteFaults: Array<Fault> = null;
  otherFaults: Array<Fault> = null;
  displayOther = false;
  pickFault = false;
  faultGroup: UntypedFormGroup;

  selectedFault: Fault;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    const map = new Map();
    for (const item of this.damageMatrix) {
      if (!map.has(item.componentId)) {
        map.set(item.componentId, true);
        const component = this.vehicleArea.components.find(x => x.componentId === item.componentId);
        if (component) {
          if (component.isFavourite) {
            this.favouriteComponents.push(item);
          } else {
            this.otherComponents.push(item);
          }
        }
      }
    }
    this.displayOther = this.favouriteComponents.length === 0;

    if (this.vehicleArea.isSingleSelection) {
      this.faultGroup = this.formBuilder.group({ items: this.formBuilder.array([]) });
      const formArray = this.faultGroup.get('items') as UntypedFormArray;

      this.otherComponents.forEach(element => {
        let faultId = null;
        if (this.vehicleArea.faults) {
          const componentFault = this.vehicleArea.faults.find(x => x.componentId === element.componentId);
          if (componentFault) {
            faultId = componentFault.faultId;
          }
        }
        formArray.push(this.formBuilder.group({
          id: element.componentId,
          name: element.componentName,
          value: faultId
        }));
      });
    }
  }

  showMore(): void {
    this.displayOther = true;
  }

  back(): void {
    this.displayOther = false;
  }

  componentSelected(componentId: number): void {
    this.favouriteFaults = this.damageMatrix.filter(f => f.componentId === componentId && f.isFavouriteFault);
    this.otherFaults = this.damageMatrix.filter(f => f.componentId === componentId && !f.isFavouriteFault);
    this.displayOther = this.favouriteFaults.length === 0;
    this.pickFault = true;
    this.selectedFault = null;
  }

  componentUnselected(): void {
    this.favouriteFaults = null;
    this.otherFaults = null;
    this.pickFault = false;
    this.displayOther = this.favouriteComponents.length === 0;
  }

  faultSelected(fault: Fault) {
    this.selectedFault = fault;
    this.faultSelector.emit(fault);
    this.componentUnselected();
  }

  onDeleteFault(fault: Fault) {
    if (this.vehicleArea.isSingleSelection) {
      const formArray = this.faultGroup.get('items') as UntypedFormArray;
      const group = formArray.controls.find(x => (x as UntypedFormGroup).controls.id.value === fault.componentId) as UntypedFormGroup;
      if (group) {
        group.controls.value.setValue(null);
      }
    }
  }

  getSingleSelectionFaultsForComponent(componentId: number): Array<Fault> {
    const faultIds = this.vehicleArea.components.find(x => x.componentId === componentId).singleSelectionFaultIds;
    return this.damageMatrix.filter(f => f.componentId === componentId && faultIds.find(x => x === f.faultId));
  }
}
