import { Component, OnInit } from '@angular/core';
import { VrmInputModalComponent } from '../search-vrm/vrm-input-modal/vrm-input-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UpjarPurchaseDetail } from './models/upjar-purchase-detail.model';
import { PathFinderService } from '../services/path-finder.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { ValuationService } from '../services/valuation.service';
import { Upjar } from './models/upjar.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-upjar',
  templateUrl: './upjar.component.html',
  styleUrls: ['./upjar.component.scss']
})
export class UpjarComponent implements OnInit {

  loading: boolean;
  changed: boolean;
  valid: boolean;
  saving: boolean;
  purchase: UpjarPurchaseDetail;
  hasHpiData: boolean;
  keepers: string;
  motExpiry: string;
  note = new UntypedFormControl('', Validators.required);
  newOffer = new UntypedFormControl('', Validators.required);
  adjustment: number;

  constructor(private modalService: NgbModal,
    private valuationService: ValuationService,
    private pathFinder: PathFinderService,
    private confirmDialogService: ConfirmDialogService,
    private notifications: NotificationService
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.setData(new UpjarPurchaseDetail());
  }

  searchVrm() {
    const modalRef = this.modalService.open(VrmInputModalComponent, {
      keyboard: false,
      centered: true,
      size: 'lg'
    });

    modalRef.result.then(result => {
      this.loading = true;
      this.valuationService.getUpjar$(result).pipe(
        tap(upjar => {
          this.setData(upjar);
          this.loading = false;
        }),
        catchError(() => {
          this.loading = false;
          this.confirmDialogService.appointmentNotFound();
          this.setData(new UpjarPurchaseDetail());
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }, () => {});
  }

  setData(data: UpjarPurchaseDetail) {
    this.purchase = data;
    this.changed = false;
    this.valid = false;
    this.adjustment = null;
    if (this.purchase.valuation) {
      if (this.purchase.vehicleCheckId !== null && this.purchase.vehicleCheckId !== 0) {
        this.hasHpiData = true;
      } else {
        this.hasHpiData = false;
      }
      const wbacKeepers = this.pathFinder.findAnswerValue('PreviousOwners', null, this.purchase.answers, false, true);
      this.keepers = `${wbacKeepers ? wbacKeepers : '?'}/${this.purchase.hpiKeepers}`;
      this.motExpiry = this.pathFinder.findAnswerValue('MOT Date', null, this.purchase.answers, false, false);
      this.newOffer.enable();
      this.note.enable();
      if (this.purchase.upjar) {
        this.purchase.upjar.adjustment = this.purchase.upjar.newOffer - this.purchase.valuation.originalVehiclePriceOffered;
      }
    } else {
      this.hasHpiData = true;
      this.keepers = '';
      this.motExpiry = null;
      this.newOffer.disable();
      this.note.disable();
    }
    this.patchUpjar();
  }

  patchUpjar() {
    if (this.purchase.upjar) {
      this.newOffer.patchValue(this.purchase.upjar.newOffer.toString());
      this.note.patchValue(this.purchase.upjar.notes);
    } else {
      this.newOffer.patchValue(null);
      this.note.patchValue(null);
    }
  }

  get vehicleDescription(): string {
    let description = '';
    if (this.purchase.make) {
        description = `${this.purchase.make} ${this.purchase.model}`;
        if (this.purchase.derivative) {
            description = `${description} - ${this.purchase.derivative}`;
        }
    }
    return description;
}

  get originalVehiclePriceOffered(): number {
    if (this.purchase.valuation) {
      return this.purchase.valuation.originalVehiclePriceOffered;
    } else {
      return null;
    }
  }

  get upjarAdjustment(): number {
    if (this.adjustment) {
      return this.adjustment;
    } else if (this.purchase.upjar) {
      return this.purchase.upjar.adjustment;
    } else {
      return null;
    }
  }

  cancel() {
    this.patchUpjar();
    this.changed = false;
    this.adjustment = null;
  }

  save() {
    if (!this.saving) {
      this.saving = true;
      const newOffer = parseFloat(this.newOffer.value);
      const upjar = new Upjar(this.purchase.appointmentId, newOffer, this.adjustment, this.note.value);
      this.valuationService.saveUpjar$(upjar).pipe(
        tap(() => {
          this.purchase.upjar = upjar;
          this.changed = false;
          this.adjustment = null;
          this.saving = false;
          this.confirmDialogService.upjarSaved();
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to save upjar', err);
          this.saving = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }
  }

  onChange() {
    this.changed = true;
    this.valid = this.newOffer.valid && this.note.valid;
  }

  onChangeOffer() {
    this.onChange();
    if (this.newOffer.valid) {
      const newOfferPrice = parseFloat(this.newOffer.value);
      this.adjustment = newOfferPrice - this.purchase.valuation.originalVehiclePriceOffered;
    }
  }
}
