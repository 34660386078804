import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { ToDoService } from '../../services/to-do.service';
import { VehicleListValue } from '../../walk-in/models/vehicle-list-value.model';

@Component({
  selector: 'app-multiple-derivatives',
  templateUrl: './multiple-derivatives.component.html',
  styleUrls: ['./multiple-derivatives.component.scss'],
})

export class MultipleDerivativesComponent implements OnInit {
  @Input() vehicleDescriptions: Array<VehicleListValue>;
  @Output() selectedDerivativeEmitter = new EventEmitter<VehicleListValue>();

  isScrollbarRequired: boolean;
  isDerivativeSelected = false;
  selectedDerivative: VehicleListValue;
  loading: boolean;

  constructor(private todoService: ToDoService, private notifications: NotificationService) { }

  ngOnInit() {
    this.isScrollbarRequired = this.vehicleDescriptions.length > 2;
  }

  selectDerivative(selectedDerivative: VehicleListValue) {
    this.selectedDerivative = selectedDerivative;
    this.isDerivativeSelected = true;
  }

  proceedWithSelectedDerivative() {
    this.loading = true;
    this.lookupVehicleByGuideBook(this.selectedDerivative);
  }

  lookupVehicleByGuideBook(selectedDerivative: VehicleListValue) {
    this.todoService.selectVehicle$(selectedDerivative.Vrm, selectedDerivative.CapCode, selectedDerivative.Colour, selectedDerivative.RegYear, 0, null).pipe(
      tap(result => {
        this.selectedDerivative = new VehicleListValue(result.vehicleLabelValueLists[0], result.quoteId, result.quoteGuid, '', result.hasSalvage);
      }),
      catchError(err => {
        this.notifications.dangerToast('An error occurred while trying to identify vehicle. Please try again', err);
        this.loading = false;
        this.isDerivativeSelected = true;
        return EMPTY;
      }),
      take(1)
    ).subscribe(() => {
      this.loading = false;
      this.selectedDerivativeEmitter.emit(this.selectedDerivative);
    });
  }
}
