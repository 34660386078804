export class Note {
    created: Date;
    username: string;
    text: string;
    new: boolean;

    constructor(username: string, text: string) {
        this.created = new Date();
        this.username = username;
        this.text = text;
        this.new = true;
    }
}
