<div class="dialog">
  <div class="modal-header">
    <img class="modal-close" (click)="closeWindow(closeAllWindows)" alt="Close modal" />
  </div>
  <div class="modal-body">
    <img class="modal-body__image mt-4" src="{{ displayImageUrl }}" alt="Confirm image" />
    <div class="modal-body__message mt-4 mb-4">{{ message }}</div>
    <div>
      <button class="modal-body__button-confirm button_red" [ngClass]="{'modal-body__small-button': isYesNo}" *ngIf="isYesNo" (click)="activeModal.dismiss()">No</button>
      <span class="modal-body__button-spacer" *ngIf="isYesNo"></span>
      <button class="modal-body__button-confirm button_green" [ngClass]="{'modal-body__small-button': isYesNo}" (click)="activeModal.close()">{{ isYesNo ? 'Yes' : 'Ok' }}</button>
    </div>
  </div>
</div>
