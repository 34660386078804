export class Stock {
  quoteStateId: number;
  vrm: string;
  derivative: string;
  colour: string;
  here: boolean;
  make: string;
  model: string;
  stockCheck: string;
  leftBranch: boolean;
  purchaser: string;
  stockDate: Date;
  username: string;
  mechanicalConditionId: number;
  motDate?: string;
  nonRunnerWhenPurchased: boolean;
  previouslyFlaggedAsNonRunnerPostPurchase: boolean;
  markAsNonRunner: boolean;
}
