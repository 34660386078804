export class DocumentFileInfo {
    parameterName: string;
    stampCode: string;
    filename: string;
    isMainImage: boolean;
    tag: string;
    threshold: number;
    recognitionResult: number;

    constructor(parameterName: string, stampCode: string, filename: string, tag: string, threshold: number, recognitionResult: number) {
        this.parameterName = parameterName;
        this.stampCode = stampCode;
        this.filename = filename;
        this.isMainImage = stampCode === 'A'; // Only used to tell BCA which photo is the front of the vehicle
        this.tag = tag;
        this.threshold = threshold;
        this.recognitionResult = recognitionResult;
    }
}
