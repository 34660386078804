import { Appointment } from './appointment.model';
import { AppointmentsHelper } from '../helpers/appointments-helper';
import { AppointmentOutcomeTypes } from '../../enums/appointment-outcome-types';
import { BuyerSummary } from './buyer-summary.model';
import { DayTypes } from '../../enums/day-types';
import { Site } from './site.model';
import { SiteDay } from './site-day.model';
import moment from 'moment';

export class DayDetails {
  dayId: number;
  dayNumber: string;
  dayWeekName: string;
  siteName: string;
  abbreviatedSiteName: string;
  isDayOff: boolean;
  dayType: DayTypes;
  dailyAppointments: Array<Appointment>;
  appointmentLength: number;
  lunchTopPosition: number;
  lunchHeight: number;
  lunchWidth: number;
  lunchLeft: number;
  dayOffText = 'DAY OFF';
  lunchStatusStyle = 'lunch';
  scheduleUnavailableText = 'Schedule Unavailable';

  constructor(dayId: number, dayNumber: string, dayWeekName: string, site: Site, dayType: DayTypes, buyer: BuyerSummary, appointments: Array<Appointment>,
    calendarHeight: number, numberOfHours: number, dayDate: number) {
    this.dayId = dayId;
    this.dayNumber = dayNumber;
    this.dayWeekName = dayWeekName;
    this.dayType = dayType;
    this.appointmentLength = buyer.durationInMinutes;
    const calendarPixelPerMinuteCount = calendarHeight / (numberOfHours * 60);
    const browserFontSize = Number(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace('px', ''));
    if (site) {
      this.siteName = site.name;
      this.abbreviatedSiteName = site.abbreviatedSiteName;
      const siteDay = site.siteDays.find(x => x.dayId === dayId);
      this.lunchTopPosition = AppointmentsHelper.setAppointmentStartPosition(moment(siteDay.lunchStartTime, 'HH:mm:ss'), calendarHeight, numberOfHours) / browserFontSize;
      // divide by browser font size to get value in rem unit
      this.lunchHeight = AppointmentsHelper.setAppointmentHeight(siteDay.lunchDurationInMinutes, calendarPixelPerMinuteCount) / browserFontSize;
      this.isDayOff = false;
      if (appointments) {
        this.dailyAppointments = appointments.filter(app => app.status !== AppointmentOutcomeTypes.PurchaseCancelled);
        this.dailyAppointments.forEach(dailyApp => {
          dailyApp.status = dailyApp.status === '' ? AppointmentOutcomeTypes.PreAppointmentConfirmed : dailyApp.status;
          dailyApp.statusStyle = AppointmentsHelper.setStatusStyle(dailyApp.status, dailyApp.isWalkIn);
          dailyApp.timeStyle = AppointmentsHelper.setTimeStyle(dailyApp.status, dailyApp.isWalkIn);
          dailyApp.appointmentTopPosition = AppointmentsHelper.setAppointmentStartPosition(moment(dailyApp.startTime, 'HH:mm:ss'), calendarHeight, numberOfHours) / browserFontSize;
          dailyApp.appointmentHeight = AppointmentsHelper.setAppointmentHeight(this.appointmentLength, calendarPixelPerMinuteCount) / browserFontSize;
        });

        this.createLunchBreakAppointment(siteDay);
        AppointmentsHelper.findOverlappingAppointments(this.dailyAppointments);
        this.dailyAppointments.forEach(dailyApp => {
          if (dailyApp.statusStyle === this.lunchStatusStyle) {
            this.lunchWidth = AppointmentsHelper.setAppointmentWidth(dailyApp.appOverlaps);
            this.lunchLeft = AppointmentsHelper.setAppointmentLeft(dailyApp.appSequence, dailyApp.appOverlaps);
            this.dailyAppointments = this.dailyAppointments.filter(x => x.statusStyle !== this.lunchStatusStyle);
          }
          dailyApp.appointmentWidth = AppointmentsHelper.setAppointmentWidth(dailyApp.appOverlaps);
          dailyApp.appointmentLeft = AppointmentsHelper.setAppointmentLeft(dailyApp.appSequence, dailyApp.appOverlaps);
        });
      }
    } else {
      const sevenDayDateWindow = new Date();
      sevenDayDateWindow.setDate(sevenDayDateWindow.getDate() + 6);
      if (new Date(dayDate) > sevenDayDateWindow) {
        this.siteName = this.scheduleUnavailableText;
        this.abbreviatedSiteName = this.scheduleUnavailableText;
      } else {
        this.siteName = this.dayOffText;
        this.abbreviatedSiteName = this.dayOffText;
      }
      this.isDayOff = true;
      this.dayType = dayType === DayTypes.Current ? DayTypes.CurrentAndOff : DayTypes.Off;
    }
  }

  createLunchBreakAppointment(siteDay: SiteDay) {
    const lunchBreak = new Appointment();
    lunchBreak.startTime = moment(siteDay.lunchStartTime, 'HH:mm:ss');
    lunchBreak.endTime = moment(lunchBreak.startTime, 'HH:mm:ss').add(siteDay.lunchDurationInMinutes, 'minutes');
    lunchBreak.statusStyle = this.lunchStatusStyle;
    this.dailyAppointments.push(lunchBreak);
  }
}
