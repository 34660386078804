export class SiteNameAbbreviationHelper {
  static readonly abbreviationDictionary: Map<string, string> =
    new Map([
      ['Road', 'Rd.'],
      ['Retail Park', 'RP'],
      ['Street', 'St.'],
      ['Business Park', 'BP'],
      ['Train Station', 'TS'],
      ['Stadium', 'Stad'],
      ['WBAC Trade Collection', 'TC'],
      ['Shopping Centre', 'SC'],
      ['Football Club', 'FC'],
      ['Rugby Club', 'RC'],
      ['Golf Club', 'GC'],
      ['Industrial Estate', 'IE'],
      ['Shopping Park', 'SP'],
      ['Leisure Park', 'LP'],
      ['Metro Centre', 'MC'],
      ['Office Park', 'OP'],
      ['Leisure Centre', 'LC'],
      ['Business Centre', 'BC']
    ]);

  static abbreviateSiteName(siteName: string): string {
    for (const [key, value] of this.abbreviationDictionary) {
      const isStringMatched = siteName.includes(key);
      let abbreviatedSiteName: string;
      if (isStringMatched && key === 'WBAC Trade Collection') {
        abbreviatedSiteName = siteName.replace(key, '').trim();
        abbreviatedSiteName += (' ' + value);
        return abbreviatedSiteName;
      } else if (isStringMatched) {
        abbreviatedSiteName = siteName.replace(key, value);
        return abbreviatedSiteName;
      }
    }
    return siteName;
  }
}
