import { ElementRef } from '@angular/core';

export class CalendarHelper {
  static getCalendarTimePosition(currentHour: number, currentMinutes: number, currentSeconds: number, dayStartHour: number, dayHours: number[], calendarHeight: number): number {
    const totalCalendarSeconds = dayHours.length * 3600;
    const calendarPixelsPerSecond = calendarHeight / totalCalendarSeconds;
    const currentTimeInSeconds = (currentHour - dayStartHour) * 3600 + currentMinutes * 60 + currentSeconds;
    return currentTimeInSeconds * calendarPixelsPerSecond;
  }

  static setDefaultScrollPostion(defaultDisplayStartHour: number, dayStartHour: number, dayHours: number[], calendarHeight) {
    const defaultPagePosition = this.getCalendarTimePosition(defaultDisplayStartHour, 0, 0, dayStartHour, dayHours, calendarHeight);
    document.documentElement.scrollTo(0, defaultPagePosition);
  }

  static getCalendarHeight(calendarElement: ElementRef): number {
    return calendarElement.nativeElement.offsetHeight;
  }
}
