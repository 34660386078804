import { Component, Input } from '@angular/core';
import { HpiCondition } from '../models/hpi-condition.model';

@Component({
  selector: 'app-inspection-records',
  templateUrl: './inspection-records.component.html',
  styleUrls: ['./inspection-records.component.scss']
})
export class InspectionRecordsComponent {

  @Input() records: Array<HpiCondition>;

}
