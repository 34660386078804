<div class="offer-display-container">
  <div>
    <div class="offer-display">
      Your offer<span *ngIf="valuation.isVatRegistered">&nbsp;inc. VAT</span>:
    </div>
    <div *ngIf="showOriginalOffer" class="offer-display offer-pre-flex">
      {{ priceOfferedBeforeFlex | currency : 'GBP' }}
    </div>
    <div class="offer-display offer-value">
      {{ priceOffered | currency : 'GBP' }}
    </div>
  </div>

  <div *ngIf="valuation.isVatRegistered">
    <div class="offer-display">
      Your offer exc. VAT:
    </div>
    <div *ngIf="showOriginalOffer" class="offer-display offer-pre-flex">
      {{ noVatPriceOfferedBeforeFlex | currency : 'GBP' }}
    </div>
    <div class="offer-display offer-value">
      {{ noVatPriceOffered | currency : 'GBP' }}
    </div>
  </div>

  <div class="offer-display offer-warning-text">
    Admin fee of {{ valuation.adminFee | currency : 'GBP' }}
    <span *ngIf="valuation.isVatRegistered">&nbsp;({{ noVatAdminFee | currency : 'GBP' }}+VAT)</span>
    &nbsp;applied and not including any outstanding finance
  </div>
</div>