export enum AuthorizationTypes {
  HpiMismatch = 1,
  FlexBand1 = 2,
  FlexBand2 = 3,
  SuperFlex = 4,
  RecentKeeperChange = 6,
  TheftMarkerOverride = 8,
  V5VrmMismatchOverride = 9,
  DvsaClericalErrorOverride = 10,
  HistoryClearOverride = 14,
  DvsaMileageDiscrepancyOverride = 16,
  PreviousOwnerOverride = 22,
  VehicleConditionVerification = 23,
  VehicleDetailsChanged = 24,
  DateOfFirstRegistrationOverride = 25,
  ServiceHistoryOverride = 26,
  ImportStatusOverride = 28,
  IndependentDealer = 31,
  D2Authorised = 32,
  AdditionalVehicleCheckAuthorised = 33,
  V5QualityCheck = 35,
  AdditionalEngineChecks = 36,
  InsuranceWriteOffOverride = 38,
  OptionalExtra=39,
}
