import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentDetailsComponent } from '../../appointment-details/appointment-details.component';
import { Appointment } from '../models/appointment.model';
import { AppointmentOutcomeTypes } from '../../enums/appointment-outcome-types';
import { NavigationHistoryService } from '../../core/navigation-history.service';
import { PurchaseService } from '../../services/purchase.service';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { EMPTY } from 'rxjs';
import { AuthService } from '../../core/auth-service';

@Component({
  selector: 'app-day-view-appointments',
  templateUrl: './day-view-appointments.component.html',
  styleUrls: ['./day-view-appointments.component.scss']
})

export class DayViewAppointmentsComponent implements OnInit {
  @Input() dailyAppointments: Array<Appointment>;
  @Input() isWeekView: boolean;
  @Input() lunchTopPosition: number;
  @Input() lunchHeight: number;
  @Input() lunchWidth: number;
  @Input() lunchSequence: number;
  @Input() isBranchView: boolean;
  loading: boolean;

  constructor(private modalService: NgbModal, private navigationHistory: NavigationHistoryService,
    private purchaseService: PurchaseService, private notifications: NotificationService, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.navigationHistory.getId()) {
      this.showAppointment(this.navigationHistory.getId());
    }
  }

  showAppointment(appointmentId: number) {
    this.loading = true;
    this.purchaseService
      .getAppointmentDetail$(appointmentId)
      .pipe(
        tap((result) => {
          if (result) {
            const modalRef = this.modalService.open(AppointmentDetailsComponent, {
              keyboard: false,
              backdrop: 'static',
              centered: true,
              size: 'xl'
            });

            modalRef.componentInstance.appointmentId = appointmentId;
            const app = this.dailyAppointments.find(appt => appt.appointmentId === appointmentId);
            let cannotCancel = true;
            let appDuration = 30;
            if (app) {
              const appStatus = app.status === AppointmentOutcomeTypes.PurchaseStarted;
              cannotCancel = (new Date(app.appointmentTime).getTime() < new Date().getTime() || appStatus);
              appDuration = app.endTime.diff(app.startTime, 'minutes', true);
            }
            modalRef.componentInstance.cannotCancel = cannotCancel;
            modalRef.componentInstance.appointmentDuration = appDuration;
            modalRef.result.then(() => {this.loading = false; }, () => {this.loading = false; });
          }
        }),
        catchError((err) => {
          this.notifications.dangerToast(
            'Unable to view appointment details, this appointment may have been rescheduled',
            err
          );
          this.loading = false;
          return EMPTY;
        }),
        take(1)
      ).subscribe();
  }
}
