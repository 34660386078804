<div id="side-menu" [hidden]="isHidden" [ngClass]="isMenuOpen ? 'menu-container': 'collapsed'"
  class=" d-none d-lg-block ">
  <div class="fixed-top" [hidden]="isHidden"
    [ngClass]="isMenuOpen ? 'menu-container menu-container__box-shadow' : 'menu-container-collapsed'">
    <img [hidden]="!isMenuOpen" class="main-logo mt-4 ms-3" (click)="refresh()" src="../../assets/images/logo-lg.png"
      alt="Refresh" />
    <span class="menu-toggle" [ngClass]="isMenuOpen ? 'menu-toggle-expanded' : 'menu-toggle-collapsed'"
      (click)="toggleMenu()">
      <img class="burger-icon mt-4" src="../../assets/icons/menu/burger.png" alt="Burger menu">
    </span>

    <div class="menu-links user-details mt-3" [ngClass]="{ 'menu-disabled': stockCheckRequired }">
      <a (click)="refresh()">
        <img class="home-icon" src="../../assets/icons/menu/house.png" alt="Home" />
        <span [hidden]="!isMenuOpen">{{ userName }}</span>
      </a>
    </div>

    <div class="menu-links">
      <a (click)="clearIds()"
        [ngClass]="{ 'selected': currentPage === ''  && !isNotInBranch, 'not-in-branch': isNotInBranch,'semilight': currentPage !== '', 'indent': isMenuOpen, 'menu-disabled': stockCheckRequired }"
        [routerLink]="['']" routerLinkActive="router-link-active">
        <span class="menu-icon diary-icon"
          [ngClass]="{ 'selected': currentPage === '' && !isNotInBranch, 'not-in-branch diary-icon-red': isNotInBranch }"></span>
        <span [hidden]="!isMenuOpen">Diary</span>
      </a>
      <a (click)="clearIds()"
        [ngClass]="{ selected: currentPage === '/tasks', semilight: currentPage !== '/tasks', 'menu-disabled': stockCheckRequired }"
        [routerLink]="['/tasks/']" routerLinkActive="router-link-active">
        <span class="menu-icon" [ngClass]="{ 'selected':currentPage === '/tasks' }"
          style="background-image: url('../../assets/icons/menu/Task.svg');"></span>
        <span [hidden]="!isMenuOpen" class="tasks-min-width">Tasks</span>
        <div [hidden]="userTaskCount === 0" class="counter task">{{ userTaskCount }}</div>
        <div [hidden]="authTaskCount === 0" class="counter auth">{{ authTaskCount }}</div>
      </a>
      <a (click)="clearIds()" [ngClass]="{
          selected: currentPage === '/review',
          semilight: currentPage !== '/review',
          'menu-disabled': stockCheckRequired
        }" [hidden]="!canReviewPurchases" [routerLink]="['/review/']" routerLinkActive="router-link-active">
        <span class="menu-icon" [ngClass]="{ selected: currentPage === '/review' }"
          style="background-image: url('../../assets/icons/menu/CarHand.svg')"></span>
        <span [hidden]="!isMenuOpen">Purchases</span>
      </a>
      <a (click)="clearIds()" [ngClass]="{'selected': currentPage === '/stock',
        'semilight': currentPage !== '/stock',
        'menu-disabled': isNotInBranch
        }" [routerLink]="['/stock/']" routerLinkActive="router-link-active">
        <span class="menu-icon" [ngClass]="{ selected: currentPage === '/stock'}" style="
            background-image: url('../../assets/icons/menu/stock_check.png');
          "></span>
        <span [hidden]="!isMenuOpen">Stock</span>
      </a>
      <a (click)="clearIds()" [ngClass]="{'selected': currentPage === '/upjar', 'semilight': currentPage !== '/upjar'}"
        [hidden]="!canViewUpjar" [routerLink]="['/upjar/']" routerLinkActive="router-link-active">
        <span class="menu-icon" [ngClass]="{ selected: currentPage === '/upjar'}"
          style="background-image: url('../../assets/icons/menu/upjar.png');"></span>
        <span [hidden]="!isMenuOpen">Upjar</span>
      </a>
    </div>
  </div>
  <div [hidden]="isHidden" class="menu-links signout" [ngClass]="{ 'ms-5': isMenuOpen, 'signout-open': isMenuOpen }">
    <a class="signout-group" >
      <div (click)="logout()">
        <img class="signout-icon" src="../../assets/icons/menu/signout.png" alt="Sign out" />
        <span [hidden]="!isMenuOpen">Sign Out</span>
      </div>
      <div class="text-center version-text">V {{version}}</div>
    </a>
  </div>
</div>