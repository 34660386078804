import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Pipe({name: 'blankDate'})
export class BlankDatePipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  transform(value: any, args?: any): any {
    const blankDate = moment('1900-01-01T00:00:00Z');
    const momentValue = moment(value);
    if (momentValue.isSame(blankDate)) {
      return '';
    } else {
      return super.transform(momentValue.toDate(), 'mediumDate');
    }
  }
}
