import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { D2Content } from './models/d2Content.model';
import { Signature } from '../invoice/models/signature.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../services/notification.service';
import { D2Service } from '../services/d2.service';
import { PathFinderService } from '../services/path-finder.service';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { SignatureInputComponent } from '../signature-input/signature-input.component';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-d2-form',
  templateUrl: './d2-form.component.html',
  styleUrls: ['./d2-form.component.scss']
})
export class D2FormComponent implements OnInit {
  @Input() quoteStateId: number;
  @Input() group: UntypedFormGroup;
  @Input() set canLoadContent(canLoad: boolean) {
    this.isDataReady = canLoad ?? true;
    this.retrieveD2Data();
  }

  loading: boolean;
  today: Date;
  isDataReady: boolean;
  isInitialized: boolean;
  purchaseDetails: D2Content;
  customerSignature: Signature;
  buyerSignature: Signature;

  constructor(private modalService: NgbModal,
    private notifications: NotificationService,
    private d2Service: D2Service,
    private pathFinder: PathFinderService,
    private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.today = new Date();
    this.loading = true;
    this.loadingService.loading.emit(this.loading);
    this.isInitialized = true;
    this.retrieveD2Data();


    this.pathFinder.registerTransitionEvent((onComplete => {
      this.d2Service.generateD2$(this.quoteStateId, this.customerSignature).pipe(
        tap(() => {
          onComplete(true);
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to generate d2 form pdf', err);
          onComplete(false);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    }));

  }

  retrieveD2Data() {
    if (this.isInitialized && this.isDataReady) {
    this.d2Service.getD2Details$(this.quoteStateId).pipe(
      tap(result => {
        this.purchaseDetails = result;
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
      }),
      catchError(err => {
        this.loading = false;
        this.loadingService.loading.emit(this.loading);
        this.notifications.dangerToast('Failed to retrieve d2 details', err);
        return EMPTY;
      }),
      take(1)
     ).subscribe();
    }
  }

  signatureAdded() {
    if (this.customerSignature) {
      this.group.controls.value.setValue(true);
    }
  }

  customerSign() {
    this.sign(false, (result) => {
      this.customerSignature = result;
      this.signatureAdded();
    });
  }

  sign(isWbacSignature, callback) {
    const modalRef = this.modalService.open(SignatureInputComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'xl',
      windowClass: 'modal-dialog-centered'
    });
    modalRef.componentInstance.isWbacSignature = isWbacSignature;
    modalRef.componentInstance.quoteStateId = this.quoteStateId;
    modalRef.result.then(result => {
      callback(result);
    });
  }

}
