import { Component } from '@angular/core';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  errorMessage: string;
  showDetails: boolean;
  constructor(@Inject(Router) private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.previousNavigation) {
      const state = navigation.extras.state;
      if (state) {
        this.errorMessage = state.message;
      this.showDetails = state.showDetails;
      } else {
        this.errorMessage = 'An error occurred, but no additional details are available.';
      }
    } else {
      // Probably a refresh, send them back to the diary
      this.router.navigate(['/']);
    }
  }
}
