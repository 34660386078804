import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PostcodeLookupComponent } from '../postcode-lookup.component';

@Injectable({ providedIn: 'root' })
export class PostcodeLookupService {
  postcodeModalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  openPostcodeLookupModal() {
    this.postcodeModalRef = this.modalService.open(PostcodeLookupComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
  }

  isPostcodeModalClosed$(): Observable<boolean> {
    return this.postcodeModalRef.componentInstance.closeModal;
  }

  performNewPostcodeSearch$(): Observable<string> {
    return this.postcodeModalRef.componentInstance.newPostcode;
  }

  setNewPostcodeSearchCompleted() {
    if (this.postcodeModalRef) {
      this.postcodeModalRef.componentInstance.searchCompleted = true;
      this.postcodeModalRef.close();
    }
  }
}
