import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BankVerificationService } from '../../services/bank-verification.service';

@Component({
  selector: 'app-bank-verification-service',
  templateUrl: './bank-verification-service.component.html',
  styleUrls: ['./bank-verification-service.component.scss']
})
export class BankVerificationServiceComponent implements OnInit {
  @Input() questionForm: FormGroup;
  @Input() group: FormGroup;
  isBankVerificationApiAvailable = true;
  loading: boolean;

  constructor(private bankService: BankVerificationService) { }

  ngOnInit(): void {
    this.bankService.isBankVerificationServiceAvailable.subscribe((isBankServiceAvailable) => {
      this.isBankVerificationApiAvailable = isBankServiceAvailable;
    });
  }

}
