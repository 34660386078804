<div class="form-group" [formGroup]="altGroup">
  <label class="question-label" [attr.for]="identifier" *ngIf="label">{{ label }}</label>
  <div class="input-group">
    <input type="text" class="form-control  obfuscated-playback" [ngClass]="{ 'half-width': isInline && !financePage, 'full-width': !isInline && !financePage }" formControlName="list" autocomplete="off" autocorrect="off"
      [attr.placeholder]="explanation" [attr.list]="datalistId"  (change)="valueChanged($event)"/>
    <span class="placeholder">{{ explanation }}</span>
    <span class="placeholder-line"></span>
    <datalist [attr.id]="datalistId">
      <option *ngFor="let option of options" id="{{option.key}}">{{ option.value }}</option>
    </datalist>
  </div>
</div>
