<div class="page-content">
  <div class="heading-text">Before we proceed, check the customer has the following:</div>
  <div *ngFor="let doc of requiredDocuments" class="row required-document">
    <div class="col-3 doc-label">
      {{ doc.documentName }}
    </div>
    <div class="col-3 multiple-choice-btn-container">
      <a class="multiple-choice-btn" [ngClass]="doc.present === true ? 'selected-option' : doc.editable ? '' : 'disabled'" (click)="toggle(doc, true)">Yes</a>
      <a class="multiple-choice-btn last" [ngClass]="doc.present === false ? 'selected-option' : doc.editable ? '' : 'disabled'" (click)="toggle(doc, false)">No</a>
    </div>
    <div class="col-6"></div>
  </div>
  <div *ngIf="purchaseForbidden" class="no-purchase-message">
    Due to the fact the customer does not have all the required documentation, you will not be able to purchase this vehicle.
    <br>
    However, you can re-book the valuation at a convenient date and time for the customer. 
    <br><br>
    Please could you also advise the customer again that they would need everything on this list when they return.
  </div>
  <div>
    <button *ngIf="purchaseForbidden" class="btn modal-button button_green" [ngClass]="{'loading': isRescheduling, 'button_grey': !allowRebook}" (click)="reschedule()">
      Re-Book Valuation
    </button>
  </div>
</div>
  