<div *ngIf="!hasLookupError">
    <div class="modal-header">
      <h4 *ngIf="!isAlt" class="modal-title">No HPI Data</h4>
      <h4 *ngIf="isAlt" class="modal-title">Alt VRM</h4>
      <img class="modal-close" (click)="dismissModal()" alt="Dismiss modal">
    </div>
    <div class="modal-body quick-quote-width">
      <div class="container">
        <div class="row">
          <div class="col-12 replace-padding">
            <app-search-vrm [displayVrm]="vrm" [altVrm]="isAlt" [quoteStateId]="quoteStateId"
                (vrm)="setVrm($event)" (hasLookupError)="setHasLookupError($event)"
                (hasMissingVrmRelationship)="setHasMissingVrmRelationship($event)">
            </app-search-vrm>
          </div>
        </div>
      </div>
    </div>
</div>

<app-lookup-error *ngIf="hasLookupError" [vrm]="vrm" [missingVrmRelationship]="hasMissingVrmRelationship" (trySearchAgain)="tryAgain($event)"></app-lookup-error>